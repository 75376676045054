<script setup lang="ts">
defineProps({
  index: Number,
  dataItem: [Object, String, Number],
});

const emit = defineEmits(["click"]);
</script>

<template>
  <li class="k-item" @click="emit('click')">
    <span>
      {{ dataItem?.label }} <span v-if="dataItem?.parentLabel">({{ dataItem?.parentLabel }})</span>
    </span>
  </li>
</template>
