<script setup lang="ts">
import { storeToRefs } from "pinia";
import appStore from "~/store";

const { project, dataForms } = storeToRefs(appStore.projectStore);
</script>

<template>
  <div class="bg-gray-50 dark:bg-gray-900 p-10" style="height: calc(100vh - 11rem)">
    <ul role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      <div v-for="dataForm in dataForms" :key="dataForm.ref" class="overflow-hidden rounded-lg bg-white dark:bg-gray-800 shadow">
        <div class="p-5">
          <div class="flex items-center">
            <div class="shrink-0" />
            <div class="ml-5 w-0 flex-1">
              <dl>
                <dt class="truncate text-sm font-medium text-gray-500">
                  {{ dataForm.name }}
                </dt>
                <dd />
              </dl>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 dark:bg-gray-900 px-5 py-3">
          <div class="text-sm">
            <RouterLink :to="`dataForms/${dataForm.slug}/designer`" class="font-medium text-cyan-700 hover:text-cyan-900">
              Design
            </RouterLink>
            <RouterLink :to="`dataForms/${dataForm.slug}/viewer`" class="font-medium text-cyan-700 hover:text-cyan-900">
              Use
            </RouterLink>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<style scoped>

</style>
