<script lang="ts" setup>
import { storeToRefs } from "pinia";
import appStore from "~/store";

const { workspace } = storeToRefs(appStore.workspaceStore);
</script>

<template>
  <div style="width: 100%">
    <div class="flex items-center justify-center" style="margin-top: 185px">
      <MaterialDesignIcon class="text-gray-400" name="application-edit-outline" size="64" />
    </div>
    <div class="flex grid-cols-1 justify-center">
      <div v-if="workspace && workspace.workspaceStorage.overview" class="col-span-1 text-center">
        <KodexaMarkdown :content="workspace.workspaceStorage.overview" />
      </div>
      <div v-else>
        <div class="col-span-1 text-center">
          <h2 class="text-base font-semibold leading-6 text-gray-900">
            Welcome to your Workspace
          </h2>
          <p class="px-50 mt-1 text-sm text-gray-500">
            Workspaces allow you to work with documents, data views and assistants, the items on the navigation
            to
            the left are the place to start.
          </p>
        </div>
      </div>
    </div>
    <div class="flex grid-cols-1 justify-center">
      <div class="col-span-1 justify-center">
        <KodexaArticle class="mb-4 mt-3" article-id="9037361" text="Learn more about workspaces" :slide="false" />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
