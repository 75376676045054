<script setup lang="ts">
import type { ProjectTemplate, SlugBasedMetadata } from "~/model";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import NewProject from "~/components/dashboard/widgets/projects/new-project.vue";
import ProjectMarketplace from "~/components/dashboard/widgets/projects/project-marketplace.vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const currentStep = ref(0);
const newResource = ref<SlugBasedMetadata>({} as SlugBasedMetadata);
const selectedTemplate = ref<SlugBasedMetadata | undefined>();

// Check if the project has only one template, by default it is set to false
const isSingleTemplate = ref(false);

watch(() => props.modelValue, (value) => {
  if (value) {
    useIntercom().trackEvent("open-project-marketplace");
    currentStep.value = 0;
    selectedTemplate.value = undefined;
    newResource.value = {} as SlugBasedMetadata;
  }
}, {
  immediate: true,
});

function templateSelected(template: ProjectTemplate, singleTemplate: boolean) {
  selectedTemplate.value = template;
  isSingleTemplate.value = singleTemplate;
}

function close() {
  emit("update:modelValue", false);
}

function cancelNewProject() {
  selectedTemplate.value = undefined;
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4  text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 80%; height: 700px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all"
            >
              <ProjectMarketplace v-if="!selectedTemplate" @template-selected="templateSelected" @closed="close()" />
              <NewProject v-if="selectedTemplate" :project-template="selectedTemplate" @cancel="cancelNewProject" @closed="close()" />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
