<script lang="ts" setup>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import appStore from "~/store";
import { updateHandler } from "~/utils/error-handler";

const emit = defineEmits(["redirect-home-page"]);

const { user } = storeToRefs(appStore.userStore);

const firstName = ref("");
const lastName = ref("");
const loading = ref(false);
const profileOpen = ref(false);

watch(user, (newUser) => {
  if (newUser) {
    firstName.value = newUser.firstName;
    lastName.value = newUser.lastName;
  }
}, { immediate: true });

watch([firstName, lastName], ([firstNameVal, lastNameVal]) => {
  if (firstNameVal && lastNameVal) {
    emit("redirect-home-page");
  } else {
    profileOpen.value = true;
  }
}, { immediate: true });

const formValid = computed(() => {
  return firstName.value && lastName.value;
});

async function save() {
  loading.value = true;
  const updatedUser = { ...user.value, firstName: firstName.value, lastName: lastName.value };
  await updateHandler(appStore.userStore.updateUser(updatedUser), "User updated successfully");
  loading.value = false;
  profileOpen.value = false;
  emit("redirect-home-page");
}
</script>

<template>
  <TransitionRoot as="template" :show="profileOpen">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50/75 dark:bg-gray-900/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 40%; height: auto"
              class="relative mt-8 size-full overflow-hidden overflow-y-scroll rounded-lg bg-white dark:bg-gray-800 text-left shadow-lg transition-all sm:mt-0 sm:size-3/4"
            >
              <KodexaHeader class="ml-4" title="Welcome to Kodexa" sub-title="To finish the setup, lets capture some additional information" />
              <div class="px-4 py-4 text-sm text-gray-700">
                <p>To help customize your experience, we would like to capture some basic information about you.</p>
                <p>We don't share this information with anyone and you can change it at any time.</p>
              </div>
              <div class="px-4 py-4">
                <KodexaTextInput v-model="firstName" class="py-1" name="firstName" type="text" label="First name" />
                <KodexaTextInput v-model="lastName" class="py-1" name="lastName" type="text" label="Last name" />
              </div>

              <div class="mt-2 flex justify-end p-4">
                <KodexaButton icon="chevron-right" :disabled="!formValid || loading" class="px-4 py-2" @click="save">
                  Continue
                </KodexaButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
