import type { StoreDefinition } from "pinia";
import type { ILogObj } from "tslog";
import type { ComputedRef, Ref } from "vue";
import type { LoadingEvent } from "./usePlatform";
import type { ContentNode } from "~/components/document/document";
import type {
  Assistant,
  AssistantConnection,
  BulkCopy,
  BulkDelete,
  BulkLock,
  Card,
  ChannelParticipant,
  Dashboard,
  DashboardWidget,
  DataForm,
  DocumentFamily,
  Execution,
  FlowViewPort,
  Guidance,
  GuidanceSet,
  Label,
  ModelContentMetadata,
  Option,
  Project,
  ProjectDataFlow,
  ProjectMemory,
  ProjectTemplate,
  ReprocessRequest,
  SlugBasedMetadata,
  Store,
  Taxon,
  Taxonomy,
  User,
  Workspace,
} from "~/model";
import type { SelectionContext } from "~/store/useDocumentView";
import type { TagInstance } from "~/store/useWorkspace";
import confetti from "canvas-confetti";
import Fuse from "fuse.js";
import { notify } from "notiwind";
import { defineStore } from "pinia";
import { Logger } from "tslog";
import { v4 as uuidv4 } from "uuid";
import { debounce } from "vue-debounce";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import {
  createAssistantConnection,
  deleteAssistantConnection,
  getAssistantConnection,
  listAssistantConnections,
  updateAssistantConnection,
} from "~/api/assistant-connection/assistant-connection";
import { createAssistant, deleteAssistant } from "~/api/assistants/assistants";
import { createDashboard, deleteDashboard, updateDashboard } from "~/api/dashboards/dashboards";
import { createDataForm, deleteDataForm, updateDataForm } from "~/api/data-forms/data-forms";
import { cancelExecution, listExecutions } from "~/api/executions/executions";
import { getGuidance, updateGuidance } from "~/api/guidance/guidance";
import { getLabels } from "~/api/organizations/organizations";
import {
  addDashboardToProject,
  addDataFormToProject,
  addStoreToProject,
  addTaxonomyToProject,
  createProject,
  deleteProject,
  getAssistants,
  getDashboards,
  getDataForms,
  getDataStores,
  getDocumentStores,
  getModelStores,
  getProject,
  getProjectDataFlow,
  getProjectGuidance,
  getProjectMemory,
  getTaxonomies,
  updateProject,
  updateProjectAssistant,
  updateProjectDataFlow,
  updateProjectMemory,
  updateResources,
} from "~/api/projects/projects";
import {
  bulkCopyWithVersion,
  bulkSetLockWithVersion,
  createStore,
  deleteContentObjectsWithVersion,
  deleteStore,
  getContentObjectContent,
  getStoreMetadata,
  reprocessWithVersion,
  updateStore,
} from "~/api/stores/stores";
import { createTaxonomy, deleteTaxonomy, updateTaxonomy } from "~/api/taxonomies/taxonomies";
import { getWorkspace, listWorkspaces, updateWorkspace } from "~/api/workspaces/workspaces";
import KodexaSendAssistantEventPopup from "~/components/assistants/kodexa-send-assistant-event-popup.vue";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import KodexaWorkspaceUpdateConfirm from "~/components/workspace/kodexa-workspace-update-confirm.vue";
import router from "~/router/router";
import appStore from "~/store/index";
import { createDocumentViewerStore } from "~/store/useDocumentView";
import { randomColor } from "~/utils/colors";
import { updateHandler } from "~/utils/error-handler";
import { RefHelper } from "~/utils/ref-utils";
import { buildTagMetadata } from "~/utils/taxonomy-utils";

export interface TagMetadata {
  taxon: Taxon;
  taxonomy: Taxonomy;
  parentLabel: string | undefined;
  parentPath: string | undefined;
  processing: boolean;
  label: string;
  path: string;
  externalNameRelativePath?: string;
}

export interface AdditionalTaxonOption {
  ref: string;
  sourceType: string; // "assistant" or "model"
  name: string;
  source: Assistant | ModelContentMetadata;
  options: Option[];
}

export interface IProjectStore {
  project: Ref<Project | undefined>;
  documentStores: Ref<Store[]>;
  dataStores: Ref<Store[]>;
  modelStores: Ref<Store[]>;
  taxonomies: Ref<Taxonomy[]>;
  currentDocumentStore: Ref<Store | undefined>;
  projectLoading: Ref<boolean>;
  assistants: Ref<Assistant[]>;
  guidance: Ref<Map<string, GuidanceSet>>;
  dashboards: Ref<Dashboard[]>;
  projectDataFlow: Ref<ProjectDataFlow | undefined>;
  projectMemory: Ref<ProjectMemory>;
  projectDirty: Ref<boolean>;

  // Computed Properties
  contentTaxonomies: ComputedRef<Taxonomy[]>;
  additionalTaxonOptions: ComputedRef<any[]>;
  messageTemplates: ComputedRef<any[]>;
  projectSidebar: ComputedRef<any[]>;
  isLoaded: Ref<boolean>;

  // Methods
  loadProject: (id: string, force?: boolean) => Promise<Project | undefined>;
  clearCurrentProject: (notes?: string, cancelText?: string) => Promise<boolean>;
  createNewProject: (project: Project, templateRef: string) => Promise<Project>;
  updateCurrentProject: (projectUpdate: Project) => void;
  saveAllChanges: (testOnly?: boolean) => Promise<boolean>;
  createProjectAssistant: (assistant: Assistant) => Promise<Assistant>;
  createProjectStore: (newStore: Store, templateStore: Store) => Promise<Store>;
  createProjectDataForm: (newDataForm: DataForm, templateDataForm: DataForm) => Promise<DataForm>;
  createProjectTaxonomy: (newTaxonomy: Taxonomy, templateTaxonomy: Taxonomy) => Promise<Taxonomy>;
  deleteCurrentProject: (deleteAssoc: string) => Promise<void>;
  linkResource: (resource: any) => Promise<void>;
  unlinkProjectResource: (resource: any, deleteResource?: boolean) => Promise<void>;
  addToAssistantsToUpdate: (assistant: Assistant) => void;
  addToDocumentStoresToUpdate: (documentStore: Store) => void;
  updateComponent: (component: any) => Promise<void>;
  saveDashboards: () => Promise<void>;
  saveUpdatedGuidance: () => Promise<void>;
  saveUpdatedTaxonomies: () => Promise<void>;
  retrainModels: () => Promise<void>;
  reprocessDocuments: (store: Store, selectAll: boolean, documentFamilies: DocumentFamily[], assistantId: string) => Promise<void>;
  bulkDeleteDocuments: (store: Store, selectAll: boolean, documentFamilies: DocumentFamily[]) => Promise<void>;
  reloadGuidance: () => Promise<void>;
  reloadProject: () => Promise<void>;
  copyDocumentFamily: (documentFamily: DocumentFamily, storeRef: string) => Promise<void>;
  lockDocumentFamilies: (documentFamilies: DocumentFamily[]) => Promise<void>;
  unlockDocumentFamilies: (documentFamilies: DocumentFamily[]) => Promise<void>;
  addSectionGuidance: (selectionContext: SelectionContext, documentFamily: DocumentFamily) => void;
  buildGuidanceFromTag: (guidanceSet: GuidanceSet, tag: any, documentFamily: DocumentFamily) => void;
  saveAssistantConnection: (connection: AssistantConnection) => void;
  addSourceConnection: (assistantId: string) => void;
  addTargetConnection: (assistantId: string) => void;
  removeConnection: (connection: AssistantConnection) => void;

  // Other methods can be added as needed.
}

export type UseProjectStoreType = StoreDefinition<"project", ReturnType<typeof useProject>>;

export const useProject = defineStore("project", () => {
  const project = ref<Project>();
  const documentStores = ref<Store[]>([]);
  const documentStoresToUpdate = ref<Store[]>([]);
  const dataStores = ref<Store[]>([]);
  const dataStoresToUpdate = ref<Store[]>([]);
  const modelStores = ref<Store[]>([]);
  const modelMetadata = ref(new Map<string, ModelContentMetadata>());
  const modelAssistantMap = ref(new Map<string, Assistant>());
  const taxonomies = ref<Taxonomy[]>([]);
  const contentTaxonomies = computed(() => taxonomies.value.filter((taxonomy: Taxonomy) => taxonomy.taxonomyType === "CONTENT") as Taxonomy[]);
  const guidance = ref(new Map<string, any>());

  const currentDocumentStore = ref<Store>();
  const assistants = ref<Assistant[]>([]);
  const projectLoading = ref<boolean>(false);
  const projectLoadingMax = ref<number>(0);
  const projectLoadingProgress = ref<number>(0);

  const log: Logger<ILogObj> = new Logger({ name: "projectLogger" });
  const tagMetadataMap = ref<Map<string, TagMetadata>>(new Map());
  const assistantTaxonomies = ref<Map<string, Taxonomy>>(new Map());
  const assistantConnections = ref<Set<AssistantConnection>>(new Set());
  const updatedAssistantConnectionUuids = ref([] as string[]);
  const deletedAssistantConnectionIds = ref([] as string[]);
  const deletedAssistantIds = ref([] as string[]);

  const modelTaxonomies = ref<Map<string, Taxonomy>>(new Map());
  const modelStoreMap = ref<Map<string, Store>>(new Map());
  const dataForms = ref<DataForm[]>([]);
  const workspaces = ref<Workspace[]>([]);
  const isLoaded = ref<boolean>(false);
  const dashboards = ref<Dashboard[]>([]);
  const projectMemory = ref<ProjectMemory>({});
  const projectDataFlow = ref<ProjectDataFlow | undefined>();
  const refresher = ref<number>(0);
  const firstWorkspace = ref<Workspace>();
  const tempContentTaxonomies = ref<Map<string, Taxonomy>>(new Map());

  const updatedTaxonomyRefs: Ref<string[]> = ref([]);
  const updatedGuidanceSetRefs: Ref<string[]> = ref([]);
  const deletedGuidanceSetRefs: Ref<string[]> = ref([]);

  const assistantsToUpdate: Ref<Assistant[]> = ref([]);
  const dataFormRefsToUpdate: Ref<string[]> = ref([]);

  const allDataForms = computed(() => {
    const forms: DataForm[] = [...dataForms.value];
    dataStores.value.forEach((dataStore) => {
      forms.push({
        ref: `dataStore-${dataStore.slug}`,
        name: dataStore.name,
        description: dataStore.description,
        entrypoints: ["workspace"],
        editable: false,
        cards: [{
          id: `dataStore-${dataStore.ref}`,
          type: "dataStoreGrid",
          properties: {
            dataStoreRef: dataStore.ref,
            colSpan: 12,
          },
          children: [],
        }],
      });
    });

    forms.push({
      ref: "workspace-data",
      name: "Workspace Data",
      description: "Data from the workspace",
      entrypoints: ["workspace"],
      editable: false,
      cards: [{
        id: "workspace-data",
        type: "workspaceDataGrid",
        properties: {
          colSpan: 12,
        },
        children: [],
      }],
    });

    forms.push({
      ref: "side-by-side-data",
      name: "Side by Side Data",
      description: "See the data side by side for documents",
      entrypoints: ["workspace"],
      editable: false,
      cards: [{
        id: "side-by-side-data",
        type: "taxonTabs",
        properties: {
          colSpan: 12,
          rowsSpan: 10,
        },
        children: [],
      }],
    });

    forms.push({
      ref: "dataobject-tree",
      name: "Extracted Data Tree",
      description: "View the document data as a tree",
      entrypoints: ["documentFamily"],
      cards: [{
        id: "data-object-tree",
        type: "workspaceDataTreeView",
        properties: {
          colSpan: 12,
        },
        children: [],
      }],
    });

    return forms;
  });

  const projectSidebar = computed(() => {
    const navs = [
      {
        name: "Overview",
        iconName: "home",
        id: "overview",
      },
      {
        name: "Manage",
        iconName: "manage",
        id: "manage",
      },
      {
        name: "Data Flow",
        iconName: "dataFlow",
        id: "dataFlow",
      },
      {
        name: "Activity",
        iconName: "activity",
        id: "activity",
      },
      {
        name: "Marketplace",
        iconName: "marketplace",
        id: "marketplace",
      },
    ];

    const documentStorePurpose = {
      training: "TRAINING",
      operational: "OPERATIONAL",
    };

    documentStores.value.forEach((documentStore: Store) => {
      let documentStoreIcon = "";
      if (documentStore.storePurpose === documentStorePurpose.training) {
        documentStoreIcon = "documentStoreTraining";
      } else {
        documentStoreIcon = "documentStoreOperational";
      }
      navs.push({
        name: `${documentStore.name}`,
        iconName: documentStoreIcon,
        id: `documentStore-${documentStore.ref}`,
        objectRef: documentStore.ref,
      });
    });

    dataStores.value.forEach((dataStore: Store) => {
      navs.push({
        name: `${dataStore.name}`,
        iconName: "dataStore",
        id: `dataStore-${dataStore.ref}`,
        objectRef: dataStore.ref,
      });
    });

    modelStores.value.forEach((modelStore: Store) => {
      navs.push({
        name: `${modelStore.name}`,
        iconName: "model",
        id: `modelStore-${modelStore.ref}`,
        objectRef: modelStore.ref,
      });
    });
    return navs;
  });

  const messageTemplates = computed(() => {
    const templates = [] as any;
    if (modelMetadata.value) {
      for (const modelRef of Array.from(modelMetadata.value.keys()) as ModelContentMetadata[]) {
        const modelMeta = modelMetadata.value.get(modelRef);
        if (modelMeta?.messageTemplates) {
          for (const template of modelMeta.messageTemplates) {
            const newTemplate = JSON.parse(JSON.stringify(template));
            const assistant = modelAssistantMap.value.get(modelRef);
            if (assistant) {
              newTemplate.assistant = {
                id: assistant.id,
                name: assistant.name,
                description: assistant.description,
                icon: assistant.icon,
                color: assistant.color,
              };
            }
            templates.push(newTemplate);
          }
        }
      }
    }
    return templates;
  });

  const additionalTaxonOptions: ComputedRef<AdditionalTaxonOption[]> = computed(() => {
    const options: AdditionalTaxonOption[] = [];
    assistants.value.forEach((assistant) => {
      if (assistant.definition?.additionalTaxonOptions && assistant.definition.additionalTaxonOptions.length > 0) {
        if (assistant.id) {
          options.push({
            ref: assistant.id,
            sourceType: "assistant",
            name: assistant.name,
            source: assistant,
            options: assistant.definition.additionalTaxonOptions,
          });
        }
      }
    });

    modelStores.value.forEach((modelStore) => {
      if (modelStore.ref) {
        const modelMetadataInstance = modelMetadata.value.get(modelStore.ref);
        if (modelMetadataInstance && modelMetadataInstance.additionalTaxonOptions && modelMetadataInstance.additionalTaxonOptions.length > 0) {
          options.push({
            ref: modelStore.ref,
            sourceType: "model",
            name: modelStore.name,
            source: modelMetadataInstance,
            options: modelMetadataInstance.additionalTaxonOptions,
          });
        }
      }
    });
    return options;
  });

  function loadCurrentDocumentStore() {
    if (router.currentRoute.value.params.documentStoreSlug) {
      currentDocumentStore.value = documentStores.value.find(store => store.slug === router.currentRoute.value.params.documentStoreSlug);
    }
  }

  const projectDirty = ref<boolean>(false);

  watch(router.currentRoute, () => {
    loadCurrentDocumentStore();
  });

  function addToAssistantsToUpdate(assistant: Assistant) {
    const index = assistants.value.findIndex(a => a.id === assistant.id);
    if (index !== -1) {
      log.info(`Adding assistant ${assistant.id} to assistantsToUpdate`);
      assistants.value[index] = toRaw(assistant);
    } else {
      log.info(`Unable to find assistant ${assistant.id} in assistantsToUpdate`);
    }

    // Make sure we don't have the assistant twice to update
    if (!assistantsToUpdate.value.find(a => a.id === assistant.id)) {
      log.info(`Adding assistant ${assistant.id} to assistantsToUpdate`);
      assistantsToUpdate.value.push(assistant);
    } else {
      log.info(`Assistant ${assistant.id} already in assistantsToUpdate`);
      const index = assistantsToUpdate.value.findIndex(a => a.id === assistant.id);
      assistantsToUpdate.value[index] = assistant;
    }

    projectDirty.value = true;
  }

  async function loadModel(modelRef, assistant) {
    if (!modelStoreMap.value.has(modelRef)) {
      const modelRefHelper = new RefHelper(modelRef, assistant.definition?.orgSlug);
      // We need to get this model definition and load it
      const modelStore = await appStore.cacheStore.getObject(`store://${modelRefHelper.getFinalRef()}`) as Store;
      const modelStoreRefHelper = new RefHelper(modelStore.ref);
      const modelStoreMetadata = await getStoreMetadata(modelStoreRefHelper.getOrgSlug(), modelStoreRefHelper.getSlug(), modelStoreRefHelper.getVersion()) as ModelContentMetadata;
      if (modelStore.ref) {
        modelStoreMap.value.set(modelStore.ref, modelStore);
        modelMetadata.value.set(modelStore.ref, modelStoreMetadata);
        modelAssistantMap.value.set(modelStore.ref, assistant);
      }
      if (modelStoreMetadata.taxonomy && modelStore.ref) {
        modelTaxonomies.value.set(modelStore.ref, modelStoreMetadata.taxonomy);
      }
    }
  }

  function addToTaxonomiesToUpdate(taxonomy: Taxonomy) {
    const index = taxonomies.value.findIndex(t => t.ref === taxonomy.ref);
    if (index !== -1) {
      taxonomies.value[index] = taxonomy;
    }

    if (!updatedTaxonomyRefs.value.find(t => t === taxonomy.ref)) {
      updatedTaxonomyRefs.value.push(taxonomy.ref);
    } else {
      const index = updatedTaxonomyRefs.value.findIndex(t => t === taxonomy.ref);
      updatedTaxonomyRefs.value[index] = taxonomy.ref;
    }

    projectDirty.value = true;
  }

  function addToDataStoresToUpdate(dataStore: Store) {
    const index = dataStores.value.findIndex(ds => ds.ref === dataStore.ref);
    if (index !== -1) {
      dataStores.value[index] = dataStore;
    }

    if (!dataStoresToUpdate.value.find(ds => ds.ref === dataStore.ref)) {
      dataStoresToUpdate.value.push(dataStore);
    } else {
      const index = dataStoresToUpdate.value.findIndex(ds => ds.ref === dataStore.ref);
      dataStoresToUpdate.value[index] = dataStore;
    }

    projectDirty.value = true;
  }

  function addToDocumentStoresToUpdate(documentStore: Store) {
    const index = documentStores.value.findIndex(ds => ds.ref === documentStore.ref);
    if (index !== -1) {
      documentStores.value[index] = documentStore;
    }

    if (!documentStoresToUpdate.value.find(ds => ds.ref === documentStore.ref)) {
      documentStoresToUpdate.value.push(documentStore);
    } else {
      const index = documentStoresToUpdate.value.findIndex(ds => ds.ref === documentStore.ref);
      documentStoresToUpdate.value[index] = documentStore;
    }

    projectDirty.value = true;
  }

  async function loadProject(id: string, force = false): Promise<Project | undefined> {
    isLoaded.value = false;
    try {
      log.info(`Loading project ${id}`);
      const loadingEvent = {
        id,
        title: "Opening Project",
        progressMessage: "Loading Project Information",
        progress: 0,
        progressMax: 3,
      } as LoadingEvent;
      appStore.platformStore.addLoadingEvent(loadingEvent);
      projectLoading.value = true;
      if (project.value?.id === id && !force) {
        projectLoading.value = false;
        isLoaded.value = true;
        log.info("Project already loaded");
        appStore.platformStore.removeLoadingEvent(loadingEvent);

        appStore.platformStore.setCurrentHeading({
          title: project.value.name,
          subtitle: project.value.description,
        });

        return project.value as Project | undefined;
      }

      appStore.platformStore.setCurrentHeading({
        title: "",
        subtitle: "",
      });

      project.value = await appStore.cacheStore.getObject(`project://${id}`) as Project;

      appStore.userStore.addRecentProject(project.value);

      if (!project.value.options) {
        project.value.options = { options: [], properties: {} };
      }

      listWorkspaces({ filter: `project.id: '${project.value.id}'`, pageSize: 10 }).then((pageWorkspace) => {
        workspaces.value = pageWorkspace.content || [];
        if (workspaces.value && workspaces.value.length > 0) {
          firstWorkspace.value = workspaces.value[0];
        }
      });

      appStore.platformStore.setCurrentHeading({
        title: project.value.name,
        subtitle: project.value.description,
        breadcrumbs: [
          {
            name: "Home",
            link: "/a/home",
          },
        ],
      });

      assistantConnections.value.clear();

      // We want to load the assistant connections
      const assistantConnectionsResponse = await listAssistantConnections({
        filter: `targetAssistant.project.id: '${project.value.id}' or sourceAssistant.project.id: '${project.value.id}'`,
        pageSize: 100,
      });

      assistantConnectionsResponse.content?.forEach((assistantConnection) => {
        assistantConnections.value.add(assistantConnection);
      });

      appStore.platformStore.incrementLoadingProgress(loadingEvent.id, 1, undefined, "Loading Assistants");
      assistants.value = await getAssistants(id);

      assistants.value.forEach((assistant) => {
        assistant.assistantDefinitionRef = assistant.definition?.ref;
      });
      assistantTaxonomies.value.clear();
      modelMetadata.value.clear();
      modelTaxonomies.value.clear();

      appStore.platformStore.incrementLoadingProgress(loadingEvent.id, 0, assistants.value.length, "Loading Assistant Models");
      for (const assistant of assistants.value) {
        // We have a special handling for a pipeline option - since we need to determine if the pipeline has
        // models so we can load them into place
        if (assistant.options && "pipeline" in assistant.options) {
          // We need to go through the pipeline and see if we reference any models
          for (const pipelineStep of assistant.options.pipeline.steps) {
            if (pipelineStep.stepType === "MODEL") {
              const modelRefHelper = new RefHelper(pipelineStep.ref, assistant.definition?.orgSlug);
              // We need to get this model definition and load it
              try {
                const modelStore = await appStore.cacheStore.getObject(`store://${modelRefHelper.getFinalRef()}`) as Store;
                const modelStoreRefHelper = new RefHelper(modelStore.ref);
                const modelStoreMetadata = await getStoreMetadata(modelStoreRefHelper.getOrgSlug(), modelStoreRefHelper.getSlug(), modelStoreRefHelper.getVersion()) as ModelContentMetadata;
                if (modelStore.ref) {
                  modelStoreMap.value.set(modelStore.ref, modelStore);
                  modelMetadata.value.set(modelStore.ref, modelStoreMetadata);
                  modelAssistantMap.value.set(modelStore.ref, assistant);
                }
                if (modelStoreMetadata.taxonomy && modelStore.ref) {
                  modelTaxonomies.value.set(modelStore.ref, modelStoreMetadata.taxonomy);
                }
              } catch (e) {
                log.error(`Error loading model ${pipelineStep.ref} ${e}`);
                notify({
                  group: "error",
                  title: "Missing model",
                  text: `Unable to load model ${modelRefHelper.getFinalRef()}`,
                });
              }
            }
          }
        }

        for (const assistantTaxonomy of assistant.definition?.processingTaxonomies ?? []) {
          if (assistantTaxonomy.ref) {
            const taxonomyRefHelper = new RefHelper(assistantTaxonomy.ref, assistant.definition?.orgSlug);
            if (taxonomyRefHelper.isRelative()) {
              if (assistant.definition) {
                assistant.definition.services?.forEach((service) => {
                  if (service.slug === taxonomyRefHelper.getSlug()) {
                    assistantTaxonomies.value.set(taxonomyRefHelper.getFinalRef(), service as Taxonomy);
                  }
                });
              }
            } else {
              const taxonomy = await appStore.cacheStore.getObject(`taxonomy://${taxonomyRefHelper.getFinalRef()}`) as Taxonomy;
              if (taxonomy.ref) {
                assistantTaxonomies.value.set(taxonomy.ref, taxonomy);
              }
            }
          }
        }
        appStore.platformStore.incrementLoadingProgress(loadingEvent.id, 1, undefined, "Loading Assistant Models");
      }

      appStore.platformStore.incrementLoadingProgress(loadingEvent.id, 1, undefined, "Loading Project Resources");

      const [documentStoreResponse, dataStoreResponse, modelStoreResponse, taxonomiesResponse, dataFormsResponse, dataFlowResponse, dashboardResponse, projectMemoryResponse] = await Promise.all([
        getDocumentStores(id),
        getDataStores(id),
        getModelStores(id),
        getTaxonomies(id),
        getDataForms(id),
        getProjectDataFlow(id),
        getDashboards(id),
        getProjectMemory(id),
      ]);

      appStore.platformStore.incrementLoadingProgress(loadingEvent.id);

      documentStores.value = documentStoreResponse;
      dataStores.value = dataStoreResponse;
      modelStores.value = modelStoreResponse;
      taxonomies.value = taxonomiesResponse;
      dataForms.value = dataFormsResponse;
      projectDataFlow.value = dataFlowResponse;
      dashboards.value = dashboardResponse;
      projectMemory.value = projectMemoryResponse;

      await reloadGuidance();

      appStore.platformStore.incrementLoadingProgress(loadingEvent.id, undefined, modelStores.value.length, "Loading Models and Taxonomies");

      for (const modelStore of modelStores.value) {
        if (modelStoreMap.value.has(modelStore.ref as string)) {
          continue;
        }

        const modelStoreRefHelper = new RefHelper(modelStore.ref);

        getStoreMetadata(modelStoreRefHelper.getOrgSlug(), modelStoreRefHelper.getSlug(), modelStoreRefHelper.getVersion()).then((modelStoreMetadata: ModelContentMetadata) => {
          if (modelStoreMetadata.taxonomy && modelStore.ref) {
            modelStoreMap.value.set(modelStore.ref, modelStore);
            modelMetadata.value.set(modelStore.ref, modelStoreMetadata);
            modelTaxonomies.value.set(modelStore.ref, modelStoreMetadata.taxonomy);
          }
          appStore.platformStore.incrementLoadingProgress(loadingEvent.id, undefined, undefined, "Loading Models and Taxonomies");
        });
      }
      appStore.platformStore.incrementLoadingProgress(loadingEvent.id, 1, undefined, "Building Project Metadata Repository");
      buildTagMetadataMap();
      projectLoading.value = false;
      appStore.platformStore.removeLoadingEvent(loadingEvent);
      log.info(`Loaded project ${id}`);
    } catch (e: any) {
      appStore.platformStore.clearLoadingEvents();
      notify({
        group: "error",
        title: "Error loading project",
        text: e.message,
      }, 3000);
      // We redirect home
      projectLoading.value = false;
      router.push({ path: "/a/home" });
    }

    return project.value as Project | undefined;
  }

  async function clearCurrentProject(notes = "Do you want to discard the changes, or stay in the project to save them?", cancelText = "Stay in Project") {
    if (projectDirty.value) {
      const confirmLostChanges = createConfirmDialog(KodexaConfirm);
      const result = await confirmLostChanges.reveal({
        icon: "alert-circle-outline",
        title: "Unsaved Changes",
        message: "You have unsaved changes, if you continue they will be lost!",
        notes,
        confirmText: "Discard Changes",
        confirmIcon: "delete",
        cancelText,
        cancelIcon: "close",
        type: "danger",
      });

      if (result.isCanceled) {
        return false;
      }
    }

    log.info("Clearing project");
    project.value = undefined;
    documentStores.value = [];
    dataStores.value = [];
    modelStores.value = [];
    taxonomies.value = [];
    currentDocumentStore.value = undefined;
    assistants.value = [];
    dashboards.value = [];
    assistantsToUpdate.value = [];
    guidance.value.clear();
    projectDirty.value = false;

    appStore.platformStore.setCurrentHeading({
      title: "",
      subtitle: "",
    });

    appStore.workspaceStore.clearCurrentWorkspace(true);
    appStore.platformStore.destroyPiniaStores();

    return true;
  }

  async function updateComponent(component: SlugBasedMetadata) {
    const refHelper = new RefHelper(component.ref);

    if (component.type === "dataForm") {
      const updatedDataForm = await updateDataForm(refHelper.getOrgSlug(), refHelper.getSlug(), component as DataForm);
      dataForms.value = dataForms.value.filter(dataForm => dataForm.ref !== updatedDataForm.ref);
      dataForms.value.push(updatedDataForm);
    }
  }

  async function getAvailableLabels(): Promise<Label[]> {
    if (project.value?.organization?.id) {
      const labels = await getLabels(project.value.organization.id);
      return (labels as Label[]);
    } else {
      return [];
    }
  }

  function buildTagMetadataMap() {
    tagMetadataMap.value.clear();
    taxonomies.value.forEach((taxonomy) => {
      if (taxonomy.taxons) {
        taxonomy.taxons.forEach((taxon) => {
          buildTagMetadata(taxon, taxonomy, tagMetadataMap.value);
        });
      }
    });

    Array.from(tempContentTaxonomies.value.values()).forEach((taxonomy) => {
      if (taxonomy.taxons) {
        taxonomy.taxons.forEach((taxon) => {
          buildTagMetadata(taxon, taxonomy, tagMetadataMap.value);
        });
      }
    });

    assistantTaxonomies.value.forEach((taxonomy) => {
      if (taxonomy.taxons) {
        taxonomy.taxons.forEach((taxon) => {
          buildTagMetadata(taxon, taxonomy, tagMetadataMap.value);
        });
      }
    });

    modelTaxonomies.value.forEach((taxonomy) => {
      if (taxonomy.taxons) {
        taxonomy.taxons.forEach((taxon) => {
          buildTagMetadata(taxon, taxonomy, tagMetadataMap.value);
        });
      }
    });

    isLoaded.value = true;
  }

  function queryTags(query: string): TagMetadata[] {
    let taxonomyTypeFilter: string | undefined;
    let finalQuery = query;

    if (query.startsWith("`")) {
      // We will only search for the taxonomy type CONTENT
      taxonomyTypeFilter = "CONTENT";
      finalQuery = query.slice(1);
    } else if (query.startsWith("~")) {
      taxonomyTypeFilter = "MODEL";
      finalQuery = query.slice(1);
    }

    const searchableTags = [...tagMetadataMap.value.values()].filter((tagMetadata) => {
      const first = (!tagMetadata.taxon || !tagMetadata.taxon.group)
        && (tagMetadata.taxonomy && tagMetadata.taxonomy.enabled && tagMetadata.taxon.notUserLabelled !== true)
        && (tagMetadata.taxon.valuePath !== "FORMULA" && tagMetadata.taxon.valuePath !== "METADATA");
      if (first && taxonomyTypeFilter) {
        return tagMetadata.taxonomy.taxonomyType === taxonomyTypeFilter;
      } else {
        return first;
      }
    }).map((tagMetadata) => {
      return tagMetadata as TagMetadata;
    });

    const fuse = new Fuse(searchableTags, {
      keys: ["label", "path"],
      includeScore: true,
      threshold: 0.4,
    });
    return fuse.search(finalQuery).map(hit => hit.item);
  }

  function getTagMetadata(tagPath: string): TagMetadata | undefined {
    return tagMetadataMap.value.get(tagPath);
  }

  function createNewProject(project: Project, templateRef: string): Promise<Project> {
    return createProject(project, { templateRef });
  }

  function gotoProject(project: Project, projectTemplate: ProjectTemplate | undefined = undefined) {
    router.push({ path: `/a/o/${project.organization?.id}/p/${project.id}/home` }).then(() => {
      appStore.platformStore.setCurrentSidebar("home");
      if (projectTemplate) {
        confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.6 },
        });
      }
    });
  }

  function createProjectAssistant(assistant: Assistant): Promise<Assistant> {
    return new Promise<Assistant>((resolve, reject) => {
      createAssistant(assistant).then((assistant) => {
        assistants.value.push(assistant);
        resolve(assistant);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  function createProjectStore(newStore: Store, templateStore: Store) {
    return new Promise<Store>((resolve, reject) => {
      if (templateStore.template && project.value?.organization && templateStore.ref) {
        createStore(project.value.organization.slug, newStore, {
          templateRef: templateStore.ref,
          projectId: project.value.id,
        }).then((store) => {
          if (store.storeType === "DOCUMENT") {
            documentStores.value.push(store);
          } else if (store.storeType === "MODEL") {
            modelStores.value.push(store);
          } else if (store.storeType === "TABLE") {
            dataStores.value.push(store);
          }
          resolve(store);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  function createProjectDataForm(newDataForm: DataForm, templateDataForm: DataForm) {
    return new Promise<DataForm>((resolve, reject) => {
      if (templateDataForm.template && project.value?.organization && templateDataForm.ref) {
        createDataForm(project.value.organization.slug, newDataForm, {
          templateRef: templateDataForm.ref,
          projectId: project.value.id,
        }).then((dataForm) => {
          dataForms.value.push(dataForm);
          resolve(dataForm);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  function createProjectTaxonomy(newTaxonomy: Taxonomy, templateTaxonomy: Taxonomy) {
    return new Promise<Taxonomy>((resolve, reject) => {
      if (templateTaxonomy.template && project.value?.organization && templateTaxonomy.ref) {
        createTaxonomy(project.value.organization.slug, newTaxonomy, {
          templateRef: templateTaxonomy.ref,
          projectId: project.value.id,
        }).then((taxonomy) => {
          taxonomies.value.push(taxonomy);
          buildTagMetadataMap();
          resolve(taxonomy);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  async function reprocessAssistant(assistantId: string, documentFamily: DocumentFamily) {
    const refHelper = new RefHelper(documentFamily.storeRef);
    const reprocessRequest = {
      assistantIds: [assistantId],
      familyIds: [documentFamily.id],
    } as ReprocessRequest;
    await reprocessWithVersion(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), reprocessRequest);
  }

  async function updateWorkspaceName(workspaceId: string, name: string): Promise<Workspace> {
    const workspace = await getWorkspace(workspaceId);
    workspace.name = name;
    if (workspace.id) {
      return await updateWorkspace(workspace.id, workspace);
    } else {
      throw new Error("Workspace id is not defined");
    }
  }

  async function saveProjectDataFlow(dataFlow: ProjectDataFlow, viewport: FlowViewPort) {
    if (project.value?.id) {
      dataFlow.viewPort = viewport;
      await updateProjectDataFlow(project.value?.id, dataFlow);
      project.value.dataFlow = dataFlow;
    } else {
      throw new Error("Project not loaded");
    }
  }

  async function reloadProject() {
    if (project.value?.id) {
      // Save the current projectId before you clear it
      const projectId = project.value.id;

      // Now clear the project
      await clearCurrentProject();

      // Load the project because all the data was cleared
      await loadProject(projectId, true);

      // Use the projectId you saved to get the project and update it
      project.value = await updateHandler(getProject(projectId), "Reloading project");
      projectDirty.value = false;
    }
  }

  async function deleteCurrentProject(deleteAssoc: string) {
    if (project.value?.id) {
      const loadingEvent = {
        id: project.value.id,
        title: "Deleting Project",
        subtitle: "",
        progress: undefined,
        progressMax: undefined,
      } as LoadingEvent;
      appStore.platformStore.addLoadingEvent(loadingEvent);
      await updateHandler(deleteProject(project.value?.id, { deleteAssociated: deleteAssoc }), "Project has been deleted", async () => {
        appStore.platformStore.removeLoadingEvent(loadingEvent);
        projectDirty.value = false;
        await clearCurrentProject();
        await router.push({ path: `/a/o/${appStore.organizationStore.currentOrganization.id}/home` });
      }, () => {
        appStore.platformStore.removeLoadingEvent(loadingEvent);
      });
    } else {
      throw new Error("Project not loaded");
    }
  }

  async function linkResource(resource: SlugBasedMetadata) {
    if (resource.type === "store") {
      await updateHandler(addStoreToProject(project.value.id, resource as Store), "Added resource to project");
    }
    if (resource.type === "taxonomy") {
      await updateHandler(addTaxonomyToProject(project.value.id as string, resource as Taxonomy), "Added data definition to project");
    }
    if (resource.type === "dataForm") {
      await updateHandler(addDataFormToProject(project.value.id as string, resource as DataForm), "Added data form to project");
    }
    if (resource.type === "dashboard") {
      await updateHandler(addDashboardToProject(project.value.id, resource as Dashboard), "Added dashboard to project");
    }
  }

  async function cancelAssistantExecution(execution: Execution) {
    if (execution.id) {
      log.info(`Canceling execution ${execution.id}`);
      await updateHandler(cancelExecution(execution.id), "Execution canceled");
    }
  }

  function updateCurrentProject(projectUpdate: Project) {
    projectDirty.value = true;
    project.value = projectUpdate;
  }

  async function saveCurrentProject() {
    try {
      project.value = await updateHandler(updateProject(project.value?.id as string, project.value as Project), "Project updated");
      if (projectMemory.value && project.value) {
        projectMemory.value = await updateHandler(updateProjectMemory(project.value.id as string, projectMemory.value), "Project memory updated");
        project.value.changeSequence = projectMemory.value.changeSequence;
      } else {
        log.warn("Project memory not updated");
      }

      // Set projectDirty to false after successful save
      projectDirty.value = false;
    } catch (error) {
      // Handle error if needed
      console.error("Error saving project:", error);
    }
  }

  async function saveAssistant(assistant: Assistant): Promise<Assistant> {
    return await updateProjectAssistant(project.value?.id as string, assistant);
  }

  async function updateProjectResources() {
    await updateHandler(updateResources(project.value?.id as string, {
      dashboardRefs: dashboards.value.map(dashboard => dashboard.ref) as string[],
      dataFormRefs: dataForms.value.map(dataForm => dataForm.ref) as string[],
      storeRefs: [...documentStores.value, ...dataStores.value, ...modelStores.value].map(store => store.ref) as string[],
      taxonomyRefs: taxonomies.value.map(taxonomy => taxonomy.ref) as string[],
      guidanceRefs: Array.from(guidance.value.keys()),
    }), "Project resources updated");
  }

  async function newDashboard(dashboard: Dashboard) {
    if (project.value) {
      log.info("Creating new dashboard");
      dashboard.type = "dashboard";
      const newDashboard = await createDashboard(project.value.organization?.slug as string, dashboard, {});
      dashboards.value.unshift(newDashboard);

      await updateProjectResources();

      if (projectMemory.value.orderedDashboards) {
        projectMemory.value.orderedDashboards.unshift(newDashboard.ref as string);
        projectDirty.value = true;
      } else {
        log.warn("Project memory not loaded");
      }
    } else {
      log.error("Project not loaded");
    }
  }

  async function updateProjectDashboard(dashboard: Dashboard) {
    if (project.value) {
      log.info("Updating dashboard");
      const updatedDashboard = await updateDashboard(dashboard.orgSlug as string, dashboard.slug as string, dashboard);
      const updatedIndex = dashboards.value.findIndex(d => d.ref === updatedDashboard.ref);
      dashboards.value = dashboards.value.map((dashboard, index) =>
        index === updatedIndex ? updatedDashboard : dashboard,
      );
      projectDirty.value = true;
    }
  }

  async function createResource(newResource: SlugBasedMetadata, templateResource: SlugBasedMetadata) {
    if (templateResource.type === "store") {
      await createProjectStore(newResource as Store, templateResource as Store);
    }
    if (templateResource.type === "taxonomy") {
      await createProjectTaxonomy(newResource as DataForm, templateResource as Taxonomy);
    }
    if (templateResource.type === "dataForm") {
      await createProjectDataForm(newResource as DataForm, templateResource as DataForm);
    }
    if (templateResource.type === "assistant") {
      await createProjectAssistant(newResource as Assistant);
    }
  }

  async function unlinkProjectResource(resource, deleteResource = false) {
    if (resource.type === "store") {
      // We want to remove the store from the project
      if (resource.storeType === "DOCUMENT") {
        documentStores.value.splice(documentStores.value.findIndex(store => store.ref === resource.ref), 1);
        await updateHandler(updateProjectResources(), "Removed document store from project");
      }
      if (resource.storeType === "TABLE") {
        dataStores.value.splice(dataStores.value.findIndex(store => store.ref === resource.ref), 1);
        await updateHandler(updateProjectResources(), "Removed data store from project");
      }
      if (resource.storeType === "MODEL") {
        modelStores.value.splice(modelStores.value.findIndex(store => store.ref === resource.ref), 1);
        await updateHandler(updateProjectResources(), "Removed model store from project");
      }
      if (deleteResource) {
        await updateHandler(deleteStore(resource.orgSlug as string, resource.slug as string, {}), "Deleted document store");
      }
    }
    if (resource.type === "taxonomy") {
      taxonomies.value.splice(taxonomies.value.findIndex(taxonomy => taxonomy.ref === resource.ref), 1);
      await updateHandler(updateProjectResources(), "Removed taxonomy from project");
      if (deleteResource) {
        await updateHandler(deleteTaxonomy(resource.orgSlug as string, resource.slug as string, {}), "Deleted taxonomy");
      }
    }
    if (resource.type === "dataForm") {
      dataForms.value.splice(dataForms.value.findIndex(dataForm => dataForm.ref === resource.ref), 1);
      await updateHandler(updateProjectResources(), "Removed data form from project");
      if (deleteResource) {
        await updateHandler(deleteDataForm(resource.orgSlug as string, resource.slug as string, {}), "Deleted data form");
      }
    }
    if (resource.type === "dashboard") {
      dashboards.value.splice(dashboards.value.findIndex(dashboard => dashboard.ref === resource.ref), 1);
      await updateHandler(updateProjectResources(), "Removed dashboard from project");
      if (deleteResource) {
        await updateHandler(deleteDashboard(resource.orgSlug as string, resource.slug as string, {}), "Deleted dashboard");
      }
    }

    await reloadProject();
  }

  async function deleteProjectDashboard(dashboard: Dashboard) {
    if (project.value) {
      log.info("Deleting dashboard");
      dashboards.value = dashboards.value.filter(d => d.ref !== dashboard.ref);
      await updateProjectResources();
      await deleteDashboard(dashboard.orgSlug as string, dashboard.slug as string, {});
    }
  }

  function updateDashboardOrder(dashboard, offset) {
    if (project.value) {
      log.info("Updating dashboard order");
      // We get the index of the dashboard and then move it to the new position
      // in the array based on the offset
      function arrayMove(arr, old_index, new_index) {
        if (new_index >= arr.length) {
          let k = new_index - arr.length + 1;
          while (k--) {
            arr.push(undefined);
          }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
      }

      const currentIndex = dashboards.value.findIndex(d => d.ref === dashboard.ref);
      dashboards.value = arrayMove(dashboards.value, currentIndex, currentIndex + offset);
      projectMemory.value.orderedDashboards = dashboards.value.map(dashboard => dashboard.ref);
      projectDirty.value = true;
    }
  }

  function addWidgetToDashboard(dashboard: Dashboard, widget: any) {
    widget.id = uuidv4();
    if (!widget?.properties?.defaultPosition) {
      widget.properties = widget.properties || {};

      // Create defaultPosition object if it doesn't exist
      widget.properties.defaultPosition = {
        col: 1,
        colSpan: 4,
        rowSpan: 3,
      };
    }
    const realDashboard = dashboards.value.find(d => d.ref === dashboard.ref);
    if (realDashboard?.widgets) {
      realDashboard.widgets.push(widget);
    } else if (realDashboard) {
      realDashboard.widgets = [widget];
    }

    if (widget?.isSingle) {
      realDashboard.singleWidget = {
        id: "1",
        type: widget.type,
      };
    }

    projectDirty.value = true;
  }

  function deleteWidgetFromDashboard(dashboard: Dashboard, widget: any) {
    const realDashboard = dashboards.value.find(d => d.ref === dashboard.ref);
    if (realDashboard?.widgets) {
      realDashboard.widgets = realDashboard.widgets.filter(w => w.id !== widget.id);
    }
    projectDirty.value = true;
  }

  function updateWidgetInDashboard(dashboard: Dashboard, widget: any) {
    const realDashboard = dashboards.value.find(d => d.ref === dashboard.ref);

    if (!realDashboard) {
      log.warn(`Dashboard not found with ref ${dashboard.ref}`);
      return;
    }

    if (!realDashboard?.widgets) {
      realDashboard.widgets = [];
    }
    const realWidget: DashboardWidget | undefined = realDashboard.widgets.find(w => w.id === widget.id);
    if (realWidget) {
      realWidget.properties = { ...widget.properties };
    } else {
      realDashboard.widgets.push({ ...widget });
    }
    projectDirty.value = true;
  }

  /**
   * This function will add a taxonomyRef as updated by the workspace
   */
  function addUpdatedTaxonomyRef(taxonomyRef: string) {
    if (!updatedTaxonomyRefs.value.includes(taxonomyRef) && taxonomyRef) {
      updatedTaxonomyRefs.value.push(taxonomyRef);
      refresher.value = refresher.value + 1;
    }
  }

  function uploadLocalTaxonomy(taxonomy: Taxonomy) {
    const realTaxonomy = taxonomies.value.find(t => t.ref === taxonomy.ref);
    if (realTaxonomy) {
      realTaxonomy.taxons = taxonomy.taxons;

      // We need to rebuild the tag metadata map
      buildTagMetadataMap();
      projectDirty.value = true;
      refresher.value = refresher.value + 1;
      addUpdatedTaxonomyRef(taxonomy.ref);
    }
  }

  async function saveTaxonomy(taxonomy: Taxonomy) {
    const realTaxonomy = taxonomies.value.find(t => t.ref === taxonomy.ref);
    if (realTaxonomy) {
      const refHelper = new RefHelper(taxonomy.ref);
      const updatedTaxonomy = await updateTaxonomy(refHelper.getOrgSlug(), refHelper.getSlug(), taxonomy);
      realTaxonomy.taxons = updatedTaxonomy.taxons;
      buildTagMetadataMap();
      projectDirty.value = false;
    }
  }

  function findParentTaxonFromTaxons(taxonId: string, taxons: Taxon[], parent: Taxon | null = null) {
    for (let i = 0; i < taxons.length; i++) {
      const taxon = taxons[i];
      if (taxon.id === taxonId) {
        return parent;
      }
      if (taxon.children) {
        const found = findParentTaxonFromTaxons(taxonId, taxon.children, taxon);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  function findSelectedTaxonFromTaxons(taxonId: string, taxons: Taxon[], update: Taxon | undefined = undefined) {
    for (let i = 0; i < taxons.length; i++) {
      const taxon = taxons[i];
      if (taxon.id === taxonId) {
        if (update) {
          // Update taxon in place
          Object.keys(update).forEach((key) => {
            taxon[key] = update[key];
          });
        }
        return taxon;
      }
      if (taxon.children) {
        const found = findSelectedTaxonFromTaxons(taxonId, taxon.children, update);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  function findSelectedTaxonById(taxonId: string, taxonomyRef: string, update: Taxon | undefined = undefined): Taxon | undefined {
    const taxonomy = taxonomies.value.find(t => t.ref === taxonomyRef);
    if (taxonomy) {
      const taxon = findSelectedTaxonFromTaxons(taxonId, taxonomy.taxons as [], update);
      if (taxon) {
        if (update) {
          log.info("Found taxon and updated taxonomy");
          appStore.workspaceStore.addUpdatedTaxonomyRef(taxonomyRef);
        }
        return taxon;
      } else {
        log.warn("Taxon not found");
      }
    }
    return undefined;
  }

  async function saveTaxonomyByRef(taxonomyRef: string) {
    const taxonomy = taxonomies.value.find(t => t.ref === taxonomyRef);
    if (taxonomy) {
      await saveTaxonomy(taxonomy);
    }
    updatedTaxonomyRefs.value = updatedTaxonomyRefs.value.filter(ref => ref !== taxonomyRef);
  }

  function newTaxonInTaxonomy(taxonomyRef: string, parentId: string, useSelection = true, taxon: Taxon | undefined = undefined) {
    const taxonomy = taxonomies.value.find(t => t.ref === taxonomyRef);
    if (taxonomy) {
      // We want to see if we have a workspace and if we have an activeView
      const activeView = appStore.workspaceStore.activeView;
      let newLabel = "New Data Element";
      if (activeView && activeView.viewType === "document") {
        const documentView = createDocumentViewerStore(activeView.id);
        if (documentView?.selectionContext?.selectedValue && useSelection) {
          newLabel = documentView.selectionContext.selectedValue.replace(/[^A-Z0-9 ]/gi, "");
        }
      }

      const newTaxon = taxon || {
        id: uuidv4(),
        name: uuidv4(),
        generateName: true,
        enabled: true,
        label: newLabel,
        externalName: newLabel.replace(/[^a-z0-9]/gi, ""),
        valuePath: "VALUE_OR_ALL_CONTENT",
        color: randomColor(),
        description: "",
        multiValue: false,
        taxonType: "STRING",
        semanticDefinition: "",
        group: parentId === undefined,
        userEditable: true,
        children: [],
      } as Taxon;

      if (taxon?.typeFeatures) {
        newTaxon.typeFeatures = taxon.typeFeatures;
      } else {
        newTaxon.typeFeatures = { embedded: parentId !== undefined };
      }
      const parentTaxon = parentId ? findSelectedTaxonById(parentId, taxonomyRef) : undefined;
      addUpdatedTaxonomyRef(taxonomyRef);
      if (parentTaxon) {
        if (!parentTaxon.children) {
          parentTaxon.children = [];
        }
        parentTaxon.children.push(newTaxon);
        refresher.value = refresher.value + 1;
        return newTaxon;
      } else {
        if (!taxonomy.taxons) {
          taxonomy.taxons = [];
        }
        taxonomy.taxons.push(newTaxon);
        refresher.value = refresher.value + 1;
        return newTaxon;
      }
    }
    return undefined;
  }

  function deleteTaxonFromTaxonomy(taxonomyRef: string, parentTaxonId: string, taxonId: string) {
    const taxonomy = taxonomies.value.find(t => t.ref === taxonomyRef);
    if (taxonomy) {
      const parentTaxon = findSelectedTaxonById(parentTaxonId, taxonomyRef);
      if (parentTaxon) {
        parentTaxon.children = parentTaxon.children?.filter(t => t.id !== taxonId);
      } else {
        taxonomy.taxons = taxonomy.taxons.filter(t => t.id !== taxonId);
      }
    }
    refresher.value = refresher.value + 1;
  }

  async function getNativeContent(documentFamily: DocumentFamily) {
    // First we need to find the store information
    const store = documentStores.value.find(store => store.ref === documentFamily.storeRef);
    if (store) {
      const storeRefHelper = new RefHelper(store.ref);
      // We need to find the native content object in the document family
      const nativeContentObject = documentFamily.contentObjects?.find(contentObject => contentObject.contentType === "NATIVE");
      return await getContentObjectContent(storeRefHelper.getOrgSlug(), storeRefHelper.getSlug(), documentFamily.id as string, nativeContentObject?.id as string);
    } else {
      throw new Error("Store not found");
    }
  }

  async function fetchProjectExecutions() {
    if (project.value?.id) {
      const executions = await listExecutions({ filter: `assistant.project.id: '${project.value.id}' and status in ['PENDING','RUNNING']` });
      return executions.content as Execution[];
    } else {
      return [];
    }
  }

  function addAssistantConnection(source: string, sourceType: string, target: string, targetType: string) {
    // We need to make sure we don't have a connection already
    const existingConnection = [...assistantConnections.value].find(connection => connection.source === source && connection.target === target);
    if (!existingConnection) {
      assistantConnections.value.add({
        uuid: `${uuidv4()}`,
        sourceRef: source,
        sourceType,
        targetRef: target,
        targetType,
      } as AssistantConnection);
      updatedAssistantConnectionUuids.value.push(source);
    }
  }

  /**
   * This will move the taxonToMove so that it before the taxonTarget
   * in the taxonomy
   *
   * @param taxonomy the taxonomy
   * @param sourceTaxon the taxon we want to move
   * @param itemId the item id (form 1_2_3)
   * @param destItemId the destination item id, which we will insert the taxon before
   */
  function moveTaxonBeforeTaxon(taxonomy: Taxonomy, sourceTaxon: Taxon, itemId: string, destItemId: string) {
    if (itemId === destItemId) {
      return;
    }

    // if the number of _ is different, then we are moving to a different parent which isn't supported
    if (itemId.split("_").length !== destItemId.split("_").length) {
      return;
    }

    // We need to remove the source taxon and then insert it before the dest taxon in the parent taxon's children
    const taxonToMoveIdx = Number.parseInt(itemId.substring(itemId.lastIndexOf("_") + 1));
    const destTaxonIdx = Number.parseInt(destItemId.substring(destItemId.lastIndexOf("_") + 1));

    if (itemId.split("_").length === 1) {
      taxonomy.taxons?.splice(taxonToMoveIdx, 1);
      taxonomy.taxons?.splice(destTaxonIdx, 0, sourceTaxon);
      return;
    }

    const parentTaxon = findParentTaxonFromTaxons(sourceTaxon.id as string, taxonomy.taxons as []) as Taxon;

    if (parentTaxon.children) {
      // We will remove the sourceTaxon based on the taxonToMoveIdx
      parentTaxon.children.splice(taxonToMoveIdx, 1);

      // We will insert the sourceTaxon before the destTaxon
      parentTaxon.children.splice(destTaxonIdx, 0, sourceTaxon);
    }
  }

  function updateProjectNotes(notes: string) {
    if (project.value) {
      project.value.notes = notes;
      projectDirty.value = true;
    }
  }

  function createTaxon(taxonomy: Taxonomy, parentTaxonId: string | undefined, taxon: Taxon) {
    if (parentTaxonId) {
      const parentTaxon = findSelectedTaxonById(parentTaxonId, taxonomy.ref as string);
      taxon.path = `${parentTaxon?.path}/${taxon.name}`;
      if (parentTaxon) {
        if (!parentTaxon.children) {
          parentTaxon.children = [];
        }
        parentTaxon.children.push(JSON.parse(JSON.stringify(taxon)));
      }
    } else {
      taxon.path = taxon.name;
      if (!taxonomy.taxons) {
        taxonomy.taxons = [];
      }
      taxonomy.taxons.push(JSON.parse(JSON.stringify(taxon)));
    }

    buildTagMetadataMap();
  }

  function toggleTaxonomy(taxonomy: Taxonomy) {
    // First we need to find the taxonomy in the list
    const realTaxonomy = taxonomies.value.find(t => t.ref === taxonomy.ref);
    if (realTaxonomy) {
      realTaxonomy.enabled = !realTaxonomy.enabled;
      buildTagMetadataMap();
    }
  }

  async function reprocessDocuments(store: Store, selectAll: boolean, documentFamilies: DocumentFamily[], assistantId: string) {
    if (project.value?.id) {
      const reprocessRequest = {
        assistantIds: [assistantId],
        familyIds: !selectAll ? documentFamilies.map(documentFamily => documentFamily.id as string) : [],
        all: selectAll,
      } as ReprocessRequest;

      await updateHandler(reprocessWithVersion(store.orgSlug, store.slug, store.version, reprocessRequest), "Reprocessing request sent");
    }
  }

  async function bulkDeleteDocuments(store: Store, selectAll: boolean, documentFamilies: DocumentFamily[]) {
    if (project.value?.id) {
      // Sadly it is a hidden API
      const refHelper = new RefHelper(store.ref);
      const bulkDeleteRequest = {
        documentFamilyIds: documentFamilies.map(documentFamily => documentFamily.id as string),
        all: selectAll,
      } as BulkDelete;
      await deleteContentObjectsWithVersion(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), bulkDeleteRequest);
    }
  }

  watch(refresher, debounce(() => {
    buildTagMetadataMap();
  }, 1000), {
    immediate: true,
  });

  function addTempContentTaxonomy(messageId: string, taxonomy: Taxonomy) {
    tempContentTaxonomies.value.set(messageId, taxonomy);
    buildTagMetadataMap();
    refresher.value = refresher.value + 1;
  }

  function removeTempContentTaxonomy(messageId: string) {
    tempContentTaxonomies.value.delete(messageId);
    buildTagMetadataMap();
    refresher.value = refresher.value + 1;
  }

  async function reloadGuidance() {
    const guidanceResponse = await getProjectGuidance(project.value?.id as string);

    guidanceResponse.map((guidanceSet: any) => {
      guidanceSet.guidance?.forEach((guidanceItem: Guidance) => {
        if (!guidanceItem.id) {
          // Make ID a new UUID
          guidanceItem.id = uuidv4();
        }
      });
      guidance.value.set(guidanceSet.ref, guidanceSet);
    });
  }

  async function saveProjectManagement() {
    try {
      await saveCurrentProject();
      await saveDocumentStores();
      await saveDataStores();
      await saveDataForms();

      for (const id of deletedAssistantConnectionIds.value) {
        await deleteAssistantConnection(id);
      }

      for (const assistantId of deletedAssistantIds.value) {
        await deleteAssistant(assistantId);
      }

      for (const uuid of updatedAssistantConnectionUuids.value) {
        const connection = Array.from(assistantConnections.value.values()).find(connection => connection.uuid === uuid);
        if (connection) {
          if (connection.id === undefined) {
            assistantConnections.value.delete(connection);
            const updatedConnection = await createAssistantConnection(connection);
            assistantConnections.value.add(updatedConnection);
          } else {
            assistantConnections.value.delete(connection);
            await updateAssistantConnection(connection.id as string, connection);
            assistantConnections.value.add(await getAssistantConnection(connection.id as string));
          }
        }
      }

      const promises = assistantsToUpdate.value.map((assistant) => {
        return saveAssistant(assistant);
      });

      const updatedAssistants = await Promise.all(promises);

      // Update assistants.value based on updatedAssistants
      for (const updateAssistant of updatedAssistants) {
        const assistantIndex = assistants.value.findIndex(assistant => assistant.id === updateAssistant.id);
        if (assistantIndex !== -1) {
          assistants.value[assistantIndex] = updateAssistant;
        } else {
          assistants.value.push(updateAssistant);
        }
      }

      await saveUpdatedGuidance();

      updatedAssistantConnectionUuids.value = [];
      deletedAssistantConnectionIds.value = [];
      assistantsToUpdate.value = [];
      documentStoresToUpdate.value = [];
      dataStoresToUpdate.value = [];

      // Use nextTick to ensure all reactive updates are processed before setting projectDirty to false
      await nextTick();
      projectDirty.value = false;
    } catch (error) {
      console.error("Error saving project management:", error);
    // Optionally, you can set projectDirty to true if there's an error
    // projectDirty.value = true;
    }
  }

  async function saveDataForms() {
    if (!dataFormRefsToUpdate.value.length) {
      return;
    }

    const promises = dataFormRefsToUpdate.value.map((dataFormRef) => {
      const dataForm = dataForms.value.find(f => f.ref === dataFormRef);
      if (dataForm) {
        return updateDataForm(dataForm.orgSlug, dataForm.slug, dataForm);
      }
    });

    const formResults = await Promise.all(promises);

    for (const form of formResults) {
      const index = dataForms.value.findIndex(f => f.ref === form.ref);
      if (index !== -1) {
        dataForms.value[index] = form;
      } else {
        dataForms.value.push(form);
      }
    }

    dataFormRefsToUpdate.value = [];
  }

  async function saveDataStores() {
    if (!dataStoresToUpdate.value.length) {
      return;
    }

    const promises = dataStoresToUpdate.value.map((dataStore) => {
      return updateStore(dataStore.orgSlug, dataStore.slug, dataStore);
    });

    const storeResults = await Promise.all(promises);

    for (const store of storeResults) {
      const index = dataStores.value.findIndex(s => s.ref === store.ref);
      if (index !== -1) {
        dataStores.value[index] = store;
      } else {
        dataStores.value.push(store);
      }
    }
  }

  async function saveDocumentStores() {
    if (!documentStoresToUpdate.value.length) {
      return;
    }

    const promises = documentStoresToUpdate.value.map((documentStore) => {
      return updateStore(documentStore.orgSlug, documentStore.slug, documentStore);
    });

    const storeResults = await Promise.all(promises);

    for (const store of storeResults) {
      const index = documentStores.value.findIndex(s => s.ref === store.ref);
      if (index !== -1) {
        documentStores.value[index] = store;
      } else {
        documentStores.value.push(store);
      }
    }
  }

  async function saveAllChanges(testOnly = false) {
    // Before we save the workspace we need to present the current state
    // so the user knows what they will be saving
    const currentWorkspaceId = appStore.workspaceStore.currentWorkspaceId;

    if (currentWorkspaceId) {
      log.info("Opening Dialog");
      const workspaceUpdate = appStore.workspaceStore.buildWorkspaceUpdate();
      const dialog = createConfirmDialog(KodexaWorkspaceUpdateConfirm);
      dialog.onConfirm(async (retrain: boolean) => {
        try {
          await appStore.workspaceStore.saveWorkspace();
          await appStore.workspaceStore.saveWorkspaceObjects();
          await appStore.workspaceStore.saveUpdatedTaxonomies();
          await saveUpdatedGuidance();
          await saveProjectManagement();

          if (retrain) {
            await retrainModels();
          }
          notify({
            group: "generic",
            title: "Success",
            text: "Workspace changes saved",
          }, 3000);
        } catch (e) {
          notify({
            group: "error",
            title: "Error",
            text: "Error saving workspace changes",
          }, 5000);
        }
      });
      log.info("Revealing dialog");
      let closing = false;
      await dialog.reveal({
        workspaceUpdate,
        projectDirty: projectDirty.value,
        workspaceDirty: appStore.workspaceStore.workspaceDirty,
        updatedTaxonomyRefs: appStore.workspaceStore.updatedTaxonomyRefs,
        updatedDataFormRefs: dataFormRefsToUpdate.value,
        updatedGuidanceSetRefs: updatedGuidanceSetRefs.value,
        deletedGuidanceSetRefs: deletedGuidanceSetRefs.value,
        testOnly,
        onClose: () => {
          log.info("Closing dialog");
          closing = true;
          dialog.close();
        },
      });
      return !closing;
    } else {
      log.info("Opening Project Only Dialog");
      const workspaceUpdate = {};
      const dialog = createConfirmDialog(KodexaWorkspaceUpdateConfirm);
      dialog.onConfirm(async (retrain: boolean) => {
        await saveUpdatedGuidance();
        await saveUpdatedTaxonomies();
        await saveProjectManagement();
        await saveDataForms();

        if (retrain) {
          await retrainModels();
        }
      });
      log.info("Revealing dialog");
      let closing = false;
      await dialog.reveal({
        workspaceUpdate,
        updatedTaxonomyRefs: appStore.workspaceStore.updatedTaxonomyRefs,
        updatedGuidanceSetRefs: appStore.projectStore.updatedGuidanceSetRefs,
        deletedGuidanceSetRefs: appStore.projectStore.deletedGuidanceSetRefs,
        projectDirty: appStore.projectStore.projectDirty,
        workspaceDirty: appStore.workspaceStore.workspaceDirty,
        testOnly,
        onClose: () => {
          log.info("Closing dialog");
          closing = true;
          dialog.close();
        },
      });
      return !closing;
    }
  }

  async function saveDashboards() {
    if (project.value) {
      log.info("Saving dashboards");
      // Save each of the dashboards
      const updatedDashboards = [];
      for (const dashboard of dashboards.value) {
        updatedDashboards.push(await updateDashboard(dashboard.orgSlug as string, dashboard.slug as string, dashboard));
      }
      dashboards.value = updatedDashboards;
      projectDirty.value = false;
    }
  }

  function addGuidance(guidanceSet: GuidanceSet) {
    if (!guidanceSet.guidance) {
      guidanceSet.guidance = [];
    }

    guidanceSet.guidance.push({} as Guidance);
    guidance.value.set(guidanceSet.ref as string, guidanceSet);
    updatedGuidanceSetRefs.value.push(guidanceSet.ref as string);
    projectDirty.value = true;
  }

  function updateGuidanceSet(guidanceSet: GuidanceSet) {
    guidance.value.set(guidanceSet.ref as string, guidanceSet);
    updatedGuidanceSetRefs.value.push(guidanceSet.ref as string);
    projectDirty.value = true;
  }

  function updateGuidanceInGuidanceSet(guidanceRef: string, updatedGuidance: Guidance) {
    const guidanceToUpdate = guidance.value.get(guidanceRef);
    if (guidanceToUpdate) {
      // Find the index of the guidance in the guidance to update
      // and replace it
      const guidanceIndex = guidanceToUpdate.guidance?.findIndex(guidance => guidance.id === updatedGuidance.id);
      if (guidanceIndex !== -1) {
        guidanceToUpdate.guidance[guidanceIndex] = updatedGuidance;
      } else {
        guidanceToUpdate.guidance.push(updatedGuidance);
      }

      guidance.value.set(guidanceRef, guidanceToUpdate);

      if (!updatedGuidanceSetRefs.value.includes(guidanceRef)) {
        updatedGuidanceSetRefs.value.push(guidanceRef);
      }
      projectDirty.value = true;
    }
  }

  function deleteGuidanceInstance(guidanceInstance: Guidance, guidanceSetRef: string) {
    guidance.value.get(guidanceSetRef)?.guidance?.splice(guidance.value.get(guidanceSetRef)?.guidance?.findIndex(guidance => guidance.id === guidanceInstance.id), 1);
    updatedGuidanceSetRefs.value.push(guidanceSetRef);
    projectDirty.value = true;
  }

  async function saveUpdatedGuidance() {
    const uniqueGuidanceSetRefs = [...new Set(updatedGuidanceSetRefs.value)];
    const promises = uniqueGuidanceSetRefs.map((guidanceSetRef) => {
      log.info(`Saving guidance ${guidanceSetRef}`);
      const guidanceToUpdate = guidance.value.get(guidanceSetRef);
      const refHelper = new RefHelper(guidanceToUpdate.ref);
      return updateGuidance(refHelper.getOrgSlug(), refHelper.getSlug(), guidanceToUpdate);
    });
    await Promise.all(promises);
    updatedGuidanceSetRefs.value = [];
    deletedGuidanceSetRefs.value = [];

    const guidanceRefs = Array.from(guidance.value.keys());
    guidance.value.clear();
    // Use the guidance refs to reload them
    for (const guidanceRef of guidanceRefs) {
      const refHelper = new RefHelper(guidanceRef);
      guidance.value.set(guidanceRef, await getGuidance(refHelper.getOrgSlug(), refHelper.getSlug()));
    }
    projectDirty.value = false;
  }

  function buildParticipants() {
    const participants: ChannelParticipant[] = [];
    participants.push({ user: appStore.userStore.user as User } as ChannelParticipant);
    assistants.value.forEach((assistant) => {
      if (assistant.chatEnabled) {
        participants.push({ assistant } as ChannelParticipant);
      }
    });
    return participants;
  }

  function addSectionGuidance(selectionContext: SelectionContext, documentFamily: DocumentFamily) {
    if (Array.from(guidance.value.values()).length > 0) {
      log.info("Adding section guidance");
      Array.from(guidance.value.values()).forEach((guidanceSet: GuidanceSet) => {
        // We need to get the line UUID - so we need to find the first node
        // then find the parent line
        let lineUuid: undefined | string;
        let pageNumber: undefined | number;
        let pageNode: undefined | ContentNode;
        if (selectionContext.selectedNodes && selectionContext.selectedNodes.length > 0) {
          const firstNode = selectionContext.selectedNodes[0];
          const lineNode = firstNode.getLine();
          if (lineNode) {
            lineUuid = lineNode.uuid;
          }

          // We need to get the page number too
          pageNode = firstNode.getPage();
          if (pageNode) {
            pageNumber = pageNode.index;
          }
        }

        // Before we do anything we need to check if we have a guidance instance for this document
        // and page number
        const existingGuidance = guidanceSet.guidance?.find((guidance) => {
          return guidance.documentName === documentFamily.path && guidance.documentPage === pageNumber;
        });

        if (existingGuidance) {
          if (!existingGuidance.sectionMarkers) {
            existingGuidance.sectionMarkers = [{
              value: selectionContext.selectedValue,
              lineUuid,
            }];
          } else {
            existingGuidance.sectionMarkers.push({
              value: selectionContext.selectedValue,
              lineUuid,
            });
          }
        } else {
          const newGuidance = {
            active: true,
            guidanceType: "user-labeling",
            documentName: documentFamily.path,
            documentPage: pageNumber,
            applicableTags: [],
            sectionMarkers: [{
              value: selectionContext.selectedValue,
              lineUuid,
            }],
            sampleText: pageNode ? pageNode.getChildLines().map(line => `${line.getAllContent()} ('${line.uuid}')`).join("\n") : "",
            sampleResult: {},
          } as Guidance;

          if (!guidance.value.has(guidanceSet.ref as string)) {
            guidanceSet.guidance = [];
            guidanceSet.guidance.push(newGuidance);
            guidance.value.set(guidanceSet.ref as string, guidanceSet);
            updatedGuidanceSetRefs.value.push(guidanceSet.ref as string);
            projectDirty.value = true;
            notify({
              group: "success",
              title: "Success",
              text: "New guidance added",
            }, 5000);
          } else {
            guidance.value.get(guidanceSet.ref as string)?.guidance.push(newGuidance);
            updatedGuidanceSetRefs.value.push(guidanceSet.ref as string);
            projectDirty.value = true;
            notify({
              group: "success",
              title: "Success",
              text: "Guidance updated",
            }, 5000);
          }
        }
      });
    } else {
      log.warn("No guidance found");
      notify({
        group: "error",
        title: "Error",
        text: "No guidance found",
      }, 5000);
    }
  }

  function buildGuidanceFromTag(guidanceSet: GuidanceSet, tag: TagInstance, documentFamily: DocumentFamily) {
    // TODO need to check the guidanceType

    // If we have a new tag,  then we need to build a new guidance instance
    const taxonPath = tag.taxon.path;

    // We need to get the line UUID - so we need to find the first node
    // then find the parent line
    let lineUuid: undefined | string;
    let pageNumber: undefined | number;
    let pageNode: undefined | ContentNode;
    if (tag.nodes && tag.nodes.length > 0) {
      const firstNode = tag.nodes[0];
      const lineNode = firstNode.getLine();
      if (lineNode) {
        lineUuid = lineNode.uuid;
      }

      // We need to get the page number too
      pageNode = firstNode.getPage();
      if (pageNode) {
        pageNumber = pageNode.index;
      }
    }

    // Before we do anything we need to check if we have a guidance instance for this document
    // and page number
    const existingGuidance = guidanceSet.guidance?.find((guidance) => {
      return guidance.documentName === documentFamily.path && guidance.documentPage === pageNumber;
    });

    if (existingGuidance) {
      // Add taxonPath to applicable tags
      if (!existingGuidance.applicableTags) {
        existingGuidance.applicableTags = [];
      }
      if (!existingGuidance.applicableTags.includes(taxonPath as string)) {
        existingGuidance.applicableTags.push(taxonPath as string);
      }

      if (!existingGuidance.sampleResult) {
        existingGuidance.sampleResult = {
          taxonPath: [{
            value: tag.value,
            lineUuid,
          }],
        };
      } else {
        if (!existingGuidance.sampleResult[taxonPath as string]) {
          existingGuidance.sampleResult[taxonPath as string] = [];
        }
        existingGuidance.sampleResult[taxonPath as string].push({
          value: tag.value,
          lineUuid,
        });
      }

      updatedGuidanceSetRefs.value.push(guidanceSet.ref as string);
      projectDirty.value = true;
    } else {
      const newGuidance = {
        active: true,
        guidanceType: "user-labeling",
        documentName: documentFamily.path,
        documentPage: pageNumber,
        taxonomyRef: tag.taxonomy.ref,
        applicableTags: [taxonPath],
        sampleText: pageNode ? pageNode.getChildLines().map(line => `${line.getAllContent()} ('${line.uuid}')`).join("\n") : "",
        sampleResult: {},
      } as Guidance;

      if (!newGuidance.sampleResult) {
        newGuidance.sampleResult = {};
      }
      newGuidance.sampleResult[taxonPath as string] = [{
        value: tag.value,
        lineUuid,
      }];

      if (!guidance.value.has(guidanceSet.ref as string)) {
        guidanceSet.guidance = [];
        guidanceSet.guidance.push(newGuidance);
        guidance.value.set(guidanceSet.ref as string, guidanceSet);
        updatedGuidanceSetRefs.value.push(guidanceSet.ref as string);
        projectDirty.value = true;
      } else {
        guidance.value.get(guidanceSet.ref as string)?.guidance.push(newGuidance);
        updatedGuidanceSetRefs.value.push(guidanceSet.ref as string);
        projectDirty.value = true;
      }
    }
  }

  async function retrainModel(model: Store) {
    // We need to determine which assistant is associated with the model
    const modelRef = model.ref as string;
    if (modelAssistantMap.value.has(modelRef)) {
      log.info("Found assistant for model");
      const assistant = modelAssistantMap.value.get(modelRef);
      if (assistant) {
        // Find the train event on the assistant
        const trainEvent = assistant.definition?.eventTypes?.find(event => event.name === "train");

        if (trainEvent) {
          // We need to show the dialog to send an event to an assistant
          log.info("Found train event for assistant");
          const dialog = createConfirmDialog(KodexaSendAssistantEventPopup);
          await dialog.reveal({
            modelValue: true,
            selectedEventType: { eventType: trainEvent, assistant },
            embedExecution: true,
          });
          dialog.close();
        } else {
          log.warn("No train event found for assistant");
        }
      }
    }
  }

  async function retrainModels() {
    log.info("Retraining models");
    const models: Store[] = Array.from(modelStores.value.values());
    log.info(`Found ${models.length} models`);
    for (const modelStore of models) {
      log.info(`Checking model ${modelStore.ref} for retraining ${modelStore.metadata?.trainable}`);
      if (modelStore.metadata?.trainable) {
        await retrainModel(modelStore);
      }
    }
  }

  async function saveUpdatedTaxonomies() {
    const promises = Array.from(updatedTaxonomyRefs.value).map((taxonomyRef) => {
      return updateHandler(saveTaxonomyByRef(taxonomyRef), "Taxonomy saved");
    });
    await Promise.all(promises);
    updatedTaxonomyRefs.value = [];
  }

  function addSourceConnection(assistantId: string) {
    const assistant = assistants.value.find(a => a.id === assistantId);
    if (assistant) {
      const newConnection = { sourceAssistant: assistant, uuid: `${uuidv4()}` } as AssistantConnection;
      assistantConnections.value.add(newConnection);
      updatedAssistantConnectionUuids.value.push(newConnection.uuid as string);
      projectDirty.value = true;
    }
  }

  function addTargetConnection(assistantId: string) {
    const assistant = assistants.value.find(a => a.id === assistantId);
    if (assistant) {
      const newConnection = { targetAssistant: assistant, uuid: `${uuidv4()}` } as AssistantConnection;
      assistantConnections.value.add(newConnection);
      updatedAssistantConnectionUuids.value.push(newConnection.uuid as string);
      projectDirty.value = true;
    }
  }

  function saveAssistantConnection(connection: AssistantConnection) {
    assistantConnections.value.add(connection);
    updatedAssistantConnectionUuids.value.push(connection.uuid as string);
    projectDirty.value = true;
  }

  function updateAssistantTestOptions(assistant: Assistant) {
    // First we need to find the assistant
    const realAssistant = assistants.value.find(a => a.id === assistant.id);
    if (realAssistant) {
      realAssistant.testOptions = assistant.testOptions;
      addToAssistantsToUpdate(realAssistant);
      projectDirty.value = true;
    }
  }

  function removeConnection(assistantConnection: AssistantConnection) {
    assistantConnections.value.delete(assistantConnection);
    if (assistantConnection.id) {
      deletedAssistantConnectionIds.value.push(assistantConnection.id as string);
    }
    projectDirty.value = true;
  }

  async function copyDocumentFamily(documentFamily: DocumentFamily, storeRef: string) {
    const refHelper = new RefHelper(documentFamily.storeRef);
    const bulkCopy = {
      targetStoreRef: storeRef,
      documentFamilyIds: [documentFamily.id as string],
    } as BulkCopy;
    await updateHandler(bulkCopyWithVersion(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), bulkCopy), "Document copied");
  }

  async function lockDocumentFamilies(documentFamilies: DocumentFamily[]) {
    const refHelper = new RefHelper(documentFamilies[0].storeRef);
    const bulkLock = {
      documentFamilyIds: documentFamilies.map(documentFamily => documentFamily.id as string),
      lock: true,
    } as BulkLock;
    await updateHandler(bulkSetLockWithVersion(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), bulkLock), "Document locked");
  }

  async function unlockDocumentFamilies(documentFamilies: DocumentFamily[]) {
    const refHelper = new RefHelper(documentFamilies[0].storeRef);
    const bulkLock = {
      documentFamilyIds: documentFamilies.map(documentFamily => documentFamily.id as string),
      lock: false,
    } as BulkLock;
    await updateHandler(bulkSetLockWithVersion(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), bulkLock), "Document unlocked");
  }

  function getTaxonomiesJson(): string {
    return JSON.stringify(contentTaxonomies.value);
  }

  function getStoreByRef(storeRef: string): Store | undefined {
    return documentStores.value.find(store => store.ref === storeRef);
  }

  async function deletedAssistant(assistant: Assistant) {
    if (assistant.id) {
      await deleteAssistant(assistant.id);
      await reloadProject();
    }
  }

  function updateDataFormCards(dataFormRef: string, cards: Card[]) {
    const realDataForm = dataForms.value.find(df => df.ref === dataFormRef);
    if (realDataForm) {
      realDataForm.cards = cards;
      addToDataFormsToUpdate(realDataForm);
    }
  }

  async function updateDataFormByRef(ref: string, dataForm: DataForm) {
    const realDataForm = dataForms.value.find(df => df.ref === ref);
    if (realDataForm) {
      log.info("Updating data form");
      const updatedDataForm = await updateHandler(updateDataForm(dataForm.orgSlug, dataForm.slug, dataForm), "Data form updated");
      const index = dataForms.value.findIndex(df => df.ref === ref);
      dataForms.value[index] = updatedDataForm;
    } else {
      log.warn("Data form not found");
    }
  }

  function addToDataFormsToUpdate(dataForm: DataForm) {
    if (dataForm.ref && !dataFormRefsToUpdate.value.includes(dataForm.ref)) {
      dataFormRefsToUpdate.value.push(dataForm.ref);
      projectDirty.value = true;
    }
  }

  return {
    updateDataFormByRef,
    deletedAssistant,
    project,
    loadProject,
    documentStores,
    dataStores,
    modelStores,
    taxonomies,
    currentDocumentStore,
    clearCurrentProject,
    projectLoading,
    assistants,
    getTagMetadata,
    queryTags,
    contentTaxonomies,
    additionalTaxonOptions,
    getAvailableLabels,
    tagMetadataMap,
    dataForms,
    updateCurrentProject,
    updateComponent,
    createNewProject,
    gotoProject,
    createProjectAssistant,
    createProjectStore,
    createProjectDataForm,
    createProjectTaxonomy,
    projectSidebar,
    reprocessAssistant,
    isLoaded,
    updateWorkspaceName,
    projectDataFlow,
    saveProjectDataFlow,
    deleteCurrentProject,
    linkResource,
    cancelAssistantExecution,
    newDashboard,
    dashboards,
    updateDashboard,
    updateProjectDashboard,
    deleteProjectDashboard,
    updateDashboardOrder,
    projectMemory,
    addWidgetToDashboard,
    deleteWidgetFromDashboard,
    updateWidgetInDashboard,
    projectLoadingMax,
    projectLoadingProgress,
    uploadLocalTaxonomy,
    findSelectedTaxonById,
    saveTaxonomyByRef,
    newTaxonInTaxonomy,
    deleteTaxonFromTaxonomy,
    findParentTaxonFromTaxons,
    modelMetadata,
    modelStoreMap,
    getNativeContent,
    firstWorkspace,
    fetchProjectExecutions,
    assistantConnections,
    addAssistantConnection,
    moveTaxonBeforeTaxon,
    createTaxon,
    toggleTaxonomy,
    allDataForms,
    workspaces,
    reprocessDocuments,
    bulkDeleteDocuments,
    projectDirty,
    modelAssistantMap,
    saveTaxonomy,
    guidance,
    addTempContentTaxonomy,
    removeTempContentTaxonomy,
    reloadGuidance,
    deleteGuidanceInstance,
    addUpdatedTaxonomyRef,
    updatedTaxonomyRefs,
    saveAllChanges,
    refresher,
    saveDashboards,
    saveProjectManagement,
    addToAssistantsToUpdate,
    addToDocumentStoresToUpdate,
    addGuidance,
    updateGuidanceInGuidanceSet,
    saveUpdatedGuidance,
    updatedGuidanceSetRefs,
    deletedGuidanceSetRefs,
    buildParticipants,
    buildGuidanceFromTag,
    addSectionGuidance,
    retrainModels,
    reloadProject,
    saveUpdatedTaxonomies,
    addSourceConnection,
    addTargetConnection,
    saveAssistantConnection,
    updateAssistantTestOptions,
    removeConnection,
    copyDocumentFamily,
    lockDocumentFamilies,
    unlockDocumentFamilies,
    getTaxonomiesJson,
    getStoreByRef,
    messageTemplates,
    unlinkProjectResource,
    createResource,
    loadModel,
    addToDataStoresToUpdate,
    updateProjectNotes,
    updateGuidanceSet,
    addToTaxonomiesToUpdate,
    addToDataFormsToUpdate,
    updateDataFormCards,
  } as IProjectStore;
});
