<script lang="ts" setup>
import type { PropType } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import type { DashboardWidget } from "~/model";
import ProjectTemplateCreatorPopup
  from "~/views/studio/organizations/project/project-template-creator/project-template-creator-popup.vue";
import {notify} from "notiwind";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

function openTemplateCreator() {
  createConfirmDialog(ProjectTemplateCreatorPopup).reveal().then((result: any) => {
    if (result) {
      notify({
        title: "Project Template Created",
        text: "Your project has been converted into a template.",
        group: "generic",
      });
    }
  });
}
</script>

<template>
  <div>
    <!-- Features Section -->
    <div class="px-6 py-4">
      <div>
        <h2 class="text-xl font-bold">
          Create a Project Template
        </h2>
        <p class="mt-4">
          Transform your projects into templates and kickstart new ones with ease in Kodexa.
        </p>
        <p class="mt-8">
          <KodexaArticle article-id="9027817" text="Learn more about Project Templates" />
        </p>
        <p>
          <KodexaButton
            class="mt-8"
            id="convertProjectTemplate"
            icon="auto-fix" name="createProjectTemplate" type="primary"
            @click="openTemplateCreator"
          >
            Convert this Project into a Template
          </KodexaButton>
        </p>
      </div>
    </div>
  </div>
</template>
