<script lang="ts" setup>
import type { SplitterPaneProps } from "@progress/kendo-vue-layout";
import type { Edge, ViewportTransform } from "@vue-flow/core";
import type { Node } from "@vue-flow/core/dist/types/node";
import type { PropType, Ref } from "vue";
import type { DataFlowNode, ProcessingStep } from "~/model";
import { GridToolbar } from "@progress/kendo-vue-grid";
import { Splitter } from "@progress/kendo-vue-layout";
import { Controls } from "@vue-flow/controls";
import { useVueFlow, VueFlow } from "@vue-flow/core";
import { MiniMap } from "@vue-flow/minimap";
import { ref } from "vue";
import { useLayout } from "~/components/explainPlan/useLayout";
import { log } from "~/utils/logger";

const props = defineProps({
  steps: {
    type: Object as PropType<ProcessingStep[]>,
  },
  documentFamilyId: {
    type: String as PropType<string>,
  },
});

const reactiveSteps = ref(props.steps);

/**
 * useVueFlow provides all event handlers and store properties
 * You can pass the composable an object that has the same properties as the VueFlow component props
 */
const {
  setNodes,
  setEdges,
  fitView,
  setCenter,
  getViewport,
} = useVueFlow();

const zoomedNodeId = ref(null);
const isZoomed = computed(() => zoomedNodeId.value !== null);

const {
  onLayout,
} = useLayout();

const viewport = ref({ zoom: 0.4, x: 0, y: 0 });

function performLayout() {
  onLayout("LR");
}

const panes: Ref<SplitterPaneProps[]> = ref([
  {
    size: undefined,
    min: "2%",
    collapsible: false,
    content: "flow",
  },
  {
    size: "40%",
    min: "5%",
    collapsible: true,
    collapsed: true,
    content: "properties",
  },
] as SplitterPaneProps[]);

function onSplitterChange(changeEvent: any) {
  if (changeEvent.newState) {
    panes.value = changeEvent.newState;
  }
}

const selectedNode = ref();

function selectNode(node: DataFlowNode | undefined) {
  selectedNode.value = node;
  if (node) {
    panes.value[1].collapsed = false;
  }
}

function setViewport(viewportTransform: ViewportTransform) {
  viewport.value = viewportTransform;
}

function convertToVueFlow(data) {
  const nodes = [] as Node[];
  const edges = [] as Edge[];

  function traverse(item) {
    const newMetadata = {
      ...item.metadata,
      label: item.name,
    };

    // Convert arrays to comma-delimited strings and objects to JSON strings
    for (const key in newMetadata) {
      if (Array.isArray(newMetadata[key])) {
        newMetadata[key] = newMetadata[key].join(", ");
      } else if (typeof newMetadata[key] === "object") {
        newMetadata[key] = JSON.stringify(newMetadata[key], null, 2);
      }
    }

    nodes.push({
      id: item.id,
      data: {
        raw: item.metadata,
        clean: newMetadata,
        documentFamilyId: props.documentFamilyId,
      },
      type: "custom",
      position: { x: 100, y: 100 },
    } as Node);

    // Handle multiple parents
    if (item.parents && item.parents.length > 0) {
      item.parents.forEach((parent) => {
        edges.push({
          id: `${parent.id}-${item.id}`,
          source: parent.id,
          target: item.id,
        });
      });
    }

    // Traverse children if they exist
    if (item.children && item.children.length > 0) {
      item.children.forEach((child) => {
        edges.push({
          id: `${item.id}-${child.id}`,
          source: item.id,
          target: child.id,
        });
        traverse(child);
      });
    }
  }

  data.forEach(item => traverse(item));

  return { nodes, edges };
}

watchDebounced(reactiveSteps, () => {
  log.info("Data flow nodes changed");
  const { nodes, edges } = convertToVueFlow(reactiveSteps.value);
  setNodes(nodes);
  setEdges(edges);
  onLayout("LR");
}, {
  deep: true,
  immediate: true,
  debounce: 500,
});

const showRaw = ref(false);

const ZOOM_SETTINGS = {
  maxZoom: 2,
  zoomFactor: 12,
  offsetX: -140,
  offsetY: -200,
  duration: 800,
};

function zoomToNode(node: Node) {
  if (zoomedNodeId.value !== node.id) {
    const viewport = getViewport();
    const { x, y, width = 0, height = 0 } = node.position;

    const targetZoom = Math.min(ZOOM_SETTINGS.maxZoom, viewport.zoom * ZOOM_SETTINGS.zoomFactor);

    const centerX = x + width / 2 - ZOOM_SETTINGS.offsetX;
    const centerY = y + height / 2 - ZOOM_SETTINGS.offsetY;

    setCenter(centerX, centerY, { zoom: targetZoom, duration: ZOOM_SETTINGS.duration });

    zoomedNodeId.value = node.id;
  } else {
    zoomOut();
  }
}

function zoomOut() {
  fitView({
    padding: 0.5,
    duration: ZOOM_SETTINGS.duration,
  });
  zoomedNodeId.value = null;
}

function handlePaneClick(event: MouseEvent) {
  if (isZoomed.value && !event.target.closest(".vue-flow__node")) {
    zoomOut();
  }
}
</script>

<template>
  <GridToolbar class="border-0 bg-white dark:bg-gray-800">
    <KodexaButton
      id="flowFitToView"
      type="secondary"
      size="small"
      icon="fit-to-screen-outline"
      @click="fitView({ padding: .5 })"
    >
      Fit to View
    </KodexaButton>
    <KodexaButton id="flowLayout" size="small" type="secondary" icon="layers-outline" @click="performLayout">
      Layout
    </KodexaButton>
    <KodexaArticle class="mt-2 ml-2" article-id="9787648" text="Learn more about explain plans" />
  </GridToolbar>
  <div style="width: 100%; height: calc(100vh - 20rem)">
    <Splitter
      :panes="panes"
      style="height: calc(100vh - 64px); width: 100%"
      orientation="horizontal"
      @change="onSplitterChange"
    >
      <template #flow>
        <VueFlow
          class="processing-graph-flow"
          :default-viewport="viewport" :min-zoom="0.01"
          :max-zoom="4"
          fit-view-on-init
          :default-edge-options="{ type: 'smoothstep' }"
          @viewport-change="setViewport"
          @pane-click="handlePaneClick"
        >
          <MiniMap />
          <Controls />
          <template #node-custom="node">
            <KodexaExplainPlanGraphNode
              :key="node.id"
              :node="node"
              :show-raw="showRaw"
              @selected="selectNode(node)"
              @zoom-to-node="zoomToNode"
            />
          </template>
        </VueFlow>
      </template>
      <template #properties>
        <div style="height: calc(100vh - 10rem); width: 100%; overflow: auto">
          <KodexaExplainPlanGraphPanel v-if="selectedNode" :node="selectedNode" />
          <div v-else class="mt-32 text-center">
            <MaterialDesignIcon class="mx-auto text-gray-400" name="settings" size="30" />
            <h3 class="mt-2 text-sm font-semibold text-gray-900">
              No Selected Step
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Select a step from the diagram to see the properties available
            </p>
            <div class="mt-6" />
          </div>
        </div>
      </template>
    </Splitter>
  </div>
</template>

<style>
:root {
  /* Light mode variables */
  --flow-node-bg: #ffffff;
  --flow-node-border: #e2e8f0;
  --flow-node-text: #1a202c;
}

/* Alternative: class-based dark mode toggle */
.dark {
  --flow-node-bg: #1a202c;
  --flow-node-border: #2d3748;
  --flow-node-text: #e2e8f0;
}

.processing-graph-flow .vue-flow__node-custom {
  padding: 0;
  background: var(--flow-node-bg);
  color: var(--flow-node-text);
  border: 1px solid var(--flow-node-border);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 390px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.processing-graph-flow button {
  width: 25px;
  height: 25px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3);
  box-shadow: 0 5px 10px #0000004d;
  cursor: pointer
}

.processing-graph-flow button:hover {
  opacity: .9;
  transform: scale(105%);
  transition: .25s all ease
}
</style>
