<script setup lang="ts">
import type { PropType } from "vue";
import type { Execution } from "~/model";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";

defineProps({
  executions: {
    type: Array as PropType<Execution[]>,
    required: true,
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
  popupLogs: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const emit = defineEmits(["confirm", "cancel", "update:modelValue"]);
const open = ref(true);

function closePopup() {
  if (!open.value) {
    emit("update:modelValue", false);
  }
  return open.value;
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="closePopup()">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 95%; height: 720px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-2 pb-2 pt-2 text-left shadow-xl transition-all"
            >
              <div class="right e-0 absolute top-0 hidden sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="h-6 w-6" aria-hidden="true" @click="closePopup" />
                </button>
              </div>
              <div>
                <KodexaExecution v-for="execution in executions" :key="execution.id" :execution="execution" :popup-logs="popupLogs" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
