<script setup lang="ts">
import type { PropType } from "vue";
import type { AssistantExecution, Execution, ExecutionEvent, ModelInteraction } from "~/model";
import { useTimeoutPoll } from "@vueuse/core";
import Decimal from "decimal.js";
import { storeToRefs } from "pinia";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { listExecutionEventsForExecutionId } from "~/api/executions/executions";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";
import { useWorkspace } from "~/store/useWorkspace";
import { parseLocalDateTime, toHumanDuration } from "~/utils/date";
import { log } from "~/utils/logger";

interface RecentExecution {
  execution: Execution;
  assistant: AssistantExecution;
}

const props = defineProps({
  recentExecution: {
    type: Object as PropType<RecentExecution>,
    required: true,
  },
  documentFamily: {
    type: Object as PropType<{ path: string; [key: string]: any }>,
    required: true,
  },
  cancelIcon: {
    type: String,
    required: false,
    default: "cancel",
  },
});

const emit = defineEmits(["changed"]);

const executionEvents = ref([] as ExecutionEvent[]);
const modelInteractions = ref([] as ModelInteraction[]);
const cost = ref(new Decimal(0));
const analyzingCost = ref(true);
const lastUpdated = ref(new Date());

async function fetchData() {
  if (props.recentExecution.execution.id) {
    analyzingCost.value = true;
    cost.value = new Decimal(0);
    executionEvents.value = [];
    modelInteractions.value = [];

    const eventPage = await listExecutionEventsForExecutionId(props.recentExecution.execution.id as string, { pageSize: 100 });
    executionEvents.value = eventPage.content;

    executionEvents.value.forEach((executionEvent) => {
      if (executionEvent.modelUsage) {
        modelInteractions.value.push(...executionEvent.modelUsage.interactions);

        executionEvent.modelUsage.interactions.forEach((modelInteraction) => {
          if (modelInteraction.cost) {
            cost.value = cost.value.add(new Decimal(modelInteraction.cost));
          }
        });
      }
    });
    analyzingCost.value = false;
  }
}

watchEffect(() => {
  fetchData();
});

function refresh() {
  lastUpdated.value = new Date();
}

const { resume } = useTimeoutPoll(refresh, 1000);

resume();

interface ActivityEntry {
  id: number;
  lastUpdated: any;
  status: any;
  actor: any;
  message: string;
  exception: any;
  timeTaken: string | undefined;
  when: string | undefined;
  loggingEnabled: boolean;
  executionId: string;
}

const activity = computed(() => {
  const activity: ActivityEntry[] = [];

  const statusMessages = {
    SUCCEEDED: "Completed successfully",
    FAILED: "Failed",
    RUNNING: "Running",
    PENDING: "Pending",
    CANCELLED: "Cancelled",
    PENDING_REPROCESSING: "Pending reprocessing",
  };

  let message = statusMessages[props.recentExecution.execution.status] as string;

  if (props.recentExecution.execution.status === "FAILED") {
    message = props.recentExecution.execution.exceptionDetails?.errorMessage;
  }

  activity.push({
    id: 1,
    lastUpdated: lastUpdated.value,
    status: props.recentExecution.execution.status,
    actor: props.recentExecution.assistant.name,
    loggingEnabled: props.recentExecution.execution.loggingEnabled,
    executionId: props.recentExecution.execution.id,
    documentFamily: props.documentFamily,
    message,
    exception: props.recentExecution.execution.exceptionDetails,
    timeTaken: props.recentExecution.execution.startDate && props.recentExecution.execution.endDate ? toHumanDuration(parseLocalDateTime(props.recentExecution.execution.startDate), parseLocalDateTime(props.recentExecution.execution.endDate)) : undefined,
    when: props.recentExecution.execution.startDate ? parseLocalDateTime(props.recentExecution.execution.startDate).toRelative() : "Missing start date",
  } as ActivityEntry);
  return activity;
});

const loading = ref(false);

async function reprocess() {
  log.info("Reprocessing clicked");

  await appStore.workspaceStore.showSaveWarning();
  const reprocessConfirmDialog = createConfirmDialog(KodexaConfirm);

  const {
    isCanceled,
  } = await reprocessConfirmDialog.reveal({
    title: "Reprocess Assistant?",
    message: "Are you sure you want to reprocess this document?",
    notes: "This will reprocess the document using the same assistant and configuration, but will remove all labels and annotations.",
    type: "danger",
    confirmIcon: "file-document-refresh-outline",
    confirmText: "Reprocess",
  });
  if (!isCanceled) {
    loading.value = true;
    try {
      await appStore.projectStore.reprocessAssistant(props.recentExecution.assistant.id, props.documentFamily);
      emit("changed");
    } finally {
      loading.value = false;
    }
  }
}

async function cancel() {
  const cancelDialog = createConfirmDialog(KodexaConfirm);
  const {
    isCanceled,
  } = await cancelDialog.reveal({
    title: "Cancel Assistant?",
    message: "Are you sure you want to cancel?",
    type: "danger",
  });
  if (!isCanceled) {
    loading.value = true;
    try {
      await appStore.projectStore.cancelAssistantExecution(props.recentExecution.execution);
      emit("changed");
    } finally {
      loading.value = false;
    }
  }
}

const { user } = storeToRefs(appStore.userStore);

const {
  isZeroCredits,
} = storeToRefs(appStore.organizationStore);

const workspaceStore = useWorkspace();
function openModelInteractionsCost() {
  const documentPath = props.documentFamily.path || "Unknown Document";
  const costView = {
    viewType: "costView",
    id: `cost-view-${props.recentExecution.execution.id}`,
    executionId: props.recentExecution.execution.id,
    title: `${documentPath} (Cost Analysis)`,
    modelInteractions: modelInteractions.value,
    totalCost: cost.value.toDecimalPlaces(5).toString(),
  };
  workspaceStore.addView(costView);
}

const showDeveloperTools = computed(() => user.value.showDeveloperTools);
</script>

<template>
  <div class="p-1 dark:bg-gray-800" :style="{ width: '550px', height: showDeveloperTools ? '460px' : '360px' }">
    <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700 p-2" :style="{ height: showDeveloperTools ? '400px' : '300px' }">
      <li v-for="activityItem in activity" :key="activityItem.id" class="py-4">
        <div class="flex space-x-3">
          <KodexaStatusIcon :status="recentExecution.execution?.status" size="48" />
          <div class="flex-1 space-y-1">
            <div class="flex items-center justify-between">
              <h3 class="font-medium dark:text-white">
                {{ activityItem.actor }}
              </h3>
              <p class="text-sm text-gray-500 dark:text-gray-400">
                {{ activityItem.when }}
              </p>
            </div>
            <p class="mt-1 text-sm text-gray-500 dark:text-gray-400 h-12">
              <KodexaClipboardable :content="activityItem.message" :show-content="true" :trim="true" :trim-length="120" />
            </p>
            <div class="h-10">
              <p v-if="activityItem.timeTaken" class="text-xs text-gray-500 dark:text-gray-400">
                Took {{ activityItem.timeTaken }}
              </p>
              <p v-if="analyzingCost && user.showDeveloperTools" class="text-xs text-gray-500 dark:text-gray-400">
                Analyzing cost...
              </p>
            </div>
            <p
              v-if="activityItem.timeTaken && modelInteractions?.length > 0 && user.showDeveloperTools && !analyzingCost && (!new Decimal(0).eq(cost))"
              class="text-xs text-gray-500 dark:text-gray-400 cursor-pointer model-cost-link dark:model-cost-link"
              @click="openModelInteractionsCost"
            >
              {{ modelInteractions.length }} model interactions costing ${{ cost.toDecimalPlaces(5).toString() }}
            </p>
            <KodexaMiniExecutionView
              v-if="recentExecution.execution?.id" class="mt-4"
              :execution-id="recentExecution.execution.id"
              :document-family="documentFamily"
            />
          </div>
        </div>
      </li>
    </ul>
    <div style="float: right;" class="mb-3 mr-2">
      <div v-if="!isZeroCredits">
        <KodexaButton
          v-if="props.recentExecution.execution.status === 'FAILED' || props.recentExecution.execution.status === 'SUCCEEDED' || props.recentExecution.execution.status === 'CANCELLED'"
          id="reprocessExecution"
          v-close-popper
          :icon="documentFamily.locked ? 'lock' : 'file-document-refresh-outline'"
          :type="documentFamily.locked ? 'secondary' : 'danger'"
          width="150px"
          :loading="loading"
          :disabled="documentFamily.locked"
          @click="reprocess"
        >
          {{ documentFamily.locked ? 'Locked' : 'Reprocess' }}
        </KodexaButton>
      </div>
      <div v-else>
        <div class="flex justify-center mb-3 dark:border-gray-700" style="border-color: rgba(0, 0, 0, 0.08);">
          <div class="mr-4">
            <MaterialDesignIcon name="warning" size="40" aria-hidden="true" />
          </div>
          <p class="mt-3 text-sm text-center dark:text-gray-300">
            Your organization does not have any AI credits. <br>
            <span class="text-xs dark:text-gray-400">Reprocessing documents is disabled.</span>
          </p>
        </div>
        <div class="flex justify-between">
          <div />
          <KodexaButton
            icon="file-document-refresh-outline"
            type="gray"
            width="150px"
            disabled
          >
            Reprocess
          </KodexaButton>
        </div>
      </div>
      <KodexaButton
        v-if="props.recentExecution.execution.status === 'PENDING' || props.recentExecution.execution.status === 'RUNNING' || props.recentExecution.execution.status === 'PENDING_REPROCESSING'"
        id="cancelExecution"
        v-close-popper
        type="secondary"
        :icon="cancelIcon"
        :loading="loading"
        @click="cancel"
      >
        Cancel
      </KodexaButton>
    </div>
  </div>
</template>

<style scoped>
.model-cost-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.model-cost-link:hover {
  color: #0056b3;
}

.dark .model-cost-link {
  color: #3b82f6;
}

.dark .model-cost-link:hover {
  color: #60a5fa;
}
</style>
