<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import TaskManage from "~/components/tasks/task-manage.vue";
import appStore from "~/store";
import Workspace from "~/views/studio/organizations/project/workspace/workspace.vue";

// Store and Route
const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);

// Task State
const { hasChanges, currentTask } = storeToRefs(appStore.taskStore);

const currentTab = ref(undefined);

// Initialize the first tab
watch(computed(() => currentTab.value), () => {
  if (!currentTab.value) {
    currentTab.value = {
      ref: "taskManage",
      name: "Manage",
      singleWidget: {
        type: "taskManage",
      },
    };
  }
}, { immediate: true });

const orderedTabs = computed(() => {
  return [
    {
      ref: "taskManage",
      name: "Overview",
      singleWidget: {
        type: "taskManage",
      },
    },
    {
      ref: "taskWorkspace",
      name: "Workspace",
      singleWidget: {
        type: "taskWorkspace",
      },
    },
  ] as any[];
});
</script>

<template>
  <div class="h-[90vh]">
    <!-- Tabs Navigation -->
    <KodexaTabStrip
      :items="orderedTabs" :selected="currentTab" @selected="val => {
        currentTab = val
      } "
    >
      <!-- Save and Cancel Buttons -->
      <div class="mt-5 mr-8">
        <KodexaButton
          type="primary"
          size="small"
          icon="content-save"
          class="mr-1"
          :disabled="!hasChanges"
          @click="appStore.taskStore.saveChanges()"
        >
          Save
        </KodexaButton>
        <KodexaButton
          type="secondary"
          size="small"
          icon="cancel"
          :disabled="!hasChanges"
          @click="appStore.taskStore.resetChanges()"
        >
          Cancel
        </KodexaButton>
      </div>
    </KodexaTabStrip>

    <!-- Tab Content -->
    <div>
      <div v-if="currentTab?.ref === 'taskManage' && currentTask">
        <TaskManage
          class="mt-2 px-2"
        />
      </div>
      <div v-else-if="currentTab?.ref === 'taskWorkspace'">
        <Workspace :key="currentWorkspaceId" :workspace-options="{ hideToolbar: true, hideClose: true }" />
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
