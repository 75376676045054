<script setup lang="ts">
import { storeToRefs } from "pinia";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";

const {
  project,
} = storeToRefs(appStore.projectStore);

const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
const isTicked = ref(true);

const localProject = ref(undefined);

watch(project, (newValue) => {
  localProject.value = newValue;
}, { deep: true, immediate: true });

watch(localProject, (newValue) => {
  if (newValue) {
    appStore.projectStore.updateCurrentProject(newValue);
  }
}, {
  deep: true,
});

async function deleteProject(deleteAssoc: boolean) {
  const {
    isCanceled,
  } = await deleteConfirmDialog.reveal({
    title: `Delete project ${project.value.name} ?`,
    message: deleteAssoc
      ? "Are you sure you want to delete this project and all related resources?"
      : "Are you sure you want to delete this project? (All related resources are not included)",
    type: "danger",
    confirmText: "Delete Project",
    confirmIcon: "delete",
  });
  if (!isCanceled) {
    await appStore.projectStore.deleteCurrentProject(deleteAssoc);
  }
}
</script>

<template>
  <div>
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5 ">
      <div class="space-y-6 sm:px-6 lg:col-span-12 lg:px-0">
        <div>
          <div class="space-y-6 bg-white dark:bg-gray-800 px-4 py-6 sm:p-6" style="height: calc(100vh - 250px)">
            <KodexaArticle class="mb-5 mt-3" article-id="8729166" text="Learn more about projects" :slide="false" />

            <div class="grid grid-cols-6 gap-0">
              <div class="col-span-6 sm:col-span-6">
                <KodexaTextInput
                  v-model="localProject.name"
                  name="projectName"
                  type="text"
                  autocomplete="project-name"
                  label="Project Name"
                />
              </div>
              <div class="col-span-1" />
              <div class="col-span-6 mt-2 sm:col-span-6">
                <KodexaTextArea
                  v-model="localProject.description"
                  name="projectDescription"
                  type="text"
                  :rows="5"
                  autocomplete="project-description"
                  label="Project Description"
                />
              </div>
            </div>

            <div class="w-4/5 bg-white dark:bg-gray-800 shadow sm:rounded-lg">
              <div class="px-4 py-5 sm:p-6">
                <div class="sm:flex sm:items-start sm:justify-between">
                  <div>
                    <h3 class="text-base font-semibold leading-6 text-gray-900">
                      Delete Project
                    </h3>
                    <div class="mt-2 max-w-xl text-sm text-gray-500">
                      <p>
                        Once a project is deleted it can not be recovered, by default we will also delete associated
                        resources unless they are shared with another project.
                      </p>
                      <KodexaCheckbox
                        v-model="isTicked"
                        name="deleteAssoc"
                        label="Also delete related resources not associated with other projects"
                      />
                    </div>
                  </div>
                  <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
                    <KodexaButton id="deleteProject" icon="delete" type="danger" @click="deleteProject(isTicked)">
                      Delete Project
                    </KodexaButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
