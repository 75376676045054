import { defineStore } from "pinia";
import { executeOrganizationQuery } from "~/api/organizations/organizations";

export const useOrganizationQuery = defineStore("organizationQuery", () => {
  async function query(organizationId: string, payload: { [key: string]: { [key: string]: unknown } }) {
    return await executeOrganizationQuery(organizationId, {
      entityType: "",
      payload,
    });
  }

  return {
    query,
  };
});
