<script lang="ts" setup>
import type { PropType } from "vue";
import type { Project, TaskActivity, User } from "~/model";
import { ref } from "vue";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import KodexaDateTimeView from "~/components/util/kodexa-date-time-view.vue";

interface AssigneeOption {
  id: string;
  user: User;
}

const props = defineProps({
  activity: {
    type: Object as PropType<TaskActivity>,
    required: true,
  },
});

// External data that should be properly injected
const assigneeOptions = ref<AssigneeOption[]>([]);
const projects = ref<{ content: Project[] }>({ content: [] });

const statusMap = {
  TODO: "To Do",
  IN_PROGRESS: "In Progress",
  DONE: "Done",
} as const;
</script>

<template>
  <div class="flex items-start space-x-3">
    <KodexaUserAvatar
      :user="activity.user"
      size="5"
      class="flex-shrink-0 rounded-full"
    />
    <div class="flex-1 min-w-0">
      <span v-if="!activity.detail?.type">
        <b>{{ activity.user?.fullName || 'Unknown' }}</b> {{ activity.content }}
      </span>

      <template v-else>
        <span v-if="activity.detail.type === 'TASK_CREATED'">
          <b>{{ activity.user?.fullName || 'Unknown' }}</b> created the task
        </span>

        <span v-else-if="activity.detail.type === 'TITLE_CHANGED'">
          <b>{{ activity.user?.fullName || 'Unknown' }}</b> changed task name from
          "<b>{{ activity.detail.interpolatedValues.oldTitle }}</b>" to
          "<b>{{ activity.detail.interpolatedValues.newTitle }}</b>"
        </span>

        <span v-else-if="activity.detail.type === 'DESCRIPTION_UPDATED'">
          <b>{{ activity.user?.fullName || 'Unknown' }}</b> updated the task description
        </span>

        <span v-else-if="activity.detail.type === 'STATUS_CHANGED'">
          <b>{{ activity.user?.fullName || 'Unknown' }}</b> changed status
        </span>

        <span v-else-if="activity.detail.type === 'ASSIGNEE_CHANGED'">
          <b>{{ activity.user?.fullName || 'Unknown' }}</b>
          {{ activity.detail.oldValue && activity.detail.oldValue !== 'null' ? 're-assigned' : 'assigned' }} to
          <b>{{ assigneeOptions.value?.find(a => a.id === activity.detail.newValue)?.user ? formatUserName(assigneeOptions.value.find(a => a.id === activity.detail.newValue)!.user) : 'unassigned' }}</b>        </span>

        <span v-else-if="activity.detail.type === 'PROJECT_CHANGED'">
          <b>{{ activity.user?.fullName || 'Unknown' }}</b> changed project from
          <b>{{ props.activity.tra?.name || 'none' }}</b> to
          <b>{{ projects.value?.content?.find(p => p.id === activity.detail.newValue)?.name }}</b>
        </span>

        <span v-else-if="activity.detail.type === 'DUE_DATE_CHANGED'">
          <b>{{ activity.user?.fullName || 'Unknown' }}</b>
          <template v-if="activity.detail.oldValue && activity.detail.oldValue !== 'null'">
            changed due date from <b>{{ new Date(activity.detail.oldValue).toLocaleDateString() }}</b> to
          </template>
          <template v-else>
            set due date to
          </template>
          <b>{{ activity.detail.newValue ? new Date(activity.detail.newValue).toLocaleDateString() : 'none' }}</b>
        </span>
      </template>

      <p class="text-gray-500 text-xs">
        <KodexaDateTimeView
          :date="activity.createdOn"
          ago
        />
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>
