<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { useListOrganizations } from "~/api/organizations/organizations";
import router from "~/router/router";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  flow: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const gridHelper = createGridHelper("popup-organizations-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "name",
});
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListOrganizations(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = [
  { title: "Name", field: "name" },
  { title: "Description", field: "description" },
];

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});

const query = ref("");

const computedQuery = computed({
  get() {
    return query.value;
  },
  set(value) {
    query.value = value;
    gridHelper.setQuery(value);
    gridHelper.setPage(1);
  },
});

function close() {
  computedQuery.value = "";
  emit("update:modelValue", false);
}

function selectOrganization(event) {
  const organization = event.dataItem;
  appStore.organizationStore.setCurrentOrganization(organization, props.flow);
  emit("update:modelValue", false);
}

function newOrganization() {
  emit("update:modelValue", false);
  router.push({ path: "/a/o/new" });
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50/75 dark:bg-gray-900/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4  text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 80%; height: 600px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500"
                  @click="close"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="size-4" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle class="flex text-lg font-medium leading-4 text-gray-900">
                <KodexaHeader
                  class="ml-4"
                  title="Your Organizations"
                >
                  <template #subTitle>
                    Select the organization to switch your context and see its projects.
                  </template>
                </KodexaHeader>
              </DialogTitle>

              <div class="mx-4 mt-1" style="height: 430px">
                <Toolbar class="mb-1 border-0 bg-white dark:bg-gray-800">
                  <label for="query" class="sr-only">Query</label>
                  <KodexaTextInput
                    id="queryOrganization"
                    v-model="computedQuery" :show-clear="true" type="text" name="query"
                    class="-ml-1 block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm"
                    placeholder="Filter organizations"
                  />
                  <Button
                    id="filterIcon" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-gray-900 dark:text-gray-50"
                    :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()"
                  />
                  <ToolbarSpacer />
                  <KodexaButton v-if="!flow" id="newOrganizationButton" type="primary" icon="auto-fix" title="New Organization" @click="newOrganization">
                    New Organization
                  </KodexaButton>
                </Toolbar>
                <Grid
                  class="h-full"
                  :data-items="data ? data.content : []"
                  :pageable="pageSettings"
                  :loader="isLoading"
                  :sort="sort"
                  scrollable="scrollable"
                  :skip="pageSettings.skip as number"
                  :total="pageSettings.total as number"
                  :page-size="pageSettings.take as number"
                  :take="pageSettings.take as number"
                  :columns="columns"
                  :filterable="showFilter"
                  :filter="filter"
                  :resizable="true"
                  :sortable="true"
                  @pagechange="gridHelper.pageChangeHandler($event)"
                  @filterchange="gridHelper.filterChange($event)"
                  @sortchange="gridHelper.sortChange($event)"
                  @rowclick="selectOrganization"
                >
                  <GridNoRecords>
                    <KodexaGridNoRecords
                      :error="error" :is-loading="isLoading" :is-error="isError"
                      no-records-message="No organizations found"
                    />
                  </GridNoRecords>
                </Grid>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
