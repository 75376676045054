<script setup lang="ts">
import type { Ref } from "vue";
import type { GuidanceSet } from "~/model";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { EllipsisHorizontalIcon } from "@heroicons/vue/20/solid";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { deleteRetainedGuidance, useListRetainedGuidances } from "~/api/retained-guidance/retained-guidance";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";
import { log } from "~/utils/logger";

const selectedGuidanceSet = ref(null);
const showAddGuidanceSet = ref(false);

const { project } = storeToRefs(appStore.projectStore);

const gridHelper = createGridHelper(`project-processing-list-${project.id}`, {
  page: 1,
  pageSize: 6,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListRetainedGuidances(gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = computed(() => {
  return [
    { title: "Document", field: "documentFamily.path", width: "500px" },
    { title: "Guidance Key", field: "guidanceKey" },
    { title: "Data Definition Ref", field: "taxonomyRef" },
    { title: "Data Element Path", field: "taxonPath" },
    { title: "", field: "actions", width: "100px", cell: "actions" },
  ];
});

watch(selectedGuidanceSet, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`guidanceSetRef:'${newValue.ref}'`);
    gridHelper.setPage(1);
    refetch();
  } else {
    gridHelper.setBaseFilter("");
    gridHelper.setPage(1);
    refetch();
  }
});

// Function to handle guidance set selection
function selectGuidanceSet(set) {
  selectedGuidanceSet.value = set;
}

function unlinkGuidanceSet(set) {

}

function deleteGuidanceSet(set) {

}

const guidanceSets: Ref<GuidanceSet[]> = computed(() => {
  log.info("Refreshing guidance sets");
  return Array.from(guidance.value.values()) as GuidanceSet[];
});

async function deleteRow(dataItem) {
  await deleteRetainedGuidance(dataItem.id);
  refetch();
}
</script>

<template>
  <div>
    <Toolbar class="border-0 bg-white dark:bg-gray-800 p-4">
      <KodexaArticle article-id="9787707" text="Learn more about managing guidance" :slide="false" />
      <ToolbarSpacer />
      <KodexaButton id="createNewGuidanceSet" icon="plus" @click="showAddGuidanceSet = true">
        Add Guidance Set
      </KodexaButton>
    </Toolbar>
    <div class="mx-4 flex">
      <div class="w-1/4 pr-4">
        <ul role="list" class="mt-4 space-y-4">
          <li
            v-for="set in guidanceSets" :key="set.ref"
            class="cursor-pointer p-4 rounded-xl border border-gray-200"
            :class="{ 'bg-blue-100': selectedGuidanceSet === set }"
            @click="selectGuidanceSet(set)"
          >
            <div class="flex items-center gap-x-4">
              <KodexaGuidanceSet :guidance-set="set" />
              <Menu as="div" class="relative ml-auto">
                <MenuButton class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                  <span class="sr-only">Open options</span>
                  <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
                </MenuButton>
                <Transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 mt-0.5 w-52 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                  >
                    <MenuItem v-slot="{ active }">
                      <a
                        href="#"
                        class="block px-3 py-1 text-sm leading-6 text-gray-900" :class="[active ? 'bg-gray-50 dark:bg-gray-900' : '']"
                        @click.stop="unlinkGuidanceSet(set)"
                      >
                        <MaterialDesignIcon name="link" class="h-5 w-5 text-gray-500 mr-1" />
                        Unlink from Project
                      </a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <a
                        href="#"
                        class="block px-3 py-1 text-sm leading-6 text-gray-900" :class="[active ? 'bg-gray-50 dark:bg-gray-900' : '']"
                        @click.stop="deleteGuidanceSet(set)"
                      >
                        <MaterialDesignIcon name="delete" class="h-5 w-5 text-red-500 mr-1" />
                        Delete
                      </a>
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </li>
        </ul>
      </div>
      <div class="w-3/4 pl-4">
        <Grid
          v-if="selectedGuidanceSet"
          class="h-full"
          style="height: calc(100vh - 200px)"
          :data-items="data ? data.content : []"
          :pageable="pageSettings"
          :loader="isLoading"
          :sort="sort"
          scrollable="scrollable"
          :skip="pageSettings.skip as number"
          :total="pageSettings.total as number"
          :page-size="pageSettings.take as number"
          :take="pageSettings.take as number"
          :columns="columns"
          :filterable="showFilter"
          :filter="filter"
          :resizable="true"
          :sortable="true"
          @pagechange="gridHelper.pageChangeHandler($event)"
          @filterchange="gridHelper.filterChange($event)"
          @sortchange="gridHelper.sortChange($event)"
        >
          <GridNoRecords>
            <KodexaGridNoRecords
              :error="error" :is-loading="isLoading" :is-error="isError"
              no-records-message="No guidance found"
            />
          </GridNoRecords>
          <template #actions="{ props }">
            <td>
              <div class="flex justify-center">
                <KodexaButton icon="delete" type="danger" size="small" @click="deleteRow(props.dataItem)">
                  Delete
                </KodexaButton>
              </div>
            </td>
          </template>
        </Grid>
      </div>
    </div>
  </div>
</template>
