<script setup lang="ts">
import type { Ref } from "vue";
import type { Organization } from "~/model";
import { arrowRotateCwIcon, filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { updateOrganization, useListOrganizations } from "~/api/organizations/organizations";
import { updateHandler } from "~/utils/error-handler";
import AddCredits from "~/views/studio/organizations/add-credits.vue";
import NewOrganization from "~/views/studio/organizations/new-organization.vue";

// Setup our grid helpers
const gridHelper = createGridHelper("organizations", {
  pageSize: 10,
  filter: "",
  sort: "",
  query: "",
  page: 1,
});
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListOrganizations(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
    gridHelper.saveState();
  }
});

const columns = [
  {
    title: "Name",
    field: "name",
    width: "500px",
  },
  {
    title: "Slug",
    field: "slug",
    width: "500px",
  },
  {
    title: "Enterprise",
    field: "enterpriseAccount",
    width: "100px",
    cell: "enterpriseAccount",
  },
  {
    title: "Actions",
    field: "actions",
    width: "500px",
    cell: "actions",
    sortable: false,
    filterable: false,
  },
];
const showCreateOrganization = ref(false);
const showAddCredits = ref(false);
const currentOrganization: Ref<Organization | undefined> = ref(undefined);

async function saveOrg(organization) {
  const orgCopy = { ...organization };
  orgCopy.enterpriseOrganization = !orgCopy.enterpriseOrganization;
  await updateHandler(updateOrganization(organization.id, orgCopy), "Organization updated successfully");
  await refetch();
}

function archiveOrg(organization: Organization) {
  showAddCredits.value = false;
  refetch();
}

function addOrgCredit(organization: Organization) {
  showAddCredits.value = true;
  currentOrganization.value = organization;
}
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <Toolbar class="border-0 bg-white dark:bg-gray-800">
        <Button
          id="filterIcon" :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50"
          @click="gridHelper.toggleFilter()"
        />
        <Button id="refetchIcon" :svg-icon="arrowRotateCwIcon" title="refresh" :togglable="false" class=" dark:text-gray-50" @click="refetch()" />

        <ToolbarSpacer />
        <KodexaButton id="newOrganizationBtn" icon="plus" @click="showCreateOrganization = true">
          New Organization
        </KodexaButton>
      </Toolbar>
      <Grid
        style="height: calc(100vh - 11rem);"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip"
        :total="pageSettings.total"
        :page-size="pageSettings.take"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error" :is-loading="isLoading" :is-error="isError"
            no-records-message="No organizations found"
          />
        </GridNoRecords>
        <template #enterpriseAccount="{ props }">
          <td>
            <KodexaCheckbox
              :value="props.dataItem.enterpriseOrganization" name="enterpriseOrg"
              @change="saveOrg(props.dataItem)"
            />
          </td>
        </template>
        <template #actions="{ props }">
          <td>
            <KodexaButton
              id="addCredits" class="ml-2"
              icon="plus" type="primary" size="small"
              @click="addOrgCredit(props.dataItem)"
            >
              Credits
            </KodexaButton>
            <KodexaButton
              id="deleteOrganization" class="m-4"
              icon="delete" type="danger" size="small"
              @click="archiveOrg(props.dataItem)"
            >
              Archive
            </KodexaButton>
          </td>
        </template>
      </Grid>
    </div>
    <NewOrganization v-model="showCreateOrganization" @refresh="refetch()" />
    <AddCredits v-if="currentOrganization" v-model="showAddCredits" :current-organization="currentOrganization" />
  </div>
</template>
