<script setup lang="ts">
import type { PropType } from "vue";
import type { DataAttribute, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { storeToRefs } from "pinia";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaAttributeProperties from "~/components/kodexa-attribute-properties.vue";
import appStore from "~/store";
import { log } from "~/utils/logger";

const props = defineProps({
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: false,
  },
  viewId: {
    type: String,
    required: true,
  },
  placeholder: {
    type: Boolean,
    required: false,
    default: false,
  },
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["deleteAttribute", "auditTrail", "showMetadata", "addSelectedValue"]);

const { user } = storeToRefs(appStore.userStore);

const reactiveDataObject = computed(() => {
  return { ...props.dataObject };
});

interface NavItem {
  text: string;
  items?: NavItem[];
  run?: () => void;
  icon?: string;
  render?: string;
  divider?: boolean;
  color?: string;
}

const navigation = ref([] as NavItem[]);

function buildNav() {
  const items = [] as NavItem[];
  if (props.attribute?.ownerUri && props.attribute.ownerUri.includes("user")) {
    items.push(
      { icon: "human", render: "itemRender", text: "Human Labelled", color: "text-gray-400 dark:text-gray-500" },
    );
    items.push(
      { divider: true },
    );
  } else {
    items.push(
      { icon: "creation", render: "itemRender", text: "AI Labelled", color: "text-gray-400 dark:text-gray-500" },
    );
    items.push(
      { divider: true },
    );
  }

  if (user.value.showDeveloperTools && !props.placeholder) {
    items.push(
      { icon: "bag-personal-tag-outline", text: "View Metadata", run: () => emit("showMetadata") },
    );
  }

  items.push(
    {
      icon: "properties",
      text: "Properties",
      run: async () => {
        const propertiesDialog = createConfirmDialog(KodexaAttributeProperties);
        await propertiesDialog.reveal({
          dataAttribute: props.attribute,
          modelValue: true,
        });
      },
    },
  );

  items.push(
    {
      icon: "note-text-outline",
      text: "Audit/Notes",
      run: async () => {
        const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);
        const useSidebar = createSidecar(currentWorkspaceId.value);
        useSidebar.focusAttribute(props.attribute, props.dataObject);
        appStore.platformStore.setCurrentSidebar("auditNotes");
      },
    },
  );

  if (!props.dataObject?.documentFamily?.locked && !props.placeholder && props.editable) {
    items.push(
      { divider: true },
    );
    items.push(
      { color: "text-red-400 dark:text-red-500", icon: "delete", render: "itemRender", text: "Delete" },
    );
  }

  const { activeSelectionView } = storeToRefs(appStore.workspaceStore);
  if (activeSelectionView.value && activeSelectionView.value.viewId) {
    const view = appStore.workspaceStore.getViewById(activeSelectionView.value.viewId);
    if (view && view.viewType === "document") {
      const documentView = createDocumentViewerStore(view.id);
      if (reactiveDataObject.value?.documentFamily.id !== documentView?.documentFamily.id) {
        log.info(`Can't add value, different document data object is ${props.dataObject?.documentFamily.id} and document is ${documentView?.documentFamily.id}`);
        items.push(
          {
            icon: "block-helper",
            text: "Can't Add Value, Different Document",
            color: "text-gray-400 dark:text-gray-500",
            run: () => {
              log.warn("Can't add value, different document");
            },
          },
        );
      } else if (documentView?.selectionContext && documentView.selectionContext.selectedNodes.length > 0) {
        items.push(
          {
            icon: "plus",
            text: "Add Selected Value",
            run: () => {
              appStore.workspaceStore.loadDataObject(props.dataObject);

              if (documentView && props.tagMetadata) {
                documentView.addTag(props.tagMetadata, documentView.selectionContext.selectedNodes, { groupUuid: props.dataObject.groupUuid });
              } else {
                log.warn("No document view store or missing attribute path");
              }
            },
          },
        );
      }
    }
  }

  navigation.value = items;
}

function openNav(item: NavItem) {
  if (item.text === "Delete") {
    emit("deleteAttribute");
  }

  if (item.run) {
    item.run();
  }
}
</script>

<template>
  <div>
    <VDropdown :arrow-overflow="false" @click="buildNav">
      <!-- This will be the popover target (for the events and position) -->
      <MaterialDesignIcon name="THREEDOTMENU" class="size-5 pt-2 text-gray-500 dark:text-gray-400" aria-hidden="true" />
      <!-- This will be the content of the popover -->
      <template #popper>
        <nav class="space-y-1 p-2 dark:bg-gray-800" aria-label="Sidebar">
          <div v-for="item in navigation" :key="item.name">
            <a
              v-if="!item.divider"
              v-close-popper
              class="cursor-pointer flex items-center rounded-md px-3 py-2 text-xs font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-gray-100"
              :aria-current="item.current ? 'page' : undefined" @click="openNav(item)"
            >
              <MaterialDesignIcon
                :name="item.icon"
                class="-ml-1 mr-3 size-4 shrink-0"
                aria-hidden="true"
              />
              <span class="truncate">{{ item.text }}</span>
            </a>
            <div v-else class="my-2 border-t border-gray-200 dark:border-gray-700" />
          </div>
        </nav>
      </template>
    </VDropdown>
  </div>
</template>
