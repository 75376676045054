<script lang="ts" setup>
import type { TagMetadata } from "~/store/useProject";
import type { TagInstance } from "~/store/useWorkspace";
import { Combobox, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import Fuse from "fuse.js";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import KodexaLabelSource from "~/components/kodexa-label-source.vue";
import appStore from "~/store";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["closed"]);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);
const startingGroups = Array.from(tagMetadataMap.value.values()).filter(
  (tagMetadata: any) => tagMetadata.taxon.group,
);
const selectedGroups = ref<TagMetadata[]>(startingGroups as TagMetadata[]);

const availableLabels = computed(() => {
  // Get all the labels, and exclude the groups
  const nonGroups = Array.from(tagMetadataMap.value.values()).filter(
    (tagMetadata: any) => !tagMetadata.taxon.group,
  );
  const selectedGroupPaths = selectedGroups.value.map(
    (group: TagMetadata) => group.taxon.path,
  );
  return nonGroups.filter((tagMetadata: any) =>
    selectedGroupPaths.includes(tagMetadata.parentPath));
});

const fuseOptions = {
  useExtendedSearch: true,
  keys: ["taxon.label", "taxon.parentLabel"],
  findAllMatches: true,
};
const rawQuery = ref("");

const filteredLabels = computed(() => {
  if (rawQuery.value === "") {
    return availableLabels.value;
  }
  const fuse = new Fuse(availableLabels.value, fuseOptions);
  const hits: Fuse.FuseResult<any>[] = fuse.search(rawQuery.value);
  return hits.map(hit => hit.item as TagInstance) as TagInstance[];
});

const availableDataGroups = computed(() => {
  return Array.from(tagMetadataMap.value.values()).filter(
    (tagMetadata: any) => tagMetadata.taxon.group,
  );
});

function onChange(event) {
  selectedGroups.value = event.target.value;
}
</script>

<template>
  <div>
    <div class="flex h-full flex-col bg-white dark:bg-gray-800">
      <div class="flex-1">
        <!-- Header -->
        <div class="bg-gray-50 dark:bg-gray-900 px-4 py-6">
          <div class="flex items-start justify-between space-x-3">
            <div class="space-y-1">
              <p class="mt-2 text-sm text-gray-500">
                Available Labels
              </p>
            </div>
            <div class="flex h-7 items-center">
              <button class="text-gray-400 hover:text-gray-500" type="button" @click="emit('closed')">
                <span class="sr-only">Close panel</span>
                <MaterialDesignIcon aria-hidden="true" class="h-6 w-6" name="close" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Combobox>
        <div class="bg-gray-50 dark:bg-gray-900 p-1">
          <KodexaTextInput
            v-model="rawQuery"
            name="filter"
            placeholder="Filter..."
          />
          <MultiSelect
            :data-items="availableDataGroups"
            :style="{ width: '100%' }"
            :value="selectedGroups"
            class="mt-1"
            data-item-key="path"
            text-field="label"
            @change="onChange"
          />
        </div>
        <div class="flex flex-wrap items-center bg-gray-50 dark:bg-gray-900 px-4 py-2.5 text-xs text-gray-700">
          Type
          <kbd
            :class="[rawQuery.startsWith('#') ? 'border-indigo-600 text-blue-600' : 'border-gray-400 text-gray-900']"
            class="mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white dark:bg-gray-800 font-semibold"
          >~</kbd>
          <span>for model labels,</span>
          <kbd
            :class="[rawQuery.startsWith('>') ? 'border-indigo-600 text-blue-600' : 'border-gray-400 text-gray-900']"
            class="mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white dark:bg-gray-800 font-semibold sm:mx-2"
          >`</kbd>
          for content labels.
        </div>

        <p v-if="filteredLabels" class="mx-2 ml-4 mt-3">
          {{ filteredLabels.length }} labels matched.
        </p>

        <div style="height: calc(100vh - 10rem); overflow: auto">
          <ComboboxOptions
            v-if="filteredLabels && filteredLabels.length > 0"
            class="mx-1 scroll-py-10 scroll-pb-2 space-y-4 p-4 pb-2"
            static
          >
            <li v-if="filteredLabels.length > 0">
              <ul class="-mx-4 text-sm text-gray-700">
                <ComboboxOption
                  v-for="filterd in filteredLabels" :key="filterd.id"
                  as="template"
                >
                  <KodexaLabelSource :show-copy-icon="true" :tag-instance="filterd" :view-id="viewId" />
                </ComboboxOption>
              </ul>
            </li>
          </ComboboxOptions>
        </div>

        <div v-if="rawQuery === '?'" class="px-6 py-14 text-center text-sm sm:px-14">
          <MaterialDesignIcon aria-hidden="true" lass="mx-auto h-6 w-6 text-gray-400" name="help" />
          <p class="mt-4 font-semibold text-gray-900">
            Help with Labels
          </p>
          <p class="mt-2 text-gray-500">
            Use this tool to quickly search for labels for this project. You can also use
            the search modifiers found in the footer below to limit the results.
          </p>
        </div>

        <div
          v-if="rawQuery !== '' && rawQuery !== '?' && filteredLabels.length === 0"
          class="px-6 py-14 text-center text-sm sm:px-14"
        >
          <MaterialDesignIcon aria-hidden="true" class="mx-auto h-6 w-6 text-red-600" name="alertBox" />
          <p class="mt-4 font-semibold text-gray-900">
            No labels found
          </p>
          <p class="mt-2 text-gray-500">
            We couldn’t find anything with that term. Please try again.
          </p>
        </div>
      </Combobox>
    </div>
  </div>
</template>

<style scoped>

</style>
