<script setup lang="ts">
import type { PropType } from "vue";
import type { Kiosk } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { updateKiosk, useDeleteKiosk } from "~/api/kiosks/kiosks";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";
import { updateHandler } from "~/utils/error-handler";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  kiosk: {
    type: Object as PropType<Kiosk>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refetch", "kiosk-deleted"]);

const { project, dataForms, documentStores, workspaces } = storeToRefs(appStore.projectStore);
const { labels } = storeToRefs(appStore.organizationStore);

function close() {
  emit("update:modelValue", false);
}

const localKiosk = ref<Kiosk>({ ...props.kiosk });

async function doUpdate() {
  await updateHandler(updateKiosk(localKiosk.value.id, localKiosk.value), "Kiosk updated successfully");

  close();
  emit("refetch");
}

async function deleteKiosk() {
  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: `Delete kiosk ${localKiosk.value.name}?`,
    message: "This action cannot be undone.",
  });

  if (!isCanceled) {
    await updateHandler(useDeleteKiosk(localKiosk.value.id), "Kiosk deleted successfully");
    notify({
      group: "generic",
      title: "Success",
      text: "Kiosk deleted successfully",
    }, 3000);
    emit("kiosk-deleted", localKiosk.value.id);
    close();
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
                  <div class="max-h-full flex-1 overflow-y-auto">
                    <!-- Header -->
                    <div class="bg-gray-50 dark:bg-gray-900 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            Update Kiosk
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Change the details required and then choose Save Changes
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancelEditKiosk" icon="cancel" type="secondary" size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="updateKiosk" icon="content-save" type="primary" size="small"
                              @click="doUpdate"
                            >
                              Save Changes
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                      <div class="min-h-0 flex-1 overflow-y-auto">
                        <!-- Divider container -->
                        <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                          <!-- Name -->
                          <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label for="name" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Name</label>
                            </div>
                            <div class="sm:col-span-2">
                              <KodexaTextInput
                                id="name"
                                v-model="localKiosk.name"
                                name="name"
                              />
                            </div>
                          </div>

                          <!-- Workspace -->
                          <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label for="workspaceId" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Workspace</label>
                            </div>
                            <div v-if="workspaces" class="sm:col-span-2">
                              <KodexaDropDown
                                id="workspaceId"
                                v-model="localKiosk.metadata.workspaceId"
                                name="workspaceId"
                                :items="workspaces"
                                value-field="id"
                                text-field="name"
                              />
                            </div>
                          </div>

                          <!-- Data Form Ref -->
                          <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label for="dataFormRef" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Data Form</label>
                            </div>
                            <div v-if="dataForms" class="sm:col-span-2">
                              <KodexaDropDown
                                id="dataFormRef"
                                v-model="localKiosk.metadata.dataFormRef"
                                name="dataFormRef"
                                :items="dataForms"
                                value-field="ref"
                                text-field="name"
                              />
                            </div>
                          </div>

                          <!-- Store ID -->
                          <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label for="storeId" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Document Store</label>
                            </div>
                            <div v-if="documentStores" class="sm:col-span-2">
                              <KodexaDropDown
                                id="storeId"
                                v-model="localKiosk.metadata.storeId"
                                name="storeId"
                                :items="documentStores"
                                value-field="_id"
                                text-field="name"
                              />
                            </div>
                          </div>

                          <!-- Filters -->
                          <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label for="filters" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Filters</label>
                            </div>
                            <div class="sm:col-span-2">
                              <KodexaTextArea
                                id="filters"
                                v-model="localKiosk.metadata.filters"
                                name="filters"
                                :rows="5"
                              />
                            </div>
                          </div>

                          <!-- Approve Label -->
                          <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label for="approveLabel" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Approve Label</label>
                            </div>
                            <div class="sm:col-span-2">
                              <KodexaDropDown
                                id="approveLabel"
                                v-model="localKiosk.metadata.approveLabel"
                                name="approveLabel"
                                :items="labels"
                                value-field="name"
                                text-field="name"
                              />
                            </div>
                          </div>

                          <!-- Reject Label -->
                          <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label for="rejectLabel" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Reject Label</label>
                            </div>
                            <div class="sm:col-span-2">
                              <KodexaDropDown
                                id="rejectLabel"
                                v-model="localKiosk.metadata.rejectLabel"
                                name="rejectLabel"
                                :items="labels"
                                value-field="name"
                                text-field="name"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- Delete Kiosk -->
                        <div class="mb-6 border-2 border-red-200 bg-white dark:bg-gray-800 shadow sm:m-6 sm:mt-3 sm:rounded-lg">
                          <div class="px-4 py-5 sm:p-6">
                            <h3 class="text-base font-semibold leading-6 text-red-600">
                              Delete Kiosk
                            </h3>
                            <div class="mt-2 items-center justify-center sm:flex">
                              Are you sure you want to delete this kiosk? This action cannot be undone.
                              <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
                                <KodexaButton id="deleteKiosk" type="danger" @click="deleteKiosk">
                                  Delete
                                </KodexaButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </transitionchild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
