<script setup lang="ts">
import type { Membership } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useListMemberships } from "~/api/memberships/memberships";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import appStore from "~/store";

const { currentOrganization } = storeToRefs(appStore.organizationStore);

// Setup our grid helpers
const gridHelper = createGridHelper(`memberships-${currentOrganization.value?.id}`, {
  pageSize: 10,
  filter: "",
  sort: "",
  query: "",
  page: 1,
}, `organization.id: '${currentOrganization.value.id}'`);
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListMemberships(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
    gridHelper.saveState();
  }
});

const columns = [
  {
    title: "Image",
    field: "image",
    cell: "image",
    width: "100px",
    filterable: false,
  },
  {
    title: "First Name",
    field: "user.firstName",
    cell: "firstName",
  },
  {
    title: "Last Name",
    field: "user.lastName",
  },
  {
    title: "Email Address",
    field: "user.email",
  },
  {
    title: "Role",
    field: "role",
    width: "170px",
  },
];
const showCreateMember = ref(false);
const showEditMember = ref(false);
const selectedMember = ref<Membership>();
const computedQuery = computed({
  get() {
    return gridHelper.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});

function openNew() {
  showCreateMember.value = true;
}

function editMember(member: Membership) {
  selectedMember.value = member;
  showEditMember.value = true;
}

watch([showEditMember, showCreateMember], () => {
  refetch();
});
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <Toolbar class="border-0 bg-white dark:bg-gray-800">
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50" @click="gridHelper.toggleFilter()" />
        <KodexaArticle
          class="mt-1" article-id="9034626" text="Learn more about organization memberships"
          :slide="true"
        />
        <ToolbarSpacer />
        <KodexaButton id="createNewTeam" icon="plus" @click="openNew">
          Invite
        </KodexaButton>
      </Toolbar>
      <Grid
        style="height: calc(100vh - 15rem);"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error" :is-loading="isLoading" :is-error="isError"
            no-records-message="No members found"
          />
        </GridNoRecords>
        <template #actions="{ props }">
          <KodexaButton
            id="editMember"
            size="small"
            @click="editMember(props.dataItem)"
          />
        </template>
        <template #image="{ props }">
          <td>
            <KodexaUserAvatar :id="`member-${props.dataItem.user.email}`" :user="props.dataItem.user" object-type="user" size="12" @click="editMember(props.dataItem)" />
          </td>
        </template>
        <template #firstName="{ props }">
          <td>
            <div class="flex items-center" @click="editMember(props.dataItem)">
              <div class="flex-0 cursor-pointer text-gray-900">
                {{ props.dataItem?.user.firstName }}
              </div>
            </div>
          </td>
        </template>
        <template #lastName="{ props }">
          <td>
            {{ props.dataItem.user.lastName }}
          </td>
        </template>
        <template #email="{ props }">
          <td>
            {{ props.dataItem.user.email }}
          </td>
        </template>

        <template #role="{ props }">
          <td>
            {{ props.dataItem.role }}
          </td>
        </template>
      </Grid>
      <NewMember v-model="showCreateMember" @refetch-members="refetch" />
      <EditMember v-if="selectedMember" v-model="showEditMember" :member="selectedMember" @refetch-members="refetch" />
    </div>
  </div>
</template>
