import { notify } from "notiwind";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaError from "~/components/kodexa-error.vue";

export async function handleError(error: any) {
  const errorMessageDialog = createConfirmDialog(KodexaError, {
    error,
  });
  await errorMessageDialog.reveal({
    title: "Exception",
  });
}

export async function updateHandler(promise: Promise<any>, message = "Your changes have been saved", successCallback: (response: any) => void = () => {
}, errorCallback: (response: any) => void = () => {
}): Promise<any> {
  try {
    const response = await promise;

    if (message !== "") {
      notify({
        group: "generic",
        title: "Success",
        text: message,
        timeout: 10,
      });
    }

    if (successCallback) {
      successCallback(response);
    }

    return response;
  } catch (error: any) {
    errorCallback(error);
    if (error.response?.status === 409) {
      notify({
        group: "error",
        title: "Conflict",
        text: "The document has been modified since you last loaded it. Please refresh the document and try again.",
      });
      console.log("Conflict error", error);
    } else if (error.response?.status === 401) {
      notify({
        group: "error",
        title: "Unauthorized",
        text: "You are not authorized to perform this action.",
      });
    } else if (error.response?.status === 403) {
      notify({
        group: "error",
        title: "Forbidden",
        text: "You are not allowed to perform this action.",
      });
    } else if (error.response?.status === 404) {
      notify({
        group: "error",
        title: "Not Found",
        text: "The requested resource was not found.",
      });
    } else {
      await handleError(error);
    }

    if (!errorCallback) {
      throw error;
    }
  }
}
