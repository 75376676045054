<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { AttributeCheckboxOption } from "~/components/dataForm/attribute-checkbox-option";
import type { AttributeMaskedTextOption } from "~/components/dataForm/attribute-maskedtext-option";
import type { DataAttribute, DataException, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { MaskedTextBox } from "@progress/kendo-vue-inputs";
import { DateTime } from "luxon";
import { storeToRefs } from "pinia";
import KodexaNonEditableValue from "~/components/dataObject/types/kodexa-non-editable-value.vue";
import appStore from "~/store";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  dataExceptions: {
    type: Object as PropType<DataException[]>,
    required: false,
    default: () => {
      return [] as DataException[];
    },
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  maskedTextOptions: {
    type: Object as PropType<AttributeMaskedTextOption>,
    required: false,
    default: () => {
      return {} as AttributeMaskedTextOption;
    },
  },
  checkboxOptions: {
    type: Object as PropType<AttributeCheckboxOption>,
    required: false,
    default: () => {
      return {} as AttributeCheckboxOption;
    },
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);

const checkboxValue = ref(false);
let stringValue: string | undefined = getStringValue();

function getStringValue(): string | undefined {
  if (props.maskedTextOptions?.sourceDateFormat && props.maskedTextOptions?.maskDateFormat) {
    if (props.checkboxOptions?.onCheckValue && props.checkboxOptions.onCheckValue === props.attribute?.stringValue) {
      return props.attribute.stringValue;
    }
    if (!props.attribute.stringValue) {
      return undefined;
    }
    const formattedDate = DateTime.fromFormat(props.attribute.stringValue as string, props.maskedTextOptions?.sourceDateFormat);
    return formattedDate.isValid
      ? formattedDate.toFormat(
          props.maskedTextOptions?.maskDateFormat,
        )
      : undefined;
  } else {
    return props.attribute.stringValue;
  }
}

watch(checkboxValue, (newValue) => {
  if (newValue) {
    stringValue = props?.checkboxOptions?.onCheckValue;
  } else {
    stringValue = undefined;
  }

  emit("update", {
    ...props.attribute,
    value: stringValue,
    stringValue,
  });
  dataViewHelper.updated();
});

function onMaskInput(event) {
  if (props.maskedTextOptions?.sourceDateFormat && props.maskedTextOptions?.maskDateFormat) {
    if (event.value) {
      const formattedDate = DateTime.fromFormat(event.value, props.maskedTextOptions.maskDateFormat);
      stringValue = formattedDate.isValid ? formattedDate.toFormat(props.maskedTextOptions?.sourceDateFormat) : undefined;
    }
  }
  checkboxValue.value = event.target.checked;
  emit("update", {
    ...props.attribute,
    value: stringValue,
    stringValue,
  });
  dataViewHelper.updated();
}

function onStringInput(event) {
  stringValue = event.value !== undefined ? event.value : undefined;
  emit("update", {
    ...props.attribute,
    value: stringValue,
    stringValue,
  });
  dataViewHelper.updated();
}

const valid = computed(() => {
  return props.dataExceptions?.length === 0;
});

function focus() {
  dataViewHelper.focus();
  emit("focus");
}

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const isDisabled = computed(() => {
  const tagMeta = tagMetadataMap.value.get(props.attribute.path);
  return props.dataObject?.documentFamily.locked === true || tagMeta?.taxon?.valuePath === "METADATA";
});

const isLongText = computed(() => {
  const tagMeta = tagMetadataMap.value.get(props.attribute.path);
  return tagMeta?.taxon?.typeFeatures?.longText === true;
});

const { style, conditionalValidationIcon } = storeToRefs(dataViewHelper);
</script>

<template>
  <div :class="{ 'no-due-date': checkboxValue }" @click="focus">
    <template v-if="!editable">
      <KodexaNonEditableValue
        v-if="!editable"
        :style="style" :conditional-validation-icon="conditionalValidationIcon"
        :string-value="stringValue"
      />
    </template>
    <template v-else>
      <MaskedTextBox
        v-if="maskedTextOptions?.isMaskedText && !checkboxValue"
        :id="`kdx-attribute-${props.attribute.uuid}`"
        v-model="stringValue"
        class="kodexa-input"
        :mask="maskedTextOptions?.maskedText"
        :tabindex="props.tabIndex"
        :fill-mode="null"
        rounded="medium"
        :valid="valid"
        :disabled="isDisabled"
        input-prefix="prefix"
        :style="style"
        @focus="focus"
        @blur="dataViewHelper.blur()"
        @change="onMaskInput"
      >
        <template #prefix>
          <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
        </template>
      </MaskedTextBox>
      <KodexaTextInput
        v-else-if="!checkboxValue && !isLongText"
        :id="`kdx-attribute-${props.attribute.uuid}`"
        v-model="stringValue"
        :style="style"
        :name="`input-${props.attribute?.id}`"
        :disabled="isDisabled"
        :valid="valid"
        @input="onStringInput"
        @focus="focus"
        @blur="dataViewHelper.blur()"
      >
        <template #prefix>
          <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
        </template>
      </KodexaTextInput>
      <KodexaTextArea
        v-else-if="!checkboxValue && isLongText"
        :id="`kdx-attribute-${props.attribute.uuid}`"
        v-model="stringValue"
        style="min-width: 300px"
        :name="`input-${props.attribute?.id}`"
        :disabled="isDisabled"
        :valid="valid"
        :rows="10"
        :style="style"
        @input="onStringInput"
        @focus="focus"
        @blur="dataViewHelper.blur()"
      >
        <template #prefix>
          <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
        </template>
      </KodexaTextArea>

      <div v-if="checkboxOptions?.isCheckbox">
        <input
          v-if="checkboxValue"
          id="disabled-due-date"
          type="text"
          class="block w-full cursor-not-allowed rounded-lg border border-gray-300 bg-gray-100 p-2.5 text-base text-gray-900"
          value="None"
          disabled
          style="background-color: white;"
        >
        <div class="mt-1 flex items-center">
          <KodexaCheckbox
            id="due-date"
            v-model="checkboxValue"
            :name="`checkbox-${props.attribute?.id}`"
            label="Check if none"
            :disabled="isDisabled"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped>
#disabled-due-date {
  border-radius: 4px;
  padding-block: 4px;
  padding-inline: 8px;
  display: none;
}

.no-due-date .k-maskedtextbox {
  display: none;
}

.no-due-date #disabled-due-date {
  display: block;
}
.kodexa-input {
  @apply w-full appearance-none rounded-md border border-gray-300
  px-0 py-0 shadow-sm placeholder:text-gray-400 sm:text-sm
}

.kodexa-input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}
.kodexa-input:focus {
  @apply border-blue-500 ring-1 ring-blue-500
}

:deep(.k-input-inner) {
  font-size: var(--k-textbox-font-size) !important;
  font-weight: var(--k-textbox-font-weight) !important;
  @apply dark:text-gray-50 sm:text-sm dark:bg-gray-700
}
</style>
