import debounce from "lodash/debounce";
import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { log } from "~/utils/logger";

export interface KeyboardShortcut {
  key: string;
  altKey: any;
  description: string;
  callback: () => void;
}

export const useKeyboard = defineStore("keyboard", () => {
  const shortcuts = ref<Map<string, KeyboardShortcut>>(new Map());
  const _popupOpen = ref(false);
  const availableShortcuts = ref([] as KeyboardShortcut[]);

  const popupOpen = computed({
    get: () => _popupOpen.value,
    set: (value) => {
      _popupOpen.value = value;
      log.info(`Popup ${value ? "opened" : "closed"}`);
    },
  });

  function addShortcutHelper(shortcut: KeyboardShortcut) {
    if (availableShortcuts.value.find(s => s.key === shortcut.key)) {
      return;
    }
    availableShortcuts.value.push(shortcut);
  }

  function removeShortcutHelper(shortcut: KeyboardShortcut) {
    const index = availableShortcuts.value.findIndex(s => s.key === shortcut.key);
    if (index > -1) {
      availableShortcuts.value.splice(index, 1);
    }
  }

  function addShortcut(shortcut: KeyboardShortcut, addToHelper = true) {
    log.info(`Adding shortcut ${shortcut.key}`);
    shortcuts.value.set(shortcut.key, shortcut);
    if (shortcut.altKey) {
      if (Array.isArray(shortcut.altKey)) {
        shortcut.altKey.forEach((k) => {
          shortcuts.value.set(k, shortcut);
        });
      } else {
        shortcuts.value.set(shortcut.altKey, shortcut);
      }
      if (addToHelper) {
        addShortcutHelper(shortcut);
      }
    }
  }

  function removeShortcut(shortcut: KeyboardShortcut) {
    log.info(`Removing shortcut ${shortcut.key}`);
    shortcuts.value.delete(shortcut.key);
    if (shortcut.altKey) {
      shortcuts.value.delete(shortcut.altKey);
    }
    removeShortcutHelper(shortcut);
  }

  const debouncedToggle = debounce(() => {
    _popupOpen.value = !_popupOpen.value;
    log.info(`Popup ${_popupOpen.value ? "opened" : "closed"}`);
  }, 300, { leading: true, trailing: false });

  function togglePopup() {
    debouncedToggle();
  }

  function openPopup() {
    _popupOpen.value = true;
    log.info("Popup opened");
  }

  function closePopup() {
    _popupOpen.value = false;
    log.info("Popup closed");
  }

  return {
    shortcuts,
    addShortcut,
    removeShortcut,
    addShortcutHelper,
    availableShortcuts,
    popupOpen,
    openPopup,
    closePopup,
    togglePopup,
  };
});
