<script lang="ts" setup>
import type { DockviewApi, DockviewReadyEvent } from "dockview-vue";
import type { DefineComponent, PropType, Ref } from "vue";
import type { KeyboardShortcut } from "~/store/useKeyboard";
import type { PanelDefinition } from "~/views/studio/organizations/project/workspace/panel";
import { useDark } from "@vueuse/core";
import { DockviewVue } from "dockview-vue";
import { startCase } from "lodash";
import { storeToRefs } from "pinia";
import { getCurrentInstance, onMounted, ref } from "vue";
import appStore from "~/store";
import { log } from "~/utils/logger";
import { availablePanels } from "~/views/studio/organizations/project/workspace/panel";
import WorkspacePanelsEmpty from "~/views/studio/organizations/project/workspace/workspace-panels-empty.vue";
import WorkspacePanels from "~/views/studio/organizations/project/workspace/workspace-panels.vue";
import WorkspaceSidecarEmpty from "~/views/studio/organizations/project/workspace/workspace-sidecar-empty.vue";
import WorkspaceSidecar from "~/views/studio/organizations/project/workspace/workspace-sidecar.vue";
import WorkspaceViews from "~/views/studio/organizations/project/workspace/workspace-views.vue";

interface WorkspaceOptions {
  hideToolbar: boolean;
  hideClose: boolean;
  openPanel?: string;
  openSidecar?: boolean;
  openForm?: string;
}

defineProps({
  workspaceOptions: {
    type: Object as PropType<WorkspaceOptions>,
    required: false,
    default: () => {
      return {
        hideToolbar: false,
        hideClose: false,
        openPanel: undefined,
        openSidecar: false,
        openForm: undefined,
      };
    },
  },
});

const dockviewApi: Ref<DockviewApi | undefined> = ref(undefined);
const app = getCurrentInstance()?.appContext;
const { sidecarPanelOpen, panelsOpen } = storeToRefs(appStore.workspaceStore);

// Register available panels as global components
if (app) {
  availablePanels.forEach((panel: PanelDefinition) => {
    if (app.app.component(panel.type)) {
      return;
    }
    app.app.component(panel.type, panel.implementation());
  });
}

interface HeaderModule {
  default: DefineComponent<any, any, any>;
}

if (app) {
  const headerComponents = import.meta.glob<HeaderModule>("./header-components/*.vue", { eager: true });
  Object.entries(headerComponents).map(([path, module]) => {
    const name = path.replace(/^\.\/header-components\/(.*)\.vue$/, "$1");
    if (!app.app.component(startCase(name).replaceAll(" ", ""))) {
      app.app.component(startCase(name).replaceAll(" ", ""), module.default);
    }
  });
}

const mainGroup = ref();
const sidecarGroup = ref();
const panelGroup = ref();

function checkPanels() {
  if (panelsOpen.value && !panelGroup.value) {
    panelGroup.value = dockviewApi.value?.addPanel({
      id: "panels",
      component: "WorkspacePanels",
      position: { referencePanel: "main", direction: "left" },
    });

    panelGroup.value.group.locked = true;
    panelGroup.value.group.header.hidden = true;
  } else if (!panelsOpen.value && panelGroup.value) {
    dockviewApi.value?.removePanel(panelGroup.value);
    panelGroup.value = undefined;
  }
}

function checkSidecar() {
  if (sidecarPanelOpen.value && !sidecarGroup.value) {
    sidecarGroup.value = dockviewApi.value?.addPanel({
      id: "sidecar",
      component: "WorkspaceSidecar",
      initialWidth: 550,
      position: { referencePanel: "main", direction: "right" },
    });

    sidecarGroup.value.group.locked = true;
    sidecarGroup.value.group.header.hidden = true;
  } else if (sidecarGroup.value) {
    dockviewApi.value?.removePanel(sidecarGroup.value);
    sidecarGroup.value = undefined;
  }
}

watch(sidecarPanelOpen, () => {
  checkSidecar();
});

watch(panelsOpen, () => {
  checkPanels();
});

function dockReady(api: DockviewReadyEvent) {
  dockviewApi.value = api.api;

  // We need to register the view components
  if (app) {
    app.app.component("WorkspacePanels", WorkspacePanels);
    app.app.component("WorkspaceViews", WorkspaceViews);
    app.app.component("WorkspaceSidecar", WorkspaceSidecar);
    app.app.component("WorkspaceSidecarEmpty", WorkspaceSidecarEmpty);
    app.app.component("WorkspacePanelsEmpty", WorkspacePanelsEmpty);
  }

  mainGroup.value = dockviewApi.value.addPanel({
    id: "main",
    component: "WorkspaceViews",
    params: {},
  });

  mainGroup.value.group.locked = true;
  mainGroup.value.group.header.hidden = true;

  panelGroup.value = dockviewApi.value.addPanel({
    id: "panels",
    component: "WorkspacePanels",
    params: {},
    initialWidth: 450,
    position: { referencePanel: "main", direction: "left" },
  });

  panelGroup.value.group.locked = true;
  panelGroup.value.group.header.hidden = true;

  checkSidecar();
}

const isDark = useDark({
  selector: "html",
  attribute: "class",
  valueDark: "dark",
  valueLight: "light",
});

const pollTimeout: Ref<any | undefined> = ref();

function toggleSidecar() {
  appStore.workspaceStore.toggleSidecar();
}

// Setup the shortcut keys we will have for the spatial document
const toggleSidecarShortcut = {
  key: "control+shift+o",
  altKey: "control+shift+o",
  description: "Toggle Sidecar",
  callback: () => {
    log.info("Toggling sidecar");
    toggleSidecar();
  },
} as KeyboardShortcut;

onMounted(() => {
  // We want to start a polling in the workspace while this component is active
  pollTimeout.value = setInterval(() => {
    appStore.workspaceStore.poll();
  }, 2000);
  const useKeyboardStore = useKeyboard();
  useKeyboardStore.addShortcut(toggleSidecarShortcut);
});

onBeforeUnmount(() => {
  if (pollTimeout.value) {
    clearTimeout(pollTimeout.value);
  }
  const useKeyboardStore = useKeyboard();
  useKeyboardStore.removeShortcut(toggleSidecarShortcut);
});
</script>

<template>
  <div>
    <DockviewVue :class="!isDark ? 'dockview-theme-light' : 'dockview-theme-abyss'" style="height: calc(100vh - 8rem)" right-header-actions-component="RightAction" prefix-header-actions-component="PrefixHeader" @ready="dockReady" />
  </div>
</template>

<style scoped>

</style>
