<script setup lang="ts">
import type { PropType } from "vue";
import type { DocumentFamily, Store } from "~/model";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  documentFamilies: {
    type: Array as PropType<DocumentFamily[]>,
    required: true,
  },
  store: {
    type: Object as PropType<Store>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "clearSelection"]);

function close() {
  emit("update:modelValue", false);
}

const selectAll = ref(false);

const impactedDocuments = computed(() => {
  return props.documentFamilies?.length;
});

const running = ref(false);

async function startBulkDelete() {
  running.value = true;
  await appStore.projectStore.bulkDeleteDocuments(props.store, selectAll.value, props.documentFamilies);
  running.value = false;
  emit("clearSelection");
  close();
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4  text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 700px; height: 460px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all"
            >
              <div class="ml-4 pb-2">
                <KodexaHeader
                  title="Bulk Delete"
                  sub-title="Delete the selected documents in bulk"
                />
              </div>
              <div class="mx-6 mt-8 mb-24">
                <p>
                  You have selected <span class="font-bold">{{ selectAll ? "all" : impactedDocuments }}</span>
                  documents from the store <span class="font-bold">{{ props.store.name }}</span>.
                </p>
                <p>
                  <KodexaCheckbox
                    v-model="selectAll"
                    name="selectAll"
                    class="mt-6"
                    label="Select All Documents"
                    hint="Checking this option will select all documents in the store"
                  />
                </p>
                <p class="mt-10">
                  <span class="font-bold text-red-700">This action cannot be undone.</span>
                </p>
              </div>
              <div
                class="mx-4 mt-12 sm:flex sm:flex-row-reverse"
              >
                <KodexaButton icon="delete" width="180px" type="danger" :loading="running" @click="startBulkDelete">
                  Delete Documents
                </KodexaButton>
                <KodexaButton
                  class="mr-2" width="120px"
                  icon="cancel" type="secondary"
                  :disabled="running"
                  @click="close"
                >
                  Cancel
                </KodexaButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
