<script lang="ts" setup>
import type { PropType } from "vue";
import type { Assistant, DataFlowNode } from "~/model";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["update-model-options", "step-deleted", "step-moved"]);

const modelStore = computedAsync(async () => {
  return await appStore.platformStore.getModel(props.node.id.split("//")[1]);
}, null);

const currentNavigation = ref({ ref: "general", name: "General", icon: "cog" });

const tabs = computed(() => {
  return {
    general: { ref: "general", name: "General" },
    options: { ref: "options", name: "Options" },
  };
});

const { assistants } = storeToRefs(appStore.projectStore);

const localAssistant = computed(() => {
  return assistants.value.find((assistant: Assistant) => props.node && assistant.id === props.node.assistant.id);
});

const localOptions = computed({
  get: () => {
    return localAssistant.value?.options?.pipeline?.steps[props.node.idx]?.options || {};
  },
  set: (value) => {
    const idx = props.node.idx;
    // We want to replace the options for the model at idx in the assistant pipeline in options
    const assistantCopy = JSON.parse(JSON.stringify(localAssistant.value));
    assistantCopy.options.pipeline.steps[idx].options = value;
    appStore.projectStore.addToAssistantsToUpdate(assistantCopy);
  },
});

const localStep = computed({
  get: () => {
    return localAssistant.value?.options?.pipeline?.steps[props.node.idx] || {};
  },
  set: (value) => {
    const idx = props.node.idx;
    // We want to replace the entire step at idx in the assistant pipeline
    const assistantCopy = JSON.parse(JSON.stringify(localAssistant.value));
    assistantCopy.options.pipeline.steps[idx] = value;
    appStore.projectStore.addToAssistantsToUpdate(assistantCopy);
  },
});

function deleteStep() {
  const idx = props.node.idx;
  localAssistant.value.options.pipeline.steps.splice(idx, 1);
  appStore.projectStore.addToAssistantsToUpdate(localAssistant.value);
  emit("step-deleted");
}

function moveStep(direction: "up" | "down") {
  const idx = props.node.idx;
  const newIdx = direction === "up" ? idx - 1 : idx + 1;
  if (newIdx >= 0 && newIdx < localAssistant.value.options.pipeline.steps.length) {
    const assistantCopy = JSON.parse(JSON.stringify(localAssistant.value));
    const steps = assistantCopy.options.pipeline.steps;
    [steps[idx], steps[newIdx]] = [steps[newIdx], steps[idx]];
    appStore.projectStore.addToAssistantsToUpdate(assistantCopy);
    emit("step-moved", newIdx);
  }
}
</script>

<template>
  <div>
    <div v-if="modelStore" class="flex h-full flex-col bg-white dark:bg-gray-800">
      <div>
        <div class="border-b border-gray-200 dark:border-gray-700">
          <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
            <li
              v-for="(item) in tabs" :key="item.ref"
              class="mr-2"
              @click="currentNavigation = item"
            >
              <a
                class="text-md"
                :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
              >
                <MaterialDesignIcon
                  v-if="item?.icon" :name="item.icon" size="18"
                  class="text-theme-primary mr-3"
                />
                {{ item?.name }}
              </a>
            </li>
          </ul>
        </div>
        <div v-if="currentNavigation?.ref === 'general'" class="mx-2 mt-2">
          <div class="px-4 sm:px-0 flex">
            <div class="flex-grow">
              <h3 class="text-base font-semibold leading-7 text-gray-900">
                {{ modelStore.name }}
              </h3>
              <p class="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                {{ modelStore.description }}
              </p>
            </div>
            <img :src="modelStore.imageUrl || '/assets/ai-brain.png'" alt="Model Image" class="m-4 w-16 h-16 object-cover rounded-lg">
          </div>
          <div class="col-span-6 mb-1 sm:col-span-3 mt-3">
            <KodexaCodeEditor
              style="height: 290px"
              name="modelStore-conditional" :model-value="localStep.conditional" label="Condition"
              hint="Conditions can be used to determine if a model should be run in the pipeline or skipped."
              :rows="9"
            />
          </div>
          <div class="flex mt-20 space-x-2">
            <KodexaButton
              icon="arrow-left"
              type="primary"
              :disabled="props.node.idx === 0"
              @click="moveStep('up')"
            >
              Move Left
            </KodexaButton>
            <KodexaButton
              icon="arrow-right"
              type="primary"
              :disabled="props.node.idx === localAssistant.options.pipeline.steps.length - 1"
              @click="moveStep('down')"
            >
              Move Right
            </KodexaButton>
          </div>
          <KodexaButton
            icon="delete"
            type="danger"
            class="mt-4"
            @click="deleteStep"
          >
            Remove Model from Pipeline
          </KodexaButton>
        </div>
        <div v-if="currentNavigation?.ref === 'options'" class="mx-2 mt-2">
          <div class="col-span-6 mb-1 sm:col-span-3">
            <div v-for="option in modelStore.metadata.inferenceOptions" :key="option.name">
              <ConfigurationOption
                v-model="localOptions"
                :assistant="node.assistant"
                :item="option"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
