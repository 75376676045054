<script lang="ts" setup>
</script>

<template>
  <div>
    <MaterialDesignIcon name="menu" class="mr-1 ml-1" />
  </div>
</template>

<style scoped>

</style>
