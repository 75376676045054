<script setup lang="ts">
import type { PropType } from "vue";
import type { WorkspaceUpdate } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";

defineProps({
  workspaceUpdate: Object as PropType<WorkspaceUpdate>,
  updatedTaxonomyRefs: Array as PropType<string[]>,
  updatedGuidanceSetRefs: Array as PropType<string[]>,
  deletedGuidanceSetRefs: Array as PropType<string[]>,
  updatedDataFormRefs: Array as PropType<string[]>,
  leavingProject: {
    type: Boolean,
    default: false,
  },
  projectDirty: {
    type: Boolean,
    default: false,
  },
  workspaceDirty: {
    type: Boolean,
    default: false,
  },
  testOnly: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["confirm", "cancel", "close"]);
const open = ref(true);

const { modelStores } = storeToRefs(appStore.projectStore);

const shouldTrain = ref(false);

const trainableStores = computed(() => {
  if (!modelStores.value) {
    return [];
  }

  return modelStores.value.filter(store => store.metadata.trainable);
});

function close() {
  open.value = false;
  emit("cancel");
}
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50/75 dark:bg-gray-900/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" size="16" aria-hidden="true" @click="emit('cancel')" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full sm:mx-0 sm:size-10"
                >
                  <MaterialDesignIcon name="content-save" size="32" aria-hidden="true" />
                </div>
                <div class="mt-4 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                    Confirm Changes
                  </DialogTitle>
                  <div class="mt-4 h-60">
                    <p class="text-sm text-gray-500">
                      Below is a summary of the changes that will be saved:
                    </p>
                    <div class="mt-6 text-sm text-gray-500">
                      <ul class="mx-4 list-disc">
                        <li v-if="projectDirty">
                          The project details have been updated
                        </li>
                        <li v-if="workspaceDirty">
                          The workspace details have been updated
                        </li>
                        <li v-if="workspaceUpdate?.updates?.dataAttributes?.length">
                          Added <strong>{{ workspaceUpdate.updates.dataAttributes?.length ?? 0 }}</strong> attributes
                        </li>
                        <li v-if="workspaceUpdate?.deletes?.dataAttributeIds?.length">
                          Deleted <strong>{{ workspaceUpdate.deletes.dataAttributeIds?.length ?? 0 }}</strong> attributes
                        </li>
                        <li v-if="workspaceUpdate?.deletes?.dataObjectIds?.length">
                          Deleted <strong>{{ workspaceUpdate.deletes.dataObjectIds?.length ?? 0 }}</strong> data objects
                        </li>
                        <li v-if="workspaceUpdate?.deletes?.dataExceptionIds?.length">
                          Cleared <strong>{{ workspaceUpdate.deletes.dataExceptionIds?.length ?? 0 }}</strong> exceptions
                        </li>
                        <li v-if="updatedTaxonomyRefs?.length">
                          Updated <strong>{{ updatedTaxonomyRefs.length }}</strong> data definitions
                        </li>
                        <li v-if="updatedDataFormRefs?.length">
                          Updated <strong>{{ updatedDataFormRefs.length }}</strong> data forms
                        </li>
                        <li v-if="workspaceUpdate?.featureSets?.length">
                          Updated <strong>{{ workspaceUpdate.featureSets?.length ?? 0 }}</strong> document feature sets
                        </li>
                        <li v-if="updatedGuidanceSetRefs?.length">
                          Updated <strong>{{ updatedGuidanceSetRefs.length }}</strong> guidance
                        </li>
                        <li v-if="deletedGuidanceSetRefs?.length">
                          Deleted <strong>{{ deletedGuidanceSetRefs.length }}</strong> guidance
                        </li>
                      </ul>
                    </div>
                    <div v-if="trainableStores.length > 0 && !testOnly" class="mt-4">
                      <div class="text-sm text-gray-500">
                        <KodexaCheckbox
                          v-model="shouldTrain"
                          name="trainableModels"
                          label="Retrain the models in this project"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8 sm:flex sm:flex-row-reverse">
                <KodexaButton
                  id="dialogConfirm"
                  type="primary"
                  icon="content-save"
                  @click="emit('confirm', shouldTrain)"
                >
                  Save Changes {{ shouldTrain && !testOnly ? "and Train Models" : "" }}
                </KodexaButton>
                <KodexaButton
                  id="dialogCancel"
                  type="secondary"
                  class="mr-2"
                  icon="cancel"
                  @click="emit('cancel')"
                >
                  {{ leavingProject ? 'Stay in Project' : 'Cancel' }}
                </KodexaButton>
                <KodexaButton
                  v-if="leavingProject"
                  type="danger"
                  class="mr-2"
                  icon="delete"
                  @click="emit('cancel')"
                >
                  Lose Changes
                </KodexaButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
