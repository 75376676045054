<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import KodexaResourceGrid from "~/components/resources/kodexa-resource-grid.vue";

const props = defineProps({
  modelValue: Boolean,
});
const emit = defineEmits(["confirm", "cancel", "update:modelValue", "closed"]);

const open = computed({
  get: () => {
    return props.modelValue;
  },
  set: () => {
    emit("update:modelValue", open.value);
  },
});

function closeDialog() {
  emit("update:modelValue", false);
}
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="closeDialog">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 80%; height: 740px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-2 pb-2 pt-2 text-left shadow-xl transition-all"
            >
              <div class="ml-4 pb-2">
                <KodexaHeader title="Resources" sub-title="Adding or creating resources for the project">
                  <template #actions>
                    <div class="flex h-7 items-center">
                      <button type="button" class="text-gray-400 hover:text-gray-500" @click="closeDialog()">
                        <span class="sr-only">Close panel</span>
                        <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" />
                      </button>
                    </div>
                  </template>
                </KodexaHeader>
              </div>
              <div>
                <KodexaResourceGrid resource-type="dataForms" @close="closeDialog()" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
