<script lang="ts" setup>
import type { PropType } from "vue";
import type { Dashboard, DashboardWidget } from "~/model";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import appStore from "~/store";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

const componentMetadata = {
  label: "Project Overview",
  description: "Displays an overview of the project with configurable workspace visibility",
  options: [
    {
      label: "Hide Open Workspace",
      key: "hideOpenWorkspace",
      type: "boolean",
      required: false,
      description: "Controls whether the open workspace button is visible"
    }
  ]
};

// Explicitly expose the metadata
defineExpose({
  metadata: componentMetadata,
});

const {
  project,
} = storeToRefs(appStore.projectStore);

const currentNavigation = ref();

const homeDashboard = computed(() => {
  return {
    orgSlug: "kodexa",
    slug: "project-dashboard",
    version: "1.0.0",
    ref: "kodexa/project-dashboard",
    name: "Project Dashboard",
    description: "A dashboard for your project",
    type: "dashboard",
    widgets: [
      {
        id: 1,
        type: "projectInformation",
        name: "Projects",
        properties: {
          hideOpenWorkspace: props.widget.properties.hideOpenWorkspace,
          defaultPosition: { col: 1, colSpan: 12, rowSpan: 15, order: 1 },
        },
      },
    ],
  } as Dashboard;
});
</script>

<template>
  <div>
    <KodexaDashboard
      :model-value="homeDashboard" :setup-mode="false" :allow-delete="false"
    />
  </div>
</template>
