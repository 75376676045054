<script lang="ts" setup>
import { storeToRefs } from "pinia";
import KodexaMarkdown from "~/components/kodexa-markdown.vue";
import appStore from "~/store";

const { platformConfiguration, currentSidebar } = storeToRefs(appStore.platformStore);

appStore.platformStore.loadConfiguration();

watch(currentSidebar, (newValue) => {
  if (newValue === undefined || newValue === "unset") {
    appStore.platformStore.setCurrentSidebar("home");
  }
});
</script>

<template>
  <div class="bg-white dark:bg-gray-800 py-2" style="width: 100%">
    <KodexaMarkdown v-if="platformConfiguration" :content="platformConfiguration.welcomeMarkdown" />
  </div>
</template>
