<script setup lang="ts">
import type { PropType } from "vue";
import type { DocumentFamily } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: false,
  },
  modelValue: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue"]);

const tabs = [
  { name: "Metadata", ref: "metadata" },
  { name: "Content Objects", ref: "contentObjects" },
];

const currentNavigation = ref(tabs[0]);

const documentFamilyJson = computed(() => {
  if (props.documentFamily) {
    return JSON.stringify(props.documentFamily, null, 2);
  }
  return "";
});
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              v-if="documentFamily"
              style="width: 950px; height: 720px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 p-2 text-left shadow-xl transition-all sm:my-8 sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-10 pt-10 sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500"
                  @click="emit('update:modelValue', false)"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle class="flex text-lg font-medium leading-4 text-gray-900">
                <KodexaHeader
                  title="Document Family Details"
                >
                  <template #subTitle>
                    <span class="text-sm text-gray-500 dark:text-gray-400">View the details of the document family</span>
                  </template>
                </KodexaHeader>
              </DialogTitle>
              <div class="mt-1 text-sm sm:flex sm:items-start">
                <ul class="-mb-px flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
                  <li
                    v-for="item in tabs" :key="item.ref"
                    class="mr-2"
                    @click="currentNavigation = item"
                  >
                    <a
                      class="text-md"
                      :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
                    >
                      {{ item?.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="mt-5">
                <div v-if="currentNavigation.ref === 'metadata'">
                  <KodexaCodeEditor
                    :key="documentFamily.id"
                    :model-value="documentFamilyJson"
                    style="height: 480px"
                    language="json"
                    :options="{ readOnly: true }"
                  />
                </div>
                <div v-if="currentNavigation.ref === 'contentObjects'">
                  <KodexaContentObjectList
                    v-if="documentFamily" :key="documentFamily.id"
                    :document-family="documentFamily"
                  />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
