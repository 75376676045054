import type { DefineComponent } from "vue";
import { camelCase, startCase } from "lodash";
import { log } from "~/utils/logger";

interface WidgetModule {
  default: DefineComponent<any, any, any>;
}

interface WidgetDefinition {
  type: string;
  name: string;
  label: string;
  imageUrl: string;
  description: string;
  implementation: () => DefineComponent<any, any, any>;
  options: Record<string, unknown>;
}

const widgetModules = import.meta.glob<WidgetModule>("./**/*.vue", { eager: true });

const availableWidgets = Object.entries(widgetModules).map(([path, module]) => {
  const fileName = path?.split("/")?.pop()?.replace(".vue", "");

  if (!fileName) {
    return null;
  }

  try {
    const type = camelCase(fileName);
    const name = startCase(fileName);
    const component = module.default;
    const componentMetadata = component.componentMetadata || {};

    return {
      type,
      name,
      label: componentMetadata.label || name,
      imageUrl: "/assets/header_icons/platformmanagement.png",
      description: componentMetadata.description || name,
      implementation: () => component,
      options: componentMetadata.options || {},
    } satisfies WidgetDefinition;
  } catch (e) {
    log.info(`Unable to load widget ${fileName} due to error ${e}`);
    return null;
  }
}).filter((widget): widget is WidgetDefinition => widget !== null);

export { availableWidgets };
export type { WidgetDefinition };
