<script setup lang="ts">
import type { PropType } from "vue";
import type { DocumentFamily } from "~/model";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "clearSelection", "cancel"]);

function close() {
  emit("update:modelValue", false);
  emit("clearSelection");
  emit("cancel");
}

const newDocument = ref<DocumentFamily>({} as DocumentFamily);

watch(() => props.documentFamily, (value) => {
  newDocument.value = {
    path: value.path,
    metadata: value.metadata,
    labels: value.labels,
  };
}, {
  immediate: true,
});

function updateLabels(df) {
  newDocument.value = df;
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4  text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 700px; height: 360px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all"
            >
              <div class="ml-4 pb-2">
                <KodexaHeader
                  title="Duplicate Document"
                  sub-title="Create a new document based on this document with a new name and new tags"
                />
              </div>
              <div>
                <div class="p-4">
                  <KodexaTextInput
                    v-model="newDocument.path"
                    name="path"
                    class="w-full"
                    label="Document Name"
                    placeholder="Enter a name for the document"
                  />

                  <KodexaDocumentFamilyLabels :key="documentFamily.id" class="pt-8" :document-family="documentFamily" @updated="updateLabels" />
                </div>
                <div
                  class="mr-1 mt-12 sm:flex sm:flex-row-reverse p-4"
                >
                  <KodexaButton icon="content-copy" width="180px">
                    Duplicate
                  </KodexaButton>
                  <KodexaButton
                    class="mr-2" width="120px"
                    icon="cancel" type="secondary"
                    @click="close"
                  >
                    Cancel
                  </KodexaButton>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
