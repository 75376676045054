import type { DefineComponent } from "vue";
import type { Option } from "~/model";
import { camelCase, startCase } from "lodash";
import { log } from "~/utils/logger";

interface CardModule {
  default: DefineComponent<any, any, any>;
}

const cardModules = import.meta.glob<CardModule>("./*.vue", { eager: true });
const metadataModules = import.meta.glob<{ componentMetadata: any }>("./*.ts", { eager: true });

interface CardDefinition {
  type: string;
  name: string;
  label: string;
  description: string;
  supportsChildren: boolean;
  defaultWidth?: number;
  defaultHeight?: number;
  implementation: () => DefineComponent<any, any, any>;
  options: Option[];
}

const availableCards = Object.entries(cardModules).map(([path, module]) => {
  const fileName = path.split("/").pop()?.replace(".vue", "");

  if (!fileName || fileName === "kodexa-form-card") {
    return null;
  }

  try {
    const type = camelCase(fileName.replace("kodexa-form-card-", ""));
    const name = startCase(fileName.replace("kodexa-form-card-", ""));
    const component = module.default;

    const metadataPath = `./${fileName}.ts`;
    const componentMetadata = metadataModules[metadataPath]?.componentMetadata || {};

    return {
      type,
      name,
      label: componentMetadata.label || name,
      description: componentMetadata.description || name,
      options: componentMetadata.options || [],
      supportsChildren: componentMetadata.supportsChildren || false,
      implementation: () => component,
    } satisfies CardDefinition;
  } catch (e) {
    log.info(`Unable to load card ${fileName} due to error ${e}`);
    return null;
  }
}).filter((card): card is CardDefinition => card !== null);

export { availableCards };
export type { CardDefinition };
