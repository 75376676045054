<script setup lang="ts">
import type { PropType } from "vue";
import type { Project, ProjectStatus } from "~/model";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { storeToRefs } from "pinia";
import { useListProjectStatuses } from "~/api/project-status/project-status";
import { getColorByBgColor } from "~/utils/colors";

const props = defineProps({
  modelValue: {
    type: Object as PropType<ProjectStatus | undefined>,
    required: false,
  },
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
});

const emit = defineEmits(["updated", "clear"]);

const gridHelper = createGridHelper(`status-${props.project?.organization?.id}`, {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "",
}, `organization.id:'${props.project?.organization?.id}'`, undefined, false);

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListProjectStatuses(gridQuery.value);

const localSelectedStatus = ref<ProjectStatus | undefined>(props.modelValue);

function selectItem(dataItem: any, props) {
  localSelectedStatus.value = dataItem;
  emit("updated", localSelectedStatus.value);
}
</script>

<template>
  <div class="flex items-center space-x-4 rounded">
    <DropDownList
      id="projectStatusDropdown"
      v-model="localSelectedStatus"
      :data-items="data?.content || []"
      value-field="id"
      text-field="status"
      :filterable="false"
      :value-primitive="false"
      fill-mode="flat"
      class="grow"
      :loading="isLoading"
      item-render="itemRender"
      value-render="valueRender"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <template #itemRender="{ props }">
        <div
          v-if="props.dataItem" class="kodexa-input mx-2 mt-1 cursor-pointer py-0.5"
          @click="selectItem(props.dataItem, props)"
        >
          <div style="width: 100%">
            <span :style="{ 'background-color': props.dataItem.color, 'color': getColorByBgColor(props.dataItem.color) }" class="text-sm inline-flex items-center rounded-md px-2 py-1 font-medium">{{ props.dataItem.status }}</span>
          </div>
        </div>
      </template>
      <template #valueRender="{ props }">
        <div v-if="props.value" id="currentStatusDropdown" style="width: 100%" class="py-1">
          <span :style="{ 'background-color': props.value.color, 'color': getColorByBgColor(props.value.color) }" class="text-md inline-flex items-center rounded-md px-2 py-1 font-medium">{{ props.value.status }}</span>
        </div>
        <div v-else id="statusDropdown" class="py-1 w-full">
          <span class="text-gray-400">Select status...</span>
        </div>
      </template>
    </DropDownList>
    <MaterialDesignIcon id="closeProjectStatus" name="close" @click="emit('clear')" />
  </div>
</template>
