<script setup lang="ts">
import type { PropType, Ref } from "vue";
import type { AssignmentRule, DashboardWidget } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useListAssignmentRules } from "~/api/assignment-rule/assignment-rule";
import { AssignmentRuleAssignmentType } from "~/model";
import appStore from "~/store";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
  },
});

const { project } = storeToRefs(appStore.projectStore);

const gridHelper = createGridHelper("assignment-rule-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  isError,
  data,
  refetch,
} = useListAssignmentRules(computed(() => ({
  ...gridQuery.value,
})));

watch(project, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`project.id: '${project.value.id}'`);
  }
}, { immediate: true });

const assignmentTypeDisplayMap = {
  [AssignmentRuleAssignmentType.USER]: "User",
  [AssignmentRuleAssignmentType.DYNAMIC_USER]: "Dynamic User",
};

const assignments = computed(() => {
  return (data.value?.content || []).map((assignment) => {
    return {
      ...assignment,
      assignmentType: assignmentTypeDisplayMap[assignment.assignmentType],
    };
  });
});

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = [
  {
    title: "",
    width: "50px",
    cell: "dragHandle",
  },
  {
    title: "Name",
    field: "name",
    cell: "name",
  },
  {
    title: "Assignment Type",
    field: "assignmentType",
  },
  {
    title: "Priority",
    field: "priority",
  },
];

const showCreateAssignment = ref(false);
const selectedAssignment: Ref<AssignmentRule | undefined> = ref(undefined);
const showSelectedAssignment = ref(false);

function openNewAssignment() {
  showCreateAssignment.value = true;
}

function editAssignment(assignment: AssignmentRule) {
  selectedAssignment.value = {
    ...assignment,
    assignmentType: Object.keys(assignmentTypeDisplayMap).find(key => assignmentTypeDisplayMap[key] === assignment.assignmentType),
  };
  showSelectedAssignment.value = true;
}

async function handleNewAssignment() {
  await refetch();
  showCreateAssignment.value = false;
}

const gridStyle = computed(() => {
  if (props.widget?.properties?.style !== undefined) {
    return props.widget?.properties?.style;
  } else {
    const rowSpan = props.widget?.properties?.defaultPosition?.rowSpan || 6;
    const height = (rowSpan * 140) - 130;
    return {
      height: `${height}px`,
    };
  }
});
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <Toolbar class="border-0 bg-white dark:bg-gray-800">
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50" @click="gridHelper.toggleFilter()" />
        <KodexaArticle class="mt-2 ml-2" article-id="9787655" text="Learn more about automated document assignment" />
        <ToolbarSpacer />
        <KodexaButton id="createNewAssignment" icon="plus" @click="openNewAssignment">
          New Assignment
        </KodexaButton>
      </Toolbar>
      <Grid
        :key="assignments.length"
        style="height: calc(100vh - 15rem);"
        :style="gridStyle"
        :data-items="assignments"
        :loader="isLoading"
        :pageable="pageSettings"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords :is-loading="isLoading" :is-error="isError" />
        </GridNoRecords>
        <template #dragHandle="{ dataItem }">
          <td>
            <MaterialDesignIcon name="drag-vertical" size="22" class="cursor-move" />
          </td>
        </template>
        <template #name="{ props }">
          <td>
            <div class="flex items-center">
              <div
                class="flex-0 cursor-pointer font-bold text-gray-900 hover:text-blue-600"
                @click="editAssignment(props.dataItem)"
              >
                {{ props.dataItem?.name }}
              </div>
            </div>
          </td>
        </template>
        <template #assignmentType="{ dataItem }">
          <td>
            <div class="flex items-center">
              <MaterialDesignIcon
                :name="dataItem.assignmentType === 'Dynamic User' ? 'users' : 'user'"
                size="22"
                class="mr-2"
              />
              {{ dataItem.assignmentType }}
            </div>
          </td>
        </template>
      </Grid>
      <ProjectNewAssignment v-model="showCreateAssignment" @refetch-assignments="handleNewAssignment" />
      <ProjectEditAssignment
        v-if="showSelectedAssignment"
        v-model="showSelectedAssignment"
        :assignment="selectedAssignment"
        @refetch-assignments="refetch"
      />
    </div>
  </div>
</template>
