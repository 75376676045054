<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const {
  isShown,
  localValue,
} = useConfigurationBase(props, emit);

function changeValue($event) {
  localValue.value = $event.target.checked;
}
</script>

<template>
  <div v-if="isShown">
    <div class="flex items-center">
      <input
        :id="`option-${item.name}`" :aria-describedby="`option-${item.name}-description`" :name="item.name" type="checkbox"
        :value="localValue" :disabled="disabled" :checked="localValue" class="size-4 rounded border-gray-300 dark:border-gray-600 text-blue-600 dark:text-blue-500 focus:ring-blue-500 dark:focus:ring-blue-400"
        @change="changeValue($event)"
      >
      <div class="ml-3 text-sm">
        <label :for="`option-${item.name}`" class="font-medium text-gray-700 dark:text-gray-300">{{ item.label ? item.falseLabel ? value ? item.label : item.falseLabel : item.label : item.name }}</label>
      </div>
    </div>
    <p v-if="item.description" class="ml-7 text-gray-500 text-sm dark:text-gray-400">
      {{ item.description }}
    </p>
  </div>
</template>

<style>

</style>
