<script setup lang="ts">
import type { PropType } from "vue";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { storeToRefs } from "pinia";

interface LookupItem {
  id: string | number;
  [key: string]: any;
}

const props = defineProps({
  modelValue: {
    type: Object as PropType<LookupItem | undefined>,
    required: false,
  },
  baseFilter: {
    type: [String],
    required: false,
    default: "",
  },
  queryFn: {
    type: Function as PropType<(query: any) => Promise<any>>,
    required: true,
  },
  textField: {
    type: String,
    default: "name",
  },
  valueField: {
    type: String,
    default: "id",
  },
  colorField: {
    type: String,
    default: "color",
  },
  placeholder: {
    type: String,
    default: "Select item...",
  },
  gridPrefix: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    default: undefined,
  },
  isObject: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "clear"]);

const gridHelper = createGridHelper(`${props.gridPrefix}-${props.baseFilter}`, {
  page: 1,
  pageSize: 50,
  filter: "",
  query: "",
  sort: "",
}, props.baseFilter);

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = props.queryFn(gridQuery.value);

const localSelectedItem = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

function selectItem(dataItem: LookupItem) {
  localSelectedItem.value = dataItem;

  if (props.isObject) {
    // We want to emit the whole object not just the value
    data?.content?.forEach((item: LookupItem) => {
      if (item[props.valueField] === dataItem[props.valueField]) {
        emit("update:modelValue", item);
      }
    });
  } else {
    emit("update:modelValue", dataItem[props.valueField]);
  }
}

// We need dataItems to check if there is data.content or just data to be able to render the dropdown
const dataItems = computed(() => {
  if (data.value) {
    if (Array.isArray(data.value.content)) {
      return data.value.content;
    } else if (Array.isArray(data.value)) {
      return data.value;
    }
  }
  return [];
});
</script>

<template>
  <div>
    <label v-if="label" for="name" class="kodexa-label">{{ label }}</label>
    <DropDownList
      :id="`${gridPrefix}Dropdown`"
      v-model="localSelectedItem"
      class="w-full truncate overflow-ellipsis dropdown-input"
      :data-items="dataItems"
      :value-field="valueField"
      :text-field="textField"
      :filterable="false"
      :value-primitive="false"
      rounded="large"
      :loading="isLoading"
      item-render="itemRender"
      value-render="valueRender"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <template #itemRender="{ props }">
        <div
          v-if="props.dataItem"
          class="p-2"
          @click="selectItem(props.dataItem)"
        >
          <div style="width: 100%">
            <span
              class="text-sm inline-flex items-center rounded-md px-2 py-1 font-medium dark:text-gray-300"
            >
              {{ props.dataItem[textField] }}
            </span>
          </div>
        </div>
      </template>
      <template #valueRender="{ props }">
        <div
          v-if="props.value"
          :id="`current${gridPrefix}Dropdown`"
          style="width: 100%"
          class="k-input-value-text p-2"
        >
          <span
            class="text-md inline-flex items-center rounded-md px-2 py-1 font-medium dark:text-gray-300"
          >
            {{ props.value[textField] }}
          </span>
        </div>
        <div v-else :id="`${gridPrefix}Dropdown`" class="p-2 w-full">
          <span class="text-gray-400 dark:text-gray-500">{{ placeholder }}</span>
        </div>
      </template>
    </DropDownList>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-gray-700 dark:text-gray-300 mb-1;
}

.kodexa-label {
  @apply block text-sm text-gray-700 dark:text-gray-300 mb-1;
}

.kodexa-input > .k-input {
  width: 100%;
  border: 0;
}

.kodexa-input, .kodexa-input > input {
  @apply appearance-none rounded-md border border-gray-300 dark:border-gray-600 px-0 py-0 shadow-sm placeholder:text-gray-400 dark:placeholder:text-gray-500 sm:text-sm dark:bg-gray-700;
}

.kodexa-input:focus-within,
.kodexa-input > input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500;
}

.kodexa-input:focus,
.kodexa-input > input:focus {
  @apply border-blue-500 ring-1 ring-blue-500;
}

:deep(.dropdown-input) {
  height: 32px !important;
  padding-top: 0 !important;
  display: flex;
  align-items: center;
}

:deep(.k-input-value-text) {
  display: flex;
  align-items: center;
  height: 100%;
}

:deep(button.k-button.k-button-md.k-icon-button.k-button-solid.k-button-solid-base.k-input-button) {
  box-shadow: none !important;
}

:deep(.k-button:hover) {
  background-color: transparent !important;
}

:deep(.k-picker-solid) {
  border-color: #D1D5DB !important;
  background-color: white !important;
  background-image: none !important;
  @apply dark:border-gray-600 dark:bg-gray-700 !important;
}

:deep(.k-input-inner) {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>
