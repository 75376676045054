<script setup lang="ts">
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { useOrganizationQuery } from "~/store/useOrganizationQuery";

const { memberships } = storeToRefs(appStore.userStore);

const organizationQuery = useOrganizationQuery();

const organizations = computed(() => {
  return memberships.value.map(membership => membership.organization);
});

const queryResults = ref([]);

organizations.value.map(async (org) => {
  const result = await organizationQuery.query(org.id, {
    size: 0,
    aggs: {
      size_by_date: {
        date_histogram: {
          field: "createdOn",
          calendar_interval: "day",
        },
        aggs: {
          total_size: {
            sum: {
              field: "size",
            },
          },
        },
      },
    },
  });
  queryResults.value.push({ name: org.name, data: result });
});
</script>

<template>
  <div v-if="queryResults" class="mt-4 bg-white dark:bg-gray-800">
    <ChartCountByDate :datasets="queryResults" />
  </div>
</template>
