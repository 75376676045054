<script setup lang="ts">
import type { PropType } from "vue";
import type { DocumentFamily, Store } from "~/model";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  documentFamilies: {
    type: Array as PropType<DocumentFamily[]>,
    required: true,
  },
  store: {
    type: Object as PropType<Store>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "clearSelection"]);

function close() {
  emit("update:modelValue", false);
}

const impactedDocuments = computed(() => {
  return props.documentFamilies?.length;
});

const selectAll = ref(false);

const { assistants } = storeToRefs(appStore.projectStore);

const running = ref(false);
const assistantId = ref(undefined);

async function startReprocess() {
  running.value = true;
  await appStore.projectStore.reprocessDocuments(props.store, selectAll.value, props.documentFamilies, assistantId.value);
  running.value = false;
  emit("clearSelection");
  close();
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 700px; height: 560px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 text-left shadow-xl transition-all"
            >
              <div class="ml-4 pb-2">
                <KodexaHeader
                  title="Bulk Reprocessing"
                  sub-title="Select which assistant you would like to revisit your documents"
                />
              </div>
              <div class="pt-4">
                <div>
                  <div class="mx-6 mb-32">
                    <p class="text-gray-800 dark:text-gray-200">
                      You have selected <span class="font-bold">{{ selectAll ? "all" : impactedDocuments }}</span>
                      documents from the store <span class="font-bold">{{ props.store.name }}</span>.
                    </p>
                    <p>
                      <KodexaCheckbox
                        v-model="selectAll"
                        name="selectAll"
                        class="mt-6"
                        label="Select All Documents"
                        hint="Checking this option will select all documents in the store"
                      />
                    </p>
                    <p v-if="assistants" class="mt-10 text-gray-800 dark:text-gray-200">
                      Select the assistant you want to use to reprocess the documents

                      <KodexaDropDown
                        v-model="assistantId" name="assistantId" class="mt-2 w-3/5" :items="assistants"
                        text-field="name"
                        value-field="id"
                      />
                    </p>
                    <p v-else class="text-gray-800 dark:text-gray-200">
                      No assistants are available to reprocess the documents
                    </p>
                    <p class="mt-6">
                      <MaterialDesignIcon name="alert-circle-outline" class="h-6 w-6 text-red-700 dark:text-red-500 mr-2 mt-2" />
                      <span class="font-bold text-red-700 dark:text-red-500">This action cannot be undone.</span>
                    </p>
                  </div>
                </div>
                <div
                  class="mx-4 sm:flex sm:flex-row-reverse"
                >
                  <KodexaButton
                    icon="file-document-refresh-outline" width="250px" type="danger" :loading="running"
                    :disabled="!assistantId" @click="startReprocess"
                  >
                    Reprocess Documents
                  </KodexaButton>
                  <KodexaButton
                    class="mr-2" width="120px"
                    icon="cancel" type="secondary"
                    :disabled="running"
                    @click="close"
                  >
                    Cancel
                  </KodexaButton>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
