/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  AssistantDefinition,
  CreateAssistantDefinitionParams,
  DeleteAssistantDefinitionParams,
  DeleteVersionAssistantDefinitionParams,
  ExceptionResponse,
  ListAssistantDefinitionsForOrganizationParams,
  ListAssistantDefinitionsParams,
  PageAssistantDefinition,
  StreamingResponseBody,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get the current version of the object with given slug
 */
export const getAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
      
      return customAxios<AssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssistantDefinitionQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,) => {
    return ['api','assistantDefinitions',organizationSlug,slug] as const;
    }

    
export const getGetAssistantDefinitionQueryOptions = <TData = Awaited<ReturnType<typeof getAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantDefinitionQueryKey(organizationSlug,slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistantDefinition>>> = ({ signal }) => getAssistantDefinition(organizationSlug,slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistantDefinition>>, TError, TData> 
}

export type GetAssistantDefinitionQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistantDefinition>>>
export type GetAssistantDefinitionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetAssistantDefinition<TData = Awaited<ReturnType<typeof getAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetAssistantDefinitionQueryOptions(organizationSlug,slug,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the current version object with given slug in the organization
 */
export const updateAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    assistantDefinition: MaybeRef<AssistantDefinition>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
assistantDefinition = unref(assistantDefinition);
      
      return customAxios<AssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistantDefinition
    },
      options);
    }
  


export const getUpdateAssistantDefinitionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantDefinition>>, TError,{organizationSlug: string;slug: string;data: AssistantDefinition}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssistantDefinition>>, TError,{organizationSlug: string;slug: string;data: AssistantDefinition}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssistantDefinition>>, {organizationSlug: string;slug: string;data: AssistantDefinition}> = (props) => {
          const {organizationSlug,slug,data} = props ?? {};

          return  updateAssistantDefinition(organizationSlug,slug,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateAssistantDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssistantDefinition>>>
    export type UpdateAssistantDefinitionMutationBody = AssistantDefinition
    export type UpdateAssistantDefinitionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateAssistantDefinition = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantDefinition>>, TError,{organizationSlug: string;slug: string;data: AssistantDefinition}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateAssistantDefinition>>,
        TError,
        {organizationSlug: string;slug: string;data: AssistantDefinition},
        TContext
      > => {

      const mutationOptions = getUpdateAssistantDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the current version of the given object
 */
export const deleteAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<DeleteAssistantDefinitionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteAssistantDefinitionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssistantDefinition>>, TError,{organizationSlug: string;slug: string;params?: DeleteAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAssistantDefinition>>, TError,{organizationSlug: string;slug: string;params?: DeleteAssistantDefinitionParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAssistantDefinition>>, {organizationSlug: string;slug: string;params?: DeleteAssistantDefinitionParams}> = (props) => {
          const {organizationSlug,slug,params} = props ?? {};

          return  deleteAssistantDefinition(organizationSlug,slug,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteAssistantDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAssistantDefinition>>>
    
    export type DeleteAssistantDefinitionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteAssistantDefinition = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssistantDefinition>>, TError,{organizationSlug: string;slug: string;params?: DeleteAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteAssistantDefinition>>,
        TError,
        {organizationSlug: string;slug: string;params?: DeleteAssistantDefinitionParams},
        TContext
      > => {

      const mutationOptions = getDeleteAssistantDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specific version of the object with given slug
 */
export const getVersionAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<AssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}/${version}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVersionAssistantDefinitionQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','assistantDefinitions',organizationSlug,slug,version] as const;
    }

    
export const getGetVersionAssistantDefinitionQueryOptions = <TData = Awaited<ReturnType<typeof getVersionAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetVersionAssistantDefinitionQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionAssistantDefinition>>> = ({ signal }) => getVersionAssistantDefinition(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionAssistantDefinition>>, TError, TData> 
}

export type GetVersionAssistantDefinitionQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionAssistantDefinition>>>
export type GetVersionAssistantDefinitionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetVersionAssistantDefinition<TData = Awaited<ReturnType<typeof getVersionAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetVersionAssistantDefinitionQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the object with given slug and version in the organization
 */
export const updateVersionAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    assistantDefinition: MaybeRef<AssistantDefinition>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
assistantDefinition = unref(assistantDefinition);
      
      return customAxios<AssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}/${version}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistantDefinition
    },
      options);
    }
  


export const getUpdateVersionAssistantDefinitionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;data: AssistantDefinition}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;data: AssistantDefinition}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateVersionAssistantDefinition>>, {organizationSlug: string;slug: string;version: string;data: AssistantDefinition}> = (props) => {
          const {organizationSlug,slug,version,data} = props ?? {};

          return  updateVersionAssistantDefinition(organizationSlug,slug,version,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateVersionAssistantDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof updateVersionAssistantDefinition>>>
    export type UpdateVersionAssistantDefinitionMutationBody = AssistantDefinition
    export type UpdateVersionAssistantDefinitionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateVersionAssistantDefinition = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;data: AssistantDefinition}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateVersionAssistantDefinition>>,
        TError,
        {organizationSlug: string;slug: string;version: string;data: AssistantDefinition},
        TContext
      > => {

      const mutationOptions = getUpdateVersionAssistantDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the specified version of the given object
 */
export const deleteVersionAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<DeleteVersionAssistantDefinitionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}/${version}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteVersionAssistantDefinitionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionAssistantDefinitionParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>, {organizationSlug: string;slug: string;version: string;params?: DeleteVersionAssistantDefinitionParams}> = (props) => {
          const {organizationSlug,slug,version,params} = props ?? {};

          return  deleteVersionAssistantDefinition(organizationSlug,slug,version,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionAssistantDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>>
    
    export type DeleteVersionAssistantDefinitionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteVersionAssistantDefinition = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>,
        TError,
        {organizationSlug: string;slug: string;version: string;params?: DeleteVersionAssistantDefinitionParams},
        TContext
      > => {

      const mutationOptions = getDeleteVersionAssistantDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of the resources for an organization
 */
export const listAssistantDefinitionsForOrganization = (
    organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListAssistantDefinitionsForOrganizationParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
params = unref(params);
      
      return customAxios<PageAssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListAssistantDefinitionsForOrganizationQueryKey = (organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListAssistantDefinitionsForOrganizationParams>,) => {
    return ['api','assistantDefinitions',organizationSlug, ...(params ? [params]: [])] as const;
    }

    
export const getListAssistantDefinitionsForOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListAssistantDefinitionsForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListAssistantDefinitionsForOrganizationQueryKey(organizationSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>> = ({ signal }) => listAssistantDefinitionsForOrganization(organizationSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>, TError, TData> 
}

export type ListAssistantDefinitionsForOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>>
export type ListAssistantDefinitionsForOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListAssistantDefinitionsForOrganization<TData = Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListAssistantDefinitionsForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListAssistantDefinitionsForOrganizationQueryOptions(organizationSlug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the object in the organization using the template with given ref
 */
export const createAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    assistantDefinition: MaybeRef<AssistantDefinition>,
    params: MaybeRef<CreateAssistantDefinitionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
assistantDefinition = unref(assistantDefinition);
params = unref(params);
      
      return customAxios<AssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assistantDefinition,
        params: unref(params), signal
    },
      options);
    }
  


export const getCreateAssistantDefinitionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssistantDefinition>>, TError,{organizationSlug: string;data: AssistantDefinition;params: CreateAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createAssistantDefinition>>, TError,{organizationSlug: string;data: AssistantDefinition;params: CreateAssistantDefinitionParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAssistantDefinition>>, {organizationSlug: string;data: AssistantDefinition;params: CreateAssistantDefinitionParams}> = (props) => {
          const {organizationSlug,data,params} = props ?? {};

          return  createAssistantDefinition(organizationSlug,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateAssistantDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof createAssistantDefinition>>>
    export type CreateAssistantDefinitionMutationBody = AssistantDefinition
    export type CreateAssistantDefinitionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateAssistantDefinition = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssistantDefinition>>, TError,{organizationSlug: string;data: AssistantDefinition;params: CreateAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createAssistantDefinition>>,
        TError,
        {organizationSlug: string;data: AssistantDefinition;params: CreateAssistantDefinitionParams},
        TContext
      > => {

      const mutationOptions = getCreateAssistantDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of all resources
 */
export const listAssistantDefinitions = (
    params?: MaybeRef<ListAssistantDefinitionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageAssistantDefinition>(
      {url: `/api/assistantDefinitions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListAssistantDefinitionsQueryKey = (params?: MaybeRef<ListAssistantDefinitionsParams>,) => {
    return ['api','assistantDefinitions', ...(params ? [params]: [])] as const;
    }

    
export const getListAssistantDefinitionsQueryOptions = <TData = Awaited<ReturnType<typeof listAssistantDefinitions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListAssistantDefinitionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListAssistantDefinitionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAssistantDefinitions>>> = ({ signal }) => listAssistantDefinitions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitions>>, TError, TData> 
}

export type ListAssistantDefinitionsQueryResult = NonNullable<Awaited<ReturnType<typeof listAssistantDefinitions>>>
export type ListAssistantDefinitionsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListAssistantDefinitions<TData = Awaited<ReturnType<typeof listAssistantDefinitions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListAssistantDefinitionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListAssistantDefinitionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the change sequence for resource with the provided slug and version
 */
export const getModifiedForAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<number>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}/${version}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetModifiedForAssistantDefinitionQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','assistantDefinitions',organizationSlug,slug,version,'sequence'] as const;
    }

    
export const getGetModifiedForAssistantDefinitionQueryOptions = <TData = Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetModifiedForAssistantDefinitionQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>> = ({ signal }) => getModifiedForAssistantDefinition(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>, TError, TData> 
}

export type GetModifiedForAssistantDefinitionQueryResult = NonNullable<Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>>
export type GetModifiedForAssistantDefinitionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetModifiedForAssistantDefinition<TData = Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetModifiedForAssistantDefinitionQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



