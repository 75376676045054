import type { Dashboard } from "~/model";
import { defineStore } from "pinia";

export interface DashboardScope {
  name?: string;
  id?: string;
  orgSlug?: string;
}

export const useDashboardsView = defineStore("dashboardsView", () => {
  const defaultDashboards: Ref<Dashboard[]> = ref([]);

  const dashboards: Ref<Dashboard[]> = ref([]);

  const showTabs = ref(true);

  const orderedDashboards = computed(() => {
    return defaultDashboards.value.sort((a, b) => a.order - b.order);
  });

  const dashboardsDirty = ref(false);
  const dashboardScope = ref<DashboardScope | undefined>(undefined);

  const currentNavigation = ref<Dashboard | undefined>(undefined);

  function initialize(initialDashboards: Dashboard[], scope: DashboardScope, willShowTabs = true) {
    defaultDashboards.value = initialDashboards;
    showTabs.value = willShowTabs;
    dashboardScope.value = scope;
  }

  function setCurrentNavigation(dashboard: Dashboard) {
    currentNavigation.value = dashboard;
  }

  return {
    defaultDashboards,
    dashboards,
    orderedDashboards,
    dashboardsDirty,
    initialize,
    showTabs,
    currentNavigation,
    setCurrentNavigation,
  };
});
