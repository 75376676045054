<script setup lang="ts">
import type { ProductGroup } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { createProductGroup, useListProductGroups } from "~/api/product-groups/product-groups";
import { updateHandler } from "~/utils/error-handler";

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue", "refetchProductGroup"]);

const {
  data,
} = useListProductGroups({ pageSize: 100 });

function newProductGroupPlaceholder() {
  return {
    name: "",
    description: "",
    overviewMarkdown: "",
    active: false,
  };
}

const newProductGroup = ref(newProductGroupPlaceholder());

const requiredFields = ["name"];
const requiredFieldsError = ref({});
function close() {
  newProductGroup.value = newProductGroupPlaceholder();
  requiredFieldsError.value = {};
  emit("update:modelValue", false);
}

async function createNewProductGroup() {
  requiredFields.forEach((field) => {
    const value = newProductGroup.value[field];
    if (!value || (typeof value === "object" && Object.keys(value).length === 0)) {
      requiredFieldsError.value[field] = "This is required";
    } else if (requiredFieldsError.value[field]) {
      delete requiredFieldsError.value[field];
    }
  });

  if (Object.keys(requiredFieldsError.value).length !== 0) {
    return;
  }
  await updateHandler(createProductGroup(newProductGroup.value as ProductGroup), "Product Group created successfully");

  close();
  emit("refetchProductGroup");
}

function clearError(field) {
  const value = newProductGroup.value[field];
  if ((value && typeof value !== "object") || Object.keys(value).length !== 0) {
    if (requiredFieldsError.value[field]) {
      delete requiredFieldsError.value[field];
      requiredFieldsError.value = { ...requiredFieldsError.value };
    }
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
                  <div class="max-h-full flex-1 overflow-y-auto">
                    <!-- Header -->
                    <div class="bg-gray-50 dark:bg-gray-900 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            New Product Group
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Get started by filling in the information below to create a new product group.
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancelNewProduct" icon="cancel" type="secondary" size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="createNewProductGroup" icon="plus" type="primary" size="small"
                              @click="createNewProductGroup"
                            >
                              Create
                            </KodexaButton>
                          </div>
                        </div>
                        <!--                        <div class="flex h-7 items-center"> -->
                        <!--                          <button type="button" class="text-gray-400 hover:text-gray-500" @click="close"> -->
                        <!--                            <span class="sr-only">Close panel</span> -->
                        <!--                            <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" /> -->
                        <!--                          </button> -->
                        <!--                        </div> -->
                      </div>
                    </div>
                    <div class="min-h-0 flex-1 overflow-y-auto">
                      <!-- Divider container -->
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <!-- Product Group Name -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="product-group-name"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Product Group
                              Name</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput id="product-group-name" v-model="newProductGroup.name" name="name" :errors="requiredFieldsError" @input="clearError('name')" />
                          </div>
                        </div>

                        <!-- Product Group Description -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="product-group-desc"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Description</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextArea id="product-group-desc" v-model="newProductGroup.description" rows="2" name="product-group-desc" />
                          </div>
                        </div>
                        <!-- Product Overview Markdown -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="overview-markdown"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Overview
                              Markdown</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaMarkdownEditor v-model="newProductGroup.overviewMarkdown" name="overview-markdown" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
