<script setup lang="ts">
const props = defineProps<{
  status: string;
  label?: string;
}>();

const statusClasses = {
  SUCCEEDED: "bg-green-100 text-green-800",
  FAILED: "bg-red-100 text-red-800",
  PENDING: "bg-yellow-100 text-yellow-800",
  PENDING_REPROCESSING: "bg-yellow-100 text-yellow-500",
  CANCELLED: "bg-gray-100 text-gray-800",
  CANCELLING: "bg-gray-100 text-gray-800",
  RUNNING: "bg-blue-100 text-blue-800",
};
</script>

<template>
  <span
    :class="statusClasses[status]"
    class="mr-1 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium"
  >{{ props.label ? props.label : status }}</span>
</template>

<style scoped>

</style>
