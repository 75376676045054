<script lang="ts" setup>
import type { DockviewApi, DockviewReadyEvent } from "dockview-vue";
import { useDark } from "@vueuse/core";
import { DockviewVue } from "dockview-vue";
import { storeToRefs } from "pinia";
import { getCurrentInstance } from "vue";
import KodexaDocumentView from "~/components/document/kodexa-document-view.vue";
import appStore from "~/store";

const dockviewApi: Ref<DockviewApi | undefined> = ref(undefined);

const isDark = useDark({
  selector: "html",
  attribute: "class",
  valueDark: "dark",
  valueLight: "light",
});

const app = getCurrentInstance()?.appContext;

function dockReady(api: DockviewReadyEvent) {
  dockviewApi.value = api.api;

  // We need to register the view components
  if (app) {
    app.app.component("DocumentView", KodexaDocumentView);
  }
}

const {
  currentWorkspaceId,
} = storeToRefs(appStore.workspaceStore);

const sidecarStore = createSidecar(currentWorkspaceId.value);

const {
  sidecarView,
} = storeToRefs(sidecarStore);

const sidecarViewPanel = ref();

watch(sidecarView, () => {
  if (dockviewApi.value) {
    if (sidecarView.value) {
      sidecarViewPanel.value = dockviewApi.value.addPanel({
        id: sidecarView.value.id,
        component: "DocumentView",
        params: {
          viewId: sidecarView.value.id,
        },
      });
      sidecarViewPanel.value.group.locked = true;
      sidecarViewPanel.value.group.header.hidden = true;
    } else if (sidecarViewPanel.value) {
      dockviewApi.value.removePanel(sidecarViewPanel.value);
    }
  }
}, { immediate: true });
</script>

<template>
  <div>
    <DockviewVue
      :class="!isDark ? 'dockview-theme-light' : 'dockview-theme-dark'" style="height: calc(100vh - 10rem)"
      watermark-component="WorkspaceSidecarEmpty" @ready="dockReady"
    />
  </div>
</template>

<style scoped>

</style>
