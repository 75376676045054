<script setup lang="ts">
import type { PropType } from "vue";
import appStore from "~/store";
import { log } from "~/utils/logger";
import type { DashboardWidget } from "~/model";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
  refresher: {
    type: Number,
    required: false,
    default: 1,
  },
});

const componentMetadata = {
  label: "Number Card",
  description: "Displays a numeric count fetched from a query URL",
  options: [
    {
      label: "Query URL",
      key: "queryUrl",
      type: "string",
      required: true,
      description: "URL to fetch the count data from"
    },
    {
      label: "Title",
      key: "title",
      type: "string",
      required: false,
      description: "Title to display above the count"
    }
  ]
};

// Explicitly expose the metadata
defineExpose({
  metadata: componentMetadata,
});

const count = ref(undefined);

const error = ref(undefined);
const loadingMessage = ref("Running Query");

const loading = ref(false);

const fetchData = async () => {
  count.value = undefined;
  if (!props.widget?.properties?.queryUrl) {
    return;
  }
  loading.value = true;
  try {
    const data = await appStore.aggregationStore.runQuery(props.widget.properties.queryUrl, "data");
    count.value = data[0].length;
    loading.value = false;
  } catch (e) {
    error.value = e;
    console.log(e);
  }
};

onMounted(async () => {
  fetchData();
});

watch(() => [props.refresher, props.widget], async () => {
  fetchData();
});
</script>

<template>
  <div v-if="loading">
    <div class="text-center">
      <KodexaSmallLoader class="mb-6 mt-10" />
      <h2 class="text-base font-semibold leading-6 text-gray-900">
        {{ loadingMessage }}
      </h2>
      <p class="mt-1 px-10 text-sm text-gray-500">
        Please wait...
      </p>
    </div>
  </div>
  <div v-else-if="count">
    <div class="text-left">
      <div class="mb-1 text-lg font-semibold text-black">
        {{ props.widget?.properties?.title }}
      </div>
      <div class="text-xl font-bold text-gray-700">
        {{ count?.toLocaleString() ?? '0' }}
      </div>
    </div>
  </div>
  <div v-else-if="error">
    <div class="text-center">
      <MaterialDesignIcon name="information" class="mb-6 mt-10 h-12 w-12 text-gray-400" aria-hidden="true" />
      <h2 class="text-base font-semibold leading-6 text-gray-900">
        An Error Occurred
      </h2>
      <p class="mt-1 px-10 text-sm text-gray-500">
        {{ error }}
      </p>
    </div>
  </div>
  <div v-else class="flex items-center justify-center">
    <div class="text-center">
      <MaterialDesignIcon name="information" class="mb-6 mt-10 h-12 w-12 text-gray-400" aria-hidden="true" />
      <h2 class="text-base font-semibold leading-6 text-gray-900">
        Missing Set-up
      </h2>
      <p class="mt-1 px-10 text-sm text-gray-500">
        Configure this widget by the setting query.
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>
