<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Label, Option } from "~/model";
import { storeToRefs } from "pinia";
import KodexaDropDown from "~/components/inputs/kodexa-drop-down.vue";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import appStore from "~/store";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const { project } = storeToRefs(appStore.projectStore);

const labels: Label[] = await appStore.projectStore.getAvailableLabels();

const {
  isShown,
  collapsed,
  type,
  localValue,
} = useConfigurationBase(props, emit);
</script>

<template>
  <div v-if="isShown">
    <KodexaDropDown
      :name="item.name"
      :label="item.label"
      :items="labels" :value="localValue" :item-text="(itemLabel) => itemLabel.label"
      :item-value="(itemLabel) => itemLabel.name"
    />
  </div>
</template>

<style>

</style>
