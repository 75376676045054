<script setup lang="ts">
import type { TaskTemplate } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { computed, type PropType, ref } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { useDeleteTaskTemplate, useUpdateTaskTemplate } from "~/api/task-templates/task-templates";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import { updateHandler } from "~/utils/error-handler";

// Props and Emits
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  template: {
    type: Object as PropType<TaskTemplate>,
    required: true,
  },
});

const emit = defineEmits<{
  "update:modelValue": [value: boolean];
  "template-deleted": [id: string];
  "refetch-templates": [];
}>();

// State
const isOpen = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

const editedTemplate = ref<TaskTemplate>(
  props.template ? JSON.parse(JSON.stringify(props.template)) : {} as TaskTemplate,
);

const requiredFieldsError = ref<Record<string, string>>({});
const requiredFields = ["title", "description"] as const;

// Mutations
const updateTemplateMutation = useUpdateTaskTemplate();
const deleteTemplateMutation = useDeleteTaskTemplate();

// Methods
function close() {
  requiredFieldsError.value = {};
  emit("update:modelValue", false);
}

function clearError(field: string) {
  if (requiredFieldsError.value[field]) {
    delete requiredFieldsError.value[field];
    requiredFieldsError.value = { ...requiredFieldsError.value };
  }
}

function validateFields() {
  requiredFields.forEach((field) => {
    if (!editedTemplate.value[field]) {
      requiredFieldsError.value[field] = "This field is required";
    } else {
      delete requiredFieldsError.value[field];
    }
  });

  return Object.keys(requiredFieldsError.value).length === 0;
}

async function updateTemplate() {
  if (!validateFields()) {
    return;
  }

  try {
    await updateTemplateMutation.mutateAsync({
      id: editedTemplate.value.id as string,
      data: editedTemplate.value,
    });
    await updateHandler(Promise.resolve(), "Template updated successfully");
    emit("refetch-templates");
    close();
  } catch (error) {
    console.error("Error updating template:", error);
    await updateHandler(Promise.reject(error), "Failed to update template");
  }
}

async function deleteTemplate() {
  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: `Delete template ${editedTemplate.value.title}?`,
    message: "This action cannot be undone.",
  });

  if (!isCanceled) {
    try {
      await deleteTemplateMutation.mutateAsync({
        id: editedTemplate.value.id as string,
      });
      emit("refetch-templates");
      await updateHandler(Promise.resolve(), "Template deleted successfully");
      emit("template-deleted", editedTemplate.value.id);
      close();
    } catch (error) {
      console.error("Error deleting template:", error);
      await updateHandler(Promise.reject(error), "Failed to delete template");
    }
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col divide-y divide-gray-200 bg-white dark:bg-gray-800 shadow-xl">
                  <!-- Header -->
                  <div class="space-y-4 bg-gray-50 dark:bg-gray-900 px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between">
                      <div class="space-y-1">
                        <DialogTitle class="text-base font-semibold text-gray-900">
                          Update Task Template
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          Change the template details and choose Save Changes
                        </p>
                      </div>
                      <div class="flex space-x-3">
                        <KodexaButton
                          id="cancelEditTemplate"
                          icon="cancel"
                          type="secondary"
                          size="small"
                          @click="close"
                        >
                          Cancel
                        </KodexaButton>
                        <KodexaButton
                          id="updateTemplate"
                          icon="content-save"
                          type="primary"
                          size="small"
                          :loading="updateTemplateMutation.isLoading"
                          @click="updateTemplate"
                        >
                          Save Changes
                        </KodexaButton>
                      </div>
                    </div>
                  </div>

                  <!-- Form -->
                  <div class="flex-1 overflow-y-auto">
                    <div class="space-y-6 p-4 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:p-6">
                      <!-- Name -->
                      <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="title"
                          class="block text-sm font-medium text-gray-900 sm:mt-1.5"
                        >
                          Template Name
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            id="title"
                            v-model="editedTemplate.title"
                            name="title"
                            :errors="requiredFieldsError"
                            @input="clearError('title')"
                          />
                        </div>
                      </div>

                      <!-- Description -->
                      <div class="space-y-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0">
                        <label
                          for="description"
                          class="block text-sm font-medium text-gray-900 sm:mt-1.5"
                        >
                          Description
                        </label>
                        <div class="sm:col-span-2">
                          <KodexaTextArea
                            id="description"
                            v-model="editedTemplate.description"
                            name="description"
                            :rows="4"
                            :errors="requiredFieldsError"
                            @input="clearError('description')"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- Delete Section -->
                    <div class="m-6 space-y-4 rounded-lg border-2 border-red-200 bg-white dark:bg-gray-800 p-4">
                      <h3 class="text-base font-semibold text-red-600">
                        Delete Task Template
                      </h3>
                      <div class="flex flex-col items-center justify-between space-y-4 sm:flex-row sm:space-y-0">
                        <p>
                          Are you sure you want to delete this task template? This cannot be undone.
                        </p>
                        <KodexaButton
                          id="deleteTemplate"
                          type="danger"
                          :loading="deleteTemplateMutation.isLoading"
                          @click="deleteTemplate"
                        >
                          Delete
                        </KodexaButton>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
