<script setup lang="ts">
import type { GridColumnProps } from "@progress/kendo-vue-grid/dist/npm/interfaces/GridColumnProps";
import type { Assistant, Execution } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar } from "@progress/kendo-vue-buttons";

import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useListExecutions } from "~/api/executions/executions";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";
import { log } from "~/utils/logger";

dayjs.extend(relativeTime);

const dayJs = dayjs;
const { project, assistants } = storeToRefs(appStore.projectStore);

// Setup our grid helpers
const gridHelper = createGridHelper(`project-events-${project.value.id}`, {
  pageSize: 10,
  filter: "",
  sort: "",
  query: "",
  page: 1,
}, `assistant.id in [${assistants.value.map((a: Assistant) => `'${a.id}'`).join(",")}]`, [{
  field: "startDate",
  dir: "desc",
}]);

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
} = useListExecutions(gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

watch(assistants, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`assistant.id in (${assistants.value.map((a: Assistant) => `'${a.id}'`).join(",")})`);
  }
});

const columns: GridColumnProps[] = [
  { title: "Start", field: "startDate", filter: "date", cell: "startDate" },
  { title: "End", field: "endDate", filter: "date", cell: "endDate" },
  { title: "Assistant", field: "assistant", cell: "assistant" },
  { title: "Description", field: "description" },
  { title: "Status", field: "status", cell: "status" },
  { title: "", cell: "actions" },
];

const showPopup = ref(false);
const selectedExecution = ref<Execution | null>(null);

function viewDetails(execution: Execution) {
  log.info("View details", execution);
  selectedExecution.value = execution;
  showPopup.value = true;
}

function getAssistant(assistantId: string) {
  const assistant = assistants.value.find((a: Assistant) => a.id === assistantId);
  if (assistant) {
    return assistant.name;
  }
  return "";
}
</script>

<template>
  <div class="mx-4">
    <Toolbar class="mb-1">
      <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class="dark:text-gray-50" />
    </Toolbar>
    <Grid
      style="height: calc(100vh - 9rem);"
      :data-items="data ? data.content : []"
      :pageable="pageSettings"
      :loader="isLoading"
      :sort="sort"
      :skip="pageSettings.skip as number"
      :total="pageSettings.total as number"
      :page-size="pageSettings.take as number"
      :take="pageSettings.take as number"
      :columns="columns"
      :filterable="showFilter"
      :filter="filter"
      :resizable="true"
      :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No activity found"
        />
      </GridNoRecords>
      <template #startDate="{ props }">
        <td>{{ props.dataItem.startDate ? dayJs(props.dataItem.startDate).fromNow() : '' }}</td>
      </template>
      <template #endDate="{ props }">
        <td>{{ props.dataItem.endDate ? dayJs(props.dataItem.endDate).fromNow() : '' }}</td>
      </template>
      <template #assistant="{ props }">
        <td>{{ getAssistant(props.dataItem.assistantId) }}</td>
      </template>
      <template #actions="{ props }">
        <td>
          <KodexaButton id="viewEventDetails" type="secondary" @click="viewDetails(props.dataItem)">
            View Details
          </KodexaButton>
        </td>
      </template>
      <template #status="{ props }">
        <td>
          <KodexaStatusBadge :status="props.dataItem.status" :label="props.dataItem.status" />
        </td>
      </template>
    </Grid>
    <KodexaExecutionPopup v-if="selectedExecution" v-model="showPopup" :executions="[selectedExecution]" />
  </div>
</template>

<style scoped>

</style>
