<script setup lang="ts">
import type { Ref } from "vue";
import type { Taxon } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import appStore from "~/store";
import { createDocumentViewerStore } from "~/store/useDocumentView";
import { randomColor } from "~/utils/colors";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["confirm", "cancel", "update:modelValue"]);

const useDocumentViewStore = createDocumentViewerStore(props.viewId);
const {
  selectionContext,
  documentFamily,
  page,
  contentExceptions,
  changeSequence,
} = storeToRefs(useDocumentViewStore);
const { contentTaxonomies, tagMetadataMap } = storeToRefs(appStore.projectStore);
const localTaxon: Ref<Taxon> = ref({
  label: selectionContext?.value?.selectedValue,
  taxonomy: contentTaxonomies.value ? contentTaxonomies.value[0] : undefined,
  description: "",
  color: randomColor(),
  group: false,
  generateName: true,
  name: uuidv4(),
  taxonType: "STRING",
  userEditable: true,
  valuePath: "VALUE_OR_ALL_CONTENT",
  externalName: "",
} as Taxon);

const loading = ref(false);

watch(() => props.modelValue, () => {
  if (!props.modelValue) {
    return;
  }

  localTaxon.value = {
    label: "",
    description: "",
    taxonomy: contentTaxonomies.value ? contentTaxonomies.value[0] : undefined,
    color: randomColor(),
    group: false,
    generateName: true,
    id: uuidv4(),
    enabled: true,
    name: uuidv4(),
    userEditable: true,
    valuePath: "VALUE_OR_ALL_CONTENT",
    externalName: "",
    taxonType: "STRING",
  } as Taxon;
});

const taxonTypes = [
  {
    id: "STRING",
    label: "String",
  },
  {
    id: "DATE",
    label: "Date",
  },
  {
    id: "DATE_TIME",
    label: "Date Time",
  },
  {
    id: "NUMBER",
    label: "Number",
  },
  {
    id: "BOOLEAN",
    label: "Boolean",
  },
  {
    id: "CURRENCY",
    label: "Currency",
  },
  {
    id: "URL",
    label: "URL",
  },
  {
    id: "EMAIL_ADDRESS",
    label: "Email Address",
  },
  {
    id: "PHONE_NUMBER",
    label: "Phone Number",
  },
  {
    id: "SELECTION",
    label: "Selection",
  },
];

function close() {
  emit("update:modelValue", false);
}

const taxonomyRef = ref(undefined);

const selectedTaxonomy = computed(() => {
  if (contentTaxonomies.value && taxonomyRef.value === undefined) {
    taxonomyRef.value = contentTaxonomies.value[0].ref;
  }

  return contentTaxonomies.value ? contentTaxonomies.value.find(taxonomy => taxonomy.ref === taxonomyRef.value) : undefined;
});

const selectedParent = ref(undefined);

watch(contentTaxonomies, () => {
  if (contentTaxonomies.value && taxonomyRef.value === undefined) {
    taxonomyRef.value = contentTaxonomies.value[0].ref;
  }
}, {
  immediate: true,
});

const createTaxon = function () {
  const newTaxon = {
    ...localTaxon.value,
    taxonomy: selectedTaxonomy.value.ref,
  };
  appStore.projectStore.createTaxon(selectedTaxonomy.value, selectedParent.value ? selectedParent.value.id : undefined, newTaxon);
  useDocumentViewStore.addTag(tagMetadataMap.value.get(newTaxon.path), selectionContext.value.selectedNodes);

  emit("confirm", newTaxon);
  close();
};

const selectTaxon = function (taxon: Taxon) {
  selectedParent.value = taxon;
};
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative" style="z-index: 100" @close="emit('update:modelValue', false)">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 950px; height: 560px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-4"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500"
                  @click="emit('update:modelValue', false)"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="size-4" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle class="flex text-lg font-medium leading-4 text-gray-900">
                <KodexaHeader title="New Data Element" sub-title="Add a new data element to your data definition" />
              </DialogTitle>
              <div>
                <div class="flex">
                  <div class="mx-4 mt-5 flex-1 overflow-y-scroll border-r" style="height: 270px;">
                    <p class="mb-1 font-medium text-gray-600">
                      Choose Data Definition
                    </p>
                    <KodexaDropDown
                      v-model="taxonomyRef" class="mb-2 w-9/12" :disabled="loading"
                      name="taxonomy" :items="contentTaxonomies"
                      value-field="ref" text-field="name"
                    />
                    <p class="mb-1 font-medium text-gray-600">
                      Choose a Data Definition Group
                    </p>
                    <TaxonomyTree
                      v-if="selectedTaxonomy" :taxonomy="selectedTaxonomy"
                      :group-only="true" :use-selection="false"
                      @select-taxon="selectTaxon"
                    />
                  </div>
                  <div v-if="localTaxon" class="mx-5 mt-5 flex-1 overflow-y-scroll" style="height: 340px;">
                    <div class="ml-2">
                      <div v-if="selectedParent" class="flex flex-col">
                        <p class="mb-1 font-medium text-gray-600">
                          New Data Definition
                        </p>
                        <KodexaTextInput
                          v-model="localTaxon.label" label="Name" type="text" name="name"
                          :loading="loading"
                          loading-placeholder="Analyzing..." class="w-3/5"
                        />
                        <ColorPicker
                          v-model="localTaxon.color" title="Color" view="gradient" class="mt-2"
                          :disabled="loading"
                        />
                        <KodexaTextArea
                          v-model="localTaxon.description" label="Description" class="mt-2"
                          name="description"
                          :rows="4" :loading="loading"
                        />

                        <KodexaDropDown
                          v-model="localTaxon.taxonType" class="mt-2 w-3/5" :disabled="loading"
                          label="Data Type" name="taxonType" :items="taxonTypes"
                          value-field="id" text-field="label"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="mr-4 mt-12 sm:flex sm:flex-row-reverse"
                >
                  <KodexaButton
                    icon="check" type="primary"
                    @click="createTaxon"
                  >
                    Create
                  </KodexaButton>
                  <KodexaButton
                    class="mr-2"
                    icon="cancel" type="secondary"
                    @click="close"
                  >
                    Cancel
                  </KodexaButton>
                </div>
              </div>
            </dialogpanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
