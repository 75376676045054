<script setup lang="ts">
import { toRef } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: false,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  label: {
    type: String,
    required: false,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: () => {},
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  noEmit: {
    type: Boolean,
    default: false,
  },
  hint: {
    type: String,
    default: undefined,
  },
  value: {
    type: Boolean,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["update:modelValue", "input", "blur"]);

const name = toRef(props, "name");

const localValue = computed({
  get: () => props.value === undefined ? props.modelValue : props.value,
  set: value => !props.noEmit ? emit("update:modelValue", value) : undefined,
});
</script>

<template>
  <div>
    <div class="flex h-8 items-center">
      <input
        :id="name"
        :disabled="disabled" :name="name" type="checkbox"
        :value="localValue" :checked="localValue" class="size-4 rounded border-gray-300 dark:border-gray-600 text-blue-600 dark:text-blue-500 dark:bg-gray-700"
        @change="localValue = $event.target.checked"
      >
      <div class="ml-3 text-sm">
        <label :for="name" class="font-medium text-gray-700 dark:text-gray-50">{{ label }}</label>
      </div>
    </div>
    <p v-if="hint" class="ml-7 text-gray-500 text-sm dark:text-gray-400">
      {{ hint }}
    </p>
    <p v-if="errors && errors[name]" class="ml-7 mt-2 text-sm text-red-600 dark:text-red-400">
      {{ errors[name] }}
    </p>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-gray-700 dark:text-gray-300 mb-1
}
</style>
