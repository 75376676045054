<script setup lang="ts">
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import type { PropType } from "vue";
import { provide } from "vue";
import type { DashboardWidget } from "~/model";
import appStore from "~/store";
import { log } from "~/utils/logger";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

provide(THEME_KEY, "light");

const aggregationResult = ref({});

appStore.aggregationStore.runQuery("projectTagsStats?fields[projectTagsStats]=name,color,instanceCount", "data").then((result) => {
  if (result && result.length > 0) {
    aggregationResult.value = result[0].map((item: any) => {
      return {
        name: item.attributes.name,
        value: item.attributes.instanceCount,
      };
    });
  }
}).catch((error) => {
  log.error("Error running query");
});

const chartOptions = computed(() => {
  return {
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "5%",
      left: "center",
    },
    series: [
      {
        name: "Projects by Tag",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: aggregationResult.value ? aggregationResult.value : [],
      },
    ],
  };
});
</script>

<template>
  <div style="height: 320px">
    <VChart autoresize :option="chartOptions" />
  </div>
</template>

<style scoped>
</style>
