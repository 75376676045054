<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Option } from "~/model";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
);

const emit = defineEmits(["update:modelValue"]);

// Might put this somewhere
const items = [{ id: 1, name: "Bar Graph" }, { id: 2, name: "Pie Graph" }];
const {
  isShown,
  collapsed,
  type,
  localValue,
} = useConfigurationBase(props, emit);
</script>

<template>
  <div v-if="isShown && items">
    <KodexaDropDown
      v-model="localValue"
      name="workspaceOption"
      :label="item.label"
      fill-mode="outline"
      text-field="name"
      value-field="name"
      :style="{ width: '100%' }"
      :items="items"
    />
  </div>
</template>

<style>

</style>
