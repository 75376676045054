import type { ILogObj } from "tslog";
import type { DataAttribute, DataException, DataObject, Taxon, Taxonomy, TaxonValidation } from "~/model";
import { defineStore, storeToRefs } from "pinia";
import { Logger } from "tslog";
import appStore from "~/store/index";

/**
 * We have a single instance of validators that we use within the
 * workspace to ensure that all the validations are run on the data objects and
 * data attributes
 */
export const useValidators = defineStore("validators", () => {
  const log: Logger<ILogObj> = new Logger({ name: "validators" });
  let isUpdatingExceptions = false;

  const { dataObjects } = storeToRefs(appStore.workspaceStore);
  const { contentTaxonomies, tagMetadataMap } = storeToRefs(appStore.projectStore);
  const taxonomyFormulaServices = new Map<string, any>();

  // We have a few ways in which we can get validation
  // 1. From the taxonomy, so we want to store those by path
  // 2. From the data object, so we want to store those by data object
  // 3. From the data attribute, so we want to store those by data object and path
  const validationsByTaxonPath = computed(() => {
    const validations = new Map<string, TaxonValidation[]>();

    function processTaxon(taxon: Taxon) {
      // Process validations for current node
      if (taxon.validationRules) {
        taxon.validationRules.forEach((validation) => {
          if (!validations.has(taxon.path as string)) {
            validations.set(taxon.path as string, []);
          }
          validations.get(taxon.path as string)?.push(validation);
        });
      }

      // Recursively process children
      if (taxon.children) {
        taxon.children.forEach(child => processTaxon(child));
      }
    }

    contentTaxonomies.value.forEach((taxonomy: Taxonomy) => {
      taxonomyFormulaServices.set(taxonomy.ref as string, createKodexaFormulaService(ref(taxonomy)).formulaService);
      taxonomy.taxons?.forEach((taxon: Taxon) => {
        processTaxon(taxon);
      });
    });

    return validations;
  });

  const validationsByDataObject = computed(() => {
    const validations = new Map<string, TaxonValidation[]>();
    dataObjects.value.forEach((dataObject: DataObject) => {
      if (dataObject.lineage?.validations) {
        validations.set(dataObject.uuid as string, dataObject.lineage.validations);
      }

      // if we have attribute validations we will add them to the data object dict with /path on the end
      if (dataObject.lineage?.attributeValidations) {
        Object.keys(dataObject.lineage.attributeValidations).forEach((path) => {
          if (!validations.has(`${dataObject.uuid}/${path}`)) {
            validations.set(`${dataObject.uuid}/${path}`, []);
          }
          if (dataObject.lineage?.attributeValidations) {
            validations.get(`${dataObject.uuid}/${path}`)?.push(...dataObject.lineage.attributeValidations[path]);
          }
        });
      }
    });

    return validations;
  });

  function createValidationException(formulaService: any, validation: TaxonValidation, dataObject: DataObject, attribute?: DataAttribute) {
    const message = formulaService.evaluateFormula(validation.messageFormula, tagMetadataMap.value.get(dataObject.path), dataObject, Array.from(dataObjects.value.values())).result;
    const exceptionDetails = formulaService.evaluateFormula(validation.detailFormula, tagMetadataMap.value.get(dataObject.path), dataObject, Array.from(dataObjects.value.values())).result;
    return { message, exceptionDetails, dataAttribute: attribute, dataObject, exceptionType: validation.exceptionId, path: attribute ? attribute.path : dataObject.path, open: true, uuid: crypto.randomUUID() } as DataException;
  }

  function exceptionExists(dataObject: DataObject, newException: DataException): boolean {
    if (newException.dataAttribute) {
      return newException.dataAttribute.dataExceptions?.some(ex =>
        ex.exceptionType === newException.exceptionType
        && ex.dataAttribute?.path === newException.dataAttribute?.path,
      ) || false;
    } else {
      return dataObject.dataExceptions?.some(ex =>
        ex.exceptionType === newException.exceptionType
        && !ex.dataAttribute,
      ) || false;
    }
  }

  interface ValidationResult {
    exceptionsToAdd: DataException[];
    exceptionsToRemove: DataException[];
  }

  function processValidation(formulaService: any, validation: TaxonValidation, dataObject: DataObject, attribute?: DataAttribute): ValidationResult {
    log.info(`Processing validation for ${dataObject.path} ${dataObject.uuid}`);
    const result = formulaService.evaluateFormula(validation.ruleFormula, tagMetadataMap.value.get(dataObject.path), dataObject, Array.from(dataObjects.value.values()));
    const exceptionsToAdd: DataException[] = [];
    const exceptionsToRemove: DataException[] = [];

    if (result.result === false || (Array.isArray(result.result) && result.result.length === 0)) {
      const path = attribute ? `${dataObject.uuid}/${attribute.path}` : dataObject.uuid;
      log.info(`Validation failed for ${path}`);

      const newDataException = createValidationException(formulaService, validation, dataObject, attribute);
      if (!exceptionExists(dataObject, newDataException)) {
        exceptionsToAdd.push(newDataException);
      }
    } else if (dataObject.dataExceptions) {
      // Find and remove matching exceptions when validation passes
      const matchingExceptions = dataObject.dataExceptions.filter(ex =>
        ex.exceptionType === validation.exceptionId,
      );
      exceptionsToRemove.push(...matchingExceptions);

      // We need to go through the dataAttributes and remove any that match the exception type
      dataObject.attributes?.forEach((attr) => {
        const matchingAttributeExceptions = attr.dataExceptions?.filter(ex =>
          ex.exceptionType === validation.exceptionId,
        );
        exceptionsToRemove.push(...matchingAttributeExceptions || []);
      });
    }
    return { exceptionsToAdd, exceptionsToRemove };
  }

  function runDataObjectValidation(dataObject: DataObject, validations: TaxonValidation[]): { updatedObject: DataObject; exceptionsToAdd: DataException[]; exceptionsToRemove: DataException[] } {
    log.info(`Running validations for ${dataObject.uuid}`);
    const formulaService = taxonomyFormulaServices.get(dataObject.taxonomyRef as string);
    const allExceptionsToAdd: DataException[] = [];
    const allExceptionsToRemove: DataException[] = [];

    validations.forEach((validation) => {
      const { exceptionsToAdd, exceptionsToRemove } = processValidation(formulaService, validation, dataObject);
      allExceptionsToAdd.push(...exceptionsToAdd);
      allExceptionsToRemove.push(...exceptionsToRemove);
    });

    const updatedObject = { ...dataObject };
    const currentExceptions = dataObject.dataExceptions || [];
    updatedObject.dataExceptions = currentExceptions
      .filter(ex => !allExceptionsToRemove.some(removeEx =>
        removeEx.exceptionType === ex.exceptionType
        && removeEx.dataAttribute?.path === ex.dataAttribute?.path,
      ))
      .concat(allExceptionsToAdd);

    return { updatedObject, exceptionsToAdd: allExceptionsToAdd, exceptionsToRemove: allExceptionsToRemove };
  }

  function runDataAttributeValidation(dataObject: DataObject, attribute: DataAttribute, validations: TaxonValidation[]): { updatedObject: DataObject; exceptionsToAdd: DataException[]; exceptionsToRemove: DataException[] } {
    log.info(`Running validations for ${dataObject.uuid}/${attribute.path}`);
    const formulaService = taxonomyFormulaServices.get(dataObject.taxonomyRef as string);
    const allExceptionsToAdd: DataException[] = [];
    const allExceptionsToRemove: DataException[] = [];

    validations.forEach((validation) => {
      const { exceptionsToAdd, exceptionsToRemove } = processValidation(formulaService, validation, dataObject, attribute);
      allExceptionsToAdd.push(...exceptionsToAdd);
      allExceptionsToRemove.push(...exceptionsToRemove);
    });

    const updatedObject = { ...dataObject };
    const currentExceptions = dataObject.dataExceptions || [];
    updatedObject.dataExceptions = currentExceptions
      .filter(ex => !allExceptionsToRemove.some(removeEx =>
        removeEx.exceptionType === ex.exceptionType
        && removeEx.dataAttribute?.path === ex.dataAttribute?.path,
      ))
      .concat(allExceptionsToAdd);

    return { updatedObject, exceptionsToAdd: allExceptionsToAdd, exceptionsToRemove: allExceptionsToRemove };
  }

  // If any of the data objects change we need to recompute the validations
  watch(dataObjects, (newDataObjects) => {
    if (isUpdatingExceptions) {
      log.debug("Skipping validation during exception update");
      return;
    }

    try {
      isUpdatingExceptions = true;
      log.info("Data objects changed, recomputing validations");

      if (!newDataObjects || newDataObjects.size === 0) {
        log.info("No data objects, returning");
        return;
      }

      // Collect all updates first
      const updates = Array.from(newDataObjects.values()).map((dataObject: DataObject) => {
        let updatedObject = { ...dataObject };
        const allExceptionsToAdd: DataException[] = [];
        const allExceptionsToRemove: DataException[] = [];

        // Process data object level validations
        if (validationsByDataObject.value.has(dataObject.uuid as string)) {
          const result = runDataObjectValidation(
            updatedObject,
            validationsByDataObject.value.get(dataObject.uuid as string) as TaxonValidation[],
          );
          updatedObject = result.updatedObject;
          allExceptionsToAdd.push(...result.exceptionsToAdd);
          allExceptionsToRemove.push(...result.exceptionsToRemove);
        }

        if (validationsByTaxonPath.value.has(dataObject.path as string)) {
          const result = runDataObjectValidation(
            updatedObject,
            validationsByTaxonPath.value.get(dataObject.path as string) as TaxonValidation[],
          );
          updatedObject = result.updatedObject;
          allExceptionsToAdd.push(...result.exceptionsToAdd);
          allExceptionsToRemove.push(...result.exceptionsToRemove);
        }

        // Process attribute level validations
        if (dataObject.attributes) {
          dataObject.attributes.forEach((attribute: DataAttribute) => {
            const attributePath = `${dataObject.uuid}/${attribute.path}`;

            if (validationsByDataObject.value.has(attributePath)) {
              const result = runDataAttributeValidation(
                updatedObject,
                attribute,
                validationsByDataObject.value.get(attributePath) as TaxonValidation[],
              );
              updatedObject = result.updatedObject;
              allExceptionsToAdd.push(...result.exceptionsToAdd);
              allExceptionsToRemove.push(...result.exceptionsToRemove);
            }

            if (validationsByTaxonPath.value.has(attribute.path as string)) {
              const result = runDataAttributeValidation(
                updatedObject,
                attribute,
                validationsByTaxonPath.value.get(attribute.path as string) as TaxonValidation[],
              );
              updatedObject = result.updatedObject;
              allExceptionsToAdd.push(...result.exceptionsToAdd);
              allExceptionsToRemove.push(...result.exceptionsToRemove);
            }
          });
        }

        return {
          uuid: dataObject.uuid,
          updatedObject,
          exceptionsToAdd: allExceptionsToAdd,
          exceptionsToRemove: allExceptionsToRemove,
          requiresUpdate: allExceptionsToAdd.length > 0 || allExceptionsToRemove.length > 0,
        };
      });

      // Batch update all changes at once
      nextTick(() => {
        updates.forEach(({ updatedObject, requiresUpdate, exceptionsToRemove }) => {
          if (requiresUpdate) {
            appStore.workspaceStore.updateDataExceptions(updatedObject, updatedObject.dataExceptions || [], exceptionsToRemove || []);
          }
        });
      });
    } finally {
      isUpdatingExceptions = false;
    }
  }, { immediate: true, deep: true });

  return {
  };
});
