<script setup lang="ts">
import { Toolbar } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { createDataFormEditorStore } from "~/store/useDataFormEditorStore";
import { availableCards } from "../cards/cards";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
  dataForm: {
    type: Object,
    required: true,
  },
});

const store = createDataFormEditorStore(props.viewId)();
const selectedCardType = ref("cardPanel");

const cardTypes = computed(() =>
  availableCards.map(card => ({
    name: card.type,
    label: card.label,
  })),
);

function handleAddPanel() {
  store.addPanel(undefined, selectedPanel.value?.id || null, selectedCardType.value);
}

const { selectedPanel } = storeToRefs(store);

const supportsChildren = computed(() => {
  if (!selectedPanel.value) {
    return true;
  }

  const cardComponent = availableCards.find(c => c.type === selectedPanel.value.type);

  return cardComponent?.supportsChildren || false;
});
</script>

<template>
  <Toolbar class="-mt-1 flex border-0 bg-white dark:bg-gray-700">
    <DropDownList
      v-model="selectedCardType"
      :data-items="cardTypes"
      :style="{ width: '200px' }"
      :value-primitive="true"
      data-item-key="name"
      text-field="label"
      value-field="name"
      :disabled="!supportsChildren"
    />
    <KodexaButton
      icon="plus"
      size="small"
      title="Add Panel"
      type="secondary"
      class="add-button"
      :disabled="!supportsChildren"
      @click="handleAddPanel"
    >
      Add Card
    </KodexaButton>
    <KodexaButton
      icon="view-grid-compact"
      size="small"
      title="Auto Layout"
      type="secondary"
      class="add-button"
      @click="store.autoLayout(dataForm.cards)"
    >
      Auto Layout
    </KodexaButton>
  </Toolbar>
</template>

<style scoped>
</style>
