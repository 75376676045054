import type { DefineComponent } from "vue";
import { camelCase, startCase } from "lodash";
import { log } from "~/utils/logger";

interface PanelModule {
  default: DefineComponent<any, any, any>;
}

const panelModules = import.meta.glob<PanelModule>("./panels/*.vue", { eager: true });

interface PanelDefinition {
  type: string;
  name: string;
  label: string;
  description: string;
  implementation: () => DefineComponent<any, any, any>;
  options: Record<string, unknown>;
}

const availablePanels = Object.entries(panelModules).map(([path, module]) => {
  const fileName = path?.split("/")?.pop()?.replace(".vue", "");

  if (!fileName) {
    return null;
  }

  // ignore the kodexa-panel since it will try and load this
  if (fileName === "kodexa-panel") {
    return null;
  }

  try {
    const baseFileName = fileName.replace("kodexa-panel-", "").replace(/-panel$/, "");
    const type = camelCase(baseFileName).replace(" ", "");
    const name = startCase(baseFileName);
    const component = module.default;
    const componentMetadata = component.componentMetadata || {};
    return {
      type,
      name,
      label: componentMetadata.label || name,
      description: componentMetadata.description || name,
      options: componentMetadata.options || [],
      implementation: () => component,
    } satisfies PanelDefinition;
  } catch (e) {
    log.info(`Unable to load panel ${fileName} due to error ${e}`);
    return null;
  }
}).filter((panel): panel is PanelDefinition => panel !== null);

export { availablePanels };
export type { PanelDefinition };
