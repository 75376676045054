<script setup lang="ts">
import type { Option } from "~/model";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { createDataFormEditorStore } from "~/store/useDataFormEditorStore";
import { availableCards } from "../cards/cards";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});

const store = createDataFormEditorStore(props.viewId)();
const { selectedPanel } = storeToRefs(store);

// Add layout information section
const layoutInfo = computed(() => {
  if (!selectedPanel.value) {
    return null;
  }

  return {
    row: selectedPanel.value.properties.row || 0,
    col: selectedPanel.value.properties.col || 0,
    colSpan: selectedPanel.value.properties.colSpan || 12,
    rowSpan: selectedPanel.value.properties.rowSpan || 1,
  };
});

const cardOptions = computed(() => {
  if (!selectedPanel.value) {
    return [] as Option[];
  }

  const cardType = selectedPanel.value.type;
  const cardComponent = availableCards.find(c => c.type === cardType);

  if (cardComponent?.options) {
    return cardComponent?.options as Option[] || [] as Option[];
  }

  return [] as Option[];
}) as Ref<Option[]>;

const cardProperties = computed({
  get: () => {
    const props = selectedPanel.value?.properties || {};
    return {
      ...props,
      isCheckbox: props.checkboxOptions?.isCheckbox || false,
      onCheckValue: props.checkboxOptions?.onCheckValue || "None",
      isMaskedText: props.maskedTextOptions?.isMaskedText || false,
      maskPattern: props.maskedTextOptions?.maskedText || "",
      maskDateFormat: props.maskedTextOptions?.maskDateFormat || "mm/dd/yy",
      sourceDateFormat: props.maskedTextOptions?.sourceDateFormat || "yyyy-mm-dd",
    };
  },
  set: (value: any) => {
    if (selectedPanel.value?.id) {
      const newProps = {
        ...value,
        checkboxOptions: {
          isCheckbox: value.isCheckbox || false,
          onCheckValue: value.onCheckValue || "None",
        },
        maskedTextOptions: {
          isMaskedText: value.isMaskedText || false,
          maskedText: value.maskPattern || "",
          maskDateFormat: value.maskDateFormat || "mm/dd/yy",
          sourceDateFormat: value.sourceDateFormat || "yyyy-mm-dd",
        },
      };

      const panel = selectedPanel.value;
      panel.properties = newProps;
      store.updatePanelProperties(panel.id, newProps);
    }
  },
});

const childrenInfo = computed(() => {
  if (!selectedPanel.value?.children?.length) {
    return null;
  }

  const rowHeight = 70; // Base row height
  const headerHeight = 35; // Panel header height
  const marginBetweenPanels = 10; // Margin between panels

  // Group children by row
  const rowGroups = selectedPanel.value.children.reduce((groups, child) => {
    const row = child.properties.row || 0;
    if (!groups[row]) {
      groups[row] = [];
    }
    groups[row].push(child);
    return groups;
  }, {} as Record<number, Panel[]>);

  // Calculate height per row
  const totalHeight = Object.values(rowGroups).reduce((sum, rowPanels) => {
    // Get the maximum rowSpan in this row
    const maxRowSpan = Math.max(...rowPanels.map(p => p.properties.rowSpan || 1));
    return sum + (maxRowSpan * rowHeight) + headerHeight + marginBetweenPanels;
  }, 0);

  return {
    count: selectedPanel.value.children.length,
    totalHeight,
  };
});
</script>

<template>
  <div class="mx-2">
    <!-- Add Layout Information Section -->
    <div v-if="layoutInfo" class="layout-info mb-4">
      <h3 class="text-sm font-semibold mb-2">
        Layout Information
      </h3>
      <div class="grid grid-cols-2 gap-2">
        <div class="layout-item">
          <span class="label">Row:</span>
          <span class="value">{{ layoutInfo.row }}</span>
        </div>
        <div class="layout-item">
          <span class="label">Column:</span>
          <span class="value">{{ layoutInfo.col }}</span>
        </div>
        <div class="layout-item">
          <span class="label">Width:</span>
          <span class="value">{{ layoutInfo.colSpan }}</span>
        </div>
        <div class="layout-item">
          <span class="label">Height:</span>
          <span class="value">{{ layoutInfo.rowSpan }}</span>
        </div>

        <template v-if="childrenInfo">
          <div class="layout-item col-span-2">
            <span class="label">Children Count:</span>
            <span class="value">{{ childrenInfo.count }}</span>
          </div>
          <div class="layout-item col-span-2">
            <span class="label">Total Children Height:</span>
            <span class="value">{{ childrenInfo.totalHeight }}px</span>
          </div>
        </template>
      </div>
    </div>

    <!-- Existing Card Options -->
    <div v-for="cardItem in cardOptions" :key="cardItem.name">
      <ConfigurationOption
        v-model="cardProperties"
        :item="cardItem"
      />
    </div>
  </div>
</template>

<style scoped>
.layout-info {
  background-color: #f5f5f5;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

.layout-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  background: white;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
}

.label {
  color: #666;
  font-size: 0.875rem;
}

.value {
  font-weight: 500;
  color: #333;
}
</style>
