<script lang="ts" setup>
import { storeToRefs } from "pinia";
import KodexaMarkdown from "~/components/kodexa-markdown.vue";
import appStore from "~/store";

const { platformConfiguration, currentSidebar } = storeToRefs(appStore.platformStore);

watch(currentSidebar, (newValue) => {
  if (newValue === undefined || newValue === "unset") {
    appStore.platformStore.setCurrentSidebar("home");
  }
});
</script>

<template>
  <div class="mx-4 mt-1" style="height: calc(100vh - 69px)">
    <div class="bg-white dark:bg-gray-800 px-8 py-20">
      <div class="grid grid-cols-2 gap-4">
        <KodexaMarkdown
          v-if="platformConfiguration"
          :content="platformConfiguration.welcomeMarkdown"
        />
        <KodexaMarkdown v-if="platformConfiguration" :content="platformConfiguration.newsMarkdown" />
      </div>
    </div>
  </div>
</template>
