/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  DocumentFamily,
  ExceptionResponse,
  GetDocumentFamilyExternalData200,
  GetDocumentFamilyExternalDataParams,
  ListDocumentFamiliesParams,
  PageDocumentFamily,
  ProcessingStep,
  ThrowableProblem,
  UpdateDocumentFamilyExternalData200,
  UpdateDocumentFamilyExternalDataBody,
  UpdateDocumentFamilyExternalDataParams
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getDocumentFamily = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<DocumentFamily>(
      {url: `/api/documentFamilies/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDocumentFamilyQueryKey = (id: MaybeRef<string>,) => {
    return ['api','documentFamilies',id] as const;
    }

    
export const getGetDocumentFamilyQueryOptions = <TData = Awaited<ReturnType<typeof getDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDocumentFamilyQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentFamily>>> = ({ signal }) => getDocumentFamily(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamily>>, TError, TData> 
}

export type GetDocumentFamilyQueryResult = NonNullable<Awaited<ReturnType<typeof getDocumentFamily>>>
export type GetDocumentFamilyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetDocumentFamily<TData = Awaited<ReturnType<typeof getDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetDocumentFamilyQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateDocumentFamily = (
    id: MaybeRef<string>,
    documentFamily: MaybeRef<DocumentFamily>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
documentFamily = unref(documentFamily);
      
      return customAxios<DocumentFamily>(
      {url: `/api/documentFamilies/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: documentFamily
    },
      options);
    }
  


export const getUpdateDocumentFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDocumentFamily>>, TError,{id: string;data: DocumentFamily}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDocumentFamily>>, TError,{id: string;data: DocumentFamily}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDocumentFamily>>, {id: string;data: DocumentFamily}> = (props) => {
          const {id,data} = props ?? {};

          return  updateDocumentFamily(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof updateDocumentFamily>>>
    export type UpdateDocumentFamilyMutationBody = DocumentFamily
    export type UpdateDocumentFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateDocumentFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDocumentFamily>>, TError,{id: string;data: DocumentFamily}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateDocumentFamily>>,
        TError,
        {id: string;data: DocumentFamily},
        TContext
      > => {

      const mutationOptions = getUpdateDocumentFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteDocumentFamily = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/documentFamilies/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDocumentFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDocumentFamily>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDocumentFamily>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDocumentFamily>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteDocumentFamily(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteDocumentFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDocumentFamily>>>
    
    export type DeleteDocumentFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteDocumentFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDocumentFamily>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteDocumentFamily>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteDocumentFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the external data for a document family
 */
export const getDocumentFamilyExternalData = (
    id: MaybeRef<string>,
    params?: MaybeRef<GetDocumentFamilyExternalDataParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
params = unref(params);
      
      return customAxios<GetDocumentFamilyExternalData200>(
      {url: `/api/documentFamilies/${id}/externalData`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetDocumentFamilyExternalDataQueryKey = (id: MaybeRef<string>,
    params?: MaybeRef<GetDocumentFamilyExternalDataParams>,) => {
    return ['api','documentFamilies',id,'externalData', ...(params ? [params]: [])] as const;
    }

    
export const getGetDocumentFamilyExternalDataQueryOptions = <TData = Awaited<ReturnType<typeof getDocumentFamilyExternalData>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>,
    params?: MaybeRef<GetDocumentFamilyExternalDataParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamilyExternalData>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDocumentFamilyExternalDataQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentFamilyExternalData>>> = ({ signal }) => getDocumentFamilyExternalData(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamilyExternalData>>, TError, TData> 
}

export type GetDocumentFamilyExternalDataQueryResult = NonNullable<Awaited<ReturnType<typeof getDocumentFamilyExternalData>>>
export type GetDocumentFamilyExternalDataQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetDocumentFamilyExternalData<TData = Awaited<ReturnType<typeof getDocumentFamilyExternalData>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>,
    params?: MaybeRef<GetDocumentFamilyExternalDataParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamilyExternalData>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetDocumentFamilyExternalDataQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the external data for a document family
 */
export const updateDocumentFamilyExternalData = (
    id: MaybeRef<string>,
    updateDocumentFamilyExternalDataBody: MaybeRef<UpdateDocumentFamilyExternalDataBody>,
    params?: MaybeRef<UpdateDocumentFamilyExternalDataParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
updateDocumentFamilyExternalDataBody = unref(updateDocumentFamilyExternalDataBody);
params = unref(params);
      
      return customAxios<UpdateDocumentFamilyExternalData200>(
      {url: `/api/documentFamilies/${id}/externalData`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateDocumentFamilyExternalDataBody,
        params: unref(params)
    },
      options);
    }
  


export const getUpdateDocumentFamilyExternalDataMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDocumentFamilyExternalData>>, TError,{id: string;data: UpdateDocumentFamilyExternalDataBody;params?: UpdateDocumentFamilyExternalDataParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDocumentFamilyExternalData>>, TError,{id: string;data: UpdateDocumentFamilyExternalDataBody;params?: UpdateDocumentFamilyExternalDataParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDocumentFamilyExternalData>>, {id: string;data: UpdateDocumentFamilyExternalDataBody;params?: UpdateDocumentFamilyExternalDataParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  updateDocumentFamilyExternalData(id,data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentFamilyExternalDataMutationResult = NonNullable<Awaited<ReturnType<typeof updateDocumentFamilyExternalData>>>
    export type UpdateDocumentFamilyExternalDataMutationBody = UpdateDocumentFamilyExternalDataBody
    export type UpdateDocumentFamilyExternalDataMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateDocumentFamilyExternalData = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDocumentFamilyExternalData>>, TError,{id: string;data: UpdateDocumentFamilyExternalDataBody;params?: UpdateDocumentFamilyExternalDataParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateDocumentFamilyExternalData>>,
        TError,
        {id: string;data: UpdateDocumentFamilyExternalDataBody;params?: UpdateDocumentFamilyExternalDataParams},
        TContext
      > => {

      const mutationOptions = getUpdateDocumentFamilyExternalDataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listDocumentFamilies = (
    params?: MaybeRef<ListDocumentFamiliesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageDocumentFamily>(
      {url: `/api/documentFamilies`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListDocumentFamiliesQueryKey = (params?: MaybeRef<ListDocumentFamiliesParams>,) => {
    return ['api','documentFamilies', ...(params ? [params]: [])] as const;
    }

    
export const getListDocumentFamiliesQueryOptions = <TData = Awaited<ReturnType<typeof listDocumentFamilies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListDocumentFamiliesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDocumentFamilies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListDocumentFamiliesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDocumentFamilies>>> = ({ signal }) => listDocumentFamilies(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDocumentFamilies>>, TError, TData> 
}

export type ListDocumentFamiliesQueryResult = NonNullable<Awaited<ReturnType<typeof listDocumentFamilies>>>
export type ListDocumentFamiliesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListDocumentFamilies<TData = Awaited<ReturnType<typeof listDocumentFamilies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListDocumentFamiliesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDocumentFamilies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListDocumentFamiliesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createDocumentFamily = (
    documentFamily: MaybeRef<DocumentFamily>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      documentFamily = unref(documentFamily);
      
      return customAxios<DocumentFamily>(
      {url: `/api/documentFamilies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: documentFamily, signal
    },
      options);
    }
  


export const getCreateDocumentFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDocumentFamily>>, TError,{data: DocumentFamily}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createDocumentFamily>>, TError,{data: DocumentFamily}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDocumentFamily>>, {data: DocumentFamily}> = (props) => {
          const {data} = props ?? {};

          return  createDocumentFamily(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateDocumentFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof createDocumentFamily>>>
    export type CreateDocumentFamilyMutationBody = DocumentFamily
    export type CreateDocumentFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateDocumentFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDocumentFamily>>, TError,{data: DocumentFamily}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createDocumentFamily>>,
        TError,
        {data: DocumentFamily},
        TContext
      > => {

      const mutationOptions = getCreateDocumentFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Touching a document family triggers a DocumentFamilyEvent without changing the document family
 */
export const touchDocumentFamily = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/documentFamilies/${id}/touch`, method: 'GET', signal
    },
      options);
    }
  

export const getTouchDocumentFamilyQueryKey = (id: MaybeRef<string>,) => {
    return ['api','documentFamilies',id,'touch'] as const;
    }

    
export const getTouchDocumentFamilyQueryOptions = <TData = Awaited<ReturnType<typeof touchDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof touchDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getTouchDocumentFamilyQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof touchDocumentFamily>>> = ({ signal }) => touchDocumentFamily(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof touchDocumentFamily>>, TError, TData> 
}

export type TouchDocumentFamilyQueryResult = NonNullable<Awaited<ReturnType<typeof touchDocumentFamily>>>
export type TouchDocumentFamilyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useTouchDocumentFamily<TData = Awaited<ReturnType<typeof touchDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof touchDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getTouchDocumentFamilyQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Touching a document family triggers a DocumentFamilyEvent without changing the document family
 */
export const toggleGuidanceOnDocumentFamily = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/documentFamilies/${id}/toggleGuidance`, method: 'GET', signal
    },
      options);
    }
  

export const getToggleGuidanceOnDocumentFamilyQueryKey = (id: MaybeRef<string>,) => {
    return ['api','documentFamilies',id,'toggleGuidance'] as const;
    }

    
export const getToggleGuidanceOnDocumentFamilyQueryOptions = <TData = Awaited<ReturnType<typeof toggleGuidanceOnDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof toggleGuidanceOnDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getToggleGuidanceOnDocumentFamilyQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof toggleGuidanceOnDocumentFamily>>> = ({ signal }) => toggleGuidanceOnDocumentFamily(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof toggleGuidanceOnDocumentFamily>>, TError, TData> 
}

export type ToggleGuidanceOnDocumentFamilyQueryResult = NonNullable<Awaited<ReturnType<typeof toggleGuidanceOnDocumentFamily>>>
export type ToggleGuidanceOnDocumentFamilyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useToggleGuidanceOnDocumentFamily<TData = Awaited<ReturnType<typeof toggleGuidanceOnDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof toggleGuidanceOnDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getToggleGuidanceOnDocumentFamilyQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the steps from the final representation of the document in this document family
 */
export const getDocumentFamilySteps = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProcessingStep[]>(
      {url: `/api/documentFamilies/${id}/steps`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDocumentFamilyStepsQueryKey = (id: MaybeRef<string>,) => {
    return ['api','documentFamilies',id,'steps'] as const;
    }

    
export const getGetDocumentFamilyStepsQueryOptions = <TData = Awaited<ReturnType<typeof getDocumentFamilySteps>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamilySteps>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDocumentFamilyStepsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentFamilySteps>>> = ({ signal }) => getDocumentFamilySteps(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamilySteps>>, TError, TData> 
}

export type GetDocumentFamilyStepsQueryResult = NonNullable<Awaited<ReturnType<typeof getDocumentFamilySteps>>>
export type GetDocumentFamilyStepsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetDocumentFamilySteps<TData = Awaited<ReturnType<typeof getDocumentFamilySteps>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamilySteps>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetDocumentFamilyStepsQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForDocumentFamily = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/documentFamilies/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForDocumentFamilyQueryKey = (id: MaybeRef<string>,) => {
    return ['api','documentFamilies',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForDocumentFamilyQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForDocumentFamilyQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForDocumentFamily>>> = ({ signal }) => getChangeSequenceForDocumentFamily(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForDocumentFamily>>, TError, TData> 
}

export type GetChangeSequenceForDocumentFamilyQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForDocumentFamily>>>
export type GetChangeSequenceForDocumentFamilyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForDocumentFamily<TData = Awaited<ReturnType<typeof getChangeSequenceForDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForDocumentFamilyQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the external data for a document family
 */
export const getDocumentFamilyExternalDataKeys = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<string[]>(
      {url: `/api/documentFamilies/${id}/externalDataKeys`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDocumentFamilyExternalDataKeysQueryKey = (id: MaybeRef<string>,) => {
    return ['api','documentFamilies',id,'externalDataKeys'] as const;
    }

    
export const getGetDocumentFamilyExternalDataKeysQueryOptions = <TData = Awaited<ReturnType<typeof getDocumentFamilyExternalDataKeys>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamilyExternalDataKeys>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDocumentFamilyExternalDataKeysQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentFamilyExternalDataKeys>>> = ({ signal }) => getDocumentFamilyExternalDataKeys(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamilyExternalDataKeys>>, TError, TData> 
}

export type GetDocumentFamilyExternalDataKeysQueryResult = NonNullable<Awaited<ReturnType<typeof getDocumentFamilyExternalDataKeys>>>
export type GetDocumentFamilyExternalDataKeysQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetDocumentFamilyExternalDataKeys<TData = Awaited<ReturnType<typeof getDocumentFamilyExternalDataKeys>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentFamilyExternalDataKeys>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetDocumentFamilyExternalDataKeysQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



