<script setup lang="ts">
import type { PropType } from "vue";
import type { DashboardWidget } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { availableWidgets } from "~/components/dashboard/widgets/widgets";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["update:modelValue", "update-widget"]);
const loading = ref(false);
const refresher = ref(1);

function close() {
  unsavedWidget.value = undefined;
  emit("update:modelValue", false);
}

function getWidget(type) {
  return availableWidgets.find(widget => widget.type === type);
}

function getWidgetImplementation(type) {
  return availableWidgets.find(widget => widget.type === type)?.implementation;
}

function getWidgetOptions(type) {
  return availableWidgets.find(widget => widget.type === type)?.options;
}

const unsavedWidget = ref<DashboardWidget | undefined>(undefined);

const localWidget = computed({
  get() {
    if (!unsavedWidget.value) {
      unsavedWidget.value = JSON.parse(JSON.stringify(props.widget));
    }
    return unsavedWidget.value;
  },
  set(value) {
    unsavedWidget.value = value;
  },
});

function saveWidget() {
  emit("update-widget", unsavedWidget.value);
  emit("update:modelValue", false);
  unsavedWidget.value = undefined;
}

function refresh() {
  refresher.value = refresher.value + 1;
}

function updateWidget(widget) {
  unsavedWidget.value = widget;
}

function updateProperties(properties) {
  unsavedWidget.value.properties = properties;
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4  text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 80%; height: 600px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500"
                  @click="close"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="h-4 w-4" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle class="flex text-lg font-medium leading-4 text-gray-900">
                <div class="sm:flex sm:items-center sm:justify-between mt-4">
                  <div class="sm:flex sm:space-x-5">
                    <div class="shrink-0" />
                    <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p class="text-xl font-bold text-gray-900 sm:text-2xl">
                        {{ getWidget(localWidget.type)?.name }} Configuration
                      </p>
                      <p class="text-sm font-medium text-gray-400">
                        Set the properties for your widget
                      </p>
                    </div>
                  </div>
                </div>
              </DialogTitle>
              <div v-if="widget" class="grid grid-cols-2 divide-x divide-gray-200" style="height: 470px; overflow-x: scroll">
                <div class="mx-5">
                  <div
                    v-if="getWidgetOptions(localWidget.type)?.length > 0" class="mt-3"
                    style="width: 90%"
                  >
                    <ConfigurationOption
                      v-for="option in getWidgetOptions(localWidget.type)" :key="option.name"
                      v-model="localWidget.properties"
                      :item="option"
                    />
                    <KodexaButton icon="refresh" class="mt-3" size="small" @click="refresh">
                      Refresh
                    </KodexaButton>
                  </div>

                  <div v-else class="mt-3">
                    <p>No configuration options for this widget</p>
                  </div>
                </div>
                <div class="mx-5">
                  <Component :is="getWidgetImplementation(localWidget.type)" :widget="localWidget" :refresher="refresher" @update-widget="updateWidget" @update-properties="updateProperties" />
                </div>
              </div>

              <div>
                <Toolbar class="bg-white dark:bg-gray-800 border: 0">
                  <ToolbarSpacer />
                  <KodexaButton
                    icon="content-save"
                    @click="saveWidget"
                  >
                    Save
                  </KodexaButton>
                  <KodexaButton
                    class="mr-2"
                    icon="cancel" type="secondary"
                    @click="close"
                  >
                    Cancel
                  </KodexaButton>
                </Toolbar>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
