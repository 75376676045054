<script setup lang="ts">
import type { PropType } from "vue";
import type { DataObject, Taxon } from "~/model";
import { storeToRefs } from "pinia";
import appStore from "~/store";

defineProps({
  dataObject: {
    type: Object as PropType<DataObject>,
  },
  taxon: {
    type: Object as PropType<Taxon>,
  },
  viewId: {
    type: String,
    required: true,
  },
  descriptionTaxon: {
    type: Object as PropType<Taxon>,
    required: false,
  },
});

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const isOpen = ref(false);

function toggleOpen() {
  isOpen.value = !isOpen.value;
}

function totalAttributeValues(dataObject: DataObject | undefined, taxon: Taxon | undefined) {
  let total = 0;
  let found = false;
  if (dataObject && taxon) {
    dataObject.attributes?.forEach((attribute) => {
      if (attribute.path === taxon.path && attribute.decimalValue) {
        found = true;
        total += attribute.decimalValue;
      }
    });
  }

  if (taxon?.taxonType === "CURRENCY") {
    return found ? total.toLocaleString("en-US", { style: "currency", currency: "USD" }) : null;
  } else {
    return found ? total.toLocaleString("en-US") : null;
  }
}

function exceptionCount(dataObject: DataObject | undefined, taxon: Taxon | undefined) {
  let count = 0;
  if (dataObject && taxon) {
    dataObject.attributes?.forEach((attribute) => {
      if (attribute.path === taxon.path) {
        if (attribute.dataExceptions?.length && attribute.dataExceptions?.length > 0) {
          count += attribute.dataExceptions?.length;
        }
      }
    });
  }
  return count;
}

function attributeCount(dataObject: DataObject | undefined, taxon: Taxon | undefined) {
  let count = 0;
  if (dataObject && taxon) {
    dataObject.attributes?.forEach((attribute) => {
      if (attribute.path === taxon.path) {
        count++;
      }
    });
  }
  return count;
}
</script>

<template>
  <div class="dark:text-white">
    <div v-if="(taxon?.taxonType === 'NUMBER' || taxon?.taxonType === 'CURRENCY') && attributeCount(dataObject, taxon) > 1 && taxon.multiValue">
      <div style="float: right;" @click="toggleOpen">
        <MaterialDesignIcon :name="isOpen ? 'upArrow' : 'downArrow'" class="ml-3 mr-1 mt-1 dark:text-white" size="12" />
        <span
          v-if="exceptionCount(dataObject, taxon) > 0"
          class="mr-1 inline-flex items-center rounded-full bg-red-600 dark:bg-red-800 px-2.5 py-0.5 text-xs font-medium text-white"
        >{{ exceptionCount(dataObject, taxon) }}</span>
        <span
          v-if="attributeCount(dataObject, taxon) > 0"
          class="bg-theme-primary dark:bg-theme-primary-dark inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium text-white"
        >{{ attributeCount(dataObject, taxon) }}</span>
      </div>
      <div>
        <div style="height: 25px; width: 130px;" class="mb-3 flex justify-start ml-2 text-lg dark:text-white">
          <span style="text-align: right">{{ totalAttributeValues(dataObject, taxon) }}</span>
        </div>
        <KodexaDataAttributeEditor
          v-if="isOpen || attributeCount(dataObject, taxon) === 0"
          :tag-metadata="tagMetadataMap.get(taxon.path)"
          :data-object="dataObject"
          :view-id="viewId"
        />
      </div>
    </div>
    <KodexaDataAttributeEditor
      v-else
      :tag-metadata="tagMetadataMap.get(taxon?.path)"
      :data-object="dataObject"
      :view-id="viewId"
    />
  </div>
</template>

<style scoped>
</style>
