export const componentMetadata = {
  label: "Data Store Grid",
  description: "Present data store information in a grid format",
  supportsChildren: false,
  defaultWidth: 12,
  defaultHeight: 10,
  options: [
    {
      label: "Data Store Reference",
      name: "dataStoreRef",
      type: "string",
      required: true,
    },
  ],
};
