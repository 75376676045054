<script setup lang="ts">
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { useListProjects } from "~/api/projects/projects";
import appStore from "~/store";

const { user } = storeToRefs(appStore.userStore);
const { currentOrganization } = storeToRefs(appStore.organizationStore);

// Setup our grid helpers
const gridHelper = createGridHelper("recent-projects", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "",
});
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
} = useListProjects(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
    gridHelper.saveState();
  }
});

watch(currentOrganization, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`userRecents.project.organization.id:'${newValue.id}' and userRecents.user.id: '${user.value.id}'`);
    gridHelper.pageChangeHandler({ page: 1 });
  } else {
    gridHelper.setBaseFilter(`userRecents.user.id: '${user.value.id}'`);
    gridHelper.pageChangeHandler({ page: 1 });
  }
}, {
  immediate: true,
});

function setQuery(query: string) {
  gridHelper.setQuery(query);
  gridHelper.pageChangeHandler({ page: 1 });
}

const columns = [
  {
    title: "Name",
    field: "name",
    cell: "name",
    width: "500px",
  },
  {
    title: "Organization",
    field: "organization.name",
  },
  {
    title: "Status",
    field: "status.status",
    cell: "status",
  },
  {
    title: "Owner",
    field: "owner.firstName",
    cell: "owner",
  },
];
</script>

<template>
  <div>
    <KodexaHeader title="Recent Projects" object-type="project">
      <template #subTitle>
        Your recently accessed projects
      </template>
    </KodexaHeader>
    <div class="mx-4 mt-2">
      <Toolbar class="mb-1">
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50" @click="gridHelper.toggleFilter()" />
        <label for="query" class="sr-only">Query</label>
        <input
          id="query" v-debounce:300ms="setQuery" type="text" name="query" :value="projectQuery.query"
          class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Query..."
        >
      </Toolbar>
      <Grid
        style="height: calc(100vh - 240px);"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error" :is-loading="isLoading" :is-error="isError"
            no-records-message="No projects found"
          />
        </GridNoRecords>
        <template #name="{ props }">
          <td>
            <KodexaProjectCard :project="props.dataItem" />
          </td>
        </template>
        <template #status="{ props }">
          <td>
            <span
              v-if="props.dataItem.status"
              class="inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium text-gray-800"
              :style="{ 'background-color': props.dataItem.status.color }"
            >{{ props.dataItem.status.status }}</span>
          </td>
        </template>
        <template #owner="{ props }">
          <td>
            <div v-if="props.dataItem.owner" class="flex items-center">
              <div>
                <KodexaAvatar :object-instance="props.dataItem.owner" object-type="user" size="w-8" />
              </div>
              <div class="ml-3">
                <p class="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                  {{
                    props.dataItem.owner.firstName
                  }} {{ props.dataItem.owner.lastName }}
                </p>
              </div>
            </div>
          </td>
        </template>
      </Grid>
    </div>
  </div>
</template>
