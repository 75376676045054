<script setup lang="ts">
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import type { Ref } from "vue";
import type { ExecutionLogEntry } from "~/model";
import { Toolbar } from "@progress/kendo-vue-buttons";
import { parse } from "yaml";
import { updateDocumentFamilyExternalData } from "~/api/document-families/document-families";
import { getExecutionLogs } from "~/api/executions/executions";
import appStore from "~/store";
import { copyToClipboard } from "~/utils/general";
import { log } from "~/utils/logger";

const props = defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
});
const viewId = computed(() => {
  return props.params.params.viewId;
});
const logs: Ref<string | undefined> = ref(undefined);

const textOverride = ref("");
const view = computed(() => appStore.workspaceStore.getViewById(viewId.value));

const text = computed({
  get: () => {
    if (textOverride.value !== "") {
      return textOverride.value;
    }

    if (logs.value !== undefined) {
      return logs.value;
    } else if (view.value?.executionId === undefined) {
      return view.value?.text;
    } else {
      loadLogs();
    }

    return view.value?.text;
  },
  set: (value: string) => {
    textOverride.value = value;
  },
});

const currentPage = ref(1);

async function loadLogs() {
  const view = appStore.workspaceStore.getViewById(viewId.value);
  if (view) {
    const response = await getExecutionLogs(view.executionId as string, {
      page: currentPage.value,
      pageSize: 100,
      sort: "logSequence:asc",
    });
    if (logs.value === undefined) {
      logs.value = "";
    }
    if (response.content && response.content.length > 0) {
      logs.value = `${logs.value}\n${response.content
        ? response.content.map((log: ExecutionLogEntry) => {
            return `${log.logDate}: ${log.entry}`;
          }).join("\n")
        : ""}`;
    }
    currentPage.value++;
  }

  setTimeout(() => {
    loadLogs();
  }, 5);
}

function copyIt() {
  copyToClipboard(text.value, "Copied to clipboard");
}

function download() {
  const blob = new Blob([text.value], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = view.value.title;
  a.click();
  URL.revokeObjectURL(url);
}

const externalKey = ref({ id: "default" });
const isExternal = computed(() => view.value?.resourceRef?.startsWith("external:") || false);
const documentFamilyId = computed(() => view.value?.resourceRef?.split(":")[1]);

const availableKeys = ref([]);

watch(documentFamilyId, async () => {
  if (documentFamilyId.value === undefined) {
    return;
  }
  const keys = await appStore.workspaceStore.getExternalDataKeys(documentFamilyId.value);
  availableKeys.value = keys.map((key: string) => {
    return {
      id: key,
      text: key,
      icon: "undefined",
    };
  });
}, { immediate: true });

watch(externalKey, async () => {
  if (externalKey.value === undefined) {
    externalKey.value = { id: "default" };
    return;
  }

  if (documentFamilyId.value === undefined) {
    return;
  }

  const data = await appStore.workspaceStore.getExternalData(documentFamilyId.value, externalKey.value.id);
  log.info(`Updating resource from external data with key ${externalKey.value.id}`);
  textOverride.value = JSON.stringify(data, null, 2);
});

async function updateResource() {
  try {
    if (view.value?.language === "yaml") {
      const resource = parse(textOverride.value);
      log.info("Updating resource from YAML");

      if (view.value?.resourceRef?.startsWith("external:")) {
        await updateDocumentFamilyExternalData(documentFamilyId.value, resource);
      } else {
        appStore.projectStore.updateDataFormByRef(view.value.resourceRef as string, resource);
      }
    } else {
      const resource = JSON.parse(textOverride.value);
      if (view.value?.resourceRef?.startsWith("external:")) {
        await updateDocumentFamilyExternalData(documentFamilyId.value, resource);
      } else {
        appStore.projectStore.updateDataFormByRef(view.value.resourceRef as string, resource);
      }
    }
  } catch (e) {
    console.error("Error parsing resource");
    alert("Error parsing resource");
  }
}
</script>

<template>
  <div v-if="view">
    <Toolbar class="mb-1 border-0 bg-white dark:bg-gray-800">
      <KodexaButton icon="content-copy" size="small" type="secondary" @click="copyIt">
        Copy to Clipboard
      </KodexaButton>
      <KodexaButton icon="download" size="small" type="secondary" @click="download">
        Download
      </KodexaButton>
      <KodexaButton v-if="view.resourceRef" icon="content-save" size="small" type="secondary" @click="updateResource">
        Update
      </KodexaButton>
      <KodexaDropDown
        v-if="isExternal"
        v-model="externalKey"
        style="width: 200px"
        name="externalDataKeys"
        :items="availableKeys"
        :is-object="true"
      />
    </Toolbar>
    <KodexaCodeEditor
      v-if="text" v-model="text" style="height: calc(100vh - 8rem); width: 100%" :readonly="true"
      :word-wrap="false"
      :language="view.language || 'python'"
    />
  </div>
</template>
