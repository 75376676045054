<script lang="ts" setup>
import type { Workspace } from "~/model";
import { Button } from "@progress/kendo-vue-buttons";
import { storeToRefs } from "pinia";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";

const props = defineProps<{
  workspace: Workspace;
}>();

const emit = defineEmits(["goto", "refetch"]);

const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);

const { project } = storeToRefs(appStore.projectStore);

async function deleteWorkspace() {
  const {
    data,
    isCanceled,
  } = await deleteConfirmDialog.reveal({
    title: "Delete workspace?",
    message: "Are you sure you want to delete this workspace?",
  });
  if (!isCanceled) {
    await appStore.workspaceStore.deleteWorkspaceById(props.workspace.id);
    emit("refetch");
  }
}

const isEditing = ref(false);
const currentName = ref(props.workspace.name);

function undoName() {
  isEditing.value = false;
  currentName.value = props.workspace.name;
}

async function blurName() {
  if (isEditing.value) {
    isEditing.value = false;
    await appStore.projectStore.updateWorkspaceName(props.workspace.id, currentName.value);
    emit("refetch");
  }
}
</script>

<template>
  <div v-if="store" class="overflow-hidden rounded-lg bg-white dark:bg-gray-800 shadow">
    <div class="p-5">
      <div class="flex h-20 items-center">
        <div class="shrink-0">
          <MaterialDesignIcon class="text-blue-400" name="monitor" size="38" />
        </div>
        <div class="ml-5 w-0 flex-1">
          <dl>
            <dt class="truncate text-sm font-medium text-gray-500">
              Workspace
            </dt>
            <dd>
              <div v-if="!isEditing" class="text-lg font-medium text-gray-900" @dblclick="isEditing = true">
                {{ currentName }}
              </div>
              <KodexaTextInput
                v-if="isEditing" v-model="currentName" :size="props.size" fill-mode="outline" style="width: 100%"
                @blur="blurName"
                @keydown.enter="blurName" @keydown.esc="undoName"
              />
            </dd>
            <dd v-if="workspace.description">
              {{ workspace.description }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <div class="bg-gray-50 dark:bg-gray-900 px-5 py-3">
      <div class="flex justify-end gap-2">
        <Button class="text-theme-primary" fill-mode="flat" size="small" @click="emit('goto')">
          Open
        </Button>
        <Button class="text-red-500" fill-mode="flat" size="small" @click="deleteWorkspace">
          <MaterialDesignIcon name="delete" size="16" style="float:left; margin-right: 5px" />
          Delete
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
