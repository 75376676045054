<script setup lang="ts">
import type { PropType, Ref } from "vue";
import type { Dashboard, DashboardWidget } from "~/model";
import { GridItem, GridLayout } from "vue3-grid-layout-next";
import KodexaDashboardWidgetPropertiesPopup from "~/components/dashboard/kodexa-dashboard-widget-properties-popup.vue";
import { availableWidgets } from "~/components/dashboard/widgets/widgets";
import appStore from "~/store";

const props = defineProps({
  modelValue: Object as PropType<Dashboard>,
  setupMode: Boolean,
  entryPoints: Array,
  allowDelete: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue", "delete-dashboard", "save-dashboard"]);

const localDashboard = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const layout = ref([]);

const singleWidget = computed({
  get() {
    if (localDashboard.value?.singleWidget && localDashboard.value?.singleWidget.properties === undefined) {
      localDashboard.value.singleWidget.properties = {};
    }
    return localDashboard.value?.singleWidget;
  },
  set(value) {
    localDashboard.value.singleWidget = value;
  },
});

const gridItems = computed(() => {
  return localDashboard.value?.widgets?.map((widget) => {
    if (widget.properties) {
      const item = JSON.parse(JSON.stringify(widget));
      const defaultPosition = item.properties.defaultPosition ?? { x: 0, y: 0, w: 1, h: 1 };

      // Convert TileLayout positions to GridLayout format
      const gridPosition = {
        i: widget.id,
        x: defaultPosition?.col || 0,
        y: defaultPosition?.row || 0,
        w: defaultPosition?.colSpan || 1,
        h: defaultPosition?.rowSpan || 1,
        isDraggable: props.setupMode,
        isResizable: props.setupMode,
      };

      layout.value.push(gridPosition);
      return {
        ...item,
        ...gridPosition,
      };
    }
    return {};
  });
});

function getWidgetImplementation(type) {
  return availableWidgets.find(widget => widget?.type === type)?.implementation();
}

function getWidgetOptions(type) {
  return availableWidgets.find(widget => widget?.type === type)?.options;
}

function deleteWidget(widget) {
  if (props.entryPoints?.includes("organization")) {
    appStore.organizationStore.deleteWidgetFromDashboard(localDashboard.value, widget);
  } else {
    appStore.projectStore.deleteWidgetFromDashboard(localDashboard.value, widget);
  }
}

function handleLayoutUpdate(newLayout) {
  layout.value = newLayout;

  newLayout.forEach((item) => {
    const widget = localDashboard.value?.widgets?.find(w => w.id === item.i);
    if (widget) {
      if (!widget.properties) {
        widget.properties = {};
      }
      widget.properties.defaultPosition = {
        col: item.x,
        row: item.y,
        colSpan: item.w,
        rowSpan: item.h,
      };

      if (props.entryPoints?.includes("organization")) {
        appStore.organizationStore.updateWidgetInDashboard(localDashboard.value, widget);
      } else {
        appStore.projectStore.updateWidgetInDashboard(localDashboard.value, widget);
      }
    }
  });
}

function saveAndUpdateWidgetDashboard() {
  emit("save-dashboard", localDashboard.value);
}

const isSingle = computed({
  get() {
    return localDashboard.value.singleWidget !== undefined;
  },
  set(value) {
    if (value && singleWidget.value === undefined) {
      singleWidget.value = {
        id: "1",
        type: undefined,
      };
    } else {
      singleWidget.value = undefined;
    }
  },
});

const propertiesOpen = ref(false);

watch(() => props.setupMode, () => {
  if (!props.setupMode) {
    propertiesOpen.value = false;
  }
});

const dashboardWidgets = computed(() => {
  return availableWidgets.filter((widget) => {
    return widget?.dashboard;
  });
});

const showWidgetProperties = ref(false);
const selectedWidget: Ref<DashboardWidget | undefined> = ref(undefined);

function editWidget(widget: DashboardWidget) {
  showWidgetProperties.value = true;
  selectedWidget.value = widget;
}

const refresher = ref(1);

function updateWidget(widget: DashboardWidget) {
  showWidgetProperties.value = false;
  selectedWidget.value = undefined;
  if (props.entryPoints?.includes("organization")) {
    appStore.organizationStore.updateWidgetInDashboard(localDashboard.value, widget);
  } else {
    appStore.projectStore.updateWidgetInDashboard(localDashboard.value, widget);
  }
  refresher.value = refresher.value + 1;
}
</script>

<template>
  <div v-if="propertiesOpen">
    <KodexaDashboardToolbar
      v-if="setupMode" v-model="propertiesOpen" :is-dashboard="true" :widgets="availableWidgets"
      :allow-delete="allowDelete"
      :entry-points="entryPoints"
      :dashboard="localDashboard" @delete="emit('delete-dashboard')" @save="saveAndUpdateWidgetDashboard"
    />

    <div class="mx-4 mt-2">
      <KodexaTextInput
        v-model="localDashboard.name" label="Name" name="Name"
        placeholder="Enter a name for your dashboard"
      />

      <div v-if="isSingle && singleWidget.type" class="mt-1" style="width: 70%">
        <KodexaDropDown
          v-model="singleWidget.type"
          class="mt-1"
          name="Widget"
          label="Widget Type"
          text-field="name"
          value-field="type"
          :items="dashboardWidgets"
        />
      </div>

      <div v-if="isSingle && getWidgetOptions(singleWidget.type)?.length > 0" class="mt-3" style="width: 70%">
        <ConfigurationOption
          v-for="option in getWidgetOptions(singleWidget.type)" :key="option.name"
          v-model="singleWidget.properties"
          :item="option"
        />
      </div>
      <div v-if="!singleWidget">
        <GridLayout
          v-if="gridItems && !singleWidget"
          v-model:layout="layout"
          :col-num="10"
          :row-height="70"
          :is-draggable="setupMode"
          :is-resizable="setupMode"
          :margin="[10, 10]"
          :use-css-transforms="true"
          @layout-update="handleLayoutUpdate"
        >
          <GridItem
            v-for="item in gridItems"
            :key="item.id"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.id"
          >
            <div class="grid-item-content">
              <div class="grid-item-header">
                {{ item.title ?? getWidgetImplementation(item.type)?.name }}
                <div v-if="setupMode" style="float: right" class="-mt-2">
                  <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" @click="deleteWidget(item)" />
                </div>
              </div>
              <div class="grid-item-body">
                <Component :is="getWidgetImplementation(item.type)" :widget="item" />
              </div>
            </div>
          </GridItem>
        </GridLayout>
      </div>
    </div>
  </div>
  <div v-else :class="singleWidget ? ['bg-white dark:bg-gray-800'] : ['bg-gray-50 dark:bg-gray-900'] " style="overflow: auto;">
    <KodexaDashboardToolbar
      v-if="setupMode"
      v-model="propertiesOpen" :entry-points="entryPoints" :dashboard="localDashboard" :is-dashboard="true"
      :allow-delete="allowDelete" @delete="emit('delete-dashboard')" @save="saveAndUpdateWidgetDashboard"
    />
    <div v-if="singleWidget">
      <Component :is="getWidgetImplementation(singleWidget.type)" :widget="singleWidget" />
    </div>
    <GridLayout
      v-if="gridItems && !singleWidget"
      v-model:layout="layout"
      :col-num="10"
      :row-height="70"
      :is-draggable="setupMode"
      :is-resizable="setupMode"
      :margin="[10, 10]"
      :use-css-transforms="true"
      @layout-update="handleLayoutUpdate"
    >
      <GridItem
        v-for="item in gridItems"
        :key="item.id"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.id"
      >
        <div class="grid-item-content">
          <div v-if="setupMode" class="grid-item-header">
            {{ item.title ?? item.name }}
            <div style="float: right" class="-mt-2">
              <MaterialDesignIcon
                id="deleteDashboardWidget" name="close" class="size-6" aria-hidden="true"
                @click="deleteWidget(item)"
              />
              <MaterialDesignIcon
                id="editDashboardWidget" name="properties" class="size-6" aria-hidden="true"
                @click="editWidget(item)"
              />
            </div>
          </div>
          <div class="grid-item-body animate-fadeIn">
            <Component :is="getWidgetImplementation(item.type)" :key="item.id" :widget="item" :refresher="refresher" />
          </div>
        </div>
      </GridItem>
    </GridLayout>
    <div v-if="!singleWidget" class="mt-28" />
    <KodexaDashboardWidgetPropertiesPopup
      :key="selectedWidget?.id" v-model="showWidgetProperties" :widget="selectedWidget"
      @update-widget="updateWidget"
    />
  </div>
</template>

<style scoped>
.grid-item-content {
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.dark .grid-item-content {
  background-color: #1f2937;
  border-color: #374151;
}

.grid-item-header {
  padding: 0.1rem;
  background-color: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
}

.dark .grid-item-header {
  background-color: #374151;
  border-bottom-color: #4b5563;
  color: #e5e7eb;
}

.grid-item-body {
  flex: 1;
  overflow: auto;
}

.dark .grid-item-body {
  color: #e5e7eb;
}
</style>
