<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { FilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import type { PropType } from "vue";
import type { DataAttribute, DataObject, SelectionOption } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { filterBy } from "@progress/kendo-data-query";
import { AutoComplete } from "@progress/kendo-vue-dropdowns";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { ref } from "vue";
import KodexaNonEditableValue from "~/components/dataObject/types/kodexa-non-editable-value.vue";
import appStore from "~/store";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const { dataObjects } = storeToRefs(appStore.workspaceStore);

const stringValue = computed({
  get() {
    return props.attribute?.stringValue;
  },
  set(value) {
    emit("update", {
      ...props.attribute,
      stringValue: value,
      value,
    });
  },
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);
const valid = computed(() => {
  return props.attribute?.dataExceptions?.length === 0;
});

function selectionOptions() {
  const selectionOptions: SelectionOption[] | undefined = props.tagMetadata?.taxon.selectionOptions;
  if (!selectionOptions) {
    return [];
  }
  const optionList: string[] = [];
  for (const selectionOption of selectionOptions) {
    const evalValue = evalOptionValue(selectionOption);

    if (!evalValue) {
      continue;
    }
    if (selectionOption.label) {
      optionList.push(selectionOption.label as string);
    }
  }
  return optionList as any[];
}

function evalOptionValue(selectionOption: SelectionOption) {
  if (!selectionOption?.showIf) {
    return true;
  }

  const currentObject = props.dataObject;
  const parentObject = currentObject?.parent?.uuid ? dataObjects.value.get(currentObject.parent.uuid) : null;

  // prepare an object that maps attribute tags to their values, for both the current and parent objects
  const substitutions = {};
  if (currentObject && currentObject.attributes) {
    for (let i = 0; i < currentObject.attributes.length; i++) {
      substitutions[`"${currentObject.attributes[i].tag}"`] = currentObject.attributes[i].stringValue;
    }
  }

  if (parentObject && parentObject.attributes) {
    for (let i = 0; i < parentObject.attributes.length; i++) {
      substitutions[`"../${parentObject.attributes[i].tag}"`] = parentObject.attributes[i].stringValue;
    }
  }

  // replace all occurrences of attribute tags in expression with their actual values
  let expression = selectionOption.showIf;
  for (const key in substitutions) {
    expression = expression.split(key).join(`"${substitutions[key]}"`);
  }

  try {
    return eval(expression);
  } catch (e) {
    console.error("Error in evaluating condition: ", e);
    return false;
  }
}

const selectionOptionValues = computed(() => {
  return filteredData(stringValue.value);
});

function filteredData(value) {
  const data = selectionOptions();
  const filters = {
    value,
    operator: "startswith",
    ignoreCase: true,
  } as FilterDescriptor;

  return value ? filterBy(data, filters) : data;
}

function focus() {
  dataViewHelper.focus();
  emit("focus");
}

watchDebounced(() => [stringValue.value, selectionOptionValues.value], () => {
  const exceptionMessage = `Invalid value for ${props.tagMetadata?.taxon.label}`;

  if (!stringValue.value) {
    appStore.workspaceStore.removeException(props.attribute, exceptionMessage);
    return;
  }
  const validValue = selectionOptionValues.value.find((option) => {
    return option.toUpperCase() === stringValue.value?.toUpperCase();
  });

  if (validValue) {
    appStore.workspaceStore.removeException(props.attribute, exceptionMessage);
    return;
  }

  const uuid = uuidv4();
  const newException = {
    message: exceptionMessage,
    severity: "ERROR",
    open: true,
    uuid,
  };
  appStore.workspaceStore.mergeException(props.attribute, newException, props.dataObject);
}, {
  debounce: 700,
  maxWait: 500,
  deep: true,
  immediate: true,
});

const { style, conditionalValidationIcon } = storeToRefs(dataViewHelper);
</script>

<template>
  <div>
    <KodexaNonEditableValue
      v-if="!editable"
      :style="style" :conditional-validation-icon="conditionalValidationIcon"
      :string-value="attribute.stringValue"
    />
    <AutoComplete
      v-else
      :id="`kdx-attribute-${props.attribute.uuid}`"
      v-model="stringValue"
      :style="style"
      :data-items="selectionOptionValues"
      class="hover:text-black"
      :valid="valid"
      fill-mode="outline"
      style="background-color: white;"
      rounded="medium"
      @focus="focus"
      @blur="dataViewHelper.blur()"
    />
  </div>
</template>

<style scoped>
</style>
