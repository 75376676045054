<script setup lang="ts">
import type { PropType, Ref } from "vue";
import type { DashboardWidget, ListNotificationsParams, Notification } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useListNotifications } from "~/api/notifications/notifications";
import { NotificationActivityType, NotificationNotificationType } from "~/model";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
  },
});

const { project } = storeToRefs(appStore.projectStore);

const gridHelper = createGridHelper<ListNotificationsParams>("notification-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isError,
  isLoading,
  data: notificationsPage,
  refetch,
} = useListNotifications(gridQuery);

watch(notificationsPage, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

watch(project, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`project.id: '${project.value.id}'`);
  }
}, { immediate: true });

const columns = [
  {
    title: "Notification Name",
    field: "name",
    cell: "name",
  },
  {
    title: "Activity Type",
    field: "activityType",
  },
  {
    title: "Notification Type",
    field: "notificationType",
  },
];

const showCreateNotification = ref(false);
const selectedNotification: Ref<Notification | undefined> = ref(undefined);
const showSelectedNotification = ref(false);

function openNewNotification() {
  showCreateNotification.value = true;
}

const activityTypeDisplayMap = {
  [NotificationActivityType.NEW_UPLOAD]: "New Upload",
  [NotificationActivityType.NEW_DATA_AVAILABLE]: "New Data Available",
  [NotificationActivityType.ASSIGNMENT_ADDED]: "Assignment Added",
  [NotificationActivityType.ASSIGNMENT_REMOVED]: "Assignment Removed",
  [NotificationActivityType.CUSTOM_SUBSCRIPTION]: "Custom Subscription",
};

function editNotification(notification: Notification) {
  selectedNotification.value = {
    ...notification,
    activityType: Object.keys(activityTypeDisplayMap).find(key => activityTypeDisplayMap[key] === notification.activityType),
    notificationType: Object.keys(notificationTypeDisplayMap).find(key => notificationTypeDisplayMap[key] === notification.notificationType),
  };
  showSelectedNotification.value = true;
}

const notificationTypeDisplayMap = {
  [NotificationNotificationType.EMAIL_NOTIFICATION]: "Email Notification",
};

const notifications = computed(() => {
  return (notificationsPage.value?.content || []).map((notification) => {
    return {
      ...notification,
      activityType: activityTypeDisplayMap[notification.activityType],
      notificationType: notificationTypeDisplayMap[notification.notificationType],
    };
  });
});

function refetchNotifications() {
  refetch();
}

const gridStyle = computed(() => {
  if (props.widget?.properties?.style !== undefined) {
    return props.widget?.properties?.style;
  } else {
    const rowSpan = props.widget?.properties?.defaultPosition?.rowSpan || 6;
    const height = (rowSpan * 140) - 130;
    return {
      height: `${height}px`,
    };
  }
});
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <Toolbar class="border-0 bg-white dark:bg-gray-800">
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50" @click="gridHelper.toggleFilter()" />
        <KodexaArticle class="mt-2 ml-2" article-id="9787664" text="Learn more about notifications" />

        <ToolbarSpacer />
        <KodexaButton id="createNewNotification" icon="plus" @click="openNewNotification">
          New Notification
        </KodexaButton>
      </Toolbar>
      <Grid
        style="height: calc(100vh - 15rem);"
        :style="gridStyle"
        :data-items="notifications"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip"
        :total="pageSettings.total"
        :page-size="pageSettings.take"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords :is-loading="isLoading" :is-error="isError" />
        </GridNoRecords>
        <template #name="{ props }">
          <td>
            <div class="flex items-center">
              <div class="flex-0 cursor-pointer font-bold text-gray-900" @click="editNotification(props.dataItem)">
                {{ props.dataItem?.name }}
              </div>
            </div>
          </td>
        </template>
      </Grid>
      <ProjectNewNotification v-model="showCreateNotification" @refetch-notifications="refetch" />
      <ProjectEditNotification
        v-if="showSelectedNotification"
        v-model="showSelectedNotification"
        :notification="selectedNotification"
        @refetch-notifications="refetchNotifications"
      />
    </div>
  </div>
</template>
