<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType, Ref } from "vue";
import type { Card, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { TabStrip } from "@progress/kendo-vue-layout";
import { createDataFormViewerStore } from "~/store/useDataFormView";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["addCard", "selectCard", "removeCard"]);

const useDataFormViewer = createDataFormViewerStore(props.viewId);

const tabs = computed(() => {
  const children = props.card.children || [];
  return children.map((child: Card) => {
    return {
      id: child.id,
      title: child.properties?.title,
      icon: child.properties?.icon,
      content: "tabContent",
      titleRender: "titleRender",
    };
  });
});

const currentTab: Ref<number> = ref(0);

function onSelect(e: any) {
  currentTab.value = e.selected;
}

function getCard(id: string) {
  return useDataFormViewer.findCard(id);
}
</script>

<template>
  <div>
    <TabStrip :selected="currentTab" :tabs="tabs" class="-mt-1" @select="onSelect">
      <template #tabContent="{ props }">
        <div>
          <KodexaFormCard
            style="width: 100%"
            :card="getCard(props.dataItem.id)"
            :view-id="viewId"
            :parent-data-object="parentDataObject"
            :parent-tag-metadata="parentTagMetadata"
            @add-card="emit('addCard', $event)"
            @select-card="emit('selectCard', $event)"
            @remove-card="emit('removeCard', $event)"
          />
        </div>
      </template>
      <template #titleRender="{ props }">
        <span class="k-item k-state-default">
          <span class="text-sm p-0">{{ props.title }}</span>
        </span>
      </template>
    </TabStrip>
  </div>
</template>

<style scoped>
</style>
