<script setup lang="ts">
import { useRegisterSW } from "virtual:pwa-register/vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import { clearCache } from "~/utils/general";

const {
  needRefresh,
} = useRegisterSW();

const ignore = ref(false);

async function close() {
  ignore.value = true;
}

const { reveal, onConfirm, onCancel } = createConfirmDialog(KodexaConfirm);

watch(needRefresh, (newValue) => {
  if (newValue && ignore.value === false) {
    reveal({
      title: "New Release Available",
      message: "A new release is available. If you have unsaved changes, ensure you cancel the reload and save your work.  Click on reload button to update.",
      confirmText: "Reload",
      confirmIcon: "rocket-launch",
      cancelText: "Close",
      cancelIcon: "close",
      type: "primary",
      acceptClasses: ["bg-blue-600"],
      icon: "information",
      iconClasses: ["text-blue-600"],
    });

    onConfirm(() => {
      clearCache();
    });

    onCancel(() => {
      close();
    });
  }
}, { immediate: true });
</script>

<template>
</template>

<style scoped>

</style>
