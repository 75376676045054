import type { SelectedTag } from "~/components/document/document";
import type { DataAttribute, DataObject, DocumentFamily } from "~/model";
import type { DataFormViewer, DocumentViewer, TagInstance } from "~/store/useWorkspace";
import { defineStore, storeToRefs } from "pinia";
import appStore from "~/store/index";
import { createDocumentViewerStore } from "~/store/useDocumentView";
import { log } from "~/utils/logger";

export function createSidecar(workspaceId: string, createAsSidecar = true) {
  return defineStore(`sideCar-${workspaceId}-${createAsSidecar}`, () => {
    log.info(`Creating sidecar ${workspaceId} (${createAsSidecar ? "sidecar" : "main"})`);
    const {
      isSupported,
      channel,
      post,
      close,
      error,
      isClosed,
      data,
    } = useBroadcastChannel({ name: `kdxa-sidecar-${workspaceId}` });

    const {
      data: localData,
    } = useBroadcastChannel({ name: `kdxa-sidecar-${workspaceId}` });

    const sidecarView = ref<DocumentViewer | DataFormViewer | undefined>(undefined);
    const loading = ref(false);
    const isSidecar = ref(createAsSidecar);
    const title = ref("Kodexa - Sidecar");
    const currentPage = ref(0);
    const pageTags = ref([]);

    function register() {
      log.info("Calling register");
      post({ messageType: "register" });
    }

    function setSidecarView(view: DocumentViewer | DataFormViewer | undefined) {
      log.info("Calling setView");
      post({
        messageType: "setView",
        view: toRaw(view),
      });
    }

    function updateCurrentPage(newPage: number) {
      currentPage.value = newPage;

      const documentViewStore = createDocumentViewerStore(sidecarView.value?.id);
      if (documentViewStore) {
        pageTags.value = documentViewStore.pageTags;
      }

      if (!isSidecar.value) {
        post({
          messageType: "updateCurrentPage",
          page: newPage,
        });
      }
    }

    const mainProcessor = async function (messagePayload: any) {
      if (messagePayload.messageType === "register") {
        log.info("Received register message");
        const { project } = storeToRefs(appStore.projectStore);
        const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);
        post({
          messageType: "initialize",
          projectId: project.value.id,
          workspaceId: currentWorkspaceId.value,
        });
      }

      if (messagePayload.messageType === "tagAdded") {
        log.info("Received tag added message");
        appStore.workspaceStore.tagAdded(messagePayload.tag);
      }

      if (messagePayload.messageType === "tagRemoved") {
        log.info("Received tag removed message");
        appStore.workspaceStore.tagRemoved(messagePayload.tag);
      }

      if (messagePayload.messageType === "close") {
        log.info("Received close message");
        sidecarView.value = undefined;
      }

      if (messagePayload.messageType === "focusAttribute" || messagePayload.messageType === "focusTag") {
        if (messagePayload.documentFamilyId === undefined) {
          log.info(`Received focus message without documentFamilyId ${JSON.stringify(messagePayload)}`);
          return;
        }

        log.info(`Received focus attribute/tag message ${sidecarView.value?.id} ${messagePayload.documentFamilyId} ${messagePayload.tagUuid} ${messagePayload.attributeId}`);
        const newId = `${messagePayload.documentFamilyId}`;

        if (sidecarView.value?.id !== newId) {
          const newDocumentView = {
            id: newId,
            viewType: "document",
            documentFamilyId: messagePayload.documentFamilyId,
            ephemeral: true,
            currentPage: 0,
            focusTagUuid: messagePayload.tagUuid,
            isSidecar: true,
          } as DocumentViewer;
          sidecarView.value = undefined;
          appStore.workspaceStore.setFocusTagUuid(messagePayload.tagUuid);
          await appStore.workspaceStore.addView(newDocumentView);
          log.info(`Sidecar view added ${JSON.stringify(newDocumentView)}`);

          await nextTick(() => {
            log.info("Setting sidecar view");
            createDocumentViewerStore(newDocumentView.id)?.setSidecar(true);
            sidecarView.value = newDocumentView;
            appStore.workspaceStore.setActiveSelectionView(undefined);
          });
        } else {
          log.info("Setting focus tag, sidecar already open");
          await nextTick(() => {
            appStore.workspaceStore.setFocusTagUuid(messagePayload.tagUuid);
          });
        }
      }

      if (messagePayload.messageType === "updateCurrentPage") {
        currentPage.value = messagePayload.page;

        const documentViewStore = createDocumentViewerStore(sidecarView.value?.id);
        if (documentViewStore) {
          pageTags.value = documentViewStore.pageTags;
        }
      }
    };

    const sidecarProcessor = async function (messagePayload: any, local = false) {
      if (messagePayload.title) {
        title.value = messagePayload.title;
      }

      if (messagePayload.messageType === "initialize" && !local) {
        log.info("Received initialize message");
        appStore.projectStore.loadProject(messagePayload.projectId);
        appStore.workspaceStore.loadWorkspace(messagePayload.workspaceId);
        appStore.workspaceStore.setAsSidecar();
      }

      if (local && !appStore.workspaceStore.isSidecarOpen()) {
        // If the event is local (ie. in the same page) and the sidecar isnt open, we dont want to process it
        return;
      }

      if (messagePayload.messageType === "reload") {
        // We need to get the document view and reload it
        log.info("Received reload message");
        if (sidecarView.value) {
          if (sidecarView.value.viewType === "document") {
            const view = sidecarView.value as DocumentViewer;
            const documentViewStore = createDocumentViewerStore(view.id);
            documentViewStore?.reload();
            appStore.workspaceStore.setActiveSelectionView(undefined);
          }
        }
      }

      if (messagePayload.messageType === "setView") {
        loading.value = true;
        log.info("Received set view message");
        log.info(messagePayload.view);
        if (messagePayload.view === undefined) {
          sidecarView.value = undefined;
          void nextTick(() => {
            loading.value = false;
          });
        } else {
          if (!local) {
            log.info("Adding non-local view");
            messagePayload.view.id = `${messagePayload.view.id}`;
            messagePayload.view.ephermal = true;
            messagePayload.view.isSidecar = true;
            sidecarView.value = undefined;
            await appStore.workspaceStore.addView(messagePayload.view);
          } else {
            log.info("Getting local view");
            messagePayload.view = appStore.workspaceStore.getViewById(messagePayload.view.id);
          }

          await nextTick(() => {
            log.info("Setting sidecar view");
            sidecarView.value = messagePayload.view;
            void nextTick(() => {
              loading.value = false;
            });
          });
        }
      }

      if (messagePayload.messageType === "close") {
        log.info("Received close message");
        sidecarView.value = undefined;
      }
      if (messagePayload.messageType === "focusPage") {
        if (messagePayload.documentFamilyId === undefined) {
          log.info(`Received focus message without documentFamilyId ${JSON.stringify(messagePayload)}`);
          return;
        }

        log.info(`Received focus page message ${sidecarView.value?.id} ${messagePayload.documentFamilyId} ${messagePayload.pageNumber}`);

        const newId = `${messagePayload.documentFamilyId}`;
        if (sidecarView.value?.id !== newId) {
          // We need to determine if we have a view that we need to move
          const existingView = appStore.workspaceStore.getViewById(newId);
          if (existingView) {
            log.info(`Found existing view ${existingView.id}`);
            existingView.isSidecar = true;
            sidecarView.value = undefined;
            appStore.workspaceStore.addView(existingView);

            await nextTick(() => {
              log.info(`Setting sidecar view for existing view ${existingView.id}`);
              const useDocumentViewStore = createDocumentViewerStore(existingView.id);
              if (!useDocumentViewStore) {
                log.info("Could not find document view store");
                return;
              }
              useDocumentViewStore.setPage(messagePayload.pageNumber);
              appStore.workspaceStore.setActiveSelectionView(undefined);
              sidecarView.value = existingView;
              pageTags.value = useDocumentViewStore.pageTags;
            });
          } else {
            log.info("Creating new view");
            const newDocumentView = {
              id: newId,
              viewType: "document",
              documentFamilyId: messagePayload.documentFamilyId,
              ephemeral: true,
              currentPage: 0,
              focusTagUuid: messagePayload.tagUuid,
              isSidecar: true,
            } as DocumentViewer;
            sidecarView.value = undefined;
            await appStore.workspaceStore.addView(newDocumentView);
            log.info(`Sidecar view added ${JSON.stringify(newDocumentView)}`);

            await nextTick(() => {
              log.info("Setting sidecar view");
              sidecarView.value = newDocumentView;
              const useDocumentViewStore = createDocumentViewerStore(newDocumentView.id);

              if (!useDocumentViewStore) {
                return;
              }
              useDocumentViewStore.setPage(messagePayload.pageNumber);
              appStore.workspaceStore.setActiveSelectionView(undefined);
            });
          }
        } else {
          log.info("Setting focus tag, sidecar already open");
          await nextTick(() => {
            const useDocumentViewStore = createDocumentViewerStore(newId);
            useDocumentViewStore.setPage(messagePayload.pageNumber);
            appStore.workspaceStore.setActiveSelectionView(undefined);
          });
        }
      }

      if (messagePayload.messageType === "focusAttribute" || messagePayload.messageType === "focusTag") {
        if (messagePayload.documentFamilyId === undefined) {
          log.info(`Received focus message without documentFamilyId ${JSON.stringify(messagePayload)}`);
          return;
        }

        log.info(`Received focus attribute/tag message ${sidecarView.value?.id} ${messagePayload.documentFamilyId} ${messagePayload.tagUuid} ${messagePayload.attributeId}`);
        const newId = `${messagePayload.documentFamilyId}`;

        if (sidecarView.value?.id !== newId) {
          // We need to determine if we have a view that we need to move
          const existingView = appStore.workspaceStore.getViewById(newId);
          if (existingView) {
            existingView.isSidecar = true;
            appStore.workspaceStore.addView(existingView);

            await nextTick(() => {
              log.info("Setting sidecar view");

              sidecarView.value = existingView;
              const useDocumentViewStore = createDocumentViewerStore(existingView.id);
              useDocumentViewStore.setPage(messagePayload.pageNumber);
              appStore.workspaceStore.setActiveSelectionView(undefined);
            });
          } else {
            const newDocumentView = {
              id: newId,
              viewType: "document",
              documentFamilyId: messagePayload.documentFamilyId,
              ephemeral: true,
              currentPage: 0,
              focusTagUuid: messagePayload.tagUuid,
              isSidecar: true,
            } as DocumentViewer;
            sidecarView.value = undefined;
            appStore.workspaceStore.setFocusTagUuid(messagePayload.tagUuid);
            await appStore.workspaceStore.addView(newDocumentView);
            log.info(`Sidecar view added ${JSON.stringify(newDocumentView)}`);

            await nextTick(() => {
              log.info("Setting sidecar view");
              sidecarView.value = newDocumentView;
              appStore.workspaceStore.setActiveSelectionView(undefined);
            });
          }
        } else {
          log.info("Setting focus tag, sidecar already open");
          await nextTick(() => {
            appStore.workspaceStore.setFocusTagUuid(messagePayload.tagUuid);
          });
        }
      }
    };

    watch(data, async (messagePayload: any) => {
      log.info(`Received message ${messagePayload.messageType} (${isSidecar.value})`);
      isSidecar.value ? await sidecarProcessor(messagePayload, false) : await mainProcessor(messagePayload);
    });

    watch(localData, async (messagePayload: any) => {
      log.info(`Received local message ${messagePayload.messageType} (${isSidecar.value})`);
      if (isSidecar.value) {
        await sidecarProcessor(messagePayload, true);
      }
    });

    watch(error, (newValue) => {
      log.info("Received error", newValue);
    });

    function focusTag(tagUuid: string, viewId: string) {
      post({
        messageType: "focusTag",
        tagUuid,
        viewId,
      });
    }

    function focusPage(documentFamilyId: string, pageNumber: number) {
      const documentViewStore = createDocumentViewerStore(documentFamilyId);
      if (documentViewStore) {
        pageTags.value = documentViewStore.pageTags;
      }

      post({
        messageType: "focusPage",
        documentFamilyId,
        pageNumber,
      });
    }

    function unfocusTag() {
      post({
        messageType: "focusTag",
        tagUuid: undefined,
      });
    }

    function focusAttribute(attribute: DataAttribute, dataObject: DataObject) {
      log.info(`Focusing attribute [${dataObject.documentFamily.id} ${attribute.tagUuid}`);
      post({
        messageType: "focusAttribute",
        documentFamilyId: dataObject.documentFamily.id,
        tagUuid: attribute.tagUuid,
        attributeId: attribute.id,
        dataObjectId: dataObject.id,
      });
    }

    function setTitle(title: string) {
      post({
        messageType: "setTitle",
        title,
      });
    }

    function tagAdded(tag: TagInstance) {
      post({
        messageType: "tagAdded",
        tag: toRaw(tag),
      });
    }

    function tagRemoved(tag: SelectedTag) {
      post({
        messageType: "tagRemoved",
        tag: toRaw(tag),
      });
    }

    function reload(documentFamily: DocumentFamily) {
      post({
        messageType: "reload",
        documentFamily: toRaw(documentFamily),
      });
    }

    function clearSidecarView() {
      post({
        messageType: "close",
      });
    }

    return {
      isSupported,
      channel,
      post,
      close,
      error,
      isClosed,
      register,
      setSidecarView,
      sidecarView,
      focusTag,
      unfocusTag,
      focusAttribute,
      loading,
      title,
      setTitle,
      tagAdded,
      tagRemoved,
      sidecarProcessor,
      reload,
      clearSidecarView,
      focusPage,
      updateCurrentPage,
      currentPage,
      pageTags,
    };
  })();
}
