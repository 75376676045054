<script lang="ts" setup>
import type { ComputedRef } from "vue";
import { Disclosure, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ref, watch } from "vue";
import { DndProvider } from "vue3-dnd";
import Loading from "vue-loading-overlay";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { useListOrganizations } from "~/api/organizations/organizations";
import KodexaAbout from "~/components/about/kodexa-about.vue";
import KodexaThemeToggle from "~/components/kodexa-theme-toggle.vue";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import clearFlow from "~/images/kodexa-large.png";
import router from "~/router/router";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";
import { useIntercom } from "~/store/useIntercom";
import "vue-loading-overlay/dist/css/index.css";

appStore.platformStore.loadConfiguration();
const { user } = storeToRefs(appStore.userStore);

useIntercom().trackEvent("app-started");
const {
  platformErrors,
  loadingEvents,
  newsRead,
  platformConfiguration,
} = storeToRefs(appStore.platformStore);

const { project } = storeToRefs(appStore.projectStore);

const gridHelper = createGridHelper("appcontainer-organizations-list", {
  page: 1,
  pageSize: 20,
  filter: "",
  query: "",
  sort: "name",
});
const {
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  data,
} = useListOrganizations(gridQuery.value);

const { currentOrganization, creditStatus, mutateStatus } = storeToRefs(appStore.organizationStore);

const previousCreditStatus = ref(null);

// Watch for changes in creditStatus and update previousCreditStatus
watch(creditStatus, (newValue) => {
  if (mutateStatus.value !== "pending") {
    previousCreditStatus.value = newValue;
  }
});

const creditCountStatus = computed(() =>
  mutateStatus.value === "pending" ? previousCreditStatus.value : creditStatus.value,
);

interface UserNavigation {
  name: string;
  onClick?: () => void;
}

const userNavigation: ComputedRef<UserNavigation[]> = computed(() => {
  const options = [
    {
      name: "Profile",
      onClick: () => {
        const currentPath = router.currentRoute.value.path;
        const isInClearFlow = currentPath.startsWith("/f");
        router.push(isInClearFlow ? "/f/profile" : "/a/profile");
      },
    },
  ] as UserNavigation[];

  const currentPath = router.currentRoute.value.path;
  const isInClearFlow = currentPath.startsWith("/f");

  options.push(
    {
      name: "-divider-",
    },
    {
      name: isInClearFlow ? "Switch to Studio" : "Switch to Workflow",
      onClick: () => {
        const currentOrg = currentOrganization.value?.id;
        const currentProjectId = project.value?.id;

        let newPath = isInClearFlow ? "/a" : "/f";

        if (currentOrg) {
          newPath += `/o/${currentOrg}`;
          if (currentProjectId) {
            newPath += isInClearFlow ? `/p/${currentProjectId}/home` : `/p/${currentProjectId}/t`;
          } else {
            newPath += "/home";
          }
        } else {
          newPath += "/home";
        }

        router.push(newPath);
      },
    },
  );

  if (currentOrganization.value) {
    options.push(
      {
        name: "-divider-",
      },
      {
        name: "Manage Organization",
        onClick: () => {
          router.push(`/a/o/${currentOrganization.value.id}/admin`);
        },
      },
    );
  }

  options.push(
    {
      name: "-divider-",
    },
    {
      name: "About Kodexa",
      onClick: () => {
        const dialog = createConfirmDialog(KodexaAbout);
        dialog.reveal();
      },
    },
    {
      name: "Sign out",
      onClick: () => {
        appStore.userStore.logout().then(() => {
          notify({
            group: "generic",
            title: "Logged out",
            text: "Successfully logged out",
          }, 3000);
        });
      },
    },
  );

  return options;
});

watchDebounced(() => platformErrors, () => {
  if (platformErrors.value) {
    notify({
      group: "error",
      title: "Error",
      text: platformErrors.value,
    }, 3000);
  }
});

const isLoadingEvents = computed(() => loadingEvents.value.length > 0);

const availableOrganizations = computed(() => {
  return data.value?.content || [];
});

const homePath = computed(() => {
  if (currentOrganization.value && availableOrganizations.value.length === 1) {
    return "";
  }
  return "/f/home";
});

const openOrganizationPopup = ref(false);
const closeBanner = ref(true);

function close() {
  closeBanner.value = false;
}

function showNews() {
  appStore.platformStore.markNewsRead();
  useIntercom().showArticle(platformConfiguration.value.changeLogArticleId);
}
</script>

<template>
  <div class="min-h-full">
    <Loading
      v-model:active="isLoadingEvents"
      :can-cancel="false"
      :is-full-page="true"
      loader="spinner"
    >
      <div class="flex h-full w-96 flex-col rounded-lg bg-white dark:bg-gray-800 p-6 shadow-xl">
        <div class="flex items-center justify-center">
          <div class="loadership_MXBXF">
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        <div v-for="loadingEvent in loadingEvents" :key="loadingEvent.id" class="mt-2">
          <div class="flex items-center justify-center text-lg text-gray-900 dark:text-white">
            <span>{{ loadingEvent.title }}</span>
          </div>
          <div class="text-gray-500 dark:text-gray-400 mt-1 flex h-8 items-center justify-center text-sm">
            <span>{{ loadingEvent.progressMessage }}</span>
          </div>
          <div class="mt-4 flex items-center justify-center">
            <div v-if="loadingEvent.progressPercent !== undefined" class="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
              <div
                :style="{ width: `${loadingEvent.progressPercent}%` }"
                class="h-2.5 rounded-full bg-gradient-to-r from-green-500 to-blue-500"
              />
            </div>
          </div>
        </div>
      </div>
    </Loading>
    <Disclosure id="appNav" as="nav" class="border-b border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800">
      <div class="px-8">
        <div class="flex h-16 justify-between">
          <div class="flex grow-0">
            <RouterLink id="homeNav" class="flex shrink-0 items-center" :to="homePath">
              <img
                :src="clearFlow"
                alt="Kodexa" class="block h-[60px] w-[220px]"
              >
            </RouterLink>
            <div class="flex grow-0 items-start header-container" style="margin-top: 14px">
              <RouterLink class="flex" :to="homePath">
                <MaterialDesignIcon v-tooltip="`Home`" class="text-theme-primary dark:text-gray-200 ml-3 mr-2" name="home" size="24" />
                <div class="text-theme-primary dark:text-gray-200 mt-1 text-lg">
                  Home
                </div>
              </RouterLink>
              <div v-if="currentOrganization" class="ml-2 mr-3 mt-1 text-blue-900 dark:text-blue-400">
                |
              </div>
              <div class="flex organization-container">
                <RouterLink v-if="currentOrganization" id="organizationNav" :to="`/a/o/${currentOrganization.id}/home`">
                  <MaterialDesignIcon
                    v-tooltip="`Organization Home`" class="text-theme-primary dark:text-gray-200 mr-3" name="domain"
                    size="24"
                  />
                </RouterLink>
                <RouterLink v-if="currentOrganization" :to="`/f/o/${currentOrganization.id}/home`">
                  <div id="organizationNameNav" class="text-theme-primary dark:text-gray-200 text-lg organization-name">
                    {{ currentOrganization?.name }}
                  </div>
                </RouterLink>
              </div>
              <MaterialDesignIcon
                v-if="availableOrganizations.length > 1"
                id="organizationSelector"
                v-tooltip="`Change Organization`" class="text-theme-primary dark:text-gray-200 ml-1" name="menu-down"
                size="24" @click="openOrganizationPopup = true"
              />
              <div v-if="project" class="ml-2 mr-3 mt-1 text-blue-900 dark:text-blue-400">
                |
              </div>
              <RouterLink
                v-if="project && currentOrganization"
                id="projectNameNav"
                :to="`/f/o/${currentOrganization.id}/p/${project?.id}/home`" class="flex"
              >
                <MaterialDesignIcon
                  v-tooltip="`Project Home`" class="text-theme-primary dark:text-gray-200 mr-3" name="briefcase"
                  size="24"
                />
                <div class="text-theme-primary dark:text-gray-200 mt-1 text-lg project-name">
                  {{ project?.name }}
                </div>
              </RouterLink>
            </div>
          </div>
          <div class="flex grow-0 items-center justify-end">
            <div v-if="!newsRead && platformConfiguration.changeLogArticleId?.length > 0" class="flex items-center justify-center">
              <MaterialDesignIcon
                v-tooltip="`Learn about the latest updates`"
                class="text-theme-primary dark:text-gray-200 mr-3 text-blue-600"
                name="shimmer" @click="showNews"
              />
            </div>
            <div>
              <KodexaThemeToggle />
            </div>
            <!-- Profile dropdown -->
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton
                  id="userMenu"
                  class="focus:outline-none flex max-w-xs items-center rounded-full bg-white dark:bg-gray-800 text-sm"
                >
                  <span class="sr-only">Open user menu</span>
                  <KodexaUserAvatar :user="user" object-type="user" size="12" />
                </MenuButton>
              </div>
              <Transition
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800 py-1 shadow-lg"
                  style="z-index: 9999;"
                >
                  <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                    <a
                      v-if="item.name !== '-divider-'"
                      class="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200" :class="[active ? 'bg-gray-100 dark:bg-gray-700' : '']"
                      style="white-space: nowrap"
                      @click="item.onClick"
                    >{{ item.name }}</a>
                    <hr v-else class="my-1 border-gray-200 dark:border-gray-700">
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </Disclosure>
    <div v-if="creditCountStatus" id="creditBanner">
      <div v-if="creditCountStatus.status === 'RED'" class="flex justify-between items-center h-7 red-notif-banner dark:bg-red-900">
        <div id="creditBannerRedMessage" class="flex justify-center items-center w-full">
          Your organization does not have AI credits.
          <KodexaArticle
            class="school-icon ml-2 pt-0.5" article-id="9165324" text="Learn more about AI credits" size="21"
            :slide="true" icon-color="white"
          />
        </div>
      </div>
      <div
        v-if="closeBanner && creditCountStatus.status === 'AMBER'"
        class="flex justify-between items-center h-7 amber-notif-banner bg-yellow-500 dark:bg-yellow-700 text-white"
      >
        <div id="creditBannerAmberMessage" class="flex justify-center items-center w-full">
          Your organization is running low on AI credits.
          <KodexaArticle
            class="school-icon ml-2 pt-0.5" article-id="9165324" text="Learn more about AI credits"
            :slide="true" size="21" icon-color="white"
          />
        </div>
        <button id="creditClosePanel" type="button" class="text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200" @click="close">
          <span class="sr-only">Close panel</span>
          <MaterialDesignIcon name="close" class="size-6" size="20" aria-hidden="true" />
        </button>
      </div>
    </div>
    <div>
      <main>
        <DndProvider :backend="HTML5Backend">
          <RouterView />
        </DndProvider>
        <KodexaCommandPalette />
        <KodexaKeyboardShortcuts />
        <KodexaOrganizationsPopup v-model="openOrganizationPopup" :flow="true" />
      </main>
    </div>
  </div>
</template>

<style>
.loadership_MXBXF {
  display: flex;
  position: relative;
  width: 46px;
  height: 40px;
  margin-top: 5px;
}

.loadership_MXBXF div {
  position: absolute;
  width: 6px;
  height: 15px;
  background: #0f4c8c;
  top: 0;
  bottom: 3px;
  animation: loadership_MXBXF_scale 1.8s infinite,
  loadership_MXBXF_fade 1.8s infinite;
  animation-timing-function: ease-in-out;
}

:root[class~="dark"] .loadership_MXBXF div {
  background: #60a5fa;
}

.loadership_MXBXF div:nth-child(1) {
  animation-delay: 0s;
  left: 0px;
}

.loadership_MXBXF div:nth-child(2) {
  animation-delay: 0.16s;
  left: 10px;
}

.loadership_MXBXF div:nth-child(3) {
  animation-delay: 0.32s;
  left: 20px;
}

.loadership_MXBXF div:nth-child(4) {
  animation-delay: 0.48s;
  left: 30px;
}

.loadership_MXBXF div:nth-child(5) {
  animation-delay: 0.64s;
  left: 40px;
}

@keyframes loadership_MXBXF_scale {
  0%,
  44.44444444444445%,
  100% {
    transform: scaleY(1);
  }
  22.222222222222225% {
    transform: scaleY(3);
  }
}

@keyframes loadership_MXBXF_fade {
  0%,
  44.44444444444445%,
  100% {
    opacity: 1;
  }
  22.222222222222225% {
    opacity: 1;
  }
}

.intercom-launcher {
  opacity: 0.3 !important;
}

.intercom-launcher:hover {
  opacity: 1 !important;
}

:host .intercom-launcher {
  opacity: 0.3;
}

:host .intercom-launcher:hover {
  opacity: 1;
}

.red-notif-banner {
  background-color: #f44336;
  color: white;
  text-align: center;
  padding: 12px;
  font-size: 13px;
  font-weight: 500;
}

.amber-notif-banner {
  text-align: center;
  padding: 12px;
  font-size: 13px;
  font-weight: 500;
}

.school-icon {
  font-size: 12px !important;
  font-weight: normal;
}

.header-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}

.organization-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  align-items: center;
}

.organization-name,
.project-name {
  white-space: nowrap;
  overflow: hidden;
  max-width: max-content; /* Adjust the width as needed */
}

#organizationSelector {
  cursor: pointer;
}

@media (max-width: 768px) {
  .organization-name,
  .project-name {
    max-width: 100px; /* Adjust for smaller screens */
  }
}
</style>
