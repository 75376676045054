<script lang="ts" setup>
import type { Dashboard } from "~/model";
import { useDashboardsView } from "~/store/useDashboardsView";

const dashboardsView = useDashboardsView();

dashboardsView.initialize([
  {
    orgSlug: "kodexa",
    slug: "tasks-dashboard",
    version: "1.0.0",
    ref: "kodexa/flow-dashboard-1",
    name: "Tasks",
    description: "A dashboard for ClearFlow",
    type: "dashboard",
    widgets: [
      {
        id: "0",
        type: "documentCountByOrganizationCard",
        name: "Tasks",
        properties: {
          defaultPosition: { col: 0, colSpan: 12, rowSpan: 7, order: 1 },
          style: {
            height: "calc(100vh - 210px)",
          },
        },
      },
    ],
  } as Dashboard,
] as Dashboard[], {
}, false);
</script>

<template>
  <div>
    <KodexaDashboardsView />
  </div>
</template>
