<script setup lang="ts">
import type { PropType } from "vue";
import type { DataAttribute } from "~/model";
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  dataAttribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "clearSelection", "cancel", "update:dataAttribute"]);
const { tagMetadataMap } = storeToRefs(appStore.projectStore);

function close() {
  emit("cancel");
}

const taxon = ref(undefined);
const properties = ref({});

watch (() => props.dataAttribute, (value) => {
  properties.value = value.dataFeatures;
  const tagMetadata = tagMetadataMap.value.get(value.path);
  if (tagMetadata) {
    taxon.value = tagMetadata.taxon;
  }
}, {
  immediate: true,
});

function save() {
  // We need to update the attribute with its new properties in the workspace
  appStore.workspaceStore.updateAttributeProperties(props.dataAttribute, properties.value);
  emit("cancel");
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50 dark:bg-gray-9000 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4  text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 700px; height: 560px"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all"
            >
              <div class="ml-4 pb-2">
                <KodexaHeader
                  title="Attribute Properties"
                  sub-title="Edit the properties of the attribute"
                />
              </div>
              <div>
                <div class="p-4" style="height: 340px">
                  <div v-if="taxon">
                    <div v-for="option in taxon.options" :key="option.name">
                      <ConfigurationOption
                        v-model="properties"
                        :item="option"
                      />
                    </div>
                  </div>
                  <div v-else>
                    Unable to find the attribute's data definition
                  </div>
                </div>
                <div
                  class="mr-1 mt-12 sm:flex sm:flex-row-reverse p-4"
                >
                  <KodexaButton icon="content-save" width="180px" @click="save">
                    Save Changes
                  </KodexaButton>
                  <KodexaButton
                    class="mr-2" width="120px"
                    icon="cancel" type="secondary"
                    @click="close"
                  >
                    Cancel
                  </KodexaButton>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
