import type { ComputedRef, Ref } from "vue";
import type { Card, DataAttribute, DataException, DataForm, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import type { DataFormViewer } from "~/store/useWorkspace";
import { defineStore, storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import appStore from "~/store/index";
import { useValidators } from "~/store/useValidators";
import { log } from "~/utils/logger";

export interface DataFormCardLayout extends Card {
  card: Card;
  colSpan: number;
}

interface FullReviewInterface {
  parentUuid: string | undefined;
  reviewed: boolean;
}
interface OverrideException {
  parentUuid: string | undefined;
  openFlag: boolean;
}

export function createDataFormViewerStore(viewId: string) {
  const viewById: DataFormViewer = appStore.workspaceStore.getViewById(viewId);

  // Make sure we enable the validators
  useValidators();

  if (!viewById) {
    throw new Error(`Unable to find view with id ${viewId}`);
  }

  return defineStore(`dataFormViewer-${viewId}`, () => {
    log.info("Creating data form view store");
    const dataFormId: Ref<string> = ref(uuidv4());
    const dataFormViewer: Ref<DataFormViewer> = ref(viewById);

    const focusedAttributeUuid: Ref<string | undefined> = ref(undefined);

    const overrideExceptionMapper = ref<Map<string, OverrideException[]>>(new Map());

    const validatedExceptionUuids: Ref<string[]> = ref([]);

    const fullReviewPaths = ref(new Map<string, FullReviewInterface[]>(new Map()));

    const documentFamilyIds = computed(() => {
      return dataFormViewer.value.documentFamilyIds || [];
    });

    const dataForm = computed(() => {
      const { allDataForms } = storeToRefs(appStore.projectStore);
      if (allDataForms.value === undefined) {
        return undefined;
      }
      const dataF = allDataForms.value.find((dataForm: DataForm) => dataForm.ref === viewById.dataFormRef);
      if (dataF && !dataF.views) {
        dataF.views = [{ cards: [] }];
      }
      return dataF;
    });

    const selectedCard: Ref<Card | undefined> = ref(undefined);
    const title: Ref<string> = ref("");
    const designMode: Ref<boolean> = ref(false);

    const rootCards = computed(() => {
      return dataForm.value.cards;
    });

    const newDataObjects: Ref<DataObject[]> = ref([]);

    const dataObjects: ComputedRef<DataObject[]> = computed(() => {
      const { dataObjects } = storeToRefs(appStore.workspaceStore);

      if (dataForm.value && dataForm.value.entrypoints.includes("workspace")) {
        return [...dataObjects.value.values()];
      }

      const formDataObjects: DataObject[] = [];
      for (const dato of Array.from(dataObjects.value.values())) {
        if (dato.documentFamily.id && documentFamilyIds.value.includes(dato.documentFamily.id)) {
          if (formDataObjects.find(d => d.uuid === dato.uuid) === undefined) {
            formDataObjects.push(dato);
          }
        }
      }

      return formDataObjects.concat(newDataObjects.value);
    });

    const dataExceptions = computed(() => {
      const dataAttributeExceptions: DataException[] = [];
      const dataObjectExceptions: DataException[] = [];
      for (const dataObject of dataObjects.value) {
        if (dataObject.dataExceptions) {
          dataObjectExceptions.push(...dataObject.dataExceptions);
        }
        if (dataObject.attributes) {
          for (const dataAttribute of dataObject.attributes) {
            if (dataAttribute.dataExceptions) {
              dataAttributeExceptions.push(...dataAttribute.dataExceptions);
            }
          }
        }
      }
      return { dataAttributeExceptions, dataObjectExceptions };
    });

    const availableCardTypes = computed(() => {
      return [
        {
          name: "label",
          label: "Label",
          designOnly: false,
          options: [
            {
              name: "label",
              label: "Label",
              type: "string",
              default: "New Label",
            },
          ],
        },
        {
          name: "dataAttributeEditor",
          label: "Data Attribute",
          designOnly: false,
          options: [
            {
              name: "taxon",
              label: "Attribute",
              type: "taxon",
              default: undefined,
            },
          ],
        },
        {
          name: "cardPanel",
          label: "Card Panel",
          designOnly: false,
          options: [
            {
              name: "groupTaxon",
              label: "Group Taxon",
              type: "groupTaxon",
              default: undefined,
            },
            {
              name: "showHeader",
              label: "Show Header",
              type: "boolean",
              default: false,
            },
            {
              name: "title",
              label: "Title",
              type: "string",
              default: "Title",
            },
            {
              name: "subTitle",
              label: "Subtitle",
              type: "string",
              default: "",
            },
            {
              name: "hideCollapse",
              label: "Hide Collapse",
              type: "boolean",
              default: false,
            },
            {
              name: "hideAdd",
              label: "Hide Add",
              type: "boolean",
              default: false,
            },
          ],
        },
        {
          name: "dataObjectGrid",
          label: "Data Object Grid",
          designOnly: false,
          options: [
            {
              name: "groupTaxon",
              label: "Group Taxon",
              type: "groupTaxon",
              default: undefined,
            },
          ],
        },
        {
          name: "tabs",
          label: "Tabs",
          designOnly: false,
          options: [],
        },
        {
          name: "transposedGrid",
          label: "Transposed Grid",
          designOnly: false,
          options: [
            {
              name: "groupTaxon",
              label: "Group Taxon",
              type: "groupTaxon",
              default: undefined,
            },
            {
              name: "headerTaxon",
              label: "Header Taxon",
              type: "headerTaxon",
              default: undefined,
            },
          ],
        },
      ];
    });

    function addCard(newCard: Card, parentCard?: Card) {
      if (parentCard && parentCard.id) {
        const reactiveParent = findCard(parentCard.id);
        if (reactiveParent) {
          // @ts-expect-error OpenAPI issue
          reactiveParent.children.push(newCard);
        }
      } else {
        dataForm.value.cards.push(newCard);
      }
    }

    function removeCard(card: Card, parentCard?: Card) {
      if (parentCard?.id) {
        const reactiveParent = findCard(parentCard.id);
        if (reactiveParent && reactiveParent.children) {
          reactiveParent.children = reactiveParent.children.filter(c => c.id !== card.id);
        }
      } else {
        dataForm.value.cards = dataForm.value.cards.filter(c => c.id !== card.id);
      }
    }

    function toggleDesignMode() {
      designMode.value = !designMode.value;
    }

    function selectCard(card: Card) {
      selectedCard.value = card;
    }

    function findCardInChildren(cardId: string, cards: Card[] | undefined, updateCard?: Card, overwrite = false): Card | undefined {
      if (!cards) {
        return undefined;
      }

      for (const card of cards) {
        if (card.id === cardId) {
          if (updateCard) {
            card.type = updateCard.type;
            card.properties = updateCard.properties;
            if (overwrite) {
              card.children = updateCard.children;
            }
            if (selectedCard.value && selectedCard.value.id === cardId) {
              selectedCard.value = card;
            }
          }
          return card;
        }
        if (card.children) {
          const foundCard = findCardInChildren(cardId, card.children, updateCard, overwrite);
          if (foundCard) {
            return foundCard;
          }
        }
      }
      return undefined;
    }

    function findCard(cardId: string, updateCard?: Card, overwrite = false): Card | undefined {
      return findCardInChildren(cardId, dataForm.value.cards, updateCard, overwrite);
    }

    function updateCardPositions(updatedPositions: Ref, parentCard?: Card) {
      // @ts-expect-error Open API is missing the children
      const reactiveParent = parentCard ? findCard(parentCard.id) : undefined;
      if (reactiveParent && !reactiveParent.children) {
        reactiveParent.children = [];
      }

      const cards = reactiveParent ? reactiveParent.children : dataForm.value.cards;
      updatedPositions.value.forEach((position: any) => {
        const card = cards.find((card: Card) => card.id === position.id);
        if (card) {
          card.properties.col = position.col;
          card.properties.colSpan = position.colSpan;
          card.properties.rowSpan = position.rowSpan;
          card.properties.row = position.row;
          card.properties.order = position.order;
        }
      });
    }

    function getPositions(parentCard?: Card) {
      if (parentCard) {
        if (!parentCard.children) {
          return [];
        }
        return parentCard.children.map((card: Card) => {
          return {
            id: card.id,
            col: card.properties?.col || 0,
            colSpan: card.properties?.colSpan || 2,
            rowSpan: card.properties?.rowSpan || 2,
            row: card.properties?.row || 0,
            order: card.properties?.order || 0,
          };
        });
      } else {
        const cards = dataForm.value.cards || [];
        return cards.map((card: Card) => {
          return {
            id: card.id,
            col: card.properties?.col || 0,
            colSpan: card.properties?.colSpan || 2,
            rowSpan: card.properties?.rowSpan || 2,
            row: card.properties?.row || 0,
            order: card.properties?.order || 0,
          };
        });
      }
    }

    function getCards(parentCard?: Card): any[] {
      // We need to get the card into a form that the Kendo UI control
      // can work with
      const cards = parentCard ? parentCard.children || [] : dataForm.value.cards || [];
      const cardCopy = JSON.parse(JSON.stringify(cards));

      return cardCopy.map((card: any) => {
        card.item = "tile";
        card.reorderable = designMode.value;
        card.resizable = designMode.value;
        return card;
      });
    }

    function updateCard(card: Card | undefined, overwrite = false) {
      if (!card || !card.id) {
        return card;
      }
      return findCard(card.id, card, overwrite);
    }

    function findParentCard(cardId: string, startingPoint?: Card): Card | undefined {
      const cardsToSearch = startingPoint?.children || dataForm.value.cards;

      for (const card of cardsToSearch) {
        if (card.id === cardId) {
          return startingPoint;
        }
        if (card.children) {
          const foundCard = findParentCard(cardId, card);
          if (foundCard) {
            return foundCard;
          }
        }
      }
      return undefined;
    }

    // We will want to build a structure since when they user is not in design mode we want to use good old fashioned
    // HTML to render the form
    function buildDataFormViewer(card: Card | undefined = undefined): DataFormCardLayout[] {
      // Our basic form structure is made up of for a card we can return multiple rows - each row is going
      // to be turned into a div using tailwind grid layout.  We do this to allow us to have variable height
      // and use the standard HTML layout
      const rows = [];
      const positions = getPositions(card);

      // Get the maximum row
      const maxRow = Math.max(...positions.map((p: any) => p.row));

      const sortedPositions = positions.sort((a: any, b: any) => a.order - b.order);

      for (let i = 0; i <= maxRow; i++) {
        const row = sortedPositions.filter((p: any) => p.row === i);
        const rowCards = [];
        for (const rowItem of row) {
          const card = findCard(rowItem.id);
          if (card) {
            const layout = JSON.parse(JSON.stringify(card)) as DataFormCardLayout;
            layout.colSpan = rowItem.colSpan;
            rowCards.push(layout);
          }
        }

        rows.push({ cards: rowCards });
      }
      return rows;
    }

    function addChildCard(parentCardId: string, card: Card) {
      const parentCard = findCard(parentCardId);
      if (!parentCard) {
        return;
      }
      if (!parentCard.children) {
        parentCard.children = [];
      }
      parentCard.children.push(card);
    }

    function addAttribute(tagMetadata: TagMetadata, dataObject: DataObject, uuid = uuidv4()) {
      // Important, when we create an attribute or anything else that we intend to store
      // in the platform we can't create an ID since it hasn't been stored yet
      // therefore must provide an uuid - the workspace will tracked by UUID so that it
      // can handle data that is either saved to the backend or not yet save

      const attribute = {
        uuid,
        path: tagMetadata.taxon.path,
        dataExceptions: [],
        tag: tagMetadata.taxon.name,
        typeAtCreation: tagMetadata.taxon.taxonType,
        dataObject: {
          uuid: dataObject.uuid,
          id: dataObject.id,
          storeRef: dataObject.storeRef,
          documentFamily: {
            id: dataObject.documentFamily.id,
            path: dataObject.documentFamily.path,
          },
        },
      } as DataAttribute;

      if (dataObject.id) {
        attribute.dataObjId = dataObject.id;
      }
      appStore.workspaceStore.addAttribute(dataObject, attribute);
      return attribute;
    }

    function deleteAttribute(dataObject: DataObject, attribute: DataAttribute) {
      appStore.workspaceStore.deleteAttribute(dataObject, attribute);
    }

    function updateAttribute(dataObject: DataObject, attribute: DataAttribute) {
      appStore.workspaceStore.updateAttribute(dataObject, attribute);
    }

    /**
     * Update exception in the application store.
     *
     * @param {DataObject} dataObject - The data object containing the exception to update.
     * @param {Partial<DataException>} partialDataException - Partial data of the exception to update.
     * @param {string} exceptionUuid - The UUID of the exception to update.
     * @param {undefined | DataAttribute} dataAttribute - The data attribute to update.
     * @param {boolean} updateFromDataAttribute - Indicates whether to update the exception from the data attribute.
     *
     * @return {void}
     */
    function updateException(dataObject: DataObject, partialDataException: Partial<DataException>, exceptionUuid: string, dataAttribute: undefined | DataAttribute, updateFromDataAttribute = false) {
      appStore.workspaceStore.updateException(dataObject, partialDataException, exceptionUuid, dataAttribute, updateFromDataAttribute);
    }

    function updateDataObject(dataObject: DataObject, partialDataObject: Partial<DataObject>) {
      appStore.workspaceStore.updateDataObject(dataObject, partialDataObject);
    }

    /**
     * This is for human review. When all buttons are set to reviewed it will react to it by
     * removing the full review exception types
     *
     * First is we want to set it not just by path but a way of storing the parentUuid to have a proper
     * way of sectioning each path.
     * After storing those the fullReview will have a default value of false
     * Every clicking the Reviewed button it will react to it by turning the fullReview into true and by mapping that
     * particular path with the associated parentUuids
     * Next is to check if all path are true we remove the exception under it
     * @param parentDataObject
     * @param path
     * @param fullReview
     */
    function setFullReview(parentDataObject: DataObject | undefined, path: string | undefined, fullReview: boolean) {
      if (!path) {
        return;
      }

      // Retrieve the existing array (or create a new one if it doesn't exist)
      const fullReviewPathList = fullReviewPaths.value.get(path) || [];
      const fullReviewPath: FullReviewInterface = {
        parentUuid: parentDataObject?.uuid || undefined,
        reviewed: fullReview,
      };

      if (!parentDataObject) {
        // This means we are only storing the most parent group taxon path
        fullReviewPathList.splice(0, fullReviewPathList.length, fullReviewPath);
      } else {
        // Find the index of the existing override exception
        const existingIndex = fullReviewPathList.findIndex(overrideException =>
          overrideException.parentUuid === parentDataObject.uuid);

        if (existingIndex !== -1) {
          fullReviewPathList[existingIndex] = fullReviewPath;
        } else {
          // Push a new entry
          fullReviewPathList.push(fullReviewPath);
        }
      }

      fullReviewPaths.value.set(path, fullReviewPathList);
      checkReviewResult();
    }

    /**
     * Each time we delete a dataObject, we check if there's anything that we need to delete
     * on the full review list
     * @param parentDataObject: The Parent Data Object
     * @param path: Taxon Path
     */
    function removeFullReviewPath(parentDataObject: DataObject | undefined, path: string | undefined) {
      if (!path) {
        return;
      }

      const updatedMap = new Map();
      fullReviewPaths.value.forEach((fullReviewList, key) => {
        const updatedList = fullReviewList.filter(fs => fs.parentUuid !== parentDataObject?.uuid);
        if (updatedList) {
          updatedMap.set(key, updatedList);
        }
      });

      fullReviewPaths.value = updatedMap;
      checkReviewResult();
    }

    function checkReviewResult() {
      // We need to see if all the paths are set to true
      // and if so we need to remove the full review exception
      // from the data object
      //
      const reviewed = Array.from(fullReviewPaths.value.keys()).every((path) => {
        const pathArray = fullReviewPaths.value.get(path);
        return pathArray && pathArray.every(item => item.reviewed === true);
      });

      if (reviewed) {
        dataExceptions.value.dataObjectExceptions.filter((e: DataException) => e.exceptionType === "Full Data Review").forEach((e: DataException) => {
          log.info("Removing full review exception");
          appStore.workspaceStore.removeException(null, e.message, e.dataObject, true);
        });
      }
    }

    /**
     * This function is mainly for toggling the resolve flag of a data exception within a data object/data attribute
     * @param parentDataObject
     * @param path
     * @param openFlag
     */

    function setDataExceptionResolve(parentDataObject: DataObject | undefined, path: string, openFlag: boolean) {
      const currentDataObjects = dataObjects.value.filter(dataObject => dataObject.path === path && dataObject.parent?.uuid === parentDataObject?.uuid);
      if (!parentDataObject || !currentDataObjects) {
        return;
      }

      const overrideException: OverrideException = {
        parentUuid: parentDataObject.uuid,
        openFlag,
      };

      // Retrieve the existing array (or create a new one if it doesn't exist)
      const overrideExceptionList = overrideExceptionMapper.value.get(path) || [];
      // Find the index of the existing override exception
      const existingIndex = overrideExceptionList.findIndex(overrideException => overrideException.parentUuid === parentDataObject.uuid);

      if (existingIndex !== -1) {
        // Override existing entry
        overrideExceptionList[existingIndex] = overrideException;
      } else {
        // Push a new entry
        overrideExceptionList.push(overrideException);
      }

      overrideExceptionMapper.value.set(path, overrideExceptionList);

      // Reusable function for updating resolve flag of an exception for both attribute and object
      function updateDataExceptions(dataObject: DataObject, exceptions: DataException[], dataAttribute: undefined | DataAttribute = undefined, updateFromDataAttribute = false) {
        if (!exceptions) {
          return;
        }
        for (const currentDataException of exceptions) {
          const partialUpdateException: Partial<DataException> = { open: openFlag };
          updateException(dataObject, partialUpdateException, currentDataException.uuid as string, dataAttribute, updateFromDataAttribute);
        }
      }

      for (const currentDataObject of currentDataObjects) {
        if (currentDataObject.dataExceptions) {
          updateDataExceptions(currentDataObject, currentDataObject.dataExceptions);
        }

        if (currentDataObject.attributes) {
          for (const dataAttribute of currentDataObject.attributes) {
            if (!dataAttribute.dataExceptions) {
              continue;
            }
            updateDataExceptions(currentDataObject, dataAttribute.dataExceptions, dataAttribute, true);
          }
        }
      }
    }

    function updateValidateExceptions(exceptionUuids: string[]) {
      for (const exceptionUuid of exceptionUuids) {
        if (!validatedExceptionUuids.value.includes(exceptionUuid)) {
          validatedExceptionUuids.value.push(exceptionUuid);
        }
      }
    }

    function setFocusedAttributeUuid(uuid: string | undefined) {
      focusedAttributeUuid.value = uuid;
    }

    const needsFullReview = computed(() => {
      return dataExceptions.value.dataObjectExceptions.filter((e: DataException) => e.exceptionType === "Full Data Review").length > 0;
    });

    return {
      removeFullReviewPath,
      setFullReview,
      needsFullReview,
      dataObjects,
      selectedCard,
      title,
      dataForm,
      documentFamilyIds,
      dataFormId,
      availableCardTypes,
      designMode,
      addCard,
      removeCard,
      rootCards,
      dataFormViewer,
      toggleDesignMode,
      selectCard,
      updateCardPositions,
      findCard,
      getPositions,
      getCards,
      updateCard,
      findParentCard,
      buildDataFormViewer,
      addChildCard,
      addAttribute,
      deleteAttribute,
      updateAttribute,
      setDataExceptionResolve,
      focusedAttributeUuid,
      setFocusedAttributeUuid,
      updateDataObject,
      dataExceptions,
      overrideExceptionMapper,
      validatedExceptionUuids,
      updateValidateExceptions,
    };
  })();
}

export const useInputStore = defineStore("inputStore", {
  state: () => ({
    lastFocusedInput: null as string | null,
  }),
  actions: {
    setLastFocusedInput(inputRef: string) {
      this.lastFocusedInput = inputRef;
    },
  },
});
