/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  ListMessagesParams,
  Message,
  PageMessage,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getMessage = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Message>(
      {url: `/api/messages/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetMessageQueryKey = (id: MaybeRef<string>,) => {
    return ['api','messages',id] as const;
    }

    
export const getGetMessageQueryOptions = <TData = Awaited<ReturnType<typeof getMessage>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessage>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetMessageQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMessage>>> = ({ signal }) => getMessage(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMessage>>, TError, TData> 
}

export type GetMessageQueryResult = NonNullable<Awaited<ReturnType<typeof getMessage>>>
export type GetMessageQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetMessage<TData = Awaited<ReturnType<typeof getMessage>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMessage>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetMessageQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateMessage = (
    id: MaybeRef<string>,
    message: MaybeRef<Message>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
message = unref(message);
      
      return customAxios<Message>(
      {url: `/api/messages/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: message
    },
      options);
    }
  


export const getUpdateMessageMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMessage>>, TError,{id: string;data: Message}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateMessage>>, TError,{id: string;data: Message}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMessage>>, {id: string;data: Message}> = (props) => {
          const {id,data} = props ?? {};

          return  updateMessage(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateMessageMutationResult = NonNullable<Awaited<ReturnType<typeof updateMessage>>>
    export type UpdateMessageMutationBody = Message
    export type UpdateMessageMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateMessage = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMessage>>, TError,{id: string;data: Message}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateMessage>>,
        TError,
        {id: string;data: Message},
        TContext
      > => {

      const mutationOptions = getUpdateMessageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteMessage = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/messages/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteMessageMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMessage>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteMessage>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMessage>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteMessage(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteMessageMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMessage>>>
    
    export type DeleteMessageMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteMessage = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMessage>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteMessage>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteMessageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listMessages = (
    params?: MaybeRef<ListMessagesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageMessage>(
      {url: `/api/messages`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListMessagesQueryKey = (params?: MaybeRef<ListMessagesParams>,) => {
    return ['api','messages', ...(params ? [params]: [])] as const;
    }

    
export const getListMessagesQueryOptions = <TData = Awaited<ReturnType<typeof listMessages>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListMessagesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listMessages>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListMessagesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listMessages>>> = ({ signal }) => listMessages(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listMessages>>, TError, TData> 
}

export type ListMessagesQueryResult = NonNullable<Awaited<ReturnType<typeof listMessages>>>
export type ListMessagesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListMessages<TData = Awaited<ReturnType<typeof listMessages>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListMessagesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listMessages>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListMessagesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createMessage = (
    message: MaybeRef<Message>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      message = unref(message);
      
      return customAxios<Message>(
      {url: `/api/messages`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: message, signal
    },
      options);
    }
  


export const getCreateMessageMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMessage>>, TError,{data: Message}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createMessage>>, TError,{data: Message}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMessage>>, {data: Message}> = (props) => {
          const {data} = props ?? {};

          return  createMessage(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateMessageMutationResult = NonNullable<Awaited<ReturnType<typeof createMessage>>>
    export type CreateMessageMutationBody = Message
    export type CreateMessageMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateMessage = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMessage>>, TError,{data: Message}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createMessage>>,
        TError,
        {data: Message},
        TContext
      > => {

      const mutationOptions = getCreateMessageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForMessage = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/messages/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForMessageQueryKey = (id: MaybeRef<string>,) => {
    return ['api','messages',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForMessageQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForMessage>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForMessage>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForMessageQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForMessage>>> = ({ signal }) => getChangeSequenceForMessage(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForMessage>>, TError, TData> 
}

export type GetChangeSequenceForMessageQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForMessage>>>
export type GetChangeSequenceForMessageQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForMessage<TData = Awaited<ReturnType<typeof getChangeSequenceForMessage>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForMessage>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForMessageQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



