<script setup lang="ts">
import type { ECharts } from "echarts";
import * as echarts from "echarts";
import { onMounted, ref, watch } from "vue";

interface Bucket {
  key_as_string: string;
  doc_count: number;
}

interface Dataset {
  name: string;
  data: {
    aggregations: {
      size_by_date: {
        buckets: Bucket[];
      };
    };
  };
}

const props = defineProps<{
  datasets: Dataset[];
  title?: string;
  unit?: string;
}>();

const chart = ref<ECharts>();
const chartRef = ref<HTMLElement>();

function initChart() {
  if (!chartRef.value) {
    return;
  }

  const chartTitle = props.title || "Items created by date";
  const unit = props.unit || "items";

  chart.value = echarts.init(chartRef.value);

  // Extract and normalize data for the chart
  const xAxisData: string[] = [];
  const seriesData = props.datasets.map((dataset) => {
    const dataMap: Record<string, number> = {};
    dataset.data.aggregations.size_by_date.buckets.forEach((bucket) => {
      const date = bucket.key_as_string.split("T")[0];
      dataMap[date] = bucket.doc_count;
      if (!xAxisData.includes(date)) {
        xAxisData.push(date);
      }
    });
    return {
      name: dataset.name,
      type: "bar",
      stack: "total", // Stacking all bars under 'total'
      data: xAxisData.map(date => dataMap[date] || 0), // Fill missing dates with 0
      itemStyle: {
        borderRadius: [4, 4, 0, 0],
        opacity: 0.8,
      },
      emphasis: {
        itemStyle: {
          opacity: 1,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
      tooltip: {
        show: true,
        trigger: "item",
        formatter: "{a} <br/>{b}: {c}",
      },
      animation: true,
      animationDuration: 1000,
      animationEasing: "elasticOut",
    };
  });

  // Sort xAxisData for chronological order
  xAxisData.sort();

  // Configure the chart options
  const options = {
    title: {
      text: chartTitle,
      textStyle: {
        fontSize: 20,
        fontWeight: "bold",
      },
      tooltip: {
        show: true,
        formatter: `Shows ${unit} creation statistics over time`,
        backgroundColor: "rgba(50,50,50,0.7)",
        padding: [5, 10],
        textStyle: {
          fontSize: 12,
          color: "#fff",
        },
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
          backgroundColor: "#6a7985",
        },
      },
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      borderColor: "#ccc",
      borderWidth: 1,
      textStyle: {
        color: "#333",
      },
      extraCssText: "box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);",
      formatter(params: any) {
        let total = 0;
        let tooltipContent = `<div style="font-weight:bold;padding:5px 0">${params[0].axisValue}</div>`;
        params.forEach((item: any) => {
          total += item.value;
          tooltipContent += `<div style="color:${item.color};padding:3px 0">
            ${item.seriesName}: ${item.value} ${unit}
          </div>`;
        });
        tooltipContent += `<div style="font-weight:bold;margin-top:8px;padding-top:6px;border-top:1px solid #ccc">Total: ${total} ${unit}</div>`;
        return tooltipContent;
      },
    },
    legend: {
      top: "bottom",
      textStyle: {
        fontSize: 12,
      },
      icon: "roundRect",
      itemWidth: 15,
      itemHeight: 15,
      tooltip: {
        show: true,
        formatter(param: any) {
          return `Click to show/hide ${param.name} data`;
        },
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "15%",
      top: "10%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      axisLabel: {
        rotate: 45,
        fontSize: 11,
        color: "#666",
      },
      axisTick: {
        alignWithLabel: true,
      },
      splitLine: {
        show: false,
      },
      tooltip: {
        show: true,
        formatter: `${unit} creation date`,
      },
    },
    yAxis: {
      type: "value",
      name: `${unit} Count`,
      nameTextStyle: {
        fontSize: 12,
        padding: [0, 30, 0, 0],
      },
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      tooltip: {
        show: true,
        formatter: `Number of ${unit}`,
      },
    },
    series: seriesData,
    animation: true,
    animationThreshold: 2000,
    animationDuration: 1000,
    animationEasing: "cubicOut",
    animationDelay(idx: number) {
      return idx * 10;
    },
  };

  // Render the chart
  chart.value.setOption(options);
}

// Watch for changes in datasets prop
watch(() => props.datasets, () => {
  initChart();
}, { deep: true });

onMounted(() => {
  initChart();
  window.addEventListener("resize", () => chart.value?.resize());
});
</script>

<template>
  <div class="bg-white dark:bg-gray-800 p-2">
    <div ref="chartRef" style="width: 100%; height: 500px;" />
  </div>
</template>

<style scoped>
/* Add custom styles if needed */
</style>
