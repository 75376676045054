<script lang="ts" setup>
import type { Store } from "pinia";
import type { PropType } from "vue";
import type { DataFlowNode } from "~/model";
import { Handle, Position } from "@vue-flow/core";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "selectedStep"]);

const { documentStores } = storeToRefs(appStore.projectStore);

const documentStore = computed(() => {
  return documentStores.value.find((documentStore: Store) => props.node && documentStore.ref === props.node.id?.split("//")[1]);
});
</script>

<template>
  <div class="-mx-2" @click="emit('selected')">
    <img
      style="float:left" class="ml-2 mr-3 h-16 rounded-md"
      src="/assets/icon-documentstore.png" alt="Data Store"
    >
    <div class="pt-4 text-gray-700" style="width: 250px">
      {{ documentStore?.name || 'Document Store' }}
    </div>
    <div class="pl-2 text-xs text-gray-500" style="width: 250px">
      Document Store
    </div>
    <Handle id="resourceConnection" type="source" :position="Position.Left" />
    <Handle id="publishes" type="source" :position="Position.Bottom" />
    <Handle id="consumes" type="target" :position="Position.Top" />
  </div>
</template>

<style scoped>

</style>
