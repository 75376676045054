export const componentMetadata = {
  label: "Label",
  description: "A simple label card",
  supportsChildren: false,
  defaultWidth: 3,
  defaultHeight: 2,
  options: [
    {
      label: "Label",
      name: "label",
      type: "string",
      required: true,
    },
  ],
};
