<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  selectedEventType: {
    type: Object,
    required: false,
  },
  embedExecution: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const emit = defineEmits(["confirm", "update:modelValue", "close"]);

function close() {
  emit("update:modelValue", false);
  emit("close");
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog v-if="selectedEventType" as="div" class="relative z-50" @close="close()">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-50/75 dark:bg-gray-900/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 950px; height: 720px; z-index: 5000"
              class="relative overflow-hidden rounded-lg bg-white dark:bg-gray-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white dark:bg-gray-800 text-gray-400 hover:text-gray-500"
                  @click="close()"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle class="flex text-lg font-medium leading-6 text-gray-900">
                <div class="sm:flex sm:items-center sm:justify-between">
                  <div class="sm:flex sm:space-x-5">
                    <div class="shrink-0">
                      <img
                        class="mx-auto size-20 rounded-md" :src="selectedEventType.assistant.definition.imageUrl"
                        alt=""
                      >
                    </div>
                    <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p class="text-xl font-bold text-gray-900 sm:text-2xl">
                        {{ selectedEventType.assistant.name }}
                      </p>
                      <p class="text-sm font-medium text-gray-600">
                        {{ selectedEventType.assistant.description }}
                      </p>
                    </div>
                  </div>
                </div>
              </DialogTitle>
              <KodexaAssistantEventType
                class="mt-5" :assistant="selectedEventType.assistant"
                :event-type="selectedEventType.eventType"
                :button-class="['mt-50']"
                :embed-execution="embedExecution"
                @started="close()"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
