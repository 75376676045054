<script setup lang="ts">
import type { PropType } from "vue";
import type { ContentNode, SelectedTag, TagData } from "~/components/document/document";
import type { DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import type { ActiveSelection, TagInstance } from "~/store/useWorkspace";
import { Combobox, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { Toolbar } from "@progress/kendo-vue-buttons";
import { useClipboard } from "@vueuse/core";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { Tippy } from "vue-tippy";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaTagMetadata from "~/components/document/kodexa-tag-metadata.vue";
import appStore from "~/store";
import { createDocumentViewerStore } from "~/store/useDocumentView";
import { log } from "~/utils/logger";

const props = defineProps({
  activeSelectionView: {
    type: Object as PropType<ActiveSelection>,
    required: true,
  },
  kioskMode: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["closed", "updated", "selectedTaxonTag"]);

log.info("Initializing tagging popup", props.activeSelectionView);
const useDocumentViewStore = createDocumentViewerStore(props.activeSelectionView.viewId);
const tippy = ref(null);
const showNewTaxon = ref(false);

const isTrainingStore = computed(() => {
  return useDocumentViewStore?.documentStore.storePurpose === "TRAINING";
});

const isExecutionResult = computed(() => {
  return useDocumentViewStore?.documentViewer.executionId !== undefined;
});

const {
  selectionContext,
  documentFamily,
} = storeToRefs(useDocumentViewStore);

const { messageTemplates, tagMetadataMap } = storeToRefs(appStore.projectStore);

interface DataObjectTagMapping {
  path: string;
  uuid: string;
  tags: TagMetadata[];
  tagMeta?: TagMetadata;
  cellIndex: string;
  groupUuid: string;
}

const { currentWorkspace } = storeToRefs(appStore.workspaceStore);

const { currentSidebar } = storeToRefs(appStore.platformStore);

const rawQuery = ref("");

const selectedTags = ref<SelectedTag[]>([]);
const labelPopupTags = ref<TagMetadata[]>([]);
const rootTags = ref<TagMetadata[]>([]);
const dataObjectTagMapping = ref<DataObjectTagMapping[]>([]);
const possibleNoConfidenceTags = ref<TagInstance[]>([]);

watch(() => selectionContext?.value?.showTagPopup, () => {
  if (rawQuery.value !== "") {
    rawQuery.value = "";
  } else {
    refresh();
  }
});

watch(rawQuery, () => {
  refresh();
});

function getTagValue(tag: SelectedTag) {
  return useDocumentViewStore?.getTagContent(tag) || "";
}

function refresh() {
  let selectedAndParentNodes: ContentNode[] = [];

  log.info(`Refreshing popup, selected nodes: ${selectionContext.value.selectedNodes.length}`);
  log.info(`Selected value: ${selectionContext.value.selectedValue}`);
  const updatedNodes = useDocumentViewStore?.reloadNodes(selectionContext.value.selectedNodes) || [];
  updatedNodes.forEach((node) => {
    selectedAndParentNodes.push(node);
    selectedAndParentNodes = selectedAndParentNodes.concat(node.getParents());
  });

  selectedTags.value = [];

  selectedAndParentNodes.forEach((node) => {
    node.getFeatures().forEach((feature) => {
      if (feature.featureType === "tag") {
        if (selectedTags.value.filter(tag => tag.uuid === feature.value[0].uuid && tag.name === feature.name).length === 0) {
          const tagMetadata = appStore.projectStore.getTagMetadata(feature.name);

          if (tagMetadata) {
            const selectedTag = JSON.parse(JSON.stringify(appStore.projectStore.getTagMetadata(feature.name)));
            selectedTag.feature = feature;
            selectedTag.uuid = feature.value[0].uuid;
            const confidence = feature.value[0].confidence ? feature.value[0].confidence : 0;
            selectedTag.name = feature.name;
            if (selectedTag.taxonomy && selectedTag.taxonomy.enabled && confidence >= 0) {
              selectedTags.value.push(selectedTag);
            }
          }
        }
      }
    });
  });

  labelPopupTags.value = appStore.projectStore.queryTags(rawQuery.value);
  labelPopupTags.value = labelPopupTags.value.filter((tag) => {
    return selectedTags.value.filter(selectedTag => selectedTag.path === tag.taxon.path).length === 0;
  });

  rootTags.value = labelPopupTags.value.filter((tag) => {
    return !tag.parentPath;
  });

  // We need to get the data objects in the workspace, and we need to group the tags by the data object
  const dataObjects = appStore.workspaceStore.dataObjects;

  // Group the label popup tags under the data object
  const groupedTags = new Map<string, TagMetadata[]>();
  labelPopupTags.value.forEach((tag) => {
    if (!tag.parentPath) {
      return;
    }

    if (groupedTags.has(tag.parentPath)) {
      groupedTags.get(tag.parentPath)?.push(tag);
    } else {
      groupedTags.set(tag.parentPath, [tag]);
    }
  });
  dataObjectTagMapping.value = [];
  const existingParentPaths = new Set<string>();

  // If we are not looking at a training store we want to show the data objects
  // and the related tags
  if (!isTrainingStore.value) {
    log.info("Not a training store, showing data objects");
    dataObjects.forEach((dataObject: DataObject) => {
      const path = dataObject.path as string;
      const uuid = dataObject.uuid;
      const tags = groupedTags.get(path) || [];
      const dataObjectTagMetadata = tagMetadataMap.value.get(path);
      existingParentPaths.add(path);

      const cellIndex = dataObject.cellIndex;
      const groupUuid = dataObject.groupUuid;

      dataObjectTagMapping.value.push({
        path,
        uuid,
        tags,
        cellIndex,
        groupUuid,
        tagMeta: dataObjectTagMetadata,
      } as DataObjectTagMapping);
    });
  } else {
    log.info("Training store, not showing data objects");
    Array.from(groupedTags.keys()).forEach((parentPath) => {
      if (!existingParentPaths.has(parentPath)) {
        const tags = groupedTags.get(parentPath) || [];
        const dataObjectTagMetadata = tagMetadataMap.value.get(parentPath);
        dataObjectTagMapping.value.push({
          path: parentPath,
          uuid: "",
          tags,
          tagMeta: dataObjectTagMetadata,
          cellIndex: "0",
          groupUuid: null,
        } as DataObjectTagMapping);
      }
    });
  }

  // We want to go through the no confidence tags to see if they are close to this value and
  // if so we want to show them
  const noConfidenceTags: TagInstance[] = useDocumentViewStore?.getNoConfidenceTags() || [];
  const selectedValue = selectionContext.value.selectedValue;

  function normalize(value: string | undefined) {
    return value ? (`${value}`).toLowerCase().replace(/[^a-z0-9/.]/gi, "") : "";
  }

  possibleNoConfidenceTags.value = noConfidenceTags.filter((tagInstance: TagInstance) => {
    return normalize(tagInstance.value) === normalize(selectedValue);
  });
}

const finalTemplates = computed(() => {
  return messageTemplates.value.filter(template => template.requiresContext);
});

function closePopup() {
  tippy.value.hide();
  emit("closed");
}

function showProperties() {
  currentSidebar.value = "properties";
  tippy.value.hide();
  closePopup();
}

watch(() => selectionContext?.value?.refresh, () => {
  refresh();
});

watch(() => selectionContext?.value?.showTagPopup, (newValue) => {
  if (newValue && !documentFamily.value?.locked) {
    tippy.value.show();
    nextTick(() => {
      document.querySelector("#tagQueryInput")?.focus();
    });
  }
});

function replaceTag(tag: SelectedTag) {
  if (props.kioskMode || isExecutionResult.value) {
    return;
  }
  log.info("Replacing tag");
  if (useDocumentViewStore) {
    useDocumentViewStore.replaceTag(tag, selectionContext.value.selectedNodes).then(() => {
      tippy.value.hide();
      emit("closed");
    });
  } else {
    log.warn("No document view store");
  }
}

function removeTag(tag: SelectedTag) {
  if (props.kioskMode || documentFamily.value?.locked) {
    return;
  }
  log.info("Removing tag");
  if (useDocumentViewStore) {
    useDocumentViewStore.removeTag(tag).then(() => {
      tippy.value && tippy.value.hide();
      emit("closed");
    });
  } else {
    log.warn("No document view store");
  }
}

function addTagForDataObject(tag: TagMetadata, dataObjectTag: DataObjectTagMapping) {
  addTag(tag, {
    groupUuid: dataObjectTag.groupUuid,
    cellIndex: dataObjectTag.cellIndex,
  });
}

function addTag(tag: TagMetadata, tagData: TagData = {}) {
  if (props.kioskMode || documentFamily.value?.locked || isExecutionResult.value) {
    return;
  }
  if (useDocumentViewStore) {
    log.info("Adding tag");
    log.info(tagData);
    useDocumentViewStore.addTag(tag, selectionContext.value.selectedNodes, tagData);
    log.info("Sending update to re-render");
    tippy.value && tippy.value.hide();
    emit("closed");
  } else {
    log.warn("No document view store");
  }
}

function getClientRect() {
  // We want to get all the elements and then work out the bounding box
  // of all the elements
  const elements = selectionContext.value.selectedElements;

  const clientRect = {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  } as ClientRect;

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    const elementRect = element.getBoundingClientRect();
    if (i === 0) {
      clientRect.left = elementRect.left;
      clientRect.top = elementRect.top;
      clientRect.right = elementRect.right;
      clientRect.bottom = elementRect.bottom;
    } else {
      clientRect.left = Math.min(clientRect.left, elementRect.left);
      clientRect.top = Math.min(clientRect.top, elementRect.top);
      clientRect.right = Math.max(clientRect.right, elementRect.right);
      clientRect.bottom = Math.max(clientRect.bottom, elementRect.bottom);
    }
  }

  clientRect.width = clientRect.right - clientRect.left;
  clientRect.height = clientRect.bottom - clientRect.top;

  return clientRect;
}

const copySource = computed(() => {
  log.info("Selected value for the copy source", selectionContext.value.selectedValue);
  return selectionContext.value.selectedValue;
});
const {
  text,
  copy,
  copied,
} = useClipboard({ source: copySource, copiedDuring: 2000 });

const copiedText = computed(() => {
  return copied.value ? "Copied!" : "Copy to clipboard";
});

function markSectionBreak() {
  if (props.kioskMode || documentFamily.value?.locked) {
    return;
  }
  log.info("Marking section break");
  appStore.projectStore.addSectionGuidance(selectionContext.value, documentFamily.value);
  tippy.value && tippy.value.hide();
  notify({
    group: "generic",
    title: "Added guidance on section markers",
  }, 1000);
  emit("closed");
}

function openSemantic(selectedTag) {
  emit("selectedTaxonTag", selectedTag);
}

watch(() => tippy?.value, () => {
  if (tippy.value) {
    setTimeout(() => {
      document.addEventListener("click", (e) => {
        if (!tippy.value) {
          return;
        }

        // Check if the click is outside the tooltip
        if (!tippy.value.state.isVisible || tippy.value.contentElem.contains(e.target)) {
          return;
        }

        // Close the tooltip
        tippy.value.hide();
      });
    }, 500);
  } else {
    log.info("No tippy");
    document.removeEventListener("click", () => {
    });
  }
});

const allowLabeling = computed(() => {
  return !props.kioskMode && !documentFamily?.value?.locked;
});

function newLabel() {
  tippy.value.hide();
  emit("closed");
  showNewTaxon.value = true;
}

function newLinkedTask() {

}

const showMessageTemplateOptions = ref(false);
const selectedMessageTemplate = ref(undefined);

function sendTemplate(messageTemplate: any) {
  // If the message template first block is options then we
  // want to get them filled in before we send
  if (messageTemplate.messageBlock && messageTemplate.messageBlock.type === "optionForm") {
    tippy.value.hide();
    emit("closed");
    selectedMessageTemplate.value = messageTemplate;
    showMessageTemplateOptions.value = true;
  } else {
    appStore.workspaceStore.sendTemplateMessage(messageTemplate, props.activeSelectionView);
    tippy.value.hide();
    emit("closed");
  }
}

async function clearSelectedTags() {
  if (props.kioskMode || documentFamily.value?.locked || isExecutionResult.value) {
    return;
  }
  log.info("Removing tags");

  for (const tag of selectedTags.value) {
    await useDocumentViewStore.removeTag(tag);
  }

  tippy.value.hide();
  emit("closed");
}

const { user } = storeToRefs(appStore.userStore);

function showRawView(selectedTag: SelectedTag) {
  const dialog = createConfirmDialog(KodexaTagMetadata, {
    selectedTag,
  } as any);
  tippy.value.hide();
  dialog.reveal();
}
</script>

<template>
  <div>
    <Tippy
      ref="tippy" trigger="manual" :show-on-create="false" :get-reference-client-rect="() => getClientRect()"
      :interactive="true" placement="auto"
    >
      <template #content>
        <div
          v-if="!allowLabeling"
          class="animate-fadeIn border-2 border-gray-100 bg-white dark:bg-gray-800 p-1 shadow-xl animate-fast sm:rounded-lg"
        >
          <div class="m-4">
            <p>{{ selectionContext.selectedValue }}</p>
            <div class="mt-2">
              <MaterialDesignIcon name="content-copy" size="16" @click="copy()" />
              {{ copiedText }}
            </div>
          </div>
        </div>
        <div
          v-else style="width: 500px"
          class="animate-fadeIn border-2 border-gray-100 bg-white dark:bg-gray-800 p-1 shadow-xl animate-fast sm:rounded-lg"
        >
          <Combobox>
            <div v-if="isExecutionResult" class="rounded-md bg-red-50 mx-1 p-2">
              <div class="flex">
                <div class="flex-shrink-0">
                  <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="ml-3">
                  <h3 class="text-sm font-medium text-red-800">
                    You are labeling a test result, it can't be saved
                  </h3>
                </div>
              </div>
            </div>
            <KodexaTextInput
              v-if="allowLabeling"
              v-model="rawQuery"
              name="tagQueryInput"
              class="p-1"
              placeholder="Search..."
              :show-clear="true"
              @on-esc="closePopup"
              @change="rawQuery = $event.target.value"
            />
            <Toolbar class="border-0 bg-white dark:bg-gray-800">
              <KodexaButton
                v-if="allowLabeling && currentWorkspace.workspaceStorage.availablePanels.properties"
                icon="properties"
                size="small" type="secondary"
                title="Open properties"
                @click="showProperties"
              >
                Properties
              </KodexaButton>
              <KodexaButton
                v-if="allowLabeling"
                icon="content-copy"
                size="small"
                class="text-xs"
                title="Copy Selected Text"
                type="secondary" @click="copy()"
              >
                Copy
              </KodexaButton>
              <KodexaButton
                v-if="allowLabeling"
                icon="format-page-break"
                size="small"
                class="text-xs"
                title="Mark Section Break"
                type="secondary" @click="markSectionBreak"
              >
                Section Mark
              </KodexaButton>
              <KodexaButton
                v-if="allowLabeling"
                icon="plus"
                size="small"
                class="text-xs"
                title="Add to Data Definition"
                type="secondary" @click="newLabel"
              >
                New Data Element
              </KodexaButton>
            </Toolbar>
            <KodexaArticle
              article-id="9117946" text="Learn more about labeling, chats and guidance" :slide="false"
              class="mb-2 ml-2"
            />
            <ComboboxOptions
              v-slot="{ active }"
              static
              class="max-h-80 scroll-py-10 scroll-pb-2 px-4 pb-2"
              style="overflow-y: scroll; min-width: 300px"
            >
              <li class="-my-3 mb-3">
                <ul class="-mx-4 mt-1 text-sm text-gray-700">
                  <ComboboxOption v-if="rawQuery === '' && false" @click="newLinkedTask">
                    <li class="flex cursor-pointer select-none rounded-xl p-2">
                      <div
                        class="mt-2 size-7 rounded bg-green-300 p-1"
                      >
                        <MaterialDesignIcon
                          name="plus" class="m-1 text-white" aria-hidden="true"
                          size="12"
                        />
                      </div>
                      <div class="ml-4 flex-auto">
                        <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                          New Task
                        </p>
                        <p class="text-sm" :class="[active ? 'text-gray-700' : 'text-gray-500']">
                          Create a new linked task here
                        </p>
                      </div>
                    </li>
                  </ComboboxOption>
                  <ComboboxOption
                    v-for="messageTemplate in (rawQuery === '' ? finalTemplates : [])"
                    @click="sendTemplate(messageTemplate)"
                  >
                    <li class="flex cursor-pointer select-none rounded-xl p-2">
                      <div
                        class="mt-2 size-7 rounded bg-orange-400 p-1"
                      >
                        <MaterialDesignIcon
                          name="chat" class="m-1 text-white" aria-hidden="true"
                          size="12"
                        />
                      </div>
                      <div class="ml-4 flex-auto">
                        <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                          {{ messageTemplate.label }}
                        </p>
                        <p class="text-sm" :class="[active ? 'text-gray-700' : 'text-gray-500']">
                          {{ messageTemplate.description }}
                        </p>
                      </div>
                    </li>
                  </ComboboxOption>
                  <ComboboxOption v-if="selectedTags.length > 3" @click="clearSelectedTags">
                    <li class="flex cursor-pointer select-none rounded-xl p-2">
                      <div
                        class="mt-2 size-7 rounded bg-green-300 p-1"
                      >
                        <MaterialDesignIcon
                          name="minus-box-multiple-outline" class="m-1 text-white" aria-hidden="true"
                          size="12"
                        />
                      </div>
                      <div class="ml-4 flex-auto">
                        <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                          Clear Selected Labels
                        </p>
                        <p class="text-sm" :class="[active ? 'text-gray-700' : 'text-gray-500']">
                          Remove all the selected labels from the document
                        </p>
                      </div>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>
              <li v-if="possibleNoConfidenceTags.length > 0 && rawQuery === ''" class="my-2">
                <ul class="-mx-4 mt-1 text-sm text-gray-700">
                  <ComboboxOption
                    v-for="selectedTag in possibleNoConfidenceTags" :key="selectedTag.uuid" v-slot="{ active }"
                    as="template"
                  >
                    <li class="flex cursor-default select-none rounded-xl p-2">
                      <div
                        class="mt-2 size-7 rounded p-1"
                        :style="{ 'background-color': selectedTag.taxon.color }" @click="replaceTag(selectedTag)"
                      >
                        <MaterialDesignIcon
                          v-if="allowLabeling" name="file-replace-outline" class="mx-1 text-white"
                          aria-hidden="true"
                          size="12"
                        />
                      </div>
                      <div class="ml-4  flex-auto">
                        <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                          {{ selectedTag.taxon.label }}
                        </p>
                        <p class="text-sm" :class="[active ? 'text-gray-700' : 'text-gray-500']">
                          <KodexaTextContainer :text="`${selectedTag.value}`" />
                        </p>
                        <p
                          v-if="user.showDeveloperTools" class="text-xs text-gray-500"
                          @click="showRawView(selectedTag)"
                        >
                          View Feature Metadata
                        </p>
                      </div>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>
              <li v-if="selectedTags.length > 0 && rawQuery === ''" class="my-2">
                <ul class="-mx-4 mt-1 text-sm text-gray-700">
                  <ComboboxOption
                    v-for="selectedTag in selectedTags" :key="selectedTag.uuid" v-slot="{ active }" as="template"
                  >
                    <li class="flex cursor-default select-none rounded-xl p-2">
                      <div
                        class="mt-2 size-7 rounded p-1"
                        :style="{ 'background-color': selectedTag.taxon.color }" @click="removeTag(selectedTag)"
                      >
                        <MaterialDesignIcon
                          v-if="allowLabeling" name="minus" class="mx-1 text-white" aria-hidden="true"
                          size="12"
                        />
                      </div>
                      <div class="ml-4 flex justify-between w-full">
                        <div>
                          <!-- Grouped texts on the left -->
                          <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                            {{ selectedTag.taxon.label }}
                          </p>
                          <p class="text-sm" :class="[active ? 'text-gray-700' : 'text-gray-500']">
                            {{ selectedTag.parentLabel }}
                          </p>
                          <p class="my-1 font-light">
                            <KodexaTextContainer :text="getTagValue(selectedTag)" />
                          </p>
                          <p
                            v-if="user.showDeveloperTools" class="text-xs text-gray-500"
                            @click="showRawView(selectedTag)"
                          >
                            View Feature Metadata
                          </p>
                          <p>
                            <span
                              v-if="selectedTag.processing"
                              class="text-theme-primary ml-3 mt-1 inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-light"
                            >
                              Model
                            </span>
                          </p>
                        </div>

                        <div>
                          <!-- Button on the right -->
                          <KodexaButton
                            v-if="selectedTags.length > 0 && allowLabeling"
                            v-tooltip="`Open definition for ${selectedTag.taxon.label}`"
                            icon="book-open-outline"
                            class="text-xs"
                            size="small"
                            title="Open Definition for Data Element"
                            type="secondary"
                            @click="openSemantic(selectedTag)"
                          />
                        </div>
                      </div>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>
              <div v-if="rootTags.length > 0" class="mb-2">
                <div
                  class="sticky top-0 z-10 -mx-4 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 dark:bg-gray-900 py-1.5 text-sm font-semibold text-gray-900"
                >
                  <div class="ml-4">
                    Top-Level Labels
                  </div>
                </div>
                <ul class="-mx-4 text-sm text-gray-700">
                  <ComboboxOption
                    v-for="rootTag in rootTags" :key="rootTag.path" v-slot="{ active }" as="template"
                  >
                    <li
                      class="flex cursor-default select-none rounded-xl p-2" :class="[active && 'bg-gray-100']"
                      @click="addTag(rootTag, {})"
                    >
                      <div
                        class="flex size-7 flex-none rounded p-1"
                        :style="{ 'background-color': rootTag.taxon.color }"
                      >
                        <MaterialDesignIcon name="plus" class="mx-1 text-white" size="12" aria-hidden="true" />
                      </div>
                      <div class="ml-4 flex-auto">
                        <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                          {{ rootTag.taxon.label }}
                        </p>
                        <span
                          v-if="rootTag.processing"
                          class="text-theme-primary mr-1 mt-1 inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-light"
                        >Model</span>
                      </div>
                    </li>
                  </ComboboxOption>
                </ul>
              </div>
              <div v-for="dataObjectTag in dataObjectTagMapping" class="mb-2">
                <div
                  v-if="dataObjectTag.tags.length > 0"
                  class="sticky top-0 z-10 -mx-4 border-y border-b-gray-200 border-t-gray-100 bg-gray-50 dark:bg-gray-900 py-1.5 text-sm font-semibold text-gray-900"
                >
                  <div class="ml-4">
                    {{ dataObjectTag.tagMeta?.label }}
                  </div>
                </div>
                <ul class="-mx-4 text-sm text-gray-700">
                  <ComboboxOption
                    v-for="labelPopTag in dataObjectTag.tags" :key="labelPopTag.path" v-slot="{ active }" as="template"
                  >
                    <li
                      class="flex cursor-default select-none rounded-xl p-2" :class="[active && 'bg-gray-100']"
                      @click="addTagForDataObject(labelPopTag, dataObjectTag)"
                    >
                      <div
                        class="flex size-7 flex-none rounded p-1"
                        :style="{ 'background-color': labelPopTag.taxon.color }"
                      >
                        <MaterialDesignIcon name="plus" class="mx-1 text-white" size="12" aria-hidden="true" />
                      </div>
                      <div class="ml-4 flex-auto">
                        <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                          {{ labelPopTag.taxon.label }}
                        </p>
                        <span
                          v-if="labelPopTag.processing"
                          class="text-theme-primary mr-1 mt-1 inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-light"
                        >Model</span>
                        <p class="text-sm" :class="[active ? 'text-gray-700' : 'text-gray-500']">
                          {{ labelPopTag.parentLabel }}
                        </p>
                      </div>
                    </li>
                  </ComboboxOption>
                </ul>
              </div>
            </ComboboxOptions>

            <div v-if="rawQuery === '?'" class="px-6 py-14 text-center text-sm sm:px-14">
              <MaterialDesignIcon name="help" class="mx-auto size-6 text-gray-400" aria-hidden="true" />
              <p class="mt-4 font-semibold text-gray-900">
                Help with searching
              </p>
              <p class="mt-2 text-gray-500">
                Use this tool to quickly search for labels you can use on this document. You can also use
                the search modifiers found in the footer below to limit the results in various ways.
              </p>
            </div>

            <div
              v-if="rawQuery !== '' && rawQuery !== '?' && selectedTags.length === 0 && labelPopupTags.length === 0"
              class="px-6 py-14 text-center text-sm sm:px-14"
            >
              <MaterialDesignIcon name="alertBox" class="mx-auto size-6 text-gray-400" aria-hidden="true" />
              <p class="mt-4 font-semibold text-gray-900">
                No labels found
              </p>
              <p class="mt-2 text-gray-500">
                We couldn’t find labels with that term. Please try again.
              </p>
            </div>
          </Combobox>
        </div>
      </template>
    </Tippy>
    <NewTaxonPopup v-model="showNewTaxon" :view-id="activeSelectionView.viewId" />
    <KodexaMessageTemplateOptionsPopup
      v-model="showMessageTemplateOptions" :message-template="selectedMessageTemplate"
      :active-selection-view="activeSelectionView"
    />
  </div>
</template>

<style scoped>

</style>
