<script setup lang="ts">
import type { PropType } from "vue";
import type { Channel, Message, MessageBlock } from "~/model";
import KodexaMessageBlockThinking from "~/components/messages/kodexa-message-block-thinking.vue";
import KodexaMessageBlockComplete from "~/components/messages/kodexa-message-block-complete.vue";
import KodexaMessageBlockOptionForm from "~/components/messages/kodexa-message-block-option-form.vue";
import KodexaMessageBlockSection from "~/components/messages/kodexa-message-block-section.vue";
import KodexaMessageBlockError from "~/components/messages/kodexa-message-block-error.vue";
import KodexaMessageBlockProgress from "~/components/messages/kodexa-message-block-progress.vue";

defineProps({
  message: {
    type: Object as PropType<Message>,
    required: true,
  },
  channel: {
    type: Object as PropType<Channel>,
    required: false,
  },
  messageBlock: {
    type: Object as PropType<MessageBlock>,
    required: true,
  },
});

const blockTypes = {
  thinking: KodexaMessageBlockThinking,
  complete: KodexaMessageBlockComplete,
  taxonomy: KodexaMessageBlockTaxonomy,
  optionForm: KodexaMessageBlockOptionForm,
  section: KodexaMessageBlockSection,
  error: KodexaMessageBlockError,
  progress: KodexaMessageBlockProgress,
};
</script>

<template>
  <Component :is="blockTypes[messageBlock.type]" :message="message" :channel="channel" :message-block="messageBlock" />
</template>

<style scoped>

</style>
