<!--
  - Copyright (C) 2023 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { FilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import type { PropType, Ref } from "vue";
import type { DataAttribute, DataObject, SelectionOption } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { filterBy } from "@progress/kendo-data-query";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import { storeToRefs } from "pinia";
import KodexaNonEditableValue from "~/components/dataObject/types/kodexa-non-editable-value.vue";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const stringValue = computed({
  get() {
    const options = [];
    if (!props.attribute?.stringValue) {
      return options;
    }
    for (const value of props.attribute.stringValue.split(",")) {
      options.push(value.trim());
    }
    return options;
  },
  set(value) {
    emit("update", {
      ...props.attribute,
      stringValue: value.join(","),
      value: value.join(","),
    });
  },
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);
const valid = computed(() => {
  return props.attribute?.dataExceptions?.length === 0;
});

function selectionOptions() {
  const selectionOptions: SelectionOption[] | undefined = props.tagMetadata?.taxon.selectionOptions;
  if (!selectionOptions) {
    return [];
  }

  const optionList: string[] = [];
  for (const selectionOption of selectionOptions) {
    if (selectionOption.label) {
      optionList.push(selectionOption.label as string);
    }
  }
  return optionList as any[];
}

function focus() {
  dataViewHelper.focus();
  emit("focus");
}

const inputFilter: Ref<FilterDescriptor | undefined> = ref(undefined);
const selectionOptionValues = computed(() => {
  const allData = selectionOptions();
  if (inputFilter.value && inputFilter.value?.value.length > 3) {
    return filterBy(allData, inputFilter.value);
  } else {
    return allData;
  }
});
function onFilterChange(event) {
  inputFilter.value = event.filter;
}
const { style, conditionalValidationIcon } = storeToRefs(dataViewHelper);
</script>

<template>
  <div>
    <template v-if="props.editable">
      <MultiSelect
        :id="`kdx-attribute-${props.attribute.uuid}`"
        v-model="stringValue"
        :data-items="selectionOptionValues"
        :valid="valid"
        :fill-mode="null"
        :style="style"
        class="kodexa-input"
        rounded="medium"
        :filterable="true"
        @filterchange="onFilterChange"
        @focus="focus"
        @blur="dataViewHelper.blur()"
      />
    </template>
    <template v-else>
      <KodexaNonEditableValue
        v-if="!editable"
        :style="style" :conditional-validation-icon="conditionalValidationIcon"
        :string-value="stringValue.join(', ')"
      />
    </template>
  </div>
</template>

<style scoped>
.kodexa-input {
  @apply w-full appearance-none rounded-md border border-gray-300
  px-0 py-0 shadow-sm placeholder:text-gray-400 sm:text-sm
}

.kodexa-input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}
.kodexa-input:focus {
  @apply border-blue-500 ring-1 ring-blue-500
}
</style>
