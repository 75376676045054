<script setup lang="ts">
import type { ProjectTag } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useListProjectTags } from "~/api/project-tag/project-tag";
import appStore from "~/store";

const { currentOrganization } = storeToRefs(appStore.organizationStore);

// Setup our grid helpers
const gridHelper = createGridHelper(`project-tags-${currentOrganization.value?.id}`, {
  pageSize: 10,
  filter: "",
  sort: "",
  query: "",
  page: 1,
}, `organization.id: '${currentOrganization.value.id}'`);
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListProjectTags(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
    gridHelper.saveState();
  }
});

const columns = [
  {
    title: "Project Tag",
    field: "name",
    cell: "projectTag",
  },
  {
    title: "Color",
    field: "color",
    cell: "projectColor",
  },
];
const showCreateProjectTag = ref(false);
const showEditProjectTag = ref(false);
const selectedProjectTag = ref<ProjectTag>();

function openNew() {
  showCreateProjectTag.value = true;
}

function editProjectTag(projectTag: ProjectTag) {
  selectedProjectTag.value = projectTag;
  showEditProjectTag.value = true;
}
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <Toolbar class="border-0 bg-white dark:bg-gray-800">
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50" @click="gridHelper.toggleFilter()" />
        <KodexaArticle class="mt-1" article-id="9095335" text="Learn more about project tags" :slide="true" />

        <ToolbarSpacer />
        <KodexaButton id="newProjectTag" icon="plus" @click="openNew">
          New Project Tag
        </KodexaButton>
      </Toolbar>
      <Grid
        style="height: calc(100vh - 15rem);"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error" :is-loading="isLoading" :is-error="isError"
            no-records-message="No project tags found"
          />
        </GridNoRecords>
        <template #projectTag="{ props }">
          <td>
            <div class="flex items-center">
              <div class="flex-0 cursor-pointer text-lg text-gray-900" @click="editProjectTag(props.dataItem)">
                {{ props.dataItem?.name }}
              </div>
            </div>
          </td>
        </template>
        <template #projectColor="{ props }">
          <td class="px-4 py-2">
            <div class="flex items-center">
              <div class="mr-2 size-6 rounded-full" :style="`background-color: ${props.dataItem.color}`" />
              <span class="text-sm text-gray-700" style="background-color: white">{{ props.dataItem.color }}</span>
            </div>
          </td>
        </template>
      </Grid>
      <NewProjectTag v-model="showCreateProjectTag" @refetch-project-tag="refetch" />
      <EditProjectTag v-if="selectedProjectTag" v-model="showEditProjectTag" :project-tag="selectedProjectTag" @refetch-project-tag="refetch" />
    </div>
  </div>
</template>
