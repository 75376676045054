<script lang="ts" setup>
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import { onUnmounted, ref } from "vue";

const props = defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
});
const maximised = ref(props.params.api.isMaximized());

const disposable = props.params.containerApi.onDidMaximizedGroupChange(
  () => {
    maximised.value = props.params.api.isMaximized();
  },
);

// Cleanup the event listener when component is unmounted
onUnmounted(() => {
  disposable.dispose();
});

function onClick() {
  if (maximised.value) {
    props.params.api.exitMaximized();
  } else {
    props.params.api.maximize();
  }
}
</script>

<template>
  <div>
    <MaterialDesignIcon
      :name="maximised ? 'window-restore' : 'window-maximize'"
      class="mr-1 ml-1"
      @click="onClick"
    />
  </div>
</template>

<style scoped>

</style>
