<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Card, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { createDataFormViewerStore } from "~/store/useDataFormView";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const useDataFormViewer = createDataFormViewerStore(props.viewId);
const { dataObjects } = storeToRefs(useDataFormViewer);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);
const groupTaxonMetadata = computed(() => {
  return tagMetadataMap.value.get(props.card.properties?.groupTaxon);
});

// Then we will filter the data objects for the parent data object so we can get the
// attributes
const scopedDataObjects = computed(() => {
  if (!groupTaxonMetadata.value) {
    return [];
  }

  let scopedDataObjects = dataObjects.value.filter((dataObject) => {
    return dataObject.path === groupTaxonMetadata.value.parentPath;
  });

  if (props.parentDataObject) {
    scopedDataObjects = scopedDataObjects.filter((dataObject) => {
      return dataObject.parentId === props.parentDataObject?.id;
    });
  }

  return scopedDataObjects;
});
</script>

<template>
  <div>
    <KodexaDataObjectGrid v-if="groupTaxonMetadata" :data-objects="scopedDataObjects" :group-taxon-metadata="groupTaxonMetadata" />
  </div>
</template>

<style scoped>
</style>
