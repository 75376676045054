<script setup lang="ts">
import type { Store } from "~/model";
import { arrowRotateCwIcon, filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import appStore from "~/store";

const props = defineProps({
  modelStoreRef: {
    type: String,
    required: true,
  },
});

const {
  project,
  modelStores,
} = storeToRefs(appStore.projectStore);
const modelStore = ref(modelStores.value.find((ds: Store) => ds.ref === props.modelStoreRef));

// Setup our grid helpers
const gridHelper = createGridHelper(`project-trainings-${modelStore.value._id}`, {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "",
}, `store.id: '${modelStore.value._id}'`);
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListTrainings(modelStore.value.orgSlug, modelStore.value.slug, modelStore.value.version, gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
  }
});

const columns = [
  {
    title: "Training Name",
    field: "name",
    width: "200px",
  },
  {
    title: "State",
    field: "state",
    width: "150px",
  },
  {
    title: "Training Date",
    field: "trainingDate",
    width: "150px",
  },
  {
    title: "Training Materials Available",
    field: "trainingMaterialsGenerated",
    width: "150px",
  },
  {
    title: "",
    cell: "actions",
    filterable: false,
    sortable: false,
    width: "150px",
  },
];

function setQuery(query: string) {
  gridHelper.setQuery(query);
  gridHelper.pageChangeHandler({ page: 1 });
}

function updateQuery() {
  refetch();
}

function onDownloadTraining(training: ModelTraining) {
  downloadTrainingContent(modelStore.value.orgSlug, modelStore.value.slug, modelStore.value.version, training.id as string).then((blob: Blob) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "training.zip";
    link.click();
    URL.revokeObjectURL(link.href);
  });
}
</script>

<template>
  <div>
    <KodexaHeader :title="modelStore.name" class="mb-6" object-type="documentStore">
      <template #subTitle>
        {{ project.name }}
      </template>
    </KodexaHeader>
    <Toolbar class="mb-1">
      <Button :svg-icon="arrowRotateCwIcon" title="Refresh" :togglable="false" class=" dark:text-gray-50" @click="updateQuery()" />
      <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50" @click="gridHelper.toggleFilter()" />
      <label for="query" class="sr-only">Query</label>
      <input
        id="query" v-debounce:300ms="setQuery" type="text" name="query"
        class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Query..."
      >
    </Toolbar>
    <Grid
      style="height: calc(100vh - 250px);"
      :data-items="data ? data.content : []"
      :pageable="pageSettings"
      :loader="isLoading"
      :sort="sort"
      :skip="pageSettings.skip as number"
      :total="pageSettings.total as number"
      :page-size="pageSettings.take as number"
      :columns="columns"
      :filterable="showFilter"
      :filter="filter"
      :resizable="true"
      :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No trainings found"
        />
        <template #actions="{ props }">
          <td>
            <KodexaButton id="downloadTraining" @click="onDownloadTraining(props.dataItem)">
              Download
            </KodexaButton>
          </td>
        </template>
      </GridNoRecords>
    </Grid>
  </div>
</template>

<style scoped>

</style>
