<script setup lang="ts">
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";

defineProps({
  modelValue: Boolean,
  isDashboard: Boolean,
  dashboard: Object,
  allowDelete: {
    type: Boolean,
    default: true,
  },
  entryPoints: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "delete"]);
const showWidgetLibrary = ref(false);
</script>

<template>
  <Toolbar class="h-14 bg-white dark:bg-gray-800">
    <KodexaButton
      id="toggleDashboardProperties"
      icon="properties"
      size="small" type="secondary"
      @click="emit('update:modelValue', !modelValue)"
    >
      {{ modelValue ? 'Hide' : 'Show' }} Properties
    </KodexaButton>
    <KodexaButton
      v-if="dashboard.singleWidget === undefined"
      id="showDashboardWidgetLibrary"
      icon="plus"
      size="small" type="secondary"
      @click="showWidgetLibrary = true"
    >
      Add New Widget
    </KodexaButton>
    <KodexaButton
      v-if="isDashboard && allowDelete && modelValue"
      id="deleteDashboard"
      icon="delete"
      size="small" type="danger"
      @click="emit('delete')"
    >
      Delete Dashboard
    </KodexaButton>
    <ToolbarSpacer />
    <KodexaDashboardWidgetLibraryPopup v-model="showWidgetLibrary" :dashboard="dashboard" :entry-point="entryPoint" />
  </Toolbar>
</template>

<style scoped>

</style>
