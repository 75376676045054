<script setup lang="ts">
import type { TaskActivity, TaskActivityDetail } from "~/model";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { storeToRefs } from "pinia";
import { computed, nextTick, watch } from "vue";
import { useGetMembers } from "~/api/organizations/organizations";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";
import appStore from "~/store";

const emit = defineEmits(["update:currentTask"]);

const { currentTask, taskActivities } = storeToRefs(appStore.taskStore);

const localTask = computed({
  get: () => currentTask.value,
  set: () => appStore.taskStore.saveChanges(),
});

const taskDueDate = computed({
  get: () => localTask.value?.dueDate ? new Date(localTask.value.dueDate) : null,
  set: (value: Date | null) => {
    localTask.value.dueDate = value?.toISOString() || null;
    emit("update:currentTask", localTask.value);
  },
});

function adjustTextareaHeight() {
  nextTick(() => {
    const textarea = document.querySelector("textarea");
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  });
}

watch(() => localTask.value.description, adjustTextareaHeight, { immediate: true });

const { project } = storeToRefs(appStore.projectStore);
const today = new Date();

const newComment = ref("");

function addComment() {
  if (newComment.value) {
    appStore.taskStore.addActivity({
      details: {
        type: "COMMENT",
      } as TaskActivityDetail,
      comment: newComment.value,
    } as TaskActivity);
    newComment.value = "";
  }
}
</script>

<template>
  <div class="grid grid-cols-1 lg:grid-cols-12 gap-6">
    <!-- Main Content -->
    <div class="col-span-1 lg:col-span-8 xl:col-span-9 space-y-4">
      <KodexaTextInput
        v-model="localTask.title"
        borderless
        font-size="xl"
        font-weight="bold"
        name="task-title"
        class="w-full border-none focus:outline-none focus:ring-0 placeholder-gray-400"
        placeholder="Task Name"
      />
      <textarea
        v-model="localTask.description"
        class="w-full min-h-[24px] text-gray-500 border-none focus:outline-none focus:ring-0 placeholder-gray-400 resize-none transition-all duration-100 ease-in-out"
        placeholder="Add description..."
        rows="1"
      />
      <!-- Activity Logs -->
      <div class="mt-8 pt-8 space-y-4 border-t border-gray-200">
        <h3 class="text-lg font-bold">
          Activity
        </h3>
        <div class="space-y-2">
          <div
            v-for="activity in taskActivities"
            :key="`${activity.id}-${activity.createdOn}`"
            class="flex items-start gap-x-3 text-sm"
          >
            <TaskActivity :activity="activity" />
          </div>
        </div>
      </div>

      <!-- Adding a new "comment" which is a type of activity -->
      <div class="mt-8 pt-8 border-t border-gray-200">
        <div class="flex items center gap-4">
          <KodexaUserAvatar :user="appStore.userStore.user" size="8" />
          <div class="w-full">
            <textarea
              v-model="newComment"
              class="w-full min-h-[24px] text-gray-500 border-none focus:outline-none focus:ring-0 placeholder-gray-400 resize-none transition-all duration-100 ease-in-out"
              placeholder="Add a comment..."
              rows="1"
            />
            <div class="flex justify-end mt-2">
              <KodexaButton
                v-if="newComment"
                icon="send"
                size="small"
                @click="addComment"
              >
                Add Comment
              </KodexaButton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Sidebar -->
    <div class="col-span-1 lg:col-span-4 xl:col-span-3 h-auto lg:h-[90vh] px-4 lg:px-6 py-6 bg-white dark:bg-gray-800">
      <div v-if="localTask" class="space-y-6">
        <!-- Status -->
        <div>
          <KodexaDropDown
            v-model="localTask.status"
            name="status"
            :items="project.taskStatuses"
            text-field="label"
            grid-prefix="task-status"
            placeholder="Select status..."
            :is-object="true"
          />
        </div>

        <!-- Assignee -->
        <div>
          <KodexaDynamicDropDown
            v-model="localTask.assignee"
            name="assignee"
            :query-fn="useGetMembers.bind(null, project.organization.id)"
            text-field="fullName"
            grid-prefix="`task-assignee-${project.organization.id}`"
            placeholder="Select assignee..."
            class="mt-1"
            :is-object="true"
          >
            <template #item-render="{ item }">
              <div class="flex items-center gap-2">
                <KodexaUserAvatar
                  :user="item.user"
                  size="6"
                  class="flex-shrink-0"
                />
                <div class="flex flex-col overflow-hidden">
                  <span class="font-medium" :title="item.name">{{ item.name }}</span>
                  <span class="text-xs text-gray-500" :title="item.email">{{ item.email }}</span>
                </div>
              </div>
            </template>
            <template #value-render="{ item }">
              <div class="flex items-center gap-2">
                <KodexaUserAvatar
                  v-if="item?.user"
                  :user="item.user"
                  size="6"
                  class="flex-shrink-0"
                />
                <span class="font-medium truncate" :title="item?.name">{{ item?.name }}</span>
              </div>
            </template>
          </KodexaDynamicDropDown>
        </div>

        <!-- Due Date -->
        <div>
          <h3 class="text-gray-500 mb-2">
            Due
          </h3>
          <DatePicker
            v-model="taskDueDate"
            :min="today"
            class="w-full date-picker-custom"
            :clearable="true"
            placeholder="Set Due Date"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
:deep(.kodexa-input .k-input-inner::placeholder) {
  @apply font-normal text-gray-400 !important;
}
</style>
