<script setup lang="ts">
import type { PropType } from "vue";
import type { DashboardWidget, Taxon } from "~/model";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import appStore from "~/store";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

const {
  taxonomies,
  projectLoading,
} = storeToRefs(appStore.projectStore);

const taxonomyRef = ref(null);
const cachedTaxonomy = ref(null);
const refresher = ref(uuidv4());

// We have to deep watch the taxonomies to make sure we update the selected taxonomy
// when the taxonomies change
watch(taxonomies, () => {
  if (taxonomyRef.value === null && taxonomies.value && taxonomies.value.length > 0) {
    taxonomyRef.value = taxonomies.value[0].ref;
  }
  if (taxonomyRef.value) {
    cachedTaxonomy.value = taxonomies.value.find((taxonomy: any) => taxonomy.ref === taxonomyRef.value);
    refresher.value = uuidv4();
  } else {
    cachedTaxonomy.value = undefined;
  }
}, { deep: true, immediate: true });

const selectedTaxonomy = computed(() => {
  if (refresher.value) {
    return cachedTaxonomy.value;
  } else {
    return undefined;
  }
});

const selectedTaxonId = ref(undefined);

// We need a function to recursively go through a taxonomy taxons and their
// children to find the selected taxon
const selectedTaxon = computed({
  get: () => {
    if (selectedTaxonId.value && selectedTaxonomy.value && refresher.value) {
      return appStore.projectStore.findSelectedTaxonById(selectedTaxonId.value, selectedTaxonomy.value.ref);
    }
    return undefined;
  },
  set: (value: Taxon) => {
    if (value && selectedTaxonomy.value) {
      appStore.projectStore.findSelectedTaxonById(selectedTaxonId.value, selectedTaxonomy.value.ref, value);
      refresher.value = uuidv4();
    }
  },
});

function taxonSelected(taxon: any) {
  selectedTaxonId.value = taxon.id;
  selectedTaxon.value = taxon;
}

function deleteSelected() {
  if (selectedTaxon.value && selectedTaxonomy.value) {
    appStore.workspaceStore.addUpdatedTaxonomyRef(selectedTaxonomy.value.ref as string);

    // We need to find the parent of the selected taxon
    const parent = appStore.projectStore.findParentTaxonFromTaxons(selectedTaxon.value.id, selectedTaxonomy.value.taxons);
    if (parent.id === selectedTaxon.value.id) {
      // We are deleting the root taxon
      appStore.projectStore.deleteTaxonFromTaxonomy(selectedTaxonomy.value.ref as string, undefined, selectedTaxon.value.id);
      selectedTaxonId.value = undefined;
    } else {
      appStore.projectStore.deleteTaxonFromTaxonomy(selectedTaxonomy.value.ref as string, parent === true ? undefined : parent.id, selectedTaxon.value.id);
      selectedTaxonId.value = undefined;
    }
  }
}
</script>

<template>
  <div class="bg-gray-50 dark:bg-gray-900">
    <div class="flex border-r bg-gray-50 dark:bg-gray-900">
      <div class="mb-2 flex-1 px-4 py-3">
        <div style="height: calc(100vh - 9rem); overflow: auto" class="flex-1 bg-gray-50 dark:bg-gray-900">
          <KodexaDropDown
            v-model="taxonomyRef" name="taxonomies" :loading="projectLoading" :items="taxonomies"
            value-field="ref" text-field="name" class="mt-4"
          />
          <div style="overflow-y: scroll !important; height: calc(100vh - 11rem)">
            <TaxonomyTree v-if="selectedTaxonomy" :taxonomy="selectedTaxonomy" @select-taxon="taxonSelected" />
          </div>
        </div>
      </div>
      <div class="w-3/5">
        <div v-if="selectedTaxon" style="height: calc(100vh - 9rem); overflow: auto" class="flex-1 bg-gray-50 dark:bg-gray-900 py-3">
          <div>
            <div class="flex">
              <div class="mx-2 mt-3">
                <div class="text-lg">
                  {{ selectedTaxon.label }}
                </div>
                <div class="text-sm text-gray-500">
                  {{ selectedTaxonomy.name }}
                </div>
              </div>
              <div class="absolute right-2 mt-3">
                <MaterialDesignIcon
                  name="tag" size="34" :style="{
                    color: selectedTaxon.color,
                  }"
                />
              </div>
            </div>
            <TaxonDetails v-model="selectedTaxon" @delete="deleteSelected" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
