<script lang="ts" setup>
import { storeToRefs } from "pinia";
import appStore from "~/store";

const {
  activeSelectionView,
} = storeToRefs(appStore.workspaceStore);
</script>

<template>
  <div>
    <div class="flex h-full flex-col bg-white dark:bg-gray-800">
      <template v-if="activeSelectionView">
        <KodexaDocumentSearch :view-id="activeSelectionView.viewId" />
      </template>
      <template v-else>
        <div class="text-center mt-12">
          <svg
            class="mx-auto size-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vector-effect="non-scaling-stroke"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15.5 14.5L19 18M10 15.5C6.41015 15.5 3.5 12.5899 3.5 9C3.5 5.41015 6.41015 2.5 10 2.5C13.5899 2.5 16.5 5.41015 16.5 9C16.5 12.5899 13.5899 15.5 10 15.5ZM10 6V12M7 9H13"
            />
          </svg>
          <h3 class="mt-2 text-sm font-semibold text-gray-900 dark:text-gray-100">
            No Selected View
          </h3>
          <p class="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Select a searchable view
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>

</style>
