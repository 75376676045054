<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { AttributeCheckboxOption } from "~/components/dataForm/attribute-checkbox-option";
import type { AttributeMaskedTextOption } from "~/components/dataForm/attribute-maskedtext-option";
import type { AttributeTaxonOverrideOptions } from "~/components/dataForm/attribute-taxon-override-options";
import type { AttributeEditorOptions } from "~/components/dataObject/attribute-editor-options";
import type { DataAttribute, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import type { DataFormViewer, DocumentViewer } from "~/store/useWorkspace";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import appStore from "~/store";
import { log } from "~/utils/logger";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  taxonOverrideOptions: {
    type: Object as PropType<AttributeTaxonOverrideOptions>,
    required: false,
    default: () => {
      return {} as AttributeTaxonOverrideOptions;
    },
  },
  maskedTextOptions: {
    type: Object as PropType<AttributeMaskedTextOption>,
    required: false,
    default: () => {
      return {} as AttributeMaskedTextOption;
    },
  },
  checkboxOptions: {
    type: Object as PropType<AttributeCheckboxOption>,
    required: false,
    default: () => {
      return {} as AttributeCheckboxOption;
    },
  },
  editorOptions: {
    type: Object as PropType<AttributeEditorOptions>,
    required: false,
    default: () => {
      return {} as AttributeEditorOptions;
    },
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const tempUuid = ref(uuidv4());
const { views } = storeToRefs(appStore.workspaceStore);
const view = computed(() => views.value.find((view: DataFormViewer | DocumentViewer) => view.id === props.viewId));

function addAttribute(uuid = uuidv4()) {
  if (view.value) {
    const useDataFormViewer = createDataFormViewerStore(view.value.id);
    return useDataFormViewer.addAttribute(props.tagMetadata, props.dataObject, uuid);
  } else {
    log.info("No view found for add attribute");
    return undefined;
  }
}

function updateAttribute(attribute: DataAttribute) {
  if (view.value) {
    const useDataFormViewer = createDataFormViewerStore(view.value.id);
    // If we are updating the temp attribute, we need to replace it with the real one
    const attributeInstances = props.dataObject.attributes?.filter(attribute => attribute.tag === props.tagMetadata.taxon.name);
    if (attribute.uuid === tempUuid.value && attributeInstances && attributeInstances.length === 0) {
      const newAttribute = addAttribute(tempUuid.value);
      tempUuid.value = uuidv4();
      if (newAttribute) {
        newAttribute.value = attribute.value;
        newAttribute.stringValue = attribute.stringValue;
        newAttribute.dateValue = attribute.dateValue;
        newAttribute.booleanValue = attribute.booleanValue;
        newAttribute.decimalValue = attribute.decimalValue;
        appStore.workspaceStore.setFocusedAttribute(newAttribute);
        useDataFormViewer.updateAttribute(props.dataObject, newAttribute);
      }
    } else {
      useDataFormViewer.updateAttribute(props.dataObject, attribute);
    }
  }
}

// This temp attribute is used when we don't have an attribute yet
// but we want to display the control, once there is a change to it
// we will replace it with the real attribute
const tempAttribute = computed(() => {
  return {
    uuid: tempUuid.value,
    stringValue: "",
    tagUuid: "xxxx",
    tag: props.tagMetadata.taxon.name,
    path: props.tagMetadata.taxon.path,
    typeAtCreation: props.tagMetadata.taxon.taxonType,
    dataExceptions: [],
    dataObject: {
      uuid: props.dataObject.uuid,
    } as DataObject,
  } as DataAttribute;
});

const attributes = computed(() => {
  // if we don't have any attributes then we are going to return a temporary one
  const useDataFormViewer = createDataFormViewerStore(view.value.id);
  const { dataObjects } = storeToRefs(useDataFormViewer);
  if (!props.dataObject || !props.tagMetadata) {
    return [];
  }
  const dataObject = dataObjects.value.find(dato => dato.uuid === props.dataObject.uuid);

  const finalDataObject = dataObject || props.dataObject;

  // if we don't have any attributes then we are going to return a temporary one
  const attributeInstances = finalDataObject.attributes?.filter(attribute => attribute.tag === props.tagMetadata.taxon.name);
  if (!attributeInstances || attributeInstances.length === 0) {
    return [tempAttribute.value];
  } else {
    return attributeInstances;
  }
});

const sortedAttributes = computed(() => {
  // Make a copy of attributes

  return [...attributes.value].sort((a1: DataAttribute, a2: DataAttribute) => {
    // If the attributes have a page_number and line_number in dataFeatures then we need to
    // sort by page_number first and then line_number
    if (a1.dataFeatures && a2.dataFeatures) {
      const a1PageNumber = a1.dataFeatures.page_number;
      const a2PageNumber = a2.dataFeatures.page_number;
      if (a1PageNumber && a2PageNumber) {
        if (a1PageNumber > a2PageNumber) {
          return 1;
        } else if (a1PageNumber < a2PageNumber) {
          return -1;
        } else {
          const a1LineNumber = a1.dataFeatures.line_number;
          const a2LineNumber = a2.dataFeatures.line_number;
          if (a1LineNumber && a2LineNumber) {
            if (a1LineNumber > a2LineNumber) {
              return 1;
            } else if (a1LineNumber < a2LineNumber) {
              return -1;
            } else {
              return 0;
            }
          }
        }
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  });
});
</script>

<template>
  <div>
    <div v-for="attribute in sortedAttributes" :key="attribute.uuid" :data-docname="dataObject.documentFamily.path" :data-path="attribute.path" class="mb-1">
      <KodexaReadOnlyAttributeControl
        :data-key-attribute="attribute.uuid"
        :data-object="dataObject"
        :attribute="attribute"
        :tag-metadata="tagMetadata"
        :taxon-override-options="taxonOverrideOptions"
        :view-id="viewId"
        :masked-text-options="maskedTextOptions"
        :checkbox-options="checkboxOptions"
        :editor-options="editorOptions"
        :placeholder-attribute="attribute.uuid === tempUuid"
        @update="updateAttribute"
        @delete-data-object="emit('deleteDataObject', $event)"
        @add-data-object="emit('addDataObject', $event)"
      />
    </div>
  </div>
</template>

<style scoped>
</style>
