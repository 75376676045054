import { defineStore, storeToRefs } from "pinia";
import appStore from "~/store/index";

declare global {
  interface Window {
    Intercom: (
      command: "trackEvent" | "update" | "show" | "hide" | "showArticle" | "boot",
      ...args: any[]
    ) => void;
  }
}

export const useIntercom = defineStore("intercom", () => {
  const { user } = storeToRefs(appStore.userStore);

  function trackEvent(eventName: string, eventProperties: any | undefined = undefined) {
    window.Intercom("trackEvent", eventName, eventProperties);
  }

  function update() {
    window.Intercom("update");
  }

  function show() {
    window.Intercom("show");
  }

  function hide() {
    window.Intercom("hide");
  }

  function showArticle(articleId: string) {
    window.Intercom("showArticle", articleId);
  }

  watch(user, () => {
    if (user.value) {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "lpwv255d",
        name: `${user.value.firstName} ${user.value.lastName}`, // Full name
        email: user.value.email, // Email address
        created_at: user.value.createdAt,
        user_id: user.value.email,
        user_hash: user.value.userHash,
      });
    }
  }, {
    immediate: true,
  });

  return {
    hide,
    show,
    trackEvent,
    update,
    showArticle,
  };
});
