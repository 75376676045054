<script lang="ts" setup>
import type { PropType, Ref } from "vue";
import type { DashboardWidget } from "~/model";
import { Splitter, type SplitterPaneProps } from "@progress/kendo-vue-layout";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import appStore from "~/store";
import ChannelPanel from "~/views/studio/organizations/project/workspace/panels/channel-panel.vue";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

const {
  project,
} = storeToRefs(appStore.projectStore);

const panes: Ref<SplitterPaneProps[]> = ref([
  {
    size: "50%",
    min: "0%",
    collapsible: true,
    content: "sidebar",
  },
  {
    min: "0%",
    collapsible: true,
    content: "sidecar",
  },
] as SplitterPaneProps[]);

function onSplitterChange(changeEvent: any) {
  if (changeEvent.newState) {
    panes.value = changeEvent.newState;
  }
}
</script>

<template>
  <Splitter
    v-if="panes"
    :panes="panes"
    style="height: calc(100vh - 8rem); width: 100%; overflow-y: hidden;"
    orientation="horizontal"
    @change="onSplitterChange"
  >
    <template #sidebar>
      <div style="height: calc(100vh - 8rem); width: 100%; overflow-y: hidden;" class="bg-gray-50 dark:bg-gray-900">
        <ChannelPanel entrypoint="project" />
      </div>
    </template>
    <template #sidecar />
  </splitter>
</template>
