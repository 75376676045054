import type { Offset } from "@progress/kendo-vue-popup";
import type { SelectionEvent } from "@viselect/vanilla";
import type { ILogObj } from "tslog";
import type { Ref } from "vue";
import type { ContentNode, LineContent, ModelInsight, SelectedTag, TagData } from "~/components/document/document";
import type { ContentObject, DataAttribute, DocumentFamily, Store } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import type { DocumentFeatureSet, DocumentViewer, TagInstance } from "~/store/useWorkspace";
import SelectionArea from "@viselect/vanilla";
import { defineStore, storeToRefs } from "pinia";
import { Logger } from "tslog";
import { v4 as uuidv4 } from "uuid";
import { getContentObjectContentWithVersion } from "~/api/stores/stores";
import { KddbDocument } from "~/components/document/document";
import appStore from "~/store/index";
import { log } from "~/utils/logger";
import { RefHelper } from "~/utils/ref-utils";

export interface SelectionContext extends Offset {
  selectedNodes: ContentNode[];
  selectedElements: Element[];
  showTagPopup: boolean;
  editable: boolean;
  left: number;
  top: number;
  refresh: string;
  selectedValue: string;
}

export interface FindLineHit {
  uuid: string;
  content: string;
  idx: number;
  pageNumber: number;
}

const notParsed = ref(false);

export function createDocumentViewerStore(viewId: string) {
  const viewById: DocumentViewer = appStore.workspaceStore.getViewById(viewId);
  if (!viewById) {
    log.warn(`Unable to find view with id ${viewId}`);
    return;
  }

  return defineStore(`documentViewer-${viewId}`, () => {
    const log: Logger<ILogObj> = new Logger();

    log.info("Loading document view store");
    const project = useProject();
    const documentViewer: Ref<DocumentViewer> = ref(viewById);
    const kddbDocument: Ref<KddbDocument | undefined> = ref();
    const page = ref(0);
    const numPages = ref(1);
    const imageWidth = ref(500);
    const imageHeight = ref(500);
    const pageImage: Ref<string | undefined> = ref();
    const modelInsights: Ref<ModelInsight[]> = ref([]);
    const contentExceptions: Ref<any | undefined> = ref();
    const selection: Ref<SelectionArea | undefined> = ref();
    const documentLines: Ref<LineContent[]> = ref([]);
    const changeSequence = ref(0);
    const documentLoading = ref(false);
    const selectionContext: Ref<SelectionContext> = ref({
      selectedNodes: [] as ContentNode[],
      selectedElements: [],
      showTagPopup: false,
      editable: true,
      top: 0,
      left: 0,
      refresh: uuidv4(),
      selectedValue: "",
    } as SelectionContext);
    const pageText = ref("");

    const nativeDocument: Ref<Uint8Array | undefined> = ref();

    const taggedInstances: Ref<TagInstance[]> = ref([]);
    const documentFamilyId: Ref<string | undefined> = ref(viewById.documentFamilyId);

    const { documentFamilies } = storeToRefs(appStore.workspaceStore);
    const documentStore: Ref<Store | undefined> = ref(undefined);
    const documentFamily = computed(() => {
      const df: DocumentFamily | undefined = Array.from(documentFamilies.value.values()).find(df => df.id === documentFamilyId.value);
      if (df) {
        documentStore.value = appStore.workspaceStore.getStoreByRef(df.storeRef);
      }
      return df as DocumentFamily;
    });

    const { focusTagUuid } = storeToRefs(appStore.workspaceStore);
    const loadingMessage = ref("");
    const { tagMetadataMap } = storeToRefs(appStore.projectStore);

    const isSidecar = computed(() => {
      return documentViewer.value.isSidecar as boolean;
    });

    const pageTags = computed(() => {
      // Dont' try and query the page node while the document is loading
      if (documentLoading.value) {
        return [];
      }

      log.info(`Loading page tags ${selectionContext.value.refresh}`);

      const pageNode = getPageNode(page.value);
      return pageNode ? pageNode.getTagGroups(tagMetadataMap.value) : [];
    });

    const noConfidenceTags: Ref<TagInstance[]> = ref([]);

    watch(focusTagUuid, (uuid) => {
      // We need to find a node with the tag, and then we
      // need to work out if we need to change the page number

      if (kddbDocument.value && uuid) {
        const nodes = kddbDocument.value.findNodesByTagUuid(uuid);
        log.info(`Found ${nodes.length} nodes with tag ${uuid}`);
        if (nodes.length > 0) {
          const node = nodes[0];
          const page = node.getPage(node);
          log.info(`Found page ${page?.index} for focused tag ${uuid}`);
          if (page) {
            setPage(page.index);

            // We want to set the selection context to the node we are focusing on
            nextTick(() => {
              const nodeElements = nodes.map(n => document.getElementById(`node-${n.uuid}`)) as Element[];
              selectionContext.value.selectedNodes = nodes;
              selectionContext.value.selectedValue = kddbDocument.value?.getNodeText(nodes) || "";
              if (nodeElements) {
                selectionContext.value.selectedElements = nodeElements;

                // // We need to work out the position of the node
                // scrollIntoView(nodeElements[0]);

                const nodeRect = nodeElements.length > 0 && nodeElements[0] ? nodeElements[0].getBoundingClientRect() : undefined;
                if (nodeRect) {
                  void nextTick(() => {
                    selectionContext.value.top = nodeRect.top;
                    selectionContext.value.left = nodeRect.left;
                    selectionContext.value.refresh = uuidv4();
                  });
                }
              }
            }).catch((error) => {
              log.error("Unable to set selection context [1]");
              log.error(error);
            });
          }
        }
      }
    });

    function getLatestContentObject(): ContentObject | undefined {
      if (documentFamily.value && documentFamily.value.contentObjects) {
        return documentFamily.value.contentObjects[documentFamily.value.contentObjects.length - 1];
      } else {
        return undefined;
      }
    }

    const selectedNodeIds = computed(() => {
      return selectionContext.value.selectedNodes.map(node => node.uuid as string);
    });

    function gotoTagInstance(tagInstance: TagInstance) {
      appStore.workspaceStore.setFocusTagUuid(tagInstance.uuid);
    }

    function getAllTagInstances(): Promise<TagInstance[]> {
      return new Promise((resolve) => {
        const tagInstances: TagInstance[] = [];
        const tagUuids: string[] = [];
        if (kddbDocument.value) {
          // We only care about tags in our taxonomy
          const prefixes = [] as string[];
          for (const [, tagMetadata] of project.tagMetadataMap) {
            if (tagMetadata.parentPath === undefined) {
              prefixes.push(tagMetadata.taxon.name);
            }
          }
          const taggedNodes = kddbDocument.value.getTaggedNodes(prefixes);
          for (const node of taggedNodes) {
            tagInstances.push(...buildTagInstances(node, tagUuids));
          }
        }
        resolve(tagInstances);
      });
    }

    function getPageUuid(pageNum: number): string | undefined {
      if (kddbDocument.value && kddbDocument.value.contentNode) {
        const pageNode = kddbDocument.value.contentNode.getChildren().find(node => node.index === pageNum);
        return pageNode ? pageNode.uuid as string : undefined;
      } else {
        return undefined;
      }
    }

    function getPageNode(pageNum: number): ContentNode | undefined {
      if (kddbDocument.value && kddbDocument.value.contentNode) {
        const pageNode = kddbDocument.value.contentNode.getChildren().find(node => node.index === pageNum);
        return pageNode || undefined;
      } else {
        return undefined;
      }
    }

    async function initializeFromDocument() {
      loadingMessage.value = "Indexing content ...";
      const start = new Date().getTime();
      documentLines.value = kddbDocument.value?.getLines() || [];
      const elapsed = new Date().getTime() - start;
      log.info(`Indexing took ${elapsed} ms`);
      loadingMessage.value = "Loading labels ...";
      const start2 = new Date().getTime();
      await loadTagInstances();
      const elapsed2 = new Date().getTime() - start2;
      log.info(`Loading labels took ${elapsed2} ms`);
      if (kddbDocument.value) {
        loadingMessage.value = "Loading model insights  ...";
        modelInsights.value = kddbDocument.value.getModelInsights();
        loadingMessage.value = "Loading exceptions ...";
        contentExceptions.value = kddbDocument.value.getContentExceptions();
        loadingMessage.value = "Loading completing ...";
        const pageNode = kddbDocument.value.contentNode?.getChildren()[page.value];
        if (pageNode) {
          pageText.value = pageNode.getAllContent();
        }
      } else {
        modelInsights.value = [];
        contentExceptions.value = [];
      }

      log.info("Document loaded");
      documentLoading.value = false;

      if (isSidecar.value) {
        useIntercom().trackEvent("sidecar-document-view-opened");
      } else {
        useIntercom().trackEvent("document-view-opened");
      }
    }

    async function reload() {
      if (kddbDocument.value) {
        log.info("Forcing reload of document");
        await loadDocument(true);
      }
    }

    async function loadDocument(force = false) {
      if (kddbDocument.value && !force) {
        log.info("Document already loaded");
        documentLoading.value = false;
        return;
      }

      if (documentLoading.value) {
        log.info("Document already loading");
        return;
      }

      documentLoading.value = true;
      loadingMessage.value = "Downloading structure ...";

      log.info("Loading document");

      if (documentViewer.value.executionId) {
        const executionStore = createExecutionStore(documentViewer.value.executionId);
        if (executionStore.kddbDocument) {
          loadingMessage.value = "Unpacking structure ...";
          kddbDocument.value = executionStore.kddbDocument as KddbDocument;
          loadingMessage.value = "Processing pages ...";
          try {
            numPages.value = kddbDocument.value.getNumPages();
            notParsed.value = false;
            await initializeFromDocument();
          } catch {
            log.info("Unable to load document from execution store, no pages");
            notParsed.value = true;
          }
        }
      } else if (documentFamily.value && documentFamily.value.contentObjects) {
        log.info(`Loading document for ${documentFamily.value.id}`);
        const latestContentObject = documentFamily.value.contentObjects[documentFamily.value.contentObjects.length - 1];
        if (!latestContentObject || !latestContentObject.id) {
          log.info("No content object found for document family");
          documentLoading.value = false;
          return;
        }

        if (documentFamily.value.id && (!kddbDocument.value || force)) {
          log.info("Loading KDDB");
          const refHelper = new RefHelper(documentFamily.value.storeRef);
          try {
            const kddbBlob = await getContentObjectContentWithVersion(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), documentFamily.value.id, latestContentObject.id);
            loadingMessage.value = "Unpacking structure ...";
            const start = new Date().getTime();
            if (kddbDocument.value) {
              log.info("Destroying existing document");
              kddbDocument.value?.destroy();
            }

            kddbDocument.value = await KddbDocument.fromBlob(kddbBlob);

            const elapsed = new Date().getTime() - start;
            log.info(`Loaded KDDB in ${elapsed}ms`);
            if (kddbDocument.value) {
              loadingMessage.value = "Processing pages ...";
              try {
                numPages.value = kddbDocument.value.getNumPages();
                notParsed.value = false;
                await initializeFromDocument();
              } catch {
                notParsed.value = true;
                documentLoading.value = false;
              }
            } else {
              log.error("Unable to load document");
              notParsed.value = true;
              documentLoading.value = false;
            }
          } catch (error) {
            documentLoading.value = false;
            log.warn("Unable to reload,  this is probably due to the content object changing");
            log.error(error);
          }
        }
      } else {
        documentLoading.value = false;
        log.info("No document family set, unable to load document", documentFamily);
      }
    }

    const loadingNative = ref(false);

    async function loadNative() {
      if (documentFamily.value && !loadingNative.value) {
        loadingNative.value = true;
        const storeRef = new RefHelper(documentFamily.value.storeRef);
        if (documentFamily.value.id != null) {
          log.info(`Loading native ${documentFamily.value.id}`);
          loadingMessage.value = "Loading document ...";
          if (!nativeDocument.value) {
            try {
              const pdfBlob = await getContentObjectContentWithVersion(
                storeRef.getOrgSlug(),
                storeRef.getSlug(),
                storeRef.getVersion(),
                documentFamily.value.id,
                documentFamily.value.contentObjects.find(co => co.contentType === "NATIVE")?.id,
              );
              nativeDocument.value = new Uint8Array(await pdfBlob.arrayBuffer());
              loadingMessage.value = "Loaded document ...";
              loadingNative.value = false;
            } catch (error) {
              log.error("Unable to load native content", error);
              nativeDocument.value = undefined;
              loadingNative.value = false;
            }
          }
        } else {
          log.info("No document family set, unable to load image");
          nativeDocument.value = undefined;
          loadingNative.value = false;
        }
      }
    }

    function loadTagInstances(): Promise<TagInstance[]> {
      return new Promise((resolve) => {
        getAllTagInstances().then((tagInstances) => {
          taggedInstances.value = tagInstances.filter(tagInstance => tagInstance.confidence >= 0);
          noConfidenceTags.value = tagInstances.filter(tagInstance => tagInstance.confidence < 0);
          resolve(tagInstances);
        }).catch((error) => {
          log.error("Error tagging", error);
          resolve([]);
        });
      });
    }

    function addTagInstance(tagInstance: TagInstance) {
      if (tagInstance.confidence >= 0) {
        taggedInstances.value.push(tagInstance);
      } else {
        noConfidenceTags.value.push(tagInstance);
      }
    }

    function removeTagInstance(tagUuid: string) {
      taggedInstances.value = taggedInstances.value.filter(ti => ti.uuid !== tagUuid);
      noConfidenceTags.value = noConfidenceTags.value.filter(ti => ti.uuid !== tagUuid);
    }

    function buildTagInstanceByNodeId(nodeId: string, processedUuids: string[]): TagInstance[] {
      if (!kddbDocument.value) {
        return [];
      }
      const node = kddbDocument.value.getNodeById(nodeId);
      return buildTagInstances(node, processedUuids);
    }

    function buildTagInstances(node: ContentNode, processedUuids: string[]): TagInstance[] {
      const tagInstances: TagInstance[] = [];
      node.getTagFeatures().forEach((tag) => {
        if (processedUuids.includes(tag.value[0].uuid) || !tagMetadataMap.value.has(tag.name)) {
          return;
        }

        const page = node.getPage(node);
        const tagInstance = {
          uuid: tag.value[0].uuid,
          taxon: project.tagMetadataMap.get(tag.name)?.taxon,
          taxonomy: project.tagMetadataMap.get(tag.name)?.taxonomy,
          nodes: kddbDocument.value?.getSpatiallySortedNodes(kddbDocument.value?.findNodesByTagUuid(tag.value[0].uuid) || []),
          tagData: tag.value[0],
          pageNumber: page ? page.index : 0,
          value: tag.value[0].value,
          path: tag.name,
          confidence: tag.value[0].confidence || 1,
          cellIndex: tag.value[0].cell_index || "0",
          groupUuid: tag.value[0].group_uuid as string,
          lineNumber: tag.value[0].line_number || 0,
          ownerUri: tag.value[0].owner_uri || undefined,
          isPage: node.nodeType === "page",
        } as TagInstance;

        if (tagInstance.taxon) {
          tagInstances.push(tagInstance);
          processedUuids.push(tag.value[0].uuid);
        }
      });

      const nodeParent = node.getParent();
      if (nodeParent !== undefined) {
        tagInstances.push(...buildTagInstances(nodeParent, processedUuids));
      }
      return tagInstances;
    }

    function updateImageSize(newWidth: number, newHeight: number) {
      log.info("Updating image size ", newWidth, newHeight);
      imageWidth.value = newWidth;
      imageHeight.value = newHeight;
    }

    function setFocusNodeUuid(uuid: string | undefined) {
      if (kddbDocument.value && uuid) {
        const node = kddbDocument.value?.findNodeByUUID(uuid);
        if (node) {
          const page = node.getPage(node);
          log.info(`Found page ${page?.index}`);
          if (page) {
            setPage(page.index);

            // We want to set the selection context to the node we are focusing on
            nextTick(() => {
              const nodeElement = document.getElementById(`node-${uuid}`);
              selectionContext.value.selectedNodes = [node];
              selectionContext.value.selectedValue = node.getAllContent();
              if (nodeElement) {
                selectionContext.value.selectedElements = [nodeElement];
                const nodeRect = nodeElement?.getBoundingClientRect();
                if (nodeRect && nodeElement) {
                  void nextTick(() => {
                    selectionContext.value.top = nodeRect.top;
                    selectionContext.value.left = nodeRect.left;
                    selectionContext.value.refresh = uuidv4();
                  });
                }
              }
            }).catch((error) => {
              log.error("Unable to set selection context [2]");
              log.error(error);
            });
          }
        }
      }
    }

    const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);

    function setPage(newPage: number) {
      page.value = newPage;

      if (currentWorkspaceId.value) {
        const useSidebar = createSidecar(currentWorkspaceId.value);
        useSidebar.updateCurrentPage(newPage);
      }

      if ("currentPage" in appStore.workspaceStore && typeof appStore.workspaceStore.currentPage === "object" && appStore.workspaceStore.currentPage.value !== undefined) {
        appStore.workspaceStore.currentPage.value = newPage;
      }

      // We want to get all the text from the page and set pageText
      if (kddbDocument.value) {
        const pageNode = kddbDocument.value.contentNode?.getChildren().find(node => node.index === newPage);
        if (pageNode) {
          pageText.value = pageNode.getAllContent();
        }
      }
    }

    async function replaceTag(tag: SelectedTag, nodes: ContentNode[], data: TagData = {}) {
      // We will remove the old tag - then use the information from that
      // tag and add the new tag
      await removeTag(tag);

      const tagMetadata = project.tagMetadataMap.get(tag.path);
      if (!tagMetadata) {
        log.error(`Unable to find tag metadata for ${tag.path}`);
        return;
      }
      addTag(tagMetadata, nodes, data);
    }

    async function removeTag(tag: SelectedTag) {
      // We need to find the tagged nodes in the document and then remove the tag
      log.info(`Removing tag uuid ${tag.uuid}`);
      if (kddbDocument.value) {
        const nodes = kddbDocument.value.findNodesByTagUuid(tag.uuid);
        log.info(`Found ${nodes.length} nodes with tag ${tag.uuid}`);
        nodes.forEach((node) => {
          log.info(`Removing tag ${tag.path} from node ${node.uuid}`);
          node.untag(tag.path);
        });
      } else {
        log.error("Unable to remove tag, no document loaded");
      }

      selectionContext.value.selectedNodes = [];
      selectionContext.value.selectedElements = [];
      selection.value?.clearSelection();
      selectionContext.value.refresh = uuidv4();

      // We need to inform the workspace that the tag has been removed
      // so that it can handle any attribute changes
      const documentFeatureSet = {
        featureSet: kddbDocument.value?.buildFeatureSet(),
        contentObjectId: getLatestContentObject()?.id,
      } as DocumentFeatureSet;
      removeTagInstance(tag.uuid);
      await appStore.workspaceStore.tagRemoved(tag, documentFamily.value, documentFeatureSet);
    }

    function addTag(tag: TagMetadata, nodes: ContentNode[], data: TagData = {}) {
      const tagUuid = uuidv4();

      // We need to get the value of the tag from nodes
      let tagValue = "";

      // We need to find the real content nodes, since the nodes passed in will be proxies now
      const realNodes = nodes.map(node => kddbDocument.value?.findNodeByUUID(node.uuid) || node);
      const { user } = storeToRefs(appStore.userStore);
      const ownerUri = user ? `user://${user.value.id}/${user.value.email}` : undefined;

      realNodes.forEach((node) => {
        if (tag.taxon.nodeTypes && tag.taxon.nodeTypes[0] === "line") {
          if (node.nodeType === "line") {
            log.info(`Tagging node ${node.uuid} ${tag.path}`);
            if (tagValue !== "") {
              tagValue = `${tagValue} ${node.getAllContent()}`;
            } else {
              tagValue = node.getAllContent();
            }
          }
        } else {
          if (tagValue !== "") {
            tagValue = `${tagValue} ${node.getAllContent()}`;
          } else {
            tagValue = node.getAllContent();
          }
        }
      });

      realNodes.forEach((node) => {
        if (tag.taxon.nodeTypes && tag.taxon.nodeTypes[0] === "line") {
          if (node.nodeType === "line") {
            log.info(`Tagging node ${node.uuid} ${tag.path}`);
            node.tag(tag.path, tagUuid, data, 1, ownerUri, tagValue, data.cellIndex, data.groupUuid);
          } else {
            if (node.nodeType === "word") {
              const parent = node.getParent();
              if (parent) {
                parent.tag(tag.path, tagUuid, data, 1, ownerUri, tagValue, data.cellIndex, data.groupUuid);
              } else {
                throw new Error("Unable to find parent of word node");
              }
            }
          }
        } else {
          log.info(`Tagging node ${node.uuid} ${tag.path}`);
          node.tag(tag.path, tagUuid, data, 1, ownerUri, tagValue, data.cellIndex, data.groupUuid);
        }
      });

      selectionContext.value.selectedNodes = nodes;
      // We need to inform the workspace that the tag has been removed
      // so that it can handle any attribute changes

      const tagInstance = {
        uuid: tagUuid,
        taxon: tag.taxon,
        taxonomy: tag.taxonomy,
        nodes,
        pageNumber: nodes[0].getPage(nodes[0])?.index || 0,
        lineNumber: nodes[0].getLine(nodes[0])?.index || 0,
        tagData: data,
        value: tagValue,
        confidence: 1,
        cellIndex: data.cellIndex || "0",
        groupUuid: data.groupUuid as string,
        ownerUri,
      } as TagInstance;

      selectionContext.value.selectedNodes = [];
      selectionContext.value.selectedElements = [];
      selection.value?.clearSelection();
      selectionContext.value.refresh = uuidv4();

      addTagInstance(tagInstance);

      const documentFeatureSet = {
        featureSet: kddbDocument.value?.buildFeatureSet(),
        contentObjectId: getLatestContentObject()?.id,
      } as DocumentFeatureSet;
      appStore.workspaceStore.tagAdded(tagInstance, documentFamily.value, documentFeatureSet);
    }

    function processMove(event: SelectionEvent, selectionContext: SelectionContext) {
      const oe = event.event;
      const removed = event.store.changed.removed;
      const added = event.store.changed.added;

      if (!oe) {
        return;
      }

      // Remove the class from elements that where removed
      // since the last selection
      for (const el of removed) {
        if (!el) {
          continue;
        }
        el.classList.remove("kodexa-selected-node");

        // @ts-expect-error we know this is a spatial node
        if (el.spatialNode) {
          selectionContext.selectedElements = selectionContext.selectedElements.filter(selEl => selEl !== el);
          // @ts-expect-error we know this is a spatial node
          selectionContext.selectedNodes = selectionContext.selectedNodes ? selectionContext.selectedNodes.filter(node => node.uuid !== el.spatialNode.node.uuid) : [];
        }
      }

      // Add a custom class to the elements that where selected.

      let overlayParents: ContentNode[] = [];

      // Get all the parents, so we don't add and remove them

      // @ts-expect-error we know this is a spatial node
      const selectedAndToBeAddedNodes = selectionContext.selectedNodes.concat(added.filter(el => el.spatialNode && el.spatialNode.nodeType === "word").map(el => el.spatialNode.node));

      // @ts-expect-error we know this is a spatial node
      const selectedAndToBeAddedElements = selectionContext.selectedElements.concat(added.filter(el => el.spatialNode && el.spatialNode.nodeType === "word"));
      for (const node of selectedAndToBeAddedNodes) {
        overlayParents = overlayParents.concat(node.getOverlayParents(selectedAndToBeAddedNodes));
      }

      appStore.workspaceStore.setActiveSelectionView(viewId);

      if (overlayParents.length > 0) {
        overlayParents.forEach((op) => {
          // We need to deselect the parents
          selectionContext.selectedElements = selectedAndToBeAddedElements.filter((selEl) => {
            // @ts-expect-error we know this is a spatial node
            if (selEl.spatialNode.node.uuid
              !== op.uuid) {
              selEl.classList.add("kodexa-selected-node");
              return true;
            } else {
              selEl.classList.remove("kodexa-selected-node");
              return false;
            }
          });
          selectionContext.selectedNodes = [...new Set(selectedAndToBeAddedNodes.filter(node => node.uuid !== op.uuid))];
        });
      } else {
        selectionContext.selectedNodes = [...new Set(selectedAndToBeAddedNodes)];
        selectionContext.selectedElements = selectedAndToBeAddedElements.filter((selEl) => {
          selEl.classList.add("kodexa-selected-node");
          return true;
        });
      }

      if (selectionContext.selectedNodes && selectionContext.selectedNodes.length > 0) {
        selectionContext.selectedValue = selectionContext.selectedNodes[0].getDocument().getNodeText(selectionContext.selectedNodes);
      } else {
        selectionContext.selectedValue = "";
      }
    }

    function buildSelection(): SelectionArea {
      if (selection.value !== undefined) {
        selection.value.destroy();
      }

      selection.value = new SelectionArea({

        // Class for the selection-area-element
        selectionAreaClass: "kodexa-spatial-page",

        selectionContainerClass: "kodexa-spatial-page",

        // Query selectors from elements which can be selected
        selectables: [".kodexa-spatial-page *"],

        // Query selectors for elements from where a selection can be start
        startAreas: [".kodexa-spatial-page *"],

        // Query selectors for elements which will be used as boundaries for the selection
        boundaries: [".kodexa-spatial-page"],

        behaviour: {

          // Specifies what should be done if already selected elements get selected again.
          //   invert: Invert selection for elements which were already selected
          //   keep: Keep selected elements (use clearSelection() to remove those)
          //   drop: Remove stored elements after they have been touched
          overlap: "invert",

          // On which point an element should be selected.
          // Available modes are cover (cover the entire element), center (touch the center) or
          // the default mode is touch (just touching it).
          intersect: "touch",

          // px, how many pixels the point should move before starting the selection (combined distance).
          // Or specifiy the threshold for each axis by passing an object like {x: <number>, y: <number>}.
          startThreshold: 0,

          // Scroll configuration.
          scrolling: {

            // On scrollable areas the number on px per frame is devided by this amount.
            // Default is 10 to provide a enjoyable scroll experience.
            speedDivider: 10,

            // Browsers handle mouse-wheel events differently, this number will be used as
            // numerator to calculate the mount of px while scrolling manually: manualScrollSpeed / scrollSpeedDivider.
            manualSpeed: 750,

            // This property defines the virtual inset margins from the borders of the container
            // component that, when crossed by the mouse/touch, trigger the scrolling. Useful for
            // fullscreen containers.
            startScrollMargins: {
              x: 0,
              y: 0,
            },
          },
        },

        // Features.
        features: {

          // Enable / disable touch support.
          touch: true,

          // Range selection.
          range: true,

          // Configuration in case a selectable gets just clicked.
          singleTap: {

            // Enable single-click selection (Also disables range-selection via shift + ctrl).
            allow: true,

            // 'native' (element was mouse-event target) or 'touch' (element visually touched).
            intersect: "native",
          },
        },

      }).on("start", (event) => {
        selectionContext.value.showTagPopup = false;
        appStore.workspaceStore.setFocusTagUuid(undefined);

        const oe = event.event;
        if (oe === null) {
          return;
        }
        // Unselect all elements
        for (const el of selectionContext.value.selectedElements) {
          if (!el) {
            continue;
          }
          el.classList.remove("kodexa-selected-node");
        }

        // Clear previous selection and start over
        selectionContext.value.selectedNodes = [];
        selectionContext.value.selectedElements = [];
        selectionContext.value.showTagPopup = false;
        selection.value?.clearSelection();
      }).on("move", (event) => {
        processMove(event, selectionContext.value);
      }).on("stop", (event) => {
        processMove(event, selectionContext.value);
        appStore.workspaceStore.setActiveSelectionViewById(undefined);
        if (event.event != null && selectionContext.value.selectedNodes.length > 0) {
          if (selectionContext.value.editable) {
            void nextTick(() => {
              appStore.workspaceStore.setActiveSelectionViewById(viewId);
              void nextTick(() => {
                if (event.event?.clientX !== undefined && event.event?.clientY !== undefined) {
                  // @ts-expect-error none standardized? seems odd
                  selectionContext.value.left = event.event.clientX;
                  // @ts-expect-error none standardized? seems odd
                  selectionContext.value.top = event.event.clientY;
                }
              });
            });
          }
        } else {
          selectionContext.value.showTagPopup = false;
        }
      });

      return selection.value;
    }

    function getKddbDocument() {
      if (kddbDocument.value !== undefined) {
        return kddbDocument.value;
      } else {
        return undefined;
      }
    }

    function findLines(searchString: string): FindLineHit[] {
      if (documentLines.value) {
        const hits: FindLineHit[] = [];
        let lineIndex = 0;
        while (lineIndex >= 0 && lineIndex < documentLines.value.length) {
          let match = false;
          if (searchString.startsWith("/")) {
            const regex = new RegExp(searchString.substring(1, searchString.length - 1));
            if (regex.test(documentLines.value[lineIndex].content.trim())) {
              match = true;
            }
          } else {
            if (documentLines.value[lineIndex].content.toLowerCase().includes(searchString.toLowerCase())) {
              match = true;
            }
          }

          if (match) {
            let pageNumber = 0;
            const uuid = documentLines.value[lineIndex].uuid;
            if (kddbDocument.value && uuid) {
              const node = kddbDocument.value?.findNodeByUUID(uuid);
              if (node) {
                const page = node.getPage(node);
                if (page) {
                  pageNumber = page.index;
                }
              }
            }

            hits.push({
              uuid: documentLines.value[lineIndex].uuid,
              content: documentLines.value[lineIndex].content,
              idx: lineIndex,
              pageNumber: pageNumber + 1,
            } as FindLineHit);
          }
          lineIndex++;
        }

        return hits;
      }
      return [];
    }

    /**
     * We pass nodes around, but we need to make sure we get the latest from the document before we
     * make any changes
     *
     * @param {ContentNode[]} nodes
     * @returns {ContentNode[]}
     */
    function reloadNodes(nodes: ContentNode[]): ContentNode[] {
      const document = getKddbDocument();
      const newNodes: ContentNode[] = [];
      for (const node of nodes) {
        const newNode = document?.findNodeByUUID(node.uuid);
        if (newNode !== undefined) {
          newNodes.push(newNode);
        }
      }
      return newNodes;
    }

    function getTagContent(tag: SelectedTag): string {
      if (tag.feature?.value[0].value) {
        return tag.feature?.value[0].value as string;
      }

      const document = getKddbDocument();
      const nodes = document?.findNodesByTagUuid(tag.uuid);
      if (nodes !== undefined && nodes.length > 0) {
        let content = "";
        for (const node of nodes) {
          content += `${node.getAllContent()} `;
        }
        return content;
      } else {
        return "";
      }
    }

    function getNoConfidenceTags(): TagInstance[] {
      return noConfidenceTags.value;
    }

    function updateTagInstance(tagInstance: TagInstance) {
      // I have a tag instance, that has data - and I need to find all nodes with
      // this tag UUID and then update the data on each of the tag features
      const document = getKddbDocument();
      if (document) {
        document.findNodesByTagUuid(tagInstance.uuid).forEach(async (node) => {
          const feature = node.getFeature("tag", tagInstance.taxon.path as string);
          if (feature) {
            feature.value = [tagInstance.tagData];
            node.__replaceFeatures();
            const documentFeatureSet = {
              featureSet: kddbDocument.value?.buildFeatureSet(),
              contentObjectId: getLatestContentObject()?.id,
            } as DocumentFeatureSet;
            await appStore.workspaceStore.updateFeatureSet(documentFamily.value, documentFeatureSet);
          }
        });
      } else {
        log.error("No document found");
      }
    }

    function updateTagValue(attribute: DataAttribute) {
      if (attribute.tagUuid) {
        const document = getKddbDocument();
        if (document) {
          document.findNodesByTagUuid(attribute.tagUuid).forEach(async (node) => {
            const feature = node.getFeature("tag", attribute.path as string);
            if (feature) {
              // We need update the value based on the taxonType
              switch (attribute.typeAtCreation) {
                case "STRING":
                  feature.value[0].value = attribute.stringValue;
                  break;
                case "NUMBER":
                  feature.value[0].value = attribute.decimalValue;
                  break;
                case "CURRENCY":
                  feature.value[0].value = attribute.decimalValue;
                  break;
                case "BOOLEAN":
                  feature.value[0].value = attribute.booleanValue;
                  break;
                case "DATE":
                  feature.value[0].value = attribute.dateValue;
                  break;
                case "DATE_TIME":
                  feature.value[0].value = attribute.dateValue;
                  break;
                case "SELECTION":
                  feature.value[0].value = attribute.stringValue;
                  break;
                default:
                  feature.value[0].value = attribute.value;
              }

              feature.value[0].value = attribute.value;
              node.__replaceFeatures();
              const documentFeatureSet = {
                featureSet: kddbDocument.value?.buildFeatureSet(),
                contentObjectId: getLatestContentObject()?.id,
              } as DocumentFeatureSet;
              await appStore.workspaceStore.updateFeatureSet(documentFamily.value, documentFeatureSet);
            }
          });
        } else {
          log.error("No document found");
        }
      }
    }

    function setSidecar(sidecar: boolean) {
      documentViewer.value.isSidecar = sidecar;
    }

    function getPdfPageNumberFromIndex(pageNum: number): number {
      if (kddbDocument.value?.contentNode?.getChildren() && kddbDocument.value?.contentNode?.getChildren().length > pageNum) {
        const page = kddbDocument.value?.contentNode?.getChildren()[pageNum];
        if (page && page.index) {
          return page.index + 1;
        }
      }
      return 0;
    }

    function showTagPopup(top: number, left: number, viewId: string) {
      appStore.workspaceStore.setActiveSelectionViewById(viewId);
      nextTick(() => {
        selectionContext.value.top = top;
        selectionContext.value.left = left;
        selectionContext.value.showTagPopup = true;
      });
    }

    return {
      gotoTagInstance,
      buildTagInstances,
      loadNative,
      loadTagInstances,
      selectionContext,
      imageWidth,
      kddbDocument,
      numPages,
      documentViewer,
      page,
      pageImage,
      imageHeight,
      getPageUuid,
      loadDocument,
      updateImageSize,
      documentFamily,
      taggedInstances,
      selectedNodeIds,
      buildTagInstanceByNodeId,
      focusTagUuid,
      isSidecar,
      setPage,
      addTag,
      removeTag,
      getLatestContentObject,
      modelInsights,
      setFocusNodeUuid,
      buildSelection,
      contentExceptions,
      getKddbDocument,
      reloadNodes,
      nativeDocument,
      findLines,
      documentLines,
      loadingMessage,
      reload,
      changeSequence,
      documentLoading,
      getTagContent,
      noConfidenceTags,
      getNoConfidenceTags,
      replaceTag,
      updateTagInstance,
      pageText,
      updateTagValue,
      setSidecar,
      getPdfPageNumberFromIndex,
      showTagPopup,
      documentStore,
      getPageNode,
      pageTags,
      notParsed,
    };
  })();
}
