<script setup lang="ts">
import type { PropType } from "vue";
import type { DocumentFamily } from "~/model";
import { getContentObjectImage, renameFamily } from "~/api/stores/stores";
import KodexaDocumentPageLoader from "~/components/document/kodexa-document-page-loader.vue";
import { log } from "~/utils/logger";
import { RefHelper } from "~/utils/ref-utils";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
  showAvatar: {
    type: Boolean,
    required: false,
    default: true,
  },
  size: {
    type: String,
    required: false,
    default: "large",
  },
  showLabels: {
    type: Boolean,
    required: false,
    default: true,
  },
  isRenaming: {
    type: Boolean,
    required: false,
    default: false,
  },
  labelSize: {
    type: String,
    required: false,
    default: "medium",
  },
  selected: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits<{
  (e: "nameClick"): void;
  (e: "change"): void;
  (e: "toggleSelected"): void;
}>();

const mixins = computed((): string[] => {
  if (props.documentFamily.mixins) {
    return props.documentFamily.mixins;
  }
  return [];
});

const lastContentObject = computed(() => {
  if (props.documentFamily.contentObjects) {
    return props.documentFamily.contentObjects[props.documentFamily.contentObjects.length - 1];
  }
  return null;
});

const contentMetadata = computed(() => {
  if (lastContentObject.value) {
    return lastContentObject.value.contentMetadata;
  } else {
    return {};
  }
});

const pathEdit = ref<boolean>(false);

watch(() => props.isRenaming, (newValue) => {
  pathEdit.value = (props.documentFamily.id === newValue);
});

const currentPath = ref(props.documentFamily.path);

function undoName() {
  log.info("Undoing rename family");
  pathEdit.value = false;
  currentPath.value = props.documentFamily.path;
}

async function blurName() {
  log.info("Renaming family");
  if (pathEdit.value) {
    pathEdit.value = false;
    const refHelper = new RefHelper(props.documentFamily.storeRef);
    await renameFamily(refHelper.getOrgSlug(), refHelper.getSlug(), {
      path: props.documentFamily.path,
      rename: currentPath.value,
    });
    emit("change");
  }
}

const loadingImage = ref<boolean>(false);

const pageImage = ref<string>("");
loadingImage.value = true;
if (props.documentFamily && props.documentFamily.id && props.documentFamily.path.toLowerCase().endsWith(".pdf")) {
  const refHelper = new RefHelper(props.documentFamily.storeRef);
  getContentObjectImage(refHelper.getOrgSlug(), refHelper.getSlug(), props.documentFamily.id, 0, { dpi: 5 }).then((blob) => {
    blob.arrayBuffer().then((arrayBuffer) => {
      pageImage.value = btoa(
        String.fromCharCode.apply(null, new Uint8Array(arrayBuffer)),
      );
      loadingImage.value = false;
    });
  });
} else {
  loadingImage.value = false;
}

const latestContentMetadata = computed(() => {
  if (props.documentFamily.contentObjects && props.documentFamily.contentObjects.length > 0) {
    return props.documentFamily.contentObjects[props.documentFamily.contentObjects.length - 1].contentMetadata;
  }
  return {};
});

const nameInput = ref<HTMLInputElement | null>(null);

function startRename() {
  pathEdit.value = true;
  setTimeout(() => {
    if (nameInput.value) {
      nameInput.value.focus();
    }
  }, 500);
}
</script>

<template>
  <div class="dark:bg-gray-900">
    <div class="flex items-center">
      <div class="flex">
        <div v-if="showAvatar" style="position: relative; display: inline-block;">
          <input
            :checked="selected" class="size-3 rounded border-gray-300 dark:border-gray-600 text-blue-600 dark:text-blue-500 mr-2 dark:bg-gray-700" style="position: absolute; left: 6px; top: 4px; z-index: 2;"
            type="checkbox"
            @click="emit('toggleSelected')"
          >
          <KodexaDocumentPageLoader v-if="!pageImage && loadingImage" :page-image="pageImage" class="size-12" @click="emit('nameClick')" />
          <KodexaFileIcon v-if="!pageImage && !loadingImage" :filename="documentFamily.path" class="size-12 dark:text-gray-300" @click="emit('nameClick')" />
          <img
            v-if="pageImage && !loadingImage"
            class="ml-1 mr-2 shadow dark:shadow-gray-900"
            :src="`data:image/png;base64,${pageImage}`" width="40" alt="Page Preview"
            style="position: relative; z-index: 1;"
            @click="emit('nameClick')"
          >
        </div>
        <div class="-mt-1 ml-2" style="width: 85%;">
          <div
            :class="props.size === 'large' ? ['text-md', 'font-medium', 'flex', 'dark:text-white'] : ['text-md', 'font-medium', 'flex', 'dark:text-white']"
          >
            <input
              v-show="pathEdit"
              ref="nameInput"
              v-model="currentPath"
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-blue-600 dark:focus:ring-blue-500 sm:text-sm sm:leading-6 dark:bg-gray-700" name="path" style="width: 100%;"
              @keydown.enter="blurName"
              @dblclick="undoName"
              @keydown.esc="undoName" @blur="blurName"
            >
            <div v-show="!pathEdit" class="cursor-pointer overflow-scroll dark:text-gray-200">
              <KodexaClipboardable
                class="wrap-text" :content="documentFamily.path"
                @dblclick="startRename" @click="emit('nameClick')"
              />
              <MaterialDesignIcon
                v-if="documentFamily.locked" v-tooltip="`Document is locked`" name="lock" size="12"
                class="ml-3 dark:text-gray-400"
              />
            </div>
          </div>
          <div v-if="documentFamily.activeAssistant" class="dark:text-gray-300">
            👀{{ documentFamily.activeAssistant.name }}
          </div>
          <div v-if="showLabels" class="-ml-1">
            <span class="text-xs mx-1 dark:text-gray-300">{{ latestContentMetadata.pages ? `${latestContentMetadata.pages} pages` : "" }}</span>
            <KodexaDocumentFamilyLabels :key="documentFamily.id + documentFamily.changeSequence" :document-family="documentFamily" :label-size="labelSize" @updated="emit('change')" />
          </div>
          <div class="-ml-1 mt-2 w-full">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
