<script setup lang="ts">
import type { PropType } from "vue";
import VChart from "vue-echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LegendComponent, TitleComponent, TooltipComponent } from "echarts/components";
import appStore from "~/store";
import { log } from "~/utils/logger";
import type { DashboardWidget } from "~/model";
import { DocumentFamily } from "~/model";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
  refresher: {
    type: Number,
    required: false,
    default: 1,
  },
});
use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

const option = {
  tooltip: {
    trigger: "axis",
    axisPointer: {
      // Use axis to trigger tooltip
      type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
    },
  },
  legend: {},
  grid: {
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },
  xAxis: {
    type: "value",
  },
  yAxis: {
    type: "category",
    data: ["Kate", "Steve", "Maurice", "Judy", "Louise"],
  },
  series: [
    {
      name: "Verified",
      type: "bar",
      stack: "total",
      label: {
        show: true,
      },
      emphasis: {
        focus: "series",
      },
      data: [20, 18, 25, 31, 16],
    },
    {
      name: "Rejected",
      type: "bar",
      stack: "total",
      label: {
        show: true,
      },
      emphasis: {
        focus: "series",
      },
      data: [2, 5, 10, 8, 7],
    },
  ],
};
</script>

<template>
  <div style="height: 320px">
    <VChart :option="option" autoresize />
  </div>
</template>

<style scoped>

</style>
