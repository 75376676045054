<script setup lang="ts">
import type { Ref } from "vue";
import type { PropType } from "vue/dist/vue";
import type { Card, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import type { Tab } from "~/utils/tab";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import appStore from "~/store";

defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
});

const { tagMetadataMap } = storeToRefs(appStore.projectStore);
const { dataObjects } = storeToRefs(appStore.workspaceStore);

const groupTaxons: Ref<TagMetadata[]> = computed(() => {
  return Array.from(tagMetadataMap.value.values()).filter((tagMetadata: any) => {
    return tagMetadata.taxon.group && tagMetadata.taxon.enabled && tagMetadata.taxon.group && tagMetadata.taxonomy.taxonomyType === "CONTENT" && !tagMetadata.taxon.path.includes("/");
  }) as TagMetadata[];
});

const tabs = computed(() => {
  return groupTaxons.value.map(taxon => ({
    ref: taxon.taxon.id,
    name: taxon.label,
  })) as Tab[];
});

const currentTab = ref(tabs.value[0]);

const currentTaxon = computed(() => {
  return groupTaxons.value.find(taxon => taxon.taxon.id === currentTab.value?.ref);
});

const rawDataObjects: Ref<DataObject[]> = computed(() => {
  return dataObjects.value ? Array.from(dataObjects.value.values()) : [];
});
</script>

<template>
  <div>
    <KodexaTabStrip
      :selected="currentTab"
      :items="tabs"
      tab-name="group-taxon"
      @selected="currentTab = $event"
    />
    <div>
      <KodexaWorkspaceDataObjectGridContainer
        v-if="currentTaxon"
        :key="currentTaxon.taxon.id"
        :view-id="viewId"
        :data-objects="rawDataObjects"
        :group-taxon-metadata="currentTaxon"
        class="mt-1"
        :group-taxons="groupTaxons"
        :editable="true"
      />
    </div>
  </div>
</template>

<style scoped>

</style>
