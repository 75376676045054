<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Card, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { availableCards } from "./cards";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentDataObjectGroups: {
    type: Map as PropType<Map<string, DataObject>>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["addCard", "selectCard", "removeCard", "addDataObject", "deleteDataObject", "moveDataObject"]);

// Get the component implementation for a given card type
function getCardComponent(type: string) {
  const cardDef = availableCards.find(card => card.type === type);
  if (!cardDef) {
    console.warn(`Unknown card type: ${type}`);
    return null;
  }
  return cardDef.implementation();
}
</script>

<template>
  <div class="mx-3">
    <Component
      :is="getCardComponent(card.type as string)" :card="card"
      :view-id="props.viewId"
      :parent-data-object="props.parentDataObject"
      :parent-tag-metadata="props.parentTagMetadata"
      :parent-data-object-groups="parentDataObjectGroups"
      @add-card="emit('addCard', $event)"
      @select-card="emit('selectCard', $event)"
      @delete-data-object="emit('deleteDataObject', $event)"
      @add-data-object="emit('addDataObject', $event)"
      @move-data-object="emit('moveDataObject', $event)"
    />
  </div>
</template>

<style scoped>
</style>
