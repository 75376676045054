<script setup lang="ts">
import type { PropType } from "vue/dist/vue";
import type { Card } from "~/model";
import type { Tab } from "~/utils/tab";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import appStore from "~/store";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
});

const { tagMetadataMap, dataStores } = storeToRefs(appStore.projectStore);

const dataStore = computed(() => {
  return dataStores.value.find((dataStore: any) => {
    return dataStore.ref === props.card?.properties?.dataStoreRef;
  });
});

const groupTaxons = computed(() => {
  return Array.from(tagMetadataMap.value.values())
    .filter((tagMetadata: any) => {
      return tagMetadata.taxon.enabled && tagMetadata.taxon.group
        && tagMetadata.taxonomy.taxonomyType === "CONTENT"
        && !tagMetadata.taxon.path.includes("/");
    })
    .map(taxon => ({
      ref: taxon.taxon.id,
      name: taxon.label,
      metadata: taxon,
    }));
});

const tabs = computed(() => {
  const baseTabs = groupTaxons.value.map((t) => {
    return {
      ref: t.ref,
      name: t.name,
    } as Tab;
  });

  baseTabs.push({
    ref: "download",
    name: "Download as Excel",
  } as Tab);

  return baseTabs;
});

const currentTaxon = ref(groupTaxons.value[0]);
const currentTab = ref(tabs.value[0]);

watch(currentTab, () => {
  if (currentTab.value.ref !== "download") {
    currentTaxon.value = groupTaxons.value.find(t => t.ref === currentTab.value.ref);
  }
});
</script>

<template>
  <div>
    <div v-if="dataStore">
      <KodexaTabStrip
        :items="tabs"
        :selected="currentTab"
        tab-name="data-view"
        @selected="currentTab = $event"
      />
      <KodexaDataObjectGridContainer
        v-if="currentTaxon.ref !== 'download'"
        :key="currentTaxon.ref"
        :view-id="viewId"
        :data-store="dataStore"
        :group-taxon-metadata="currentTaxon.metadata"
        class="mt-1 px-1"
        :editable="false"
      />
      <div v-else>
        <KodexaDataStoreDownloadToExcel
          :data-store="dataStore"
          :group-taxons="groupTaxons.filter(t => t.ref !== 'download').map(t => t.metadata)"
        />
      </div>
    </div>
    <div v-else>
      <div class="mt-10 flex flex-col items-center justify-center">
        <MaterialDesignIcon name="database" size="48" class="text-gray-400" />
        <p class="mt-2 text-sm text-gray-500">
          Select a data store to view the data, it is either
          not set of the store no longer exists.
        </p>
        <p class="mt-2 text-sm text-gray-500">
          {{ props.card?.properties?.dataStoreRef }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
