<script lang="ts" setup>
import type { Organization } from "~/model";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { createOrganization } from "~/api/organizations/organizations";
import { updateHandler } from "~/utils/error-handler";

defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refresh"]);

function newOrgPlaceholder() {
  return {
    name: "",
    slug: "",
    description: "",
  } as Organization;
}

const organization = ref(newOrgPlaceholder());

function close() {
  organization.value = newOrgPlaceholder();
  emit("update:modelValue", false);
}

function syncSlug() {
  organization.value.slug = organization.value.name.toLowerCase().replace(/[^a-z0-9\- ]/gi, "").replace(/ /g, "-");
}

async function create() {
  await updateHandler(createOrganization(organization.value), "Organization created successfully");
  emit("refresh");
  close();
}
</script>

<template>
  <TransitionRoot :show="modelValue" as="template">
    <Dialog as="div" class="relative z-10" @close="close()">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <!-- Action buttons -->

                <div class="flex h-full flex-col bg-white dark:bg-gray-800 shadow-xl">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-gray-50 dark:bg-gray-900 px-2 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            New Organization
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Get started by filling in the information below to create your new organization.
                          </p>
                        </div>
                        <div class="shrink-0 py-5">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancel-org"
                              icon="cancel"
                              type="secondary"
                              size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="createOrganizationBtn"
                              icon="content-save"
                              size="small"
                              @click="create"
                            >
                              Create
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <!-- Project name -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            for="organization-name"
                          >Organization
                            name</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            v-model="organization.name"
                            name="organization-name"
                            @input="syncSlug"
                          />
                        </div>
                      </div>

                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <!-- Project name -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                              for="organization-slug"
                            >Slug</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              v-model="organization.slug"
                              disabled
                              name="organization-slug"
                            />
                          </div>
                        </div>
                      </div>

                      <!-- Organization description -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            for="project-description"
                          >Description</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextArea
                            v-model="organization.description"
                            name="organization-description"
                            :rows="10"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
