<script lang="ts" setup>
import type { Ref } from "vue";
import type { TagInstance } from "~/store/useWorkspace";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { createDocumentViewerStore } from "~/store/useDocumentView";

const {
  activeSelectionView,
} = storeToRefs(appStore.workspaceStore);

const useDocumentViewStore = createDocumentViewerStore(activeSelectionView.value.viewId);
const { selectedNodeIds } = storeToRefs(useDocumentViewStore);
const { additionalTaxonOptions } = storeToRefs(appStore.projectStore);
const selectedTagInstance = ref<TagInstance>();

const tagInstances: Ref<TagInstance[]> = ref([]);

watch(() => selectedNodeIds, () => {
  selectedTagInstance.value = undefined;
  tagInstances.value = [];

  // This is used to make sure we dont' repeat things we already found
  const identifiedTagUuids: string[] = [];
  if (selectedNodeIds.value && selectedNodeIds.value.length > 0) {
    selectedNodeIds.value.forEach((nodeId: string) => {
      tagInstances.value.push(...useDocumentViewStore.buildTagInstanceByNodeId(nodeId, identifiedTagUuids));
    });
  }

  if (tagInstances.value.length > 0) {
    selectedTagInstance.value = tagInstances.value[0];
  }
}, { immediate: true, deep: true });

function updateTagInstance(value) {
  if (selectedTagInstance.value) {
    selectedTagInstance.value.tagData = value;
    useDocumentViewStore.updateTagInstance(selectedTagInstance.value);
  }
}

const tagData = computed({
  get: () => selectedTagInstance.value?.tagData,
  set: value => updateTagInstance(value),
});

const tagDataValue = computed({
  get: () => {
    return tagData.value?.data;
  },
  set: (value) => {
    if (tagData.value) {
      tagData.value.data = value;
      updateTagInstance(tagData.value);
    }
  },
});

const selectedAdditionalTaxonIdx = ref<number>(0);

function setSelectedAdditionalTaxonOptions(index) {
  selectedAdditionalTaxonIdx.value = index;
}
</script>

<template>
  <div class="bg-gray-50 dark:bg-gray-900" style="height: calc(100vh - 20rem)">
    <div v-if="tagInstances.length === 0">
      <div class="mt-40 flex flex-col justify-center items-center">
        <MaterialDesignIcon class="text-gray-200" name="tag" size="48" />
        <h3 class="mt-10 text-sm font-semibold text-gray-900">
          No labels selected
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Select a label in the document in order to see the properties
        </p>
        <p>
          <KodexaArticle article-id="9136691" text="Learn more about label properties" :slide="false" />
        </p>
      </div>
    </div>
    <div v-else class="p-3">
      <div>
        <DropDownList
          v-model="selectedTagInstance"
          label="Selected Label"
          style="width: 100%"
          :data-items="tagInstances" data-text-field="taxon.name" fill-mode="outline"
          text-field="taxon.label"
        />
      </div>
      <div v-if="selectedTagInstance" class="p-1">
        <div class="p2 mt-3 mb-1">
          <div v-for="option in selectedTagInstance.taxon.options" :key="option.name">
            <ConfigurationOption v-model="tagDataValue" :item="option" />
          </div>
          <KodexaArticle article-id="9136691" text="Learn more about label properties" :slide="false" />
        </div>
        <div v-if="additionalTaxonOptions">
          <p class="my-1 font-bold">
            Models and Assistants
          </p>
          <p class="mb-3">
            Select the model or assistant to see additional properties
          </p>
          <span
            v-for="(selectedAdditionalTaxonOptions, index) in additionalTaxonOptions" :key="index"
            :class="index === selectedAdditionalTaxonIdx ? ['text-white', 'bg-theme-primary', 'border', 'border-black'] : ['text-white', 'bg-blue-500']"
            class="m-0.5 inline-flex items-center  rounded-md px-2.5 py-0.5 text-sm font-medium"
            @click="setSelectedAdditionalTaxonOptions(index)"
          >
            {{ selectedAdditionalTaxonOptions.name }}
          </span>
        </div>
        <div class="p2 mt-3">
          <div v-for="(selectedAdditionalTaxonOptions, index) in additionalTaxonOptions" :key="index">
            <div v-if="selectedAdditionalTaxonIdx === index">
              <div v-for="option in selectedAdditionalTaxonOptions.options" :key="option.name">
                <ConfigurationOption
                  v-model="tagDataValue"
                  :item="option"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
