<script setup lang="ts">
import { storeToRefs } from "pinia";
import appStore from "~/store";
import {notify} from "notiwind";

const { platformConfiguration } = storeToRefs(appStore.platformStore);
const localConfiguration = ref();
const saving = ref(false);
const hasChanges = ref(false);

appStore.platformStore.loadConfiguration();

// Create a deep copy when platformConfiguration changes
watch(platformConfiguration, (newValue) => {
  localConfiguration.value = JSON.parse(JSON.stringify(newValue));
  hasChanges.value = false;
}, { immediate: true });

// Track changes
watch(localConfiguration, () => {
  hasChanges.value = true;
}, { deep: true });

async function saveChanges() {
  saving.value = true;
  await appStore.platformStore.saveConfiguration(localConfiguration.value);
  notify({
    group: "generic",
    title: "Platform content saved",
  }, 500);
  hasChanges.value = false;
  saving.value = false;
}
</script>

<template>
  <div v-if="localConfiguration" class="p-4">
    <KodexaTextArea
      name="welcomeMarkdown"
      v-model="localConfiguration.welcomeMarkdown" 
      :rows="10" 
      label="Home markdown"
    />
    <KodexaTextInput
      name="changeLogArticleId"
      v-model="localConfiguration.changeLogArticleId" 
      :rows="10" 
      label="Change Log Article ID" 
      hint="Put an intercom article ID here, and it will be displayed in the navbar if the user hasnt' already seen it."
    />

    <KodexaButton 
      icon="content-save" 
      size="small" 
      class="mt-2" 
      @click="saveChanges" 
      :loading="saving"
      :disabled="!hasChanges"
    >
      Save Content
    </KodexaButton>
  </div>
</template>
