<script lang="ts" setup>
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import { log } from "~/utils/logger";

defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
});

const iconMap = {
  store: "text-box-multiple-outline",
  dataForms: "form-select",
  channel: "forum-outline",
  taxonomy: "sitemap-outline",
  workspaceConfig: "tune-vertical",
  navigation: "map-legend",
  search: "magnify",
};

function getIcon(panelIcon: string) {
  const icon = panelIcon.split("-")[0];
  log.info(`Panel icon ${icon}`);
  return iconMap[icon] || "text-box-multiple-outline";
}
</script>

<template>
  <div class="p-2">
    <MaterialDesignIcon
      :name="getIcon(params.params.icon)"
      size="12"
      class="mr-1 ml-1 text-gray-500"
    />
    {{ params.params.title }}
  </div>
</template>

<style scoped>

</style>
