<script lang="ts" setup>
import { storeToRefs } from "pinia";
import appStore from "~/store";

const { sidecarPanelOpen, panelsOpen } = storeToRefs(appStore.workspaceStore);
</script>

<template>
  <div>
    <MaterialDesignIcon
      :name="panelsOpen ? 'dock-left' : 'dock-left'"
      class="mr-1 ml-1 text-gray-500"
      @click="() => appStore.workspaceStore.togglePanels()"
    />
    <MaterialDesignIcon
      :name="sidecarPanelOpen ? 'dock-right' : 'dock-right'"
      class="mr-1 ml-1 text-gray-500"
      @click="() => appStore.workspaceStore.toggleSidecar()"
    />
  </div>
</template>

<style scoped>

</style>
