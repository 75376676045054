<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { NumberFormatOptions } from "@progress/kendo-intl/dist/npm/numbers";

import type { ComputedRef, PropType } from "vue";
import type { AttributeMaskedTextOption } from "~/components/dataForm/attribute-maskedtext-option";
import type { DataAttribute, DataException, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { storeToRefs } from "pinia";
import KodexaNonEditableValue from "~/components/dataObject/types/kodexa-non-editable-value.vue";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  dataExceptions: {
    type: Object as PropType<DataException[]>,
    required: false,
    default: () => {
      return [] as DataException[];
    },
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  maskedTextOptions: {
    type: Object as PropType<AttributeMaskedTextOption>,
    required: false,
    default: () => {
      return {} as AttributeMaskedTextOption;
    },
  },
  editable: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);

const decimalValue = computed({
  get() {
    return props.attribute.decimalValue !== null || props.attribute?.decimalValue !== undefined ? props.attribute?.decimalValue : null;
  },
  set(value) {
    if (props.maskedTextOptions?.isMaskedText) {
      const formattedValue = toNumberStr(value);
      const formattedDecimalValue = formattedValue !== null ? Number(formattedValue.replace(/[$,]/g, "")) : null;
      emit("update", {
        ...props.attribute,
        value: formattedDecimalValue,
        decimalValue: formattedDecimalValue,
      });
    } else {
      emit("update", {
        ...props.attribute,
        value,
        decimalValue: value,
      });
    }
  },
});

function toNumberStr(value: number | null | undefined) {
  if (value === null || value === undefined) {
    return null;
  }
  let divider = 1;
  const regex = /^-?\d+$/;
  if (regex.test(value.toString())) {
    divider = 100;
  }
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / divider);
}

const valid = computed(() => {
  return props.dataExceptions?.length === 0;
});

const focused = ref(false);

const format: ComputedRef<string | NumberFormatOptions> = computed(() => {
  if (focused.value) {
    return "##.##";
  } else {
    return {
      style: "currency",
      currency: "USD",
    };
  }
});

function focus() {
  focused.value = true;
  if (props.maskedTextOptions?.isMaskedText) {
    nextTick(() => {
      const numericTextBox = document.querySelector(`#kdx-attribute-${props.attribute.uuid}`);
      if (numericTextBox) {
        numericTextBox.select();
      }
    });
  }
  dataViewHelper.focus();
  emit("focus");
}

function blur() {
  focused.value = false;
  dataViewHelper.blur();
}

const { style, conditionalValidationIcon } = storeToRefs(dataViewHelper);

const formattedValue = computed(() => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(decimalValue.value || 0);
});
</script>

<template>
  <div :style="style">
    <template v-if="!editable">
      <KodexaNonEditableValue
        v-if="!editable"
        :style="style" :conditional-validation-icon="conditionalValidationIcon"
        :string-value="formattedValue"
      />
    </template>
    <template v-else>
      <div @click="focus">
        <NumericTextBox
          v-if="maskedTextOptions?.isMaskedText"
          :id="`kdx-attribute-${props.attribute?.uuid}`"
          v-model="decimalValue"
          :spinners="false"
          :style="style"
          input-prefix="prefix"
          :fill-mode="null"
          :rounded="null"
          :valid="valid"
          :required="false"
          :disabled="props.dataObject?.documentFamily.locked === true"
          :format="format"
          class="kodexa-input"
          :step="0"
          @focus="focus"
          @blur="blur"
        >
          <template #prefix>
            <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
          </template>
        </NumericTextBox>
        <NumericTextBox
          v-else
          :id="`kdx-attribute-${props.attribute?.uuid}`"
          v-model="decimalValue"
          input-prefix="prefix"
          class="kodexa-input"
          :style="style"
          :tabindex="props.tabIndex"
          :step="0"
          :disabled="props.dataObject?.documentFamily.locked === true"
          :required="false"
          :valid="valid"
          :spinners="false"
          :format="{
            style: 'currency',
            currency: 'USD',
          }" @focus="focus"
          @click="focus"
          @blur="dataViewHelper.blur()"
          @keypress.alt="$event.preventDefault()"
        >
          <template #prefix>
            <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
          </template>
        </NumericTextBox>
      </div>
    </template>
  </div>
</template>

<style scoped>
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.kodexa-label {
  @apply block text-sm text-gray-700 mb-1
}

.kodexa-input {
  @apply w-full appearance-none rounded-md border border-gray-300
  px-0 py-0 shadow-sm placeholder:text-gray-400 sm:text-sm
}

.kodexa-input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}
.kodexa-input:focus {
  @apply border-blue-500 ring-1 ring-blue-500
}

:deep(.k-input-inner) {
  font-size: var(--k-textbox-font-size) !important;
  font-weight: var(--k-textbox-font-weight) !important;
  @apply dark:text-gray-50 sm:text-sm dark:bg-gray-700
}
</style>
