export const componentMetadata = {
  label: "Exception Card",
  description: "Display exceptions matching a specific message",
  supportsChildren: false,
  defaultWidth: 12,
  defaultHeight: 4,
  options: [
    {
      label: "Exception Message",
      name: "exceptionMessage",
      type: "string",
      required: true,
      description: "The message to filter exceptions by",
    },
  ],
};
