<script setup lang="ts">
import type { GridColumnProps } from "@progress/kendo-vue-grid/dist/npm/interfaces/GridColumnProps";
import type { Execution } from "~/model";
import { arrowRotateCwIcon, filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar } from "@progress/kendo-vue-buttons";

import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useListExtensionPacks } from "~/api/extension-packs/extension-packs";
import ExtensionPackStats from "~/components/dashboard/widgets/app/extensions/extension-pack-stats.vue";
import appStore from "~/store";

dayjs.extend(relativeTime);

const { project, assistants } = storeToRefs(appStore.projectStore);

// Setup our grid helpers
const gridHelper = createGridHelper("extension-packs");
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListExtensionPacks(gridQuery.value, { query: { refetchInterval: 5000, staleTime: 5000 } });

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
  }
});

const columns: GridColumnProps[] = [
  { title: "Organization", field: "orgSlug", cell: "orgSlug" },
  { title: "Name", field: "name" },
  { title: "Description", field: "description" },
  { title: "Version", field: "version" },
  { title: "Status", field: "status", cell: "status" },
  { title: "Deployment Type", cell: "deployment" },
  { title: "Stats", cell: "stats" },
  { title: "", cell: "actions" },
];

const showPopup = ref(false);
const selectedExecution = ref<Execution | null>(null);

function viewDetails(execution: Execution) {
  selectedExecution.value = execution;
  showPopup.value = true;
}

const deploymentTypes = {
  AWS_LAMBDA: "AWS Lambda",
  AZURE_FUNCTION: "Azure Function",
  KUBERNETES: "Kubernetes",
};
</script>

<template>
  <div class="mx-4 mt-2">
    <Toolbar class="border-0 bg-white dark:bg-gray-800">
      <Button :svg-icon="filterIcon" title="Filter" :togglable="true" class=" dark:text-gray-50" @click="gridHelper.toggleFilter()" />
      <Button :svg-icon="arrowRotateCwIcon" title="refresh" :togglable="false" class=" dark:text-gray-50" @click="refetch()" />
    </Toolbar>
    <Grid
      style="height: calc(100vh - 11rem);"
      :data-items="data ? data.content : []"
      :pageable="pageSettings"
      :loader="isLoading"
      :sort="sort"
      :skip="pageSettings.skip as number"
      :total="pageSettings.total as number"
      :page-size="pageSettings.take as number"
      :columns="columns"
      :filterable="showFilter"
      :filter="filter"
      :resizable="true"
      :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No extension packs found"
        />
      </GridNoRecords>
      <template #orgSlug="{ props }">
        <td>
          <div class="flex h-10 items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
            <MaterialDesignIcon
              name="organization"
              class="mr-3 size-5 shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {{ props.dataItem.orgSlug }}
          </div>
        </td>
      </template>
      <template #status="{ props }">
        <td>
          <KodexaStatusIcon style="float: left" :status="props.dataItem.status" />
          <div class="ml-8 mt-2 capitalize">
            {{ props.dataItem.status.toLowerCase() }}
          </div>
        </td>
      </template>
      <template #deployment="{ props }">
        <td>
          <p>{{ deploymentTypes[props.dataItem.deployment.deploymentType] }}</p>
          <p v-if="props.dataItem.deployment.deploymentType === 'KUBERNETES'">
            Max Replicas: {{
              props.dataItem.deployment.maxReplicas
            }}<br>Min Replicas: {{ props.dataItem.deployment.minReplicas }}
          </p>
          <p v-else>
            Reserved Concurrency: {{
              props.dataItem.deployment.reservedConcurrency
            }}<br>Assigned Memory: {{ props.dataItem.deployment.memoryAssigned }}
          </p>
        </td>
      </template>
      <template #stats="{ props }">
        <td>
          <ExtensionPackStats :extension-pack="props.dataItem" />
        </td>
      </template>
      <template #actions="{ props }">
        <td>
          <KodexaExtensionPackMenu
            :extension-pack="props.dataItem"
            @change="refetch"
            @view-details="viewDetails(props.dataItem)"
          />
        </td>
      </template>
    </Grid>
  </div>
</template>

<style scoped>

</style>
