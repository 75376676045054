<script setup lang="ts">
import { storeToRefs } from "pinia";
import { customAxios } from "~/api/custom-axios";
import appStore from "~/store";

const { currentOrganization } = storeToRefs(appStore.organizationStore);

async function downloadInvoices() {
  await customAxios(
    {
      url: `/api/payments/createCustomerPortalSession/${currentOrganization.value.id}`,
      method: "get",
    },
    {},
  );
}
</script>

<template>
  <div>
    <div class="flex grid-cols-1 justify-center">
      <div class="col-span-1 text-center">
        <h2 class="text-2xl font-semibold text-gray-900 mt-10">
          Click on the following buttons to manage your billing information.
        </h2>
        <p class="px-50 mt-1 text-md text-gray-500">
          You will be redirected to Stripe.
        </p>
      </div>
    </div>
    <hr width="35%" class="block m-auto border-2 mt-6" style="border-color: rgb(15, 76, 140);">
    <div class="billingButtons mt-12">
      <KodexaButton class="block m-auto kodexa-button-secondary-large w-80 text-gray-200" @click="downloadInvoices">
        Download Invoices
      </KodexaButton>
      <KodexaButton class="block m-auto kodexa-button-secondary-large w-80">
        Update Billing Information
      </KodexaButton>
      <KodexaButton class="block m-auto kodexa-button-secondary-large w-80">
        Update Payment Method
      </KodexaButton>
      <KodexaButton class="block m-auto kodexa-button-secondary-large w-80">
        Update Subscription
      </KodexaButton>
    </div>
  </div>
</template>

<style scoped>
.kodexa-button-secondary-large {
  border: 1px solid #eaeaea !important;
  font-weight: 300;
  font-size: 15px;
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  color: #6a6a6a;
}

.kodexa-button-secondary-large:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  background-color: #1359a5;
  color:white;
  font-size: 15px;
  font-weight: 500;
  border-radius: 10px;
}
</style>
