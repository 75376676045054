<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option, Store } from "~/model";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const { modelStores } = storeToRefs(appStore.projectStore);

const tab = ref("_base");

function getModel(modelRef: string) {
  return modelStores.value.find((modelStore: Store) => modelStore.ref === modelRef);
}

function setTab(newTab: string) {
  tab.value = newTab;
}

function getStyle(styleTab: string): any {
  if (tab.value === styleTab) {
    return {
      "color": "white",
      "background-color": "#2196F3",
    };
  }
  return {};
}

const myValue = computed(() => {
  if (props.assistant?.options?.pipeline.steps !== undefined && props.modelValue !== undefined) {
    let value = props.modelValue[props.item.name];
    if (value === undefined) {
      value = { steps: [] };
      props.assistant.options.pipeline.steps.forEach(() => {
        value.steps.push({});
      });
    }
    return value;
  } else {
    return {};
  }
});

function updateStep(value: any, idx: number) {
  const tmpValue = { ...props.modelValue };
  if (tmpValue[props.item.name] === undefined) {
    tmpValue[props.item.name] = {};
    tmpValue[props.item.name].steps = [];
    if (props.assistant?.options?.pipeline.steps !== undefined) {
      props.assistant.options.pipeline.steps.forEach(() => {
        tmpValue[props.item.name].steps.push({});
      });
      tmpValue[props.item.name].steps[idx] = value;
      emit("update:modelValue", tmpValue);
    }
  }
}

const open = ref(false);
onMounted(() => {
  open.value = true;
});
</script>

<template>
  <div v-if="open && assistant && assistant.options && modelStores !== undefined">
    <div v-for="(step, idx) in assistant.options.pipeline.steps" :key="idx">
      <div v-if="step.stepType === 'MODEL'">
        <div v-if="getModel(step.ref) && getModel(step.ref).metadata.trainingOptions">
          <div class="text-md mt-2 font-bold text-gray-700">
            {{ getModel(step.ref).name }}
          </div>

          <div class="mt-1">
            <div
              v-if="(!getModel(step.ref).metadata.optionTabs || getModel(step.ref).metadata.optionTabs.length === 0) && getModel(step.ref).metadata.optionTabs.length > 0"
              key="4" class="text-md mb-1 mr-1 text-theme-primary"
              :style="getStyle('_base')"
              @click="setTab('_base')"
            >
              <MaterialDesignIcon name="settings" />
              Configuration
            </div>
            <div v-else class="text-sm">
              Available option groups for the model are:
            </div>
            <span
              v-for="optionTab in getModel(step.ref).metadata.optionTabs"
              :key="optionTab.name"
              class="mt-2 mr-2 inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800" :style="getStyle(optionTab.name)"
              @click="setTab(optionTab.name)"
            ><MaterialDesignIcon :name="optionTab.icon ? optionTab.icon.replace('mdi-', '') : 'cogs'" class="text-md mr-1" size="12" />
              {{ optionTab.name }}</span>
            <div
              v-show="tab === '_base'"
              v-if="(!getModel(step.ref).metadata.optionTabs || getModel(step.ref).metadata.optionTabs.length === 0) && getModel(step.ref).metadata.optionTabs.length > 0"
              key="4"
            >
              <div fluid>
                <div class="text-md mt-2 text-gray-700">
                  Options
                </div>
                <p>
                  Below are the available options for the assistant.
                </p>
                <div v-for="modelOption in getModel(step.ref).metadata.trainingOptions" :key="modelOption.name">
                  <ConfigurationOption
                    v-model="myValue.steps[idx]"
                    :assistant="assistant"
                    :model-stores="modelStores"
                    :item="modelOption"
                    @update:model-value="updateStep($event, idx)"
                  />
                </div>
              </div>
            </div>
            <div
              v-for="optionTab in getModel(step.ref).metadata.optionTabs"
              :key="optionTab.name"
            >
              <div v-show="tab === optionTab.name" fluid>
                <div v-for="modelOption in getModel(step.ref).metadata.trainingOptions" :key="modelOption.name">
                  <ConfigurationOption
                    v-if="optionTab.optionNames.includes(modelOption.name)" v-model="myValue.steps[idx]"
                    :assistant="assistant"
                    :model-stores="modelStores"
                    :item="modelOption"
                    @update:model-value="updateStep($event, idx)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>
