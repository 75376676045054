<script setup lang="ts">
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import type { Ref } from "vue";
import type { DataForm } from "~/model";
import { storeToRefs } from "pinia";
import { parse, stringify } from "yaml";
import {
  BillChargesValidator,
  BillReadingValidator,
  BillServiceValidator,
  DateValidator,
  MatchingAggregationValidator,
  RequiredValidator,
} from "~/components/dataForm/cards/validators/cass-validators";
import { createDataFormViewerStore } from "~/store/useDataFormView";

const props = defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
});
const emit = defineEmits(["formSaved", "dataExceptionsChanged"]);

const viewId = props.params.api.id.replace("view-", "") as string;

const useDataFormViewer = createDataFormViewerStore(viewId as string);
const {
  dataForm,
  dataObjects,
  dataExceptions,
  overrideExceptionMapper,
} = storeToRefs(useDataFormViewer);

watch(dataExceptions, () => {
  const allExceptions = dataExceptions.value.dataAttributeExceptions.concat(dataExceptions.value.dataObjectExceptions);
  emit("dataExceptionsChanged", allExceptions);
});

function buildDataFormViewer() {
  return useDataFormViewer.buildDataFormViewer();
}

const showSource: Ref<boolean> = ref(false);
const latestDataFormYaml = ref("");

watch(showSource, (newValue) => {
  if (newValue) {
    latestDataFormYaml.value = stringify(dataForm.value, { indent: 2 });
  } else {
    const dataFormYaml = parse(latestDataFormYaml.value) as DataForm;
    if (dataFormYaml.cards) {
      useDataFormViewer.updateCard(dataFormYaml.cards[0], true);
    }
  }
});

function updateYaml(value: string) {
  latestDataFormYaml.value = value;
}

// List of cass validators
const cassValidators = [
  new MatchingAggregationValidator(),
  new BillChargesValidator(),
  new BillServiceValidator(),
  new BillReadingValidator(),
  new RequiredValidator(),
  new DateValidator(),
];

/**
 * Need to watch the scoped data objects for changes, if we have a required validator
 * we need to use it
 */
watchDebounced([dataObjects], () => {
  for (const currentDataObject of dataObjects.value) {
    const currentPath = currentDataObject.path;

    if (!currentPath) {
      continue; // Skip to the next iteration
    }

    const currentPathMapper = overrideExceptionMapper.value.get(currentPath);
    if (currentPathMapper) {
      const parent = currentDataObject.parent;

      const resolveException = currentPathMapper.find(overrideException => overrideException.parentUuid === parent.uuid && !overrideException.openFlag);
      if (resolveException) {
        continue;
      }
    }

    for (const validatorImplementation of cassValidators) {
      if (validatorImplementation.getTaxonPath() === currentDataObject.path) {
        validatorImplementation.callConfigure(currentDataObject, dataObjects.value);
        validatorImplementation.callValidation(currentDataObject);
        const exceptionUuids = validatorImplementation.callMissingExceptions(currentDataObject);
        useDataFormViewer.updateValidateExceptions(exceptionUuids);
      }
    }
  }
}, {
  debounce: 1000,
  maxWait: 500,
  deep: true,
  immediate: true,
});
</script>

<template>
  <div v-if="dataForm">
    <div v-if="showSource">
      <KodexaCodeEditor
        v-model="latestDataFormYaml"
        theme="vs"
        style="height: calc(100vh - 200px); width: 100%;"
        language="yaml"
        @change="updateYaml"
      />
    </div>
    <div v-else>
      <div style="overflow: scroll; height: calc(96vh - 10rem)">
        <KodexaRowLayout
          :rows="buildDataFormViewer()" :view-id="viewId as string"
        />
      </div>
    </div>
  </div>
</template>
