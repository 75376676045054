<script setup lang="ts">
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { toRef } from "vue";
import { ensureNumber } from "~/utils/data";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  label: {
    type: String,
    required: false,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: () => {
    },
  },
  spinners: {
    type: Boolean,
    default: false,
  },
  format: {
    type: String,
    default: "n",
  },
});

const emit = defineEmits(["update:modelValue", "input", "blur"]);

const name = toRef(props, "name");

const localValue = computed({
  get: () => ensureNumber(props.modelValue),
  set: value => emit("update:modelValue", value),
});
</script>

<template>
  <div>
    <div
      :class="{ 'has-error': errors && errors[name], 'success': errors && !errors[name] }"
    />
    <label v-if="label" for="name" class="kodexa-label"> {{ label }}</label>
    <NumericTextBox
      v-model="localValue"
      style="width: 100%"
      class="kodexa-input"
      :valid="!errors || errors && !errors[name]"
      :name="name"
      :placeholder="placeholder"
      :autocomplete="name"
      :spinners="spinners"
      :format="format"
      @blur="emit('blur', $event)"
      @input="emit('input', $event)"
    />
    <p v-if="errors && errors[name]" class="mt-2 text-sm text-red-600 dark:text-red-400">
      {{ errors[name] }}
    </p>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-gray-700 dark:text-gray-200 mb-1
}

.kodexa-input {
  @apply w-full appearance-none rounded-md border border-gray-300 dark:border-gray-600
  px-0 py-0 shadow-sm placeholder:text-gray-400 dark:placeholder:text-gray-500
  dark:bg-gray-700 dark:text-white
}

.kodexa-input:focus-within {
  @apply border-blue-500 dark:border-blue-400 ring-1 ring-blue-500 dark:ring-blue-400
}
.kodexa-input:focus {
  @apply border-blue-500 dark:border-blue-400 ring-1 ring-blue-500 dark:ring-blue-400
}

.kodexa-input :deep(.k-input-inner) {
  font-size: var(--k-textbox-font-size) !important;
  font-weight: var(--k-textbox-font-weight) !important;
  @apply dark:text-gray-50 sm:text-sm dark:bg-gray-700
}
</style>
