// Component metadata definition
export const componentMetadata = {
  label: "Data Element Panel",
  description: "Present a data element panel for editing",
  supportsChildren: true,
  defaultWidth: 12,
  defaultHeight: 4,
  options: [
    {
      label: "Title",
      name: "title",
      type: "string",
      description: "Header title to display",
      required: false,
    },
    {
      label: "Show Header",
      name: "showHeader",
      type: "boolean",
      description: "Whether to show the header",
      required: false,
      default: true,
    },
    {
      label: "Group Taxon",
      name: "groupTaxon",
      type: "taxon",
      description: "Taxon group to use",
      required: true,
    },
    {
      label: "Hide Add",
      name: "hideAdd",
      type: "boolean",
      description: "Hide the add button",
      required: false,
      default: false,
    },
    {
      label: "Hide Exceptions",
      name: "hideExceptions",
      type: "boolean",
      description: "Hide exceptions display",
      required: false,
      default: false,
    },
    {
      label: "Exception Sticky",
      name: "exceptionSticky",
      type: "boolean",
      description: "Make exceptions stick to top",
      required: false,
      default: false,
    },
    {
      label: "Is Jump On",
      name: "isJumpOn",
      type: "boolean",
      description: "Enable jumping to this section",
      required: false,
      default: false,
    },
    {
      label: "Override Exception",
      name: "overrideException",
      type: "boolean",
      description: "Allow exception override",
      required: false,
      default: false,
    },
  ],
};
