<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { storeToRefs } from "pinia";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import appStore from "~/store";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    onlyGroups: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const {
  isShown,
  localValue,
} = useConfigurationBase(props, emit);

const filter = ref<string>("");

const tags = computed((): any[] => {
  const prefiltered = [];
  if (props.onlyGroups) {
    prefiltered.push(...Array.from(tagMetadataMap.value.values()).filter((tag: TagMetadata) => tag.taxon.group));
  } else {
    prefiltered.push(...Array.from(tagMetadataMap.value.values()));
  }

  if (filter.value) {
    return prefiltered.filter((tag: TagMetadata) => tag.label.toLowerCase().includes(filter.value.value.toLowerCase()) || (tag.parentLabel && tag.parentLabel.toLowerCase().includes(filter.value.value.toLowerCase())));
  }

  return prefiltered;
});

function filterChange(event: any) {
  filter.value = event.filter;
}

const emptyTaxonSelection = {
  label: "No data element selected",
  path: "",
};
</script>

<template>
  <div v-if="isShown">
    <div>
      <p>{{ item.label ? item.label : item.name }}</p>
      <DropDownList
        v-model="localValue"
        fill-mode="outline"
        text-field="label"
        value-field="path"
        :default-item="emptyTaxonSelection"
        data-item-key="path"
        :value-primitive="true"
        :style="{ width: '100%' }"
        :data-items="tags"
        :filterable="true"
        item-render="taxonTemplate"
        @filterchange="filterChange"
      >
        <template #taxonTemplate="{ props }">
          <TaxonDropdownItem
            :class="props.itemClass" :data-item="props.dataItem"
            :index="props.index" @click="(ev) => props.onClick(ev)"
          />
        </template>
      </DropDownList>
    </div>
  </div>
</template>

<style>

</style>
