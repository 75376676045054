import type { ILogObj } from "tslog";
import type { DocumentFamily, Task, TaskActivity } from "~/model";
import _ from "lodash";
import { defineStore } from "pinia";
import { Logger } from "tslog";
import { computed } from "vue";
import { createTaskActivity, listTaskActivities } from "~/api/task-activity/task-activity";
import { listTaskDocumentFamilies } from "~/api/task-document-families/task-document-families";
import { getTask, updateTask } from "~/api/tasks/tasks";
import { updateHandler } from "~/utils/error-handler";

export const useTask = defineStore("task", () => {
  const log: Logger<ILogObj> = new Logger({ name: "task" });

  const currentTask: Ref<Task | undefined> = ref(undefined);
  const originalTask: Ref<Task | undefined> = ref(undefined);
  const taskActivities: Ref<TaskActivity[]> = ref([]);
  const documentFamilies: Ref<DocumentFamily[]> = ref([]);

  async function loadTask(taskId: string) {
    log.info(`Loading task with ID: ${taskId}`);
    currentTask.value = await getTask(taskId);
    originalTask.value = { ...currentTask.value };
    await loadActivities(taskId);
    await loadDocumentFamilies(taskId);
  }

  async function loadDocumentFamilies(taskId: string) {
    log.info(`Loading document families for task with ID: ${taskId}`);
    const taskDocumentFamilies = await listTaskDocumentFamilies({ filter: `task.id:'${taskId}'` });
    if (taskDocumentFamilies.content) {
      documentFamilies.value = taskDocumentFamilies.content.map(f => f.documentFamily) as DocumentFamily[];
    } else {
      documentFamilies.value = [];
    }
  }

  async function addActivity(activity: TaskActivity) {
    if (!currentTask.value) {
      return;
    }

    activity.task = currentTask.value;

    log.info(`Adding activity for task with ID: ${currentTask.value.id}`);
    await updateHandler(
      createTaskActivity(activity),
      "Activity added successfully",
    );

    await loadActivities(currentTask.value.id as string);
  }

  async function loadActivities(taskId: string) {
    log.info(`Loading task activities for task with ID: ${taskId}`);
    const activityPage = await listTaskActivities({ filter: `task.id:'${taskId}'`, sort: "createdOn:desc", pageSize: 99 });
    if (activityPage.content) {
      taskActivities.value = activityPage.content;
    } else {
      taskActivities.value = [];
    }
  }

  const hasChanges = computed(() => {
    if (!currentTask.value || !originalTask.value) {
      return false;
    }

    const currentValues = {
      title: currentTask.value.title,
      description: currentTask.value.description,
      status: currentTask.value.status,
      dueDate: currentTask.value.dueDate,
      project: currentTask.value.project?.id,
      assignee: currentTask.value.assignee?.id,
    };

    const originalValues = {
      title: originalTask.value.title,
      description: originalTask.value.description,
      status: originalTask.value.status,
      dueDate: originalTask.value.dueDate,
      project: originalTask.value.project?.id,
      assignee: originalTask.value.assignee?.id,
    };

    return !_.isEqual(currentValues, originalValues);
  });

  async function saveChanges(): Promise<void> {
    if (!currentTask.value?.id || !hasChanges.value) {
      return;
    }

    // Save the task
    const updatedTask = await updateHandler(updateTask(currentTask.value.id.toString(), currentTask.value), "Task updated successfully");

    if (!updatedTask) {
      return;
    }
    originalTask.value = { ...updatedTask };
    currentTask.value = { ...updatedTask };

    if (currentTask.value) {
      await loadActivities(currentTask.value.id);
      await loadDocumentFamilies(currentTask.value.id);
    }
  }

  function resetChanges() {
    if (!currentTask.value || !originalTask.value) {
      return;
    }

    currentTask.value = { ...originalTask.value };
  }

  async function updateCurrentTask(task: Task) {
    if (!currentTask.value) {
      return;
    }

    currentTask.value = { ...task };
  }

  return {
    hasChanges,
    currentTask,
    loadTask,
    saveChanges,
    resetChanges,
    updateCurrentTask,
    loadActivities,
    taskActivities,
    addActivity,
  };
});
