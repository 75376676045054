<!--
  - Copyright (C) 2025 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { AttributeEditorOptions } from "~/components/dataObject/attribute-editor-options";
import type { DataAttribute, DataObject, Taxon } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { process } from "@progress/kendo-data-query";
import { Toolbar } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { getAggregationFromTaxons, getAttributeValueByTaxonType } from "~/components/util/attribute-utils";
import appStore from "~/store";
import { getColumnsFromTaxon } from "~/utils/general";
import { log } from "~/utils/logger";
import { useGridScroll } from "~/utils/useGridScroll";

const props = defineProps({
  groupTaxonMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObjects: {
    type: Object as PropType<DataObject[]>,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: null,
  },
  tabIndex: {
    type: Number as PropType<number>,
    required: false,
  },
  isLoading: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
  isError: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: false,
  },
  error: {
    type: Object,
    required: false,
    default: null,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  showHeader: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: true,
  },
  editable: {
    type: Boolean as PropType<boolean>,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["height-updated"]);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const editorOptions = ref({ showPreview: false } as AttributeEditorOptions);

const pageable = {
  buttonCount: 5,
  info: true,
  type: "numeric",
  pageSizes: [10, 25, 50, 100, 1000],
  previousNext: true,
  pageSizeValue: 10,
};

// We pass in a dummy grid query since we want to pick up the store that
// already exists here from the calling component
const gridHelper = createGridHelper("data-object-grid", {
  page: 1,
  pageSize: pageable.pageSizeValue,
  filter: "",
  query: "",
  sort: "",
});

const { pageSettings, sort, filter, showFilter } = storeToRefs(gridHelper);

const expandedItems = ref([] as string[]);

const reactiveDataObjects = computed(() => [...props.dataObjects]);

const dataItems = computed(() => {
  // We need to identify the parent data objects in case we need to include

  const dataItems = [];
  for (const dataObject of reactiveDataObjects.value) {
    if (dataObject.path !== props.groupTaxonMetadata?.path) {
      continue;
    }

    if (props.parentDataObject && dataObject.parentId !== props.parentDataObject.id) {
      continue;
    }

    const dataItem = {
      attributes: new Map<string, DataAttribute>(),
      dataObjectInstance: dataObject,
      tagMetadata: new Map<string, TagMetadata>(),
      expanded: expandedItems.value.includes(dataObject?.uuid as string),
    };
    if (dataObject?.attributes !== undefined) {
      for (const attribute of dataObject?.attributes) {
        dataItem.attributes.set(attribute.tag, attribute);
        dataItem[attribute.path] = getAttributeValueByTaxonType(attribute, tagMetadataMap.value);
        dataItem.tagMetadata.set(attribute.tag, tagMetadataMap.value.get(`${props.groupTaxonMetadata.taxon.path}/${attribute.tag}`));
      }
    }
    dataItems.push(dataItem);
  }

  // We need to slice the data items using the page settings
  gridHelper.setTotal(dataItems.length);
  return dataItems;
});

const group = ref([]);

watch(group, (newValue) => {
  if (newValue) {
    gridHelper.setGroups(newValue.map(group => `${group.field}:${group.dir || "asc"}`));
  }
});

const columnLocks: Ref<string[]> = ref([]);
const columns = computed(() => {
  const baseColumns = getColumnsFromTaxon(props.groupTaxonMetadata?.taxon, columnLocks.value);
  return [
    ...baseColumns,
    {
      field: "actions",
      title: " ",
      cell: "actionsTemplate",
    },
  ];
});

const gridItems = computed(() => {
  return process(dataItems.value, {
    group: group.value,
    take: pageSettings.value.take,
    skip: pageSettings.value.skip,
    sort: sort.value,
    filter: "logic" in filter.value ? filter.value : undefined,
  });
});
const aggregates = ref([]);

function dataStateChange(event) {
  // Need to determine if we have any aggregates
  // this is based on the taxons that we can aggregate on
  aggregates.value = getAggregationFromTaxons(props.groupTaxonMetadata.taxon?.children || []);

  if (event.data.group) {
    event.data.group.map(group => (group.aggregates = aggregates.value));
  }
  group.value = event.data.group;
}

function clickHandler(e) {
  const id = e.dataObjectInstance.uuid as string;
  if (expandedItems.value.includes(id)) {
    expandedItems.value.splice(expandedItems.value.indexOf(id), 1);
  } else {
    expandedItems.value.push(id);
  }
}

function getChildItems(taxon: Taxon) {
  const items = [];
  if (!taxon.children) {
    return items;
  }

  taxon.children.forEach((child) => {
    if (!child.group || child.taxonType === "SECTION" || !child.enabled) {
      return;
    }
    items.push({
      ref: child.id,
      name: child.label,
      taxon: child,
    });
  });

  return items;
}

const selectedItems = ref({});

function selectedChild(taxon: Taxon, dataObjectId: string) {
  if (dataObjectId in selectedItems.value) {
    return selectedItems.value[dataObjectId];
  } else {
    selectedItems.value[dataObjectId] = getChildItems(taxon)[0];
    return selectedItems.value[dataObjectId];
  }
}

function selectChild(dataObjectId: string, selected: any) {
  selectedItems.value[dataObjectId] = selected;
}

const tableElement = ref(null);
const gridScroll = useGridScroll(tableElement); // Use the composable
onUnmounted(() => {
  gridScroll.unmount();
});

const { sidecarPanelOpen } = storeToRefs(appStore.workspaceStore);

async function addDataObject() {
  log.info("Adding a new data object");
  // Lets make sure we set the document view
  const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);
  log.info(`Focusing on new tag in ${currentWorkspaceId.value}`);
  if (currentWorkspaceId.value && props.parentDataObject) {
    const useSidebar = createSidecar(currentWorkspaceId.value);

    if (!useSidebar.currentPage) {
      useSidebar.focusPage(props.parentDataObject?.documentFamily?.id as string, 0);
    }
  }

  await appStore.workspaceStore.addNewDataObject(props.groupTaxonMetadata, props.parentDataObject?.documentFamily, props.parentDataObject);
}

function toggleSidecar() {
  appStore.workspaceStore.toggleSidecar();
}

function toggleLockOnColumn(column) {
  if (columnLocks.value.includes(column.field)) {
    columnLocks.value.splice(columnLocks.value.indexOf(column.field), 1);
  } else {
    columnLocks.value.push(column.field);
  }
}

const updatedHeight = ref<number | null>(null);

function updateGridHeight(height: number) {
  updatedHeight.value = height + 170;
}

const gridHeight = computed(() => {
  if (props.parentDataObject) {
    // Calculate height based on number of rows
    const rowHeight = 60; // Adjust this value based on your actual row height
    const headerHeight = 123; // Adjust this value based on your actual header height
    const maxHeight = Number.parseInt(window.getComputedStyle(document.documentElement).fontSize) * 19; // 19rem in pixels
    const calculatedHeight = (gridItems.value.data.length * rowHeight) + headerHeight + (updatedHeight.value ?? 0);
    const newHeight = Math.min(calculatedHeight, window.innerHeight - maxHeight);
    emit("height-updated", newHeight);
    return `${newHeight}px`;
  } else {
    // Use the original height
    return "calc(100vh - 19rem)";
  }
});
</script>

<template>
  <div>
    <Toolbar
      :class="{ 'bg-white dark:bg-gray-700 -ml-2 mb-1 border-0': showHeader, 'bg-gray-100 dark:bg-gray-700': !showHeader }"
    >
      <KodexaButton
        v-if="showHeader"
        :type="showFilter ? 'primary' : 'secondary'" icon="filter" size="small" title="Filter"
        @click="gridHelper.toggleFilter()"
      >
        Filter
      </KodexaButton>
      <KodexaButton
        v-if="showHeader && editable"
        :type="editorOptions.showPreview ? 'primary' : 'secondary'"
        icon="image" title="Preview" size="small"
        @click="editorOptions.showPreview = !editorOptions.showPreview"
      >
        Preview Popup
      </KodexaButton>
      <KodexaButton :icon="sidecarPanelOpen ? 'close' : 'dock-right'" size="small" :title="sidecarPanelOpen ? 'Close Sidecar' : 'Open Sidecar'" type="secondary" @click="toggleSidecar">
        {{ sidecarPanelOpen ? 'Close' : 'Open' }} Sidecar
      </KodexaButton>
      <KodexaButton v-if="!showHeader" icon="plus" size="small" title="Add" type="secondary" @click="addDataObject">
        Add
      </KodexaButton>
    </Toolbar>
    <div ref="tableElement">
      <Grid
        :style="{ 'height': gridHeight, 'margin-bottom': '10px', 'width': '100%' }"
        cell-render="dataAttribute"
        :data-items="gridItems"
        :columns="columns"
        :reorderable="true"
        :pageable="pageable"
        :loader="isLoading"
        :sort="sort"
        :take="pageSettings.take"
        :skip="pageSettings.skip"
        :total="pageSettings.total"
        :page-size="pageSettings.take"
        :filterable="showFilter"
        :filter="filter"
        :detail="getChildItems(groupTaxonMetadata.taxon).length > 0 ? 'detailTemplate' : undefined"
        :resizable="true"
        :sortable="true"
        :expand-field="getChildItems(groupTaxonMetadata.taxon).length > 0 ? 'expanded' : undefined"
        :groupable="{ footer: 'visible', enabled: true }"
        :group="group"
        column-menu="kodexaCustomColumnMenu"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
        @datastatechange="dataStateChange"
        @expandchange="clickHandler"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :is-error="props.isError" :is-loading="props.isLoading" :error="props.error"
            no-records-message="No data found"
          />
        </GridNoRecords>
        <template #kodexaCustomColumnMenu="{ props }">
          <KodexaGridCustomColumnMenu
            :column="props.column"
            :sortable="true"
            :sort="props.sort"
            :columns="columns"
            @sortchange="(e) => props.onSortchange(e)"
            @closemenu="(e) => props.onClosemenu(e)"
            @contentfocus="(e) => props.onContentfocus(e)"
            @toggle-column-lock="toggleLockOnColumn(props.column)"
          />
        </template>
        <template #detailTemplate="{ props }">
          <KodexaTabStrip
            :items="getChildItems(groupTaxonMetadata.taxon)"
            :selected="selectedChild(groupTaxonMetadata.taxon, props.dataItem.dataObjectInstance.uuid)"
            :tab-name="props.dataItem.dataObjectInstance.uuid"
            @selected="selectChild(props.dataItem.dataObjectInstance.uuid, $event)"
          />
          <KodexaDataObjectGrid
            v-if="selectedChild(groupTaxonMetadata.taxon, props.dataItem.dataObjectInstance.uuid)"
            :group-taxon-metadata="tagMetadataMap.get(selectedChild(groupTaxonMetadata.taxon, props.dataItem.dataObjectInstance.uuid).taxon.path)"
            :data-objects="dataObjects" :parent-data-object="props.dataItem.dataObjectInstance"
            :view-id="viewId" :show-header="false"
            :editable="editable" @height-updated="updateGridHeight"
          />
        </template>
        <template #actionsTemplate="{ props }">
          <td>
            <KodexaButton
              icon="delete" size="small" title="Edit" type="danger" class="p-4"
              @click="appStore.workspaceStore.deleteDataObjectByUuid(props.dataItem.dataObjectInstance)"
            />
          </td>
        </template>
        <template #dataAttribute="{ props }">
          <KodexaDataObjectGridGrouping
            style="width: 150px"
            :field="props.field"
            :col-span="props.colspan"
            :style="props.style"
            :expanded="props.expanded || false"
            :row-type="props.rowType"
            :level="props.level"
            :column-index="props.columnIndex"
            :columns-count="props.columnsCount"
            :data-item="props.dataItem"
            :class-name="props.class"
            :editor-options="editorOptions"
            :view-id="viewId"
            :editable="editable"
            @click="clickHandler(props.dataItem)"
          />
        </template>
      </Grid>
    </div>
  </div>
</template>

<style scoped>
</style>
