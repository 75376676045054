<script setup lang="ts">
import type { Panel } from "./editor-panel";
import { GridLayout } from "grid-layout-plus";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import appStore from "~/store";
import { createDataFormEditorStore } from "~/store/useDataFormEditorStore";

const props = defineProps<{
  panel: Panel;
  isSelected: boolean;
  viewId: string;
}>();

const emit = defineEmits<{
  (e: "click", id: string): void;
  (e: "select", id: string): void;
  (e: "delete", id: string): void;
}>();

const store = createDataFormEditorStore(props.viewId)();
const { tagMetadataMap } = storeToRefs(appStore.projectStore);

function getTaxonLabel(taxonPath: string): string {
  const metadata = tagMetadataMap.value.get(taxonPath);
  return metadata?.label || taxonPath.split("/").pop() || "";
}

const childrenLayout = computed(() =>
  props.panel.children.map(child => ({
    x: child.x,
    y: child.y,
    w: child.w,
    h: child.h,
    i: child.id,
    static: child.static,
    minW: 2,
    maxW: 12,
    minH: 2,
    isDraggable: !child.static,
    isResizable: !child.static,
    margin: [10, 10],
  })),
);

function handleChildLayoutUpdate(newLayout: any) {
  store.updateChildrenLayout(props.panel.id, newLayout);
}

function handleClick(e: Event | string) {
  if (e instanceof Event) {
    e.stopPropagation();
  }
  const panelId = typeof e === "string" ? e : props.panel.id;
  store.selectPanel(panelId);
}

function handleDelete(e: Event) {
  e.stopPropagation();
  emit("delete", props.panel.id);
}
</script>

<template>
  <div
    :id="`panel-${panel.id}`"
    class="panel"
    :class="{ selected: isSelected }"
    :style="{ backgroundColor: panel.properties?.backgroundColor }"
    @click="handleClick"
  >
    <div class="panel-header">
      <span>{{ panel.properties?.taxon ? getTaxonLabel(panel.properties.taxon) : panel.properties.title }}</span>
      <div class="panel-actions">
        <MaterialDesignIcon name="delete" class="text-red-700" size="14" @click="handleDelete" />
      </div>
    </div>
    <div class="panel-content">
      <GridLayout
        v-if="panel.children?.length"
        v-model:layout="childrenLayout"
        :cols="{ lg: 24, md: 24, sm: 24, xs: 24, xxs: 24 }"
        :row-height="30"
        :margin="[10, 10]"
        :use-css-transforms="true"
        @layout-updated="handleChildLayoutUpdate"
      >
        <template #item="{ item }">
          <EditorDataFormPanel
            :panel="panel.children.find(p => p.id === item.i)!"
            :is-selected="store.selectedPanel?.id === item.i"
            :view-id="viewId"
            @click="handleClick"
            @select="store.selectPanel"
            @delete="store.deletePanel"
          />
        </template>
      </GridLayout>
    </div>
  </div>
</template>

<style scoped>
.panel {
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.panel.selected {
  border: 2px solid #68B984;
}

.panel-header {
  padding: 8px;
  background: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-actions {
  display: flex;
  gap: 4px;
}

.delete-btn {
  background: none;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  color: #666;
  border-radius: 4px;
}

.delete-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.panel-content {
  flex: 1;
  padding: 8px;
  overflow: auto;
}
</style>
