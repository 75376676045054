<script setup>
import Tagify from "@yaireo/tagify";
import { onMounted, ref } from "vue";
import { getColorByBgColor, randomColor } from "~/utils/colors";

const props = defineProps(["tags", "availableTags"]);

const emits = defineEmits(["addTag", "removeTag"]);

const inputRef = ref();
const tagify = ref();

const allTags = computed(() => {
  const tags = [];
  for (const tag of props.tags) {
    const formattedTag = {
      value: tag.name,
      color: tag.color,
    };
    tags.push(formattedTag);
  }
  return tags;
});

function addTag(e) {
  const tag = e.detail.data.value;

  // Determine if it is one of the available tags - if not add it
  const availableTag = props.availableTags.find(availableTag => availableTag.label === tag);
  if (!availableTag) {
    const newTag = {
      name: tag,
      label: tag,
      color: randomColor(),
    };
    emits("addTag", newTag);
    // Temporary add it in the availableTags
  } else {
    emits("addTag", availableTag);
  }
}

function removeTag(e) {
  const tag = e.detail.data.value;
  emits("removeTag", tag);
}

function transformTags(tagData) {
  // Temporary add a randomColor on it if it doesn't have one
  if (!tagData.color) {
    tagData.color = randomColor();
  }
  // For some reason it doesn't capture the color need to create a filter
  tagData.style = `--tag-bg:${tagData.color}; --tag-text-color:${getColorByBgColor(tagData.color)}; --tag-hover:black;`;
  return tagData;
}

onMounted(() => {
  // const whiteListTags = allTags.value;
  const whiteListTags = props.availableTags.map((tag) => {
    const newTag = JSON.parse(JSON.stringify(tag));
    newTag.value = tag.label;
    newTag.color = tag.color;
    return newTag;
  });
  tagify.value = new Tagify(inputRef.value, {
    delimiters: null,
    whitelist: whiteListTags,
    enforceWhitelist: false,
    placeholder: "Add tags",
    dropdown: {
      maxItems: 20, // <- maximum allowed rendered suggestions
      classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
      enabled: 1, // <- show suggestions on focus
      closeOnSelect: false, // <- do not hide the suggestions dropdown once an item has been selected
    },
    transformTag: transformTags,
    templates: {
      tag(tagData) {
        // tagData.style = `--tag-bg:${tagData.color}; --tag-text-color:${getColorByBgColor(tagData.color)};`;
        return `<div title='${tagData.value}' contenteditable='false' spellcheck="false" class='tagify__tag ${tagData.class ? tagData.class : ""}' ${this.getAttributes(tagData)}>
                        <x title='remove tag' class='tagify__tag__removeBtn' style="color: ${getColorByBgColor(tagData.color)}"></x>
                        <div>
                            <span class='tagify__tag-text'>${tagData.value}</span>
                        </div>
                    </div>`;
      },
    },
  });
  tagify.value.addTags(allTags.value);
  tagify.value.on("add", addTag);
  tagify.value.on("remove", removeTag);
});
</script>

<template>
  <input ref="inputRef">
</template>

<style>
.tagify {
  border: 0px !important;
  font-size: .8em !important;
}
.tags-look .tagify__dropdown__item{
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  padding: .3em .5em;
  border: 1px solid #e3e3e3;
  background: #F3F3F3;
  margin: .2em;
  font-size: .85em;
  color: black;
  transition: 0s;
}

.dark .tags-look .tagify__dropdown__item {
  border: 1px solid #4a4a4a;
  background: #2d2d2d;
  color: #fff;
}

.tags-look .tagify__dropdown__item--active{
  color: black;
}

.dark .tags-look .tagify__dropdown__item--active {
  color: #fff;
}

.tags-look .tagify__dropdown__item:hover{
  background: lightyellow;
  border-color: gold;
  color: black;
}

.dark .tags-look .tagify__dropdown__item:hover {
  background: #3d3d3d;
  border-color: #666;
  color: #fff;
}

.tags-look .tagify__dropdown__item--hidden {
  max-width: 0;
  max-height: initial;
  padding: .1em 0;
  margin: .1em 0;
  white-space: nowrap;
  text-indent: -20px;
  border: 0;
}

.tagify__input {
  pointer-events: none !important;
}

.dark .tagify__input {
  color: #fff;
}
</style>
