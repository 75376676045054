<script lang="ts" setup>
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import { cancelIcon } from "@progress/kendo-svg-icons";
import { Menu } from "@progress/kendo-vue-layout";
import { Popup } from "@progress/kendo-vue-popup";
import { storeToRefs } from "pinia";
import { onMounted, ref } from "vue";
import KodexaTruncatedText from "~/components/kodexa-truncated-text.vue";
import appStore from "~/store";
import WorkspaceViewMetadata from "~/views/studio/organizations/project/workspace/workspace-view-metadata.vue";

const props = defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
});

const viewId = props.params.params.viewId;

const { views } = storeToRefs(appStore.workspaceStore);

function closeView() {
  appStore.workspaceStore.removeViewById(viewId);
}

const contextMenu = ref({
  show: false,
  offset: { left: 0, top: 0 },
  targetItem: null,
});

const menuItems = ref([
  { text: "Close", svgIcon: cancelIcon },
  { text: "Close All", svgIcon: cancelIcon },
  { text: "Close Others", svgIcon: cancelIcon },
]);

function handleContextMenu(e) {
  e.preventDefault();
  contextMenu.value.show = true;
  contextMenu.value.offset = { left: e.clientX, top: e.clientY };
  contextMenu.value.targetItem = viewId;
}

function onMenuSelect(e) {
  const item = contextMenu.value.targetItem;

  if (!item) {
    return;
  }

  switch (e.item.text) {
    case "Close":
      closeView(item);
      break;
    case "Close All":
      views.value.forEach((view) => {
        closeView(view.id);
      });

      break;
    case "Close Others":
      views.value.forEach((view) => {
        if (view.id !== item) {
          closeView(view.id);
        }
      });
      break;
  }
  contextMenu.value.show = false;
}

function bodyClick() {
  contextMenu.value.show = false;
}

onMounted(() => {
  document.body.addEventListener("click", bodyClick);
});

onBeforeUnmount(() => {
  document.body.removeEventListener("click", bodyClick);
});

function getViewIcon() {
  const icons = {
    document: "document",
    dataForm: "card-account-details",
    execution: "play-circle",
    text: "text",
    processingStep: "graph",
    costView: "currency-usd",
  };
  const view = views.value.find(v => v.id === viewId);
  if (view) {
    return icons[view.viewType];
  }
  return "document";
}

function getViewMetadata() {
  const view = views.value.find(v => v.id === viewId);
  return appStore.workspaceStore.getViewMetadata(view);
}

function getTabTitle() {
  const view = views.value.find(v => v.id === viewId);
  return view ? appStore.workspaceStore.getTitle(view) : "";
}
</script>

<template>
  <div class="p-2 mx-1 flex" @contextmenu="handleContextMenu($event)">
    <VMenu :popper-triggers="['hover']">
      <MaterialDesignIcon :name="getViewIcon()" class="mr-2" size="16" />
      <template #popper>
        <WorkspaceViewMetadata :view-metadata="getViewMetadata()" />
      </template>
    </VMenu>
    <div class="mt-1 text-xs font-medium">
      <KodexaTruncatedText :length="30" :text="getTabTitle()" />
    </div>
    <MaterialDesignIcon class="ml-2" name="close" size="16" @click="closeView()" />
    <Popup :show="contextMenu.show" :offset="contextMenu.offset">
      <Menu
        :items="menuItems"
        :vertical="true"
        @select="onMenuSelect"
      />
    </Popup>
  </div>
</template>

<style scoped>

</style>
