<script lang="ts" setup>
import { storeToRefs } from "pinia";
import appStore from "~/store";
import ProjectMarketplace from "~/components/dashboard/widgets/projects/project-marketplace.vue";
import ProjectsList from "~/components/dashboard/widgets/projects/projects-list.vue";
import ProjectsRecent from "~/components/dashboard/widgets/projects/projects-recent.vue";
import type { DashboardWidget } from "~/model";

const { currentSidebar } = storeToRefs(appStore.platformStore);

watch(currentSidebar, (newValue) => {
  if (newValue === undefined || newValue === "unset") {
    appStore.platformStore.setCurrentSidebar("projects");
  }
}, {
  immediate: true,
});
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div v-if="currentSidebar === 'projects'">
      <ProjectsList />
    </div>
    <div v-if="currentSidebar === 'recent'">
      <ProjectsRecent />
    </div>
    <div v-if="currentSidebar === 'marketplace'">
      <ProjectMarketplace />
    </div>
  </div>
</template>
