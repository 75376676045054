<script setup lang="ts">
import type { PropType } from "vue";
import type { DashboardWidget } from "~/model";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import {
  deleteProjectTemplate,
  useListProjectTemplatesForOrganization,
} from "~/api/project-templates/project-templates";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";
import { createGridHelper } from "~/store/useGridHelper";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
  },
});

const {
  currentOrganization,
} = storeToRefs(appStore.organizationStore);

const widget = ref(props.widget);

const gridHelper = createGridHelper(`organization-resources-${currentOrganization.value.id}`, {
  page: 1,
  pageSize: 6,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListProjectTemplatesForOrganization(currentOrganization.value.slug, gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = [
  { title: "Slug", field: "slug" },
  { title: "Name", field: "name" },
  { title: "Description", field: "organization.name" },
  { title: "Template", field: "template", type: "boolean" },
  { title: " ", field: "actions", cell: "actionCol" },
];

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});

const computedQuery = computed({
  get() {
    return gridHelper.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});

const el = ref(null);
const { width, height } = useElementSize(el);

const gridStyle = computed(() => {
  if (widget.value?.properties?.defaultPosition?.rowSpan) {
    const rowSpan = widget.value?.properties?.defaultPosition?.rowSpan || 6;
    const height = (rowSpan * 140) - 130;
    return {
      rowSpan: `${rowSpan}`,
      height: `${height}px`,
    };
  } else {
    return {
      rowSpan: "6",
      height: "calc(100vh - 12rem)",
    };
  }
});

const { user } = storeToRefs(appStore.userStore);

async function deleteItem(dataItem: any) {
  const confirmLostChanges = createConfirmDialog(KodexaConfirm);
  const result = await confirmLostChanges.reveal({
    icon: "alert-circle-outline",
    title: "Delete Project Template",
    message: `Are you sure you want to delete "${dataItem.name}" project template? This action cannot be undone.`,
    confirmText: "Delete Project Template",
    confirmIcon: "delete",
    cancelText: "Cancel",
    cancelIcon: "close",
    type: "danger",
  });

  if (!result) {
    return;
  }

  deleteProjectTemplate(dataItem.orgSlug, dataItem.slug).then(() => {
    notify({
      group: "generic",
      title: "Success",
      text: "Project template deleted successfully",
    });
    refetch();
  });
}

const showResource = ref(false);
const selectedResource = ref<any>();

function openResource(dataItem: any) {
  selectedResource.value = dataItem;
  showResource.value = true;
}
</script>

<template>
  <div ref="el" class="mx-4">
    <Toolbar class="mb-1 border-0 bg-white dark:bg-gray-800 mt-1">
      <Button
        id="projectTemplatesFilter"
        class="rounded-md bg-white dark:bg-gray-800 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:bg-gray-900  dark:text-gray-50" :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()"
      />
      <!--      <KodexaTextInput -->
      <!--        id="query" -->
      <!--        v-model="computedQuery" :show-clear="true" type="text" name="query" -->
      <!--        class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Filter project templates" -->
      <!--      /> -->
      <KodexaArticle class="mt-1" article-id="9034630" text="Learn more about managing Project Templates" :slide="true" />

      <ToolbarSpacer />
    </Toolbar>
    <Grid
      class="h-full"
      :style="gridStyle"
      :data-items="data ? data.content : []"
      :pageable="pageSettings"
      :loader="isLoading"
      :sort="sort"
      scrollable="scrollable"
      :skip="pageSettings.skip as number"
      :total="pageSettings.total as number"
      :page-size="pageSettings.take as number"
      :take="pageSettings.take as number"
      :columns="columns"
      :filterable="showFilter"
      :filter="filter"
      :resizable="true"
      :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No project templates found"
        />
      </GridNoRecords>
      <template #actionCol="{ props }">
        <td>
          <div class="flex items-center justify-end">
            <KodexaButton v-if="user.showDeveloperTools" id="viewProjectTemplate" name="view" icon="code-block-braces" size="small" type="secondary" @click="openResource(props.dataItem)">
              View
            </KodexaButton>
            <KodexaButton id="deleteProjectTemplate" name="delete" size="small" type="danger" class="ml-1" icon="delete" @click="deleteItem(props.dataItem)">
              Delete
            </KodexaButton>
          </div>
        </td>
      </template>
    </Grid>
    <OrganizationResourcePopup v-model="showResource" :resource="selectedResource" />
  </div>
</template>
