/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse,
  ListTaskDocumentFamiliesParams,
  PageTaskDocumentFamily,
  TaskDocumentFamily,
  ThrowableProblem
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getTaskDocumentFamily = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<TaskDocumentFamily>(
      {url: `/api/taskDocumentFamilies/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskDocumentFamilyQueryKey = (id: MaybeRef<string>,) => {
    return ['api','taskDocumentFamilies',id] as const;
    }

    
export const getGetTaskDocumentFamilyQueryOptions = <TData = Awaited<ReturnType<typeof getTaskDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTaskDocumentFamilyQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaskDocumentFamily>>> = ({ signal }) => getTaskDocumentFamily(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaskDocumentFamily>>, TError, TData> 
}

export type GetTaskDocumentFamilyQueryResult = NonNullable<Awaited<ReturnType<typeof getTaskDocumentFamily>>>
export type GetTaskDocumentFamilyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetTaskDocumentFamily<TData = Awaited<ReturnType<typeof getTaskDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaskDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetTaskDocumentFamilyQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateTaskDocumentFamily = (
    id: MaybeRef<string>,
    taskDocumentFamily: MaybeRef<TaskDocumentFamily>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
taskDocumentFamily = unref(taskDocumentFamily);
      
      return customAxios<TaskDocumentFamily>(
      {url: `/api/taskDocumentFamilies/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: taskDocumentFamily
    },
      options);
    }
  


export const getUpdateTaskDocumentFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskDocumentFamily>>, TError,{id: string;data: TaskDocumentFamily}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTaskDocumentFamily>>, TError,{id: string;data: TaskDocumentFamily}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTaskDocumentFamily>>, {id: string;data: TaskDocumentFamily}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTaskDocumentFamily(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTaskDocumentFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof updateTaskDocumentFamily>>>
    export type UpdateTaskDocumentFamilyMutationBody = TaskDocumentFamily
    export type UpdateTaskDocumentFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateTaskDocumentFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTaskDocumentFamily>>, TError,{id: string;data: TaskDocumentFamily}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateTaskDocumentFamily>>,
        TError,
        {id: string;data: TaskDocumentFamily},
        TContext
      > => {

      const mutationOptions = getUpdateTaskDocumentFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteTaskDocumentFamily = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/taskDocumentFamilies/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskDocumentFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskDocumentFamily>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTaskDocumentFamily>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTaskDocumentFamily>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteTaskDocumentFamily(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskDocumentFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTaskDocumentFamily>>>
    
    export type DeleteTaskDocumentFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteTaskDocumentFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTaskDocumentFamily>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteTaskDocumentFamily>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteTaskDocumentFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listTaskDocumentFamilies = (
    params?: MaybeRef<ListTaskDocumentFamiliesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageTaskDocumentFamily>(
      {url: `/api/taskDocumentFamilies`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListTaskDocumentFamiliesQueryKey = (params?: MaybeRef<ListTaskDocumentFamiliesParams>,) => {
    return ['api','taskDocumentFamilies', ...(params ? [params]: [])] as const;
    }

    
export const getListTaskDocumentFamiliesQueryOptions = <TData = Awaited<ReturnType<typeof listTaskDocumentFamilies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListTaskDocumentFamiliesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaskDocumentFamilies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListTaskDocumentFamiliesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTaskDocumentFamilies>>> = ({ signal }) => listTaskDocumentFamilies(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTaskDocumentFamilies>>, TError, TData> 
}

export type ListTaskDocumentFamiliesQueryResult = NonNullable<Awaited<ReturnType<typeof listTaskDocumentFamilies>>>
export type ListTaskDocumentFamiliesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListTaskDocumentFamilies<TData = Awaited<ReturnType<typeof listTaskDocumentFamilies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListTaskDocumentFamiliesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTaskDocumentFamilies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListTaskDocumentFamiliesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createTaskDocumentFamily = (
    taskDocumentFamily: MaybeRef<TaskDocumentFamily>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      taskDocumentFamily = unref(taskDocumentFamily);
      
      return customAxios<TaskDocumentFamily>(
      {url: `/api/taskDocumentFamilies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taskDocumentFamily, signal
    },
      options);
    }
  


export const getCreateTaskDocumentFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaskDocumentFamily>>, TError,{data: TaskDocumentFamily}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createTaskDocumentFamily>>, TError,{data: TaskDocumentFamily}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTaskDocumentFamily>>, {data: TaskDocumentFamily}> = (props) => {
          const {data} = props ?? {};

          return  createTaskDocumentFamily(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTaskDocumentFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof createTaskDocumentFamily>>>
    export type CreateTaskDocumentFamilyMutationBody = TaskDocumentFamily
    export type CreateTaskDocumentFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateTaskDocumentFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTaskDocumentFamily>>, TError,{data: TaskDocumentFamily}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createTaskDocumentFamily>>,
        TError,
        {data: TaskDocumentFamily},
        TContext
      > => {

      const mutationOptions = getCreateTaskDocumentFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForTaskDocumentFamily = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/taskDocumentFamilies/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForTaskDocumentFamilyQueryKey = (id: MaybeRef<string>,) => {
    return ['api','taskDocumentFamilies',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForTaskDocumentFamilyQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForTaskDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForTaskDocumentFamilyQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForTaskDocumentFamily>>> = ({ signal }) => getChangeSequenceForTaskDocumentFamily(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskDocumentFamily>>, TError, TData> 
}

export type GetChangeSequenceForTaskDocumentFamilyQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForTaskDocumentFamily>>>
export type GetChangeSequenceForTaskDocumentFamilyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForTaskDocumentFamily<TData = Awaited<ReturnType<typeof getChangeSequenceForTaskDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTaskDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForTaskDocumentFamilyQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



