<script lang="ts" setup>
import type { DataForm, DocumentFamily } from "~/model";
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { Button } from "@progress/kendo-vue-buttons";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import appStore from "~/store";

const emit = defineEmits(["closed"]);

const {
  dataForms,
} = storeToRefs(appStore.projectStore);

const { documentFamilies } = storeToRefs(appStore.taskStore);

function isInWorkspace(documentFamily: DocumentFamily) {
  return appStore.workspaceStore.hasDocumentFamilyById(documentFamily.id);
}

function addDocumentFamily(documentFamily: DocumentFamily) {
  appStore.workspaceStore.addDocumentFamily(documentFamily, false, false);
}

function removeDocumentFamily(documentFamily: DocumentFamily) {
  appStore.workspaceStore.removeDocumentFamily(documentFamily);
}

function openDocumentFamily(documentFamily: DocumentFamily) {
  appStore.workspaceStore.openDocumentView(documentFamily, !isInWorkspace(documentFamily));
}

function isDocumentFamilyOpen(documentFamily: DocumentFamily) {
  return appStore.workspaceStore.isDocumentViewOpen(documentFamily);
}

const rawQuery = ref("");

function setQuery(query: string) {
  rawQuery.value = query;
}

const filteredDocumentFamilies = computed(() => {
  return documentFamilies.value.filter((df: DocumentFamily) => {
    return df.path.toLowerCase().includes(rawQuery.value.toLowerCase());
  });
});

const documentForms = computed(() => {
  return dataForms.value.filter((df: DataForm) => df.entrypoints?.includes("documentFamily"));
});

function openDataForm(dataForm: DataForm, documentFamily: DocumentFamily) {
  appStore.workspaceStore.openDataForm(dataForm, documentFamily, !isInWorkspace(documentFamily));
}
</script>

<template>
  <div style="height: calc(100vh - 71px);">
    <div class="flex flex-col bg-white dark:bg-gray-800">
      <div class="flex-1">
        <!-- Header -->
        <div class="bg-gray-50 dark:bg-gray-900 px-4 py-6 sm:px-6">
          <div class="flex items-start justify-between space-x-3">
            <div class="space-y-1">
              <p class="mt-2 text-sm text-gray-500">
                Task Documents
              </p>
            </div>
            <div class="flex h-7 items-center">
              <button class="text-gray-400 hover:text-gray-500" type="button" @click="emit('closed')">
                <span class="sr-only">Close panel</span>
                <MaterialDesignIcon aria-hidden="true" class="h-6 w-6" name="close" />
              </button>
            </div>
          </div>
        </div>

        <Combobox>
          <div class="relative">
            <MaterialDesignIcon
              aria-hidden="true"
              class="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
              name="search"
            />
            <ComboboxInput
              v-debounce:300ms="setQuery"
              autofocus
              class="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
              placeholder="Search..."
            />
          </div>
          <p class="mx-2 mt-3">
            Found {{ filteredDocumentFamilies.length }} documents
          </p>
          <div style="height: calc(100vh - 15rem); overflow: auto">
            <ComboboxOptions
              v-if="filteredDocumentFamilies" class="scroll-py-10 scroll-pb-2 space-y-4 p-4 pb-2"
              static
              style="height: calc(100vh - 270px);"
            >
              <li v-if="filteredDocumentFamilies.length > 0">
                <ul class="-mx-4 text-sm text-gray-700">
                  <ComboboxOption
                    v-for="documentFamily in filteredDocumentFamilies" :key="documentFamily.id"
                  >
                    <div class="mx-1 mt-2">
                      <KodexaDocumentFamilyCard
                        :document-family="documentFamily"
                        :show-avatar="false" size="small"
                      />
                      <div class="ml-1">
                        <Button
                          v-if="isInWorkspace(documentFamily)"
                          v-tooltip:right="'Remove from workspace'"
                          class="mt-2 mr-1 bg-red-500 text-white" fill-mode="solid" size="small"
                          @click="removeDocumentFamily(documentFamily)"
                        >
                          <MaterialDesignIcon name="minus" size="12" />
                        </Button>
                        <Button
                          v-else v-tooltip:right="'Add to workspace'" class="mt-2 ml-1 bg-green-600 text-white"
                          fill-mode="solid"
                          size="small" @click="addDocumentFamily(documentFamily)"
                        >
                          <MaterialDesignIcon name="plus" size="12" />
                        </Button>
                        <Button
                          v-if="!isDocumentFamilyOpen(documentFamily)"
                          v-tooltip:right="'Open Document'"
                          class="mt-2 text-theme-primary"
                          fill-mode="outline"
                          size="small" @click="openDocumentFamily(documentFamily)"
                        >
                          <MaterialDesignIcon name="open" size="12" />
                        </Button>
                        <Button
                          v-for="documentForm in documentForms" :key="documentForm.ref"
                          v-tooltip:right="`Open ${documentForm.name}`"
                          class="mt-2 ml-1 text-theme-primary"
                          fill-mode="outline"
                          size="small"
                          @click="openDataForm(documentForm, documentFamily)"
                        >
                          <MaterialDesignIcon name="formTextbox" size="12" />
                        </Button>
                      </div>
                    </div>
                  </ComboboxOption>
                </ul>
              </li>
            </ComboboxOptions>
          </div>

          <div v-if="rawQuery === '?'" class="py-14 px-6 text-center text-sm sm:px-14">
            <MaterialDesignIcon aria-hidden="true" class="mx-auto h-6 w-6 text-gray-400" name="help" />
            <p class="mt-4 font-semibold text-gray-900">
              Help with Documents
            </p>
            <p class="mt-2 text-gray-500">
              Use this tool to quickly search for documents in any of the stores that are associated with the project
              workspace you are currently in.
            </p>
          </div>

          <div
            v-if="rawQuery !== '' && rawQuery !== '?' && filteredDocumentFamilies.length === 0"
            class="py-14 px-6 text-center text-sm sm:px-14"
          >
            <MaterialDesignIcon aria-hidden="true" class="mx-auto h-6 w-6 text-gray-400" name="alertBox" />
            <p class="mt-4 font-semibold text-gray-900">
              No documents found
            </p>
            <p class="mt-2 text-gray-500">
              We could not find anything with that term. Please try again.
            </p>
          </div>
        </Combobox>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
