<script lang="ts" setup>
import { log } from "~/utils/logger";

const props = defineProps({
  message: {
    type: String,
    required: false,
    default: "There was a problem with your submission",
  },
  lastError: {
    type: Object,
    required: true,
  },
});

const dataErrors = computed(() => {
  return props.lastError?.response?.data?.errors;
});

watch(() => props.lastError, () => {
  log.info(props.lastError.cause);
});
</script>

<template>
  <div v-if="lastError" class="mb-3 rounded-md bg-red-50 p-4">
    <div class="flex">
      <div class="shrink-0">
        <MaterialDesignIcon name="alertbox" class="text-red-600" />
      </div>
      <div v-if="dataErrors" class="ml-3">
        <h3 class="text-sm font-medium text-red-800">
          {{ message }}
        </h3>
        <div class="mt-2 text-sm text-red-700">
          <ul role="list" class="list-disc space-y-1 pl-5">
            <li v-for="(value, name) in lastError?.response?.data?.errors" :key="name">
              {{
                value
              }}
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="ml-3">
        <h3 class="text-sm font-medium text-red-800">
          {{ lastError.message }}
        </h3>
        <div class="mt-2 text-sm text-red-700">
          <ul role="list" class="list-disc space-y-1 pl-5">
            <li>
              {{ lastError.cause }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
