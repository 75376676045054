<script lang="ts" setup>
import type { IDockviewHeaderActionsProps } from "dockview-vue";

const props = defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
});

function move(direction: number) {
  const panels = props.params.containerApi.panels;
  // We need to find the panel then focus the one based on
  // direction
  const panel = props.params.containerApi.activePanel;
  if (!panel) {
    return;
  }
  const index = panels.indexOf(panel);
  const newIndex = index + direction;
  if (newIndex < 0 || newIndex >= panels.length) {
    return;
  }
  const newPanel = panels[newIndex];
  newPanel.focus();
}
</script>

<template>
  <div style="width: 50px">
    <MaterialDesignIcon
      name="menu-left"
      class="text-gray-500"
      @click="move(-1)"
    />
    <MaterialDesignIcon
      name="menu-right"
      class="text-gray-500"
      @click="move(1)"
    />
  </div>
</template>

<style scoped>

</style>
