<script setup lang="ts">
import hljs from "highlight.js";
import { marked } from "marked";
import markedAlert from "marked-alert";
import { markedHighlight } from "marked-highlight";

const props = defineProps({
  content: {
    type: String,
    required: false,
    default: "",
  },
  size: {
    type: String,
    required: false,
    default: "medium",
  },
  showClipboard: {
    type: Boolean,
    required: false,
    default: false,
  },
  style: {
    type: String,
    required: false,
    default: "",
  },
});

const emit = defineEmits(["nodeSelected"]);
marked.setOptions({ mangle: false, headerIds: false });
marked.use(markedAlert());
marked.use(markedHighlight({
  langPrefix: "language-",
  async: true,
  highlight(code, lang, info) {
    if (lang === "regex") {
      lang = "java";
    }
    const language = hljs.getLanguage(lang) ? lang : "plaintext";
    return hljs.highlight(code, { language }).value;
  },
}));

const finalContent = ref("");

// Function to preprocess markdown text
function preprocessMarkdown(text: string) {
  // Regular expression to find ('number') patterns
  const regex = /\(('(\d+(-\d+)?)(?:',\s*'(\d+(-\d+)?))*')\)/g;

  // Replace the found patterns with an HTML string for an icon
  // Here, we directly convert the pattern to HTML, but you could also convert it to a special markdown syntax and handle it in a custom renderer
  return text.replaceAll(regex, (match, p1, p2) => `<span class="clickable-line-id cursor-pointer" data-line-number="${p2}">🏷️</span>`);
}

function lineIdLinkClickHandler(event) {
  const lineNumber = event.target.getAttribute("data-line-number");
  if (lineNumber) {
    if (lineNumber.includes("-")) {
      emit("nodeSelected", lineNumber.split("-")[0]);
    } else {
      emit("nodeSelected", lineNumber);
    }
  }
}

onMounted(() => {
  document.addEventListener("click", lineIdLinkClickHandler);
});

onUnmounted(() => {
  document.removeEventListener("click", lineIdLinkClickHandler);
});

watch(() => props.content, () => {
  const resp = marked.parse(preprocessMarkdown(props.content));

  // if resp is a promise then we need to wait for it to resolve
  if (resp.then) {
    resp.then((r: string) => {
      finalContent.value = r;
    });
  } else {
    finalContent.value = resp;
  }
}, { immediate: true });
</script>

<template>
  <div :style="style">
    <article class="py-2 prose" style="width: 100%; font-size: 1em">
      <div class="markdown" v-html="finalContent" />
    </article>
    <KodexaClipboardable v-if="showClipboard" class="text-xs text-gray-600" :content="content" :show-content="false" message="Copy to clipboard" />
  </div>
</template>

<style scoped>
.prose {
  max-width: 100% !important;
}
</style>
