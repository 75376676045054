export const componentMetadata = {
  label: "Data Element",
  description: "Present a data element for editing",
  supportsChildren: false,
  defaultWidth: 3,
  defaultHeight: 2,
  options: [
    {
      label: "Label",
      name: "attributeLabel",
      type: "string",
      required: false,
    },
    {
      label: "Data Element",
      name: "taxon",
      type: "taxon",
      required: true,
    },
    {
      label: "Enable Checkbox",
      name: "isCheckbox",
      type: "boolean",
      description: "Enable checkbox input type",
      required: false,
      default: false,
    },
    {
      label: "On Check Value",
      name: "onCheckValue",
      type: "string",
      description: "Value when checkbox is checked",
      required: false,
      default: "None",
      showIf: "isCheckbox === true",
    },
    {
      label: "Enable Masked Text",
      name: "isMaskedText",
      type: "boolean",
      description: "Enable masked text input",
      required: false,
      default: false,
    },
    {
      label: "Mask Pattern",
      name: "maskPattern",
      type: "string",
      description: "Mask pattern (e.g. 00/00/00)",
      required: false,
      showIf: "isMaskedText === true",
    },
    {
      label: "Mask Date Format",
      name: "maskDateFormat",
      type: "string",
      description: "Format for displaying dates (e.g. mm/dd/yy)",
      required: false,
      default: "mm/dd/yy",
      showIf: "isMaskedText === true",
    },
    {
      label: "Source Date Format",
      name: "sourceDateFormat",
      type: "string",
      description: "Format for storing dates (e.g. yyyy-mm-dd)",
      required: false,
      default: "yyyy-mm-dd",
      showIf: "isMaskedText === true",
    },
  ],
};
