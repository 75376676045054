<script setup lang="ts">
import type { Ref } from "vue";
import type { Activity } from "~/model";
import { listActivities } from "~/api/activity/activity";

const timeline: Ref<Activity[]> = ref([]);

listActivities({
  pageSize: 5,
  sort: "createdOn:desc",
}).then((activities) => {
  if (activities.content) {
    timeline.value = activities.content;
  }
});

function getBackground(event: Activity) {
  if (event.activityType?.indexOf("FAILED") !== -1) {
    return "bg-red-500";
  } else if (event.activityType?.indexOf("SUCCESS") !== -1) {
    return "bg-green-500";
  } else {
    return "bg-gray-50 dark:bg-gray-9000";
  }
}

function getIcon(event: Activity) {
  if (event.activityType?.indexOf("FAILED") !== -1) {
    return "mdi-close";
  } else if (event.activityType?.indexOf("SUCCESS") !== -1) {
    return "mdi-check";
  } else {
    return "mdi-information";
  }
}

function titleCase(str) {
  return str.replaceAll("_", " ").toLowerCase().split(" ").map((word) => {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(" ");
}
</script>

<template>
  <div class="mx-4 mt-5 overflow-y-scroll">
    <ul role="list" class="-mb-8">
      <li v-for="(event, eventIdx) in timeline" :key="event.id">
        <div class="relative pb-8">
          <span
            v-if="eventIdx !== timeline.length - 1" class="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
          <div class="relative flex space-x-3">
            <div>
              <span
                class="flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white"
                :class="[getBackground(event)]"
              >
                <MaterialDesignIcon :name="getIcon(event)" class="ml-0.5 text-white" size="16" aria-hidden="true" />
              </span>
            </div>
            <div class="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
              <div>
                <p class="text-sm text-gray-500">
                  {{ titleCase(event.activityType) }}
                </p>
              </div>
              <div class="whitespace-nowrap text-right text-sm text-gray-500">
                <time :datetime="event.createdOn">{{ event.createdOn }}</time>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
