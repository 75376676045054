<script setup lang="ts">
import type { PropType } from "vue";
import type { Project } from "~/model";

defineProps({
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
  includeOrganization: {
    type: Boolean,
    default: false,
  },
  includeTags: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["updated"]);

function getProjectLink(project: Project) {
  return `/a/o/${project.organization?.id}/p/${project.id}/home`;
}
</script>

<template>
  <div>
    <div class="flex items-center">
      <div class="ml-1">
        <RouterLink :to="getProjectLink(project)" class="cursor-pointer text-lg font-bold text-gray-900 dark:text-gray-100">
          <span v-if="project?.name" id="projectName" class="wrap-text">{{ project?.name }}</span>
          <span v-else id="untitledProject" class="wrap-text">Untitled Project</span>
        </RouterLink>
        <div v-if="includeOrganization" class="mb-2 flex text-sm font-medium capitalize dark:text-gray-300">
          {{ project.organization?.name }}
        </div>
        <div class="text-xs text-gray-700 dark:text-gray-400">
          {{ project.description }}
        </div>
        <div v-if="includeTags" class="ml-2 flex items-center">
          <KodexaProjectTags :project="project" class="-ml-4 mt-4" @updated="emit('updated')" />
        </div>
      </div>
    </div>
  </div>
</template>
