<script lang="ts" setup>
import type { SplitterPaneProps } from "@progress/kendo-vue-layout";
import type { ViewportTransform } from "@vue-flow/core";
import type { Ref } from "vue";
import type { Assistant, DataFlowNode } from "~/model";
import { GridToolbar } from "@progress/kendo-vue-grid";
import { Splitter } from "@progress/kendo-vue-layout";
import { Background } from "@vue-flow/background";
import { Controls } from "@vue-flow/controls";
import { useVueFlow, VueFlow } from "@vue-flow/core";
import { MiniMap } from "@vue-flow/minimap";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import KodexaDataFlowAssistantNode from "~/components/dataFlow/kodexa-data-flow-assistant-node.vue";
import KodexaDataFlowDataStoreNode from "~/components/dataFlow/kodexa-data-flow-data-store-node.vue";
import KodexaDataFlowDocumentStoreNode from "~/components/dataFlow/kodexa-data-flow-document-store-node.vue";
import KodexaDataFlowModelStoreNode from "~/components/dataFlow/kodexa-data-flow-model-store-node.vue";
import KodexaDataFlowTaxonomyNode from "~/components/dataFlow/kodexa-data-flow-taxonomy-node.vue";
import KodexaDataFlowAssistantPanel from "~/components/dataFlow/panels/kodexa-data-flow-assistant-panel.vue";
import KodexaDataFlowDataStorePanel from "~/components/dataFlow/panels/kodexa-data-flow-data-store-panel.vue";
import KodexaDataFlowDocumentStorePanel from "~/components/dataFlow/panels/kodexa-data-flow-document-store-panel.vue";
import KodexaDataFlowModelPanel from "~/components/dataFlow/panels/kodexa-data-flow-model-panel.vue";
import KodexaDataFlowStepPanel from "~/components/dataFlow/panels/kodexa-data-flow-step-panel.vue";
import KodexaDataFlowTaxonomyPanel from "~/components/dataFlow/panels/kodexa-data-flow-taxonomy-panel.vue";
import { createDataFlowStore } from "~/components/dataFlow/useDataFlow";
import { useLayout } from "~/components/dataFlow/useLayout";
import appStore from "~/store";
import { log } from "~/utils/logger";

const emit = defineEmits(["addNewResource", "openLibrary"]);

/**
 * useVueFlow provides all event handlers and store properties
 * You can pass the composable an object that has the same properties as the VueFlow component props
 */
const {
  onConnect,
  onEdgeUpdate,
  setNodes,
  setEdges,
  fitView,
} = useVueFlow();

const {
  onLayout,
} = useLayout();

const dataFlowStore = createDataFlowStore();
const {
  currentDataFlow,
  flowNodes,
  flowEdges,
} = storeToRefs(dataFlowStore);

const hideInactive = ref(true);

const viewport = ref({ zoom: 0, x: 0, y: 0 });

onConnect((params) => {
  dataFlowStore.onConnect(params);
});

onEdgeUpdate((params) => {
  dataFlowStore.updateEdge(params);
});

dataFlowStore.setToggleInactive(true);

function performLayout() {
  onLayout("LR");
}

const panes: Ref<SplitterPaneProps[]> = ref([
  {
    size: undefined,
    min: "2%",
    collapsible: false,
    content: "flow",
  },
  {
    size: "40%",
    min: "5%",
    collapsible: true,
    collapsed: true,
    content: "properties",
  },
] as SplitterPaneProps[]);

function onSplitterChange(changeEvent: any) {
  if (changeEvent.newState) {
    panes.value = changeEvent.newState;
  }
}

onMounted(() => {
  nextTick(() => {
    fitView();
  });
});

const selectedNode = ref();

function selectNode(node: DataFlowNode | undefined) {
  selectedNode.value = node;
  if (node) {
    panes.value[1].collapsed = false;
  }
}

const nodePanels = {
  assistant: KodexaDataFlowAssistantPanel,
  modelStore: KodexaDataFlowModelPanel,
  documentStore: KodexaDataFlowDocumentStorePanel,
  dataStore: KodexaDataFlowDataStorePanel,
  taxonomy: KodexaDataFlowTaxonomyPanel,
  step: KodexaDataFlowStepPanel,
};

const nodeComponents = {
  assistant: KodexaDataFlowAssistantNode,
  modelStore: KodexaDataFlowModelStoreNode,
  documentStore: KodexaDataFlowDocumentStoreNode,
  dataStore: KodexaDataFlowDataStoreNode,
  taxonomy: KodexaDataFlowTaxonomyNode,
};

function setViewport(viewportTransform: ViewportTransform) {
  viewport.value = viewportTransform;
}

watchDebounced(flowNodes, () => {
  log.info("Data flow nodes changed");
  setNodes(flowNodes.value);
  setEdges(flowEdges.value);
  onLayout("TR");
}, {
  deep: true,
  immediate: true,
  debounce: 500,
});

function selectModel(step, idx, assistant: Assistant) {
  selectedNode.value = {
    id: `step://${step.ref}`,
    step,
    idx,
    assistant,
  };
  panes.value[1].collapsed = false;
}

const saving = ref(false);

async function saveLayout() {
  saving.value = true;
  await appStore.projectStore.saveProjectDataFlow(currentDataFlow.value, viewport.value);
  saving.value = false;
}

function updateModelOptions(options) {
  if (selectedNode.value) {
    // We need to find the assistant and update the options for just that model
    selectedNode.value.step.options = options;
  }
}

function stepDeleted() {
  if (selectedNode.value) {
    selectedNode.value = undefined;
    panes.value[1].collapsed = true;
    log.info("Step deleted, collapsing properties pane");
  }
}

function toggleInactive() {
  hideInactive.value = !hideInactive.value;
  dataFlowStore.setToggleInactive(hideInactive.value);
}

const showConnections = ref(false);

function toggleReferences() {
  showConnections.value = !showConnections.value;
  dataFlowStore.setShowConnections(showConnections.value);
}
</script>

<template>
  <GridToolbar class="border-0 bg-white dark:bg-gray-800">
    <KodexaButton id="flowLayout" size="small" type="secondary" icon="layers-outline" @click="performLayout">
      Layout
    </KodexaButton>
    <KodexaButton id="flowLayoutToggleActive" size="small" type="secondary" icon="layers-outline" @click="toggleInactive">
      {{ hideInactive ? 'Show Inactive' : 'Hide Inactive' }}
    </KodexaButton>
    <KodexaButton id="flowLayoutToggleReferences" size="small" type="secondary" icon="link-variant" @click="toggleReferences">
      {{ showConnections ? 'Hide References' : 'Show References' }}
    </KodexaButton>
    <KodexaButton id="flowOpenLibrary" size="small" type="secondary" icon="library-outline" @click="emit('openLibrary')">
      Open Library
    </KodexaButton>
    <KodexaButton id="saveLayout" size="small" type="secondary" icon="content-save" :loading="saving" @click="saveLayout">
      Save Layout
    </KodexaButton>
    <KodexaArticle class="mt-2 ml-2" article-id="9787709" text="Learn more about data flows" :slide="false" />
  </GridToolbar>
  <div v-if="currentDataFlow" style="width: 100%; height: calc(100vh - 12rem)">
    <Splitter
      :panes="panes"
      style="height: calc(100vh - 12rem); width: 100%"
      orientation="horizontal"
      @change="onSplitterChange"
    >
      <template #flow>
        <VueFlow
          class="project-data-flow"
          :default-viewport="viewport"
          fit-view-on-init
          :default-edge-options="{ type: 'smoothstep' }"
          @viewport-change="setViewport"
        >
          <Background :gap="8" />
          <MiniMap />
          <Controls />
          <template #edge-subscription="buttonEdgeProps">
            <KodexaCustomEdgeLabel
              :id="buttonEdgeProps.id"
              :source-x="buttonEdgeProps.sourceX"
              :source-y="buttonEdgeProps.sourceY"
              :target-x="buttonEdgeProps.targetX"
              :target-y="buttonEdgeProps.targetY"
              :source-position="buttonEdgeProps.sourcePosition"
              :target-position="buttonEdgeProps.targetPosition"
              :marker-end="buttonEdgeProps.markerEnd"
              :style="buttonEdgeProps.style"
              :label="buttonEdgeProps.label"
            />
          </template>
          <template #node-custom="node">
            <div class="bg-white dark:bg-gray-900">
              <Component
                :is="nodeComponents[node?.id.split(':')[0]]"
                :key="node.id" :node="node" @selected="selectNode(node)"
                @selected-step="selectModel"
              />
            </div>
          </template>
        </VueFlow>
      </template>
      <template #properties>
        <div style="height: calc(100vh - 10rem); width: 100%; overflow: auto">
          <Component :is="nodePanels[selectedNode?.id.split(':')[0]]" v-if="selectedNode" :node="selectedNode" @update-model-options="updateModelOptions" @step-deleted="stepDeleted" />
          <div v-else class="mt-32 text-center">
            <MaterialDesignIcon class="mx-auto text-gray-400" name="settings" size="30" />
            <h3 class="mt-2 text-sm font-semibold text-gray-900">
              No Selected Component
            </h3>
            <p class="mt-1 text-sm text-gray-500">
              Select a component from the diagram to see the properties available
            </p>
            <div class="mt-6" />
          </div>
        </div>
      </template>
    </Splitter>
  </div>
</template>

<style>
:root {
  /* Light mode variables */
  --flow-node-bg: #ffffff;
  --flow-node-border: #e2e8f0;
  --flow-node-text: #1a202c;
}

/* Alternative: class-based dark mode toggle */
.dark {
  --flow-node-bg: rgb(17, 24, 39);
  --flow-node-border: #2d3748;
  --flow-node-text: #e2e8f0;
}

.project-data-flow .vue-flow__node-custom {
  border: 1px solid #777;
  padding: 10px;
  border-radius: 10px;
  background: var(--flow-node-bg);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  max-width: 250px
}

.project-data-flow button {
  padding: 5px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  -webkit-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .3);
  box-shadow: 0 5px 10px #0000004d;
  cursor: pointer
}

.project-data-flow button:hover {
  opacity: .9;
  transform: scale(105%);
  transition: .25s all ease
}
</style>
