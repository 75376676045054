<script setup lang="ts">
import type { PropType, Ref } from "vue";
import type { Channel, Message } from "~/model";
import { storeToRefs } from "pinia";
import { nextTick, onMounted, onUnmounted } from "vue";

import KodexaMessageBlock from "~/components/messages/kodexa-message-block.vue";
import appStore from "~/store";
import { createChannelStore } from "~/store/useChannel";
import { log } from "~/utils/logger";

const props = defineProps({
  channel: {
    type: Object as PropType<Channel>,
    required: true,
  },
});

defineEmits(["closed"]);

const { user } = storeToRefs(appStore.userStore);
const { activeSelectionView, activeView } = storeToRefs(appStore.workspaceStore);
const channelStore = createChannelStore(props.channel.id as string);
const {
  messages,
  isRefetching,
} = storeToRefs(channelStore);

const currentMessage = ref("");
const pageOnly = ref(false);

async function sendMessage() {
  const messageContent = currentMessage.value;
  currentMessage.value = "";

  const context = channelStore.getContext(activeSelectionView.value);

  // If we want the whole document then we want the page to be undefined
  if (pageOnly.value === false && context) {
    context.page = -1;
  }

  await channelStore.sendTextMessage(messageContent, context);
}

const chatBottom: Ref<HTMLElement | null> = ref(null);

function selectFeedback(message: Message, feedbackOption: any) {
  channelStore.sendFeedback(message, feedbackOption);
}

const chatContainer: Ref<HTMLUListElement | null> = ref(null);

let observer: MutationObserver | null = null;

onMounted(() => {
  if (chatContainer.value) {
    observer = new MutationObserver(scrollToBottom);
    observer.observe(chatContainer.value, { childList: true, subtree: true });
  }
});

onUnmounted(() => {
  if (observer) {
    observer.disconnect();
    observer = null;
  }
});

function scrollToBottom() {
  nextTick(() => {
    if (chatContainer.value) {
      const { scrollHeight, clientHeight, scrollTop } = chatContainer.value;
      const maxScrollTop = scrollHeight - clientHeight;
      const isScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 10; // Allow for small margin of error

      if (isScrolledToBottom) {
        chatContainer.value.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      }
    }
  });
}

function openLogs(executionId: string) {
  // Open a text viewer with the logs
  appStore.workspaceStore.createTextViewer("Chat Execution (logs)", "", executionId);
}

function nodeSelected(lineNumber: string) {
  log.info("Node selected", lineNumber);
  appStore.workspaceStore.setFocusedNodeUuid(lineNumber);
}

const {
  isZeroCredits,
} = storeToRefs(appStore.organizationStore);

function handleEnter(event: KeyboardEvent) {
  if (!event.shiftKey && !event.ctrlKey) {
    sendMessage();
  }
}

function handleCtrlEnter() {
  currentMessage.value += "\n";
}
</script>

<template>
  <div class="mt-4">
    <ul v-if="channel" ref="chatContainer" role="list" style="height: calc(100vh - 31rem); overflow: auto; width: 100%">
      <li v-for="message in messages.slice().reverse()" :key="message.uuid" class="mb-3 animate-fadeIn animate-fast">
        <div class="relative">
          <div class="relative flex items-start">
            <div class="min-w-0 flex-1">
              <div>
                <div class="text-sm">
                  <KodexaUserAvatar
                    v-if="message.user && !message.assistant" style="float:left; margin-top: 1px; margin-right: 8px"
                    :user="message.user"
                  />
                  <img
                    v-else style="float:left; margin-top: 1px; margin-right: 8px"
                    class="mx-auto size-8 rounded-md" src="/assets/ai-brain.png"
                    alt=""
                  >
                  <span v-if="message.user && !message.assistant" class="font-md text-sm text-black">{{
                    message.user.firstName
                  }} {{ message.user.lastName }}</span>
                  <span v-if="message.assistant" class="font-md text-sm text-black">{{ message.assistant.name }}</span>
                  <p class="ml-8 text-xs text-gray-500">
                    <KodexaDateTimeView :date="message.createdOn" :ago="true" />
                    <MaterialDesignIcon
                      name="delete" class="ml-1 size-4 text-red-600" aria-hidden="true"
                      size="12" @click="channelStore.deleteChannelMessage(message)"
                    />
                    <MaterialDesignIcon
                      v-if="message.block?.properties?.executionId && user.showDeveloperTools" name="text-long"
                      class="ml-1 size-4 text-gray-600"
                      aria-hidden="true"
                      size="12" @click="openLogs(message.block.properties.executionId)"
                    />
                  </p>
                </div>
              </div>
              <div v-if="message.messageType === 'TEXT'" class="text-gray-700">
                <KodexaMarkdown
                  :content="message.content"
                  :show-clipboard="true"
                  @node-selected="nodeSelected"
                />
              </div>
              <div v-if="(message.messageType === 'BLOCK' || message.messageType === 'TEMPLATE')">
                <KodexaMessageBlock
                  v-if="message.block" :message-block="message.block" :channel="channel"
                  :message="message"
                />
                <span v-else>No block defined?</span>
              </div>
              <div v-if="message.feedback?.options && !message.context?.feedbackOption" class="mb-4">
                <KodexaButton
                  v-for="(feedbackOption, idx) in message.feedback.options" :key="idx" size="small" type="secondary"
                  class="ml-1 mt-1" @click="selectFeedback(message, feedbackOption)"
                >
                  {{ feedbackOption.label }}
                </KodexaButton>
              </div>
              <div v-if="message.context?.feedbackOption" class="mb-1 ml-2">
                <span class="text-sm text-gray-500">Sent request: {{ message.context.feedbackOption.label }}</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div ref="chatBottom" />
  </div>
  <div v-if="channel">
    <div class="mt-1 text-xs text-gray-500">
      <KodexaArticle article-id="9122631" text="AI responses should be reviewed for accuracy" :slide="false" />
    </div>
    <KodexaTextArea
      v-if="!isZeroCredits"
      id="chatMessage"
      v-model="currentMessage" :disabled="!activeView && channel.workspace !== undefined" :rows="7"
      name="comment"
      class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
      placeholder="Send a message"
      @keydown.enter.exact.prevent="handleEnter"
      @keydown.ctrl.enter.exact.prevent="handleCtrlEnter"
      @keydown.enter.shift.exact.prevent="currentMessage += '\n'"
    />

    <KodexaTextArea
      v-else id="chatMessage"
      v-model="currentMessage"
      disabled :disabled="!activeView && channel.workspace !== undefined" :rows="7"
      name="comment"
      class="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
      placeholder="Chat is disabled. Your organization does not have any AI credits."
    />

    <KodexaButton
      v-if="activeView && channel.workspace"
      id="sendButton"
      icon="send"
      size="small"
      class="mr-2"
      :disabled="currentMessage === ''"
      @click="sendMessage"
    >
      Send
    </KodexaButton>
    <span
      class="inline-flex cursor-pointer items-center justify-end rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
      @click="pageOnly = !pageOnly"
    >
      {{ pageOnly ? 'Reviewing Page' : 'Reviewing Document' }}
      <span v-if="isRefetching" class="mx-1 inline-block size-2 animate-pulse rounded-full bg-green-500" />
      <span v-else class="mx-1 inline-block size-2 rounded-full bg-green-300" />
    </span>
  </div>
</template>

<style scoped>

</style>
