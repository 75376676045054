<script setup lang="ts">
import type { IDockviewHeaderActionsProps } from "dockview-vue";
import { Window } from "@progress/kendo-vue-dialogs";
import { GridLayout } from "grid-layout-plus";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { createDataFormViewerStore } from "~/store/useDataFormView";
import EditorDataFormPanel from "./data-form-editor/editor-data-form-panel.vue";
import EditorDataFormToolbar from "./data-form-editor/editor-data-form-toolbar.vue";

const props = defineProps({
  params: {
    type: Object as PropType<IDockviewHeaderActionsProps>,
    required: true,
  },
});
const viewId = props.params.api.id.replace("view-", "") as string;
const dataFormEditorStore = createDataFormEditorStore(viewId)();
const { layout } = storeToRefs(dataFormEditorStore);
const { panels, selectedPanel } = storeToRefs(dataFormEditorStore);
const isReady = ref(false);

// Match the 12-column layout from kodexa-data-form-view
const cols = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 };
const useDataFormViewer = createDataFormViewerStore(viewId as string);

const {
  dataForm,
} = storeToRefs(useDataFormViewer);

function handleLayoutUpdate(newLayout: any) {
  dataFormEditorStore.updateLayout(newLayout);
}

function handleOutsideClick(e: MouseEvent) {
  if ((e.target as HTMLElement).classList.contains("grid-container")) {
    dataFormEditorStore.selectPanel(null);
  }
}

onMounted(() => {
  if (dataForm.value?.cards) {
    dataFormEditorStore.initialize(dataForm.value.cards);
  }

  if (!isReady.value) {
    isReady.value = true;
  }
});

const localLayout = computed({
  get: () => layout.value,
  set: value => handleLayoutUpdate(value),
});
</script>

<template>
  <div class="app-container">
    <EditorDataFormToolbar :view-id="viewId" :data-form="dataForm" />
    <div class="grid-container" @click="handleOutsideClick">
      <GridLayout
        v-model:layout="localLayout"
        :cols="cols"
        :row-height="70"
        :margin="[5, 5]"
        :min-h="2"
        vertical-compact
        use-css-transforms
      >
        <template #item="{ item }">
          <EditorDataFormPanel
            :panel="panels.find(p => p.id === item.i)!"
            :is-selected="selectedPanel?.id === item.i"
            :view-id="viewId"
            @select="dataFormEditorStore.selectPanel"
            @delete="dataFormEditorStore.deletePanel"
          />
        </template>
      </GridLayout>
    </div>
    <Window v-if="selectedPanel" title="Properties" :width="400" :height="600">
      <EditorDataFormProperties :view-id="viewId" />
    </Window>
  </div>
</template>

<style scoped>
.app-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.grid-container {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f5f5;
  padding: 20px;
  overflow: auto;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 16px;
}

.add-button {
  padding: 12px 24px;
  background-color: #68B984;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.add-button:hover {
  background-color: #5aa873;
}
</style>
