<script setup lang="ts">
import type { GetMarketPlaceParams, ProjectTemplate } from "~/model";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Pager } from "@progress/kendo-vue-data-tools";
import { storeToRefs } from "pinia";
import { useGetAvailableTemplates } from "~/api/organizations/organizations";
import appStore from "~/store";

const emit = defineEmits(["templateSelected", "closed"]);
const { user } = storeToRefs(appStore.userStore);

const includePublic = ref(false);
const { currentOrganization } = storeToRefs(appStore.organizationStore);

// TODO we need to have pagination in here from the server
const marketPlaceQuery = ref<GetMarketPlaceParams>({
  selectionType: "projects",
  page: 1,
  pageSize: 6,
  filter: "",
  query: "",
  sort: "productProjectTemplates.displayOrder",
});
const {
  data,
  isLoading,
} = useGetAvailableTemplates(currentOrganization.value.id, marketPlaceQuery.value);

function useTemplate(projectTemplate: ProjectTemplate) {
  emit("templateSelected", projectTemplate, false);
}

const pageSettings = ref({
  skip: ((marketPlaceQuery.value.page ? marketPlaceQuery.value.page : 1) - 1)
    * (marketPlaceQuery.value.pageSize ? marketPlaceQuery.value.pageSize : 6),
  take: marketPlaceQuery.value.pageSize ? marketPlaceQuery.value.pageSize : 6,
  buttonCount: 5,
  type: "numeric",
  info: true,
  previousNext: true,
  total: data.value?.totalElements as number,
});

watch(data, (newValue) => {
  if (newValue) {
    pageSettings.value.total = newValue.totalElements as number;
  }
}, {
  immediate: true,
});

const query = ref("");

function pageChangeHandler(event: any) {
  pageSettings.value.skip = event.skip;
  pageSettings.value.take = event.take;
  marketPlaceQuery.value.page = (event.skip / marketPlaceQuery.value.pageSize) + 1;
}

watch(data, () => {
  if (data.value) {
    if (data.value.totalElements === 1 && data.value.content && query.value === "") {
      emit("templateSelected", data.value.content[0], true);
    }
  }
});

watch([currentOrganization, includePublic, query], () => {
  const queryParts: string[] = [];
  if (currentOrganization.value && !includePublic.value) {
    queryParts.push(`organization.id: '${currentOrganization.value.id}'`);
  }

  if (query.value && query.value.length > 0) {
    queryParts.push(`name~ '%${query.value}%'`);
  }

  marketPlaceQuery.value.filter = queryParts.join(" and ");
}, { immediate: true });
</script>

<template>
  <div>
    <KodexaHeader
      class="ml-4"
      title="Project Templates" sub-title="Choose a project template to get started"
      object-type="project"
    >
      <template #subTitle>
        Choose a project template from the library to get started
      </template>
      <template #actions>
        <div class="flex h-7 items-center">
          <button type="button" class="text-gray-400 hover:text-gray-500" @click="emit('closed')">
            <span class="sr-only">Close panel</span>
            <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" />
          </button>
        </div>
      </template>
    </KodexaHeader>
    <Toolbar class="mx-4 mb-1 mt-2 border-0 bg-white dark:bg-gray-800">
      <label for="query" class="sr-only">Query</label>
      <KodexaTextInput
        id="marketPlaceQuery" v-model="query" type="text" name="query"
        placeholder="Filter..."
      />

      <KodexaArticle article-id="9034107" class="mt-2" text="Learn more about creating projects from templates" :slide="true" />
      <ToolbarSpacer />
      <div v-if="data" class="mt-1 text-xs">
        {{ data.totalElements }} matches
      </div>
      <Pager
        class="float-right mx-4 mb-1 mt-2 border-0 bg-white dark:bg-gray-800"
        size="small"
        :page-size="marketPlaceQuery.pageSize"
        :button-count="pageSettings.buttonCount"
        :skip="pageSettings.skip"
        :total="pageSettings.total"
        :take="pageSettings.take"
        :previous-next="pageSettings.previousNext"
        :loading="isLoading"
        @pagechange="pageChangeHandler"
      />
    </Toolbar>
    <div>
      <div class="border-1 border-gray-600" style="height: calc(100vh); overflow-y: scroll">
        <KodexaSmallLoader v-if="isLoading" class="mt-40" />
        <div class="p-4">
          <ul v-if="data" role="list" class="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
            <li
              v-for="projectTemplate in data.content" :id="projectTemplate.slug"
              :key="projectTemplate.ref"
              class="col-span-1 flex h-60 flex-col divide-y divide-gray-200 rounded-lg bg-white dark:bg-gray-800 shadow"
            >
              <div
                class="flex flex-1 cursor-pointer p-3" style="overflow-y: hidden"
                @click="useTemplate(projectTemplate)"
              >
                <div class="flex grow flex-col p-2">
                  <div class="text-lg font-semibold text-black">
                    {{ projectTemplate.name }}
                  </div>
                  <div v-if="user?.showDeveloperTools" class="text-md mt-1 text-gray-500">
                    {{ projectTemplate.title }}
                    <div class="text-xs">
                      @{{ projectTemplate.orgSlug }} <span
                        v-if="!projectTemplate.publicAccess"
                        class="inline-flex items-center rounded-full bg-blue-50 px-2.5 py-0.5 text-xs font-light text-blue-800"
                      >Private</span>
                    </div>
                  </div>

                  <dd class="mt-3 text-sm">
                    <span>{{ projectTemplate.description }}</span>
                  </dd>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
