<script lang="ts" setup>
import type { Store } from "pinia";
import type { DocumentFamily } from "~/model";
import { Combobox, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { onUnmounted, ref } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { useListDocumentFamilies } from "~/api/document-families/document-families";
import { getContentObjectContent } from "~/api/stores/stores";
import KodexaDocumentAssignees from "~/components/document/kodexa-document-assignee.vue";
import KodexaDocumentExtractedData from "~/components/document/kodexa-document-extracted-data.vue";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import KodexaSmallLoader from "~/components/kodexa-small-loader.vue";
import KodexaDateTimeView from "~/components/util/kodexa-date-time-view.vue";
import appStore from "~/store";
import { RefHelper } from "~/utils/ref-utils";
import { useGridScroll } from "~/utils/useGridScroll";

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const storeRef = props.params.params.storeRef as string;

const {
  project,
  documentStores,
} = storeToRefs(appStore.projectStore);

// Setup our grid helpers
const gridHelper = createGridHelper(`project-documents-${project.value.id}-${storeRef}`, {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "",
}, undefined, [{
  field: "path",
  dir: "asc",
}]);

const {
  pageSettings,
  gridQuery,
  sort,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  data,
  refetch,
  isRefetching,
  error,
  isError,
} = useListDocumentFamilies(gridQuery, { query: { refetchInterval: 5000, retry: false } }, { ref: storeRef });

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
    gridHelper.saveState();
  }
});

const documentStore = computed(() => {
  return documentStores.value.find((ds: Store) => ds.ref === storeRef);
});

const { documentFamilies } = storeToRefs(appStore.workspaceStore);

function isInWorkspace(documentFamily: DocumentFamily) {
  return appStore.workspaceStore.hasDocumentFamilyById(documentFamily.id);
}

function addDocumentFamily(documentFamily: DocumentFamily) {
  appStore.workspaceStore.addDocumentFamily(documentFamily, false, false);
}

function removeDocumentFamily(documentFamily: DocumentFamily) {
  appStore.workspaceStore.removeDocumentFamily(documentFamily);
}

function openDocumentFamily(documentFamily: DocumentFamily) {
  appStore.workspaceStore.openDocumentView(documentFamily, false);
}

function downloadDocumentFamily(documentFamily: DocumentFamily) {
  const nativeContentObject = documentFamily.contentObjects?.find(co => co.contentType === "NATIVE");
  if (nativeContentObject && nativeContentObject.id && documentFamily.id) {
    const refHeader = new RefHelper(documentFamily.storeRef);
    getContentObjectContent(refHeader.getOrgSlug(), refHeader.getSlug(), documentFamily.id, nativeContentObject.id).then((content) => {
      const fileURL = window.URL.createObjectURL(new Blob([content]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", documentFamily.path);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  }
}

const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);

const isRenaming = ref("");

async function openDeleteFamilyConfirm(documentFamily: DocumentFamily) {
  deleteConfirmDialog.onConfirm(async () => {
    await appStore.workspaceStore.deleteDocumentFamilyById(documentFamily.id);
    refetch();
    notify({
      title: `Document Family ${documentFamily.path} Deleted`,
      group: "generic",
    });
  });
  await deleteConfirmDialog.reveal({
    title: `Delete ${documentFamily.path} ?`,
    message: "Are you sure you want to delete the uploaded document, and its related content?",
  });
}

async function toggleLock(documentFamily: DocumentFamily) {
  await appStore.workspaceStore.showSaveWarning();

  if (documentFamily.locked) {
    await appStore.workspaceStore.unlockDocumentFamily(documentFamily);
    await refetch();
  } else {
    await appStore.workspaceStore.lockDocumentFamily(documentFamily);
    await refetch();
  }
}

const showUpload = ref(false);

async function startUpload() {
  await appStore.workspaceStore.showSaveWarning();
  showUpload.value = true;
}

const selectedDocumentFamily = ref<DocumentFamily | null>(null);
const showDetails = ref(false);

function openDetails(documentFamily: DocumentFamily) {
  selectedDocumentFamily.value = documentFamily;
  showDetails.value = true;
}

const selectedDocumentFamilies = ref([] as DocumentFamily[]);

function toggleSelection() {
  if (selectedDocumentFamilies.value.length === 0 && data.value?.content) {
    selectedDocumentFamilies.value = data.value.content;
  } else {
    selectedDocumentFamilies.value = [];
  }
}

function toggleSingle(documentFamily: DocumentFamily) {
  if (selectedDocumentFamilies.value.some(df => df.id === documentFamily.id)) {
    selectedDocumentFamilies.value = selectedDocumentFamilies.value.filter(df => df.id !== documentFamily.id);
  } else {
    selectedDocumentFamilies.value = [...selectedDocumentFamilies.value, documentFamily];
  }
}

watch(isLoading, (newValue) => {
  if (newValue) {
    selectedDocumentFamilies.value = [];
  }
});

function clearSelection() {
  selectedDocumentFamilies.value = [];
}

const tableElement = ref(null);
const gridScroll = useGridScroll(tableElement); // Use the composable
onUnmounted(() => {
  gridScroll.unmount();
});

const columns = computed(() => {
  return [
    {
      title: "Path",
      field: "path",
      cell: "path",
      width: "300px",
      filter: "text",
    },
    {
      title: "Modified",
      field: "modified",
      cell: "modified",
      width: "150px",
      filter: "date",
    },
    {
      title: "Created",
      field: "created",
      cell: "created",
      width: "150px",
      filter: "date",
    },
    {
      title: "Extracted Data",
      field: "activity",
      cell: "extractedData",
      width: "250px",
      filterable: false,
      sortable: false,
      columnMenu: false,
    },
    {
      title: "Assignees",
      field: "assignment.user.firstName",
      cell: "assignees",
      width: "250px",
      filterable: true,
      sortable: true,
      columnMenu: true,
    },
    {
      title: "Activity",
      field: "activity",
      cell: "activity",
      width: "300px",
      filterable: false,
      sortable: false,
      columnMenu: false,
    },
    {
      title: "",
      cell: "actions",
      filterable: false,
      sortable: false,
      width: "150px",
      columnMenu: false,
    },
  ];
});

const viewEl = ref(null);
const { width } = useElementSize(viewEl);
const forceFullView = ref(false);

const fullView = computed(() => {
  return width.value > 600 || forceFullView.value;
});

const structuredFilter = ref(false);

const filterPlaceholder = computed(() => {
  if (structuredFilter.value) {
    return "Structured filter...";
  } else {
    return "Quick filter...";
  }
});

const rawQuery = ref("");
rawQuery.value = gridQuery.value.query;

setQuery(gridQuery.value.query);

function setQuery(query: string) {
  gridHelper.setPage(1);

  if (structuredFilter.value) {
    gridHelper.setQuery("*");
  } else {
    gridHelper.setQuery(query);
  }
}

watchDebounced(() => [documentStores, storeRef, rawQuery.value], () => {
  let baseDoc = `store.id in [${documentStores.value.filter((ds: Store) => ds.ref === storeRef)
    .map((ds: Store) => `'${ds._id}'`)
    .join(",")}]`;

  if (structuredFilter.value && rawQuery.value.trim().length > 0) {
    baseDoc += ` and (${rawQuery.value})`;
  }
  gridHelper.setBaseFilter(baseDoc);
  refetch();
}, {
  immediate: true,
  deep: true,
  debounce: 200,
});

async function toggleGuidance(documentFamily: DocumentFamily) {
  await appStore.workspaceStore.toggleGuidance(documentFamily);
  refetch();
}

async function removeAllFromWorkspace() {
  await appStore.workspaceStore.removeAllFromWorkspace();
  refetch();
}
</script>

<template>
  <div>
    <div class="flex flex-col border-r">
      <div class="mb-2 flex-1 bg-gray-50 dark:bg-gray-900 px-4 py-1">
        <div class="mt-4 bg-gray-50 dark:bg-gray-900">
          <KodexaTextInput
            id="filter"
            ref="viewEl"
            v-model="rawQuery"
            v-debounce:300ms="setQuery"
            name="filter"
            :placeholder="filterPlaceholder"
          >
            <template #suffix>
              <MaterialDesignIcon
                :name="structuredFilter ? 'filter-outline' : 'magnify'" size="18" class="mx-2"
                @click="structuredFilter = !structuredFilter"
              />
            </template>
          </KodexaTextInput>
        </div>
        <div class="flex flex-wrap items-center bg-gray-50 dark:bg-gray-900  text-xs text-gray-700">
          <KodexaButton
            class="mr-1 mt-1" :icon="isRefetching ? 'refresh-circle' : 'refresh'" size="small" type="secondary"
            @click="refetch()"
          >
            Refresh
          </KodexaButton>
          <KodexaButton class="mr-1 mt-1" icon="upload" size="small" type="secondary" @click="startUpload()">
            Upload
          </KodexaButton>
          <KodexaButton
            class="mr-1 mt-1" icon="checkbox-marked-outline" size="small" type="secondary"
            @click="toggleSelection"
          >
            Select/Unselect
          </KodexaButton>
          <KodexaBulkActionButton
            class="mr-1"
            :selected-document-families="selectedDocumentFamilies"
            :document-store="documentStore" @clear-selection="clearSelection"
          />
          <KodexaButton
            v-if="documentFamilies.size > 0"
            class="mr-1 mt-1" icon="table-large-remove" size="small" type="secondary"
            @click="removeAllFromWorkspace"
          >
            Clear Data Form
          </KodexaButton>
          <KodexaButton
            class="mr-1 mt-1" :icon="forceFullView ? 'table-eye' : 'table-eye-off'" size="small" type="secondary"
            @click="forceFullView = !forceFullView"
          >
            Table View
          </KodexaButton>
        </div>
        <KodexaArticle article-id="9118458" text="Learn about document storage" :slide="false" class="py-2" />
        <div v-if="isError && structuredFilter" class="mt-4">
          <div class="p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <MaterialDesignIcon name="alert-circle-outline" class="text-red-400" size="26" />
              </div>
              <div class="ml-3">
                <h3 class="text-lg font-bold text-gray-800">
                  There was an problem with your filter
                </h3>
                <p class="text-sm text-gray-600 mt-2">
                  When you are using a structured filter you need to make sure it is correctly formed.
                </p>
                <p class="mt-4">
                  <KodexaArticle article="9274298" text="Learn more about structured filters" :slide="false" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="rawQuery !== '' && rawQuery !== '?' && !isLoading && data?.content.length === 0 && !fullView"
          class="py-14 text-center text-sm"
        >
          <MaterialDesignIcon aria-hidden="true" class="mx-auto size-6 text-gray-400" name="alertBox" />
          <p class="mt-4 font-semibold text-gray-900">
            No documents found
          </p>
          <p class="mt-2 text-gray-500">
            We could not find anything with that term. Please try again.
          </p>
        </div>
        <div
          v-show="fullView"
          ref="tableElement"
        >
          <div class="mb-2">
            <p v-show="data?.content && data?.totalElements > 0" class="text-xs text-gray-700">
              Showing
              {{ ' ' }}
              <span class="font-medium">{{ pageSettings?.skip + 1 }}</span>
              {{ ' ' }}
              to
              {{ ' ' }}
              <span class="font-medium">{{ pageSettings?.skip + data?.content.length }}</span>
              {{ ' ' }}
              of
              {{ ' ' }}
              <span class="font-medium">{{ data?.totalElements }}</span>
              {{ ' ' }}
              results
            </p>
          </div>
          <Grid
            style="height: calc(100vh - 21rem);"
            selected-field="selected"
            :data-items="data?.content"
            :pageable="gridHelper.pageSettings"
            :loader="isLoading"
            :sort="sort"
            :skip="pageSettings.skip"
            :total="pageSettings.total"
            :page-size="pageSettings.take"
            :filter="gridHelper.groupedFilters"
            :columns="columns"
            :column-menu="true"
            :resizable="true"
            :sortable="true"
            size="small"
            @pagechange="gridHelper.pageChangeHandler($event)"
            @filterchange="gridHelper.filterChange($event)"
            @sortchange="gridHelper.sortChange($event)"
          >
            <GridNoRecords>
              <KodexaGridNoRecords
                :error="error" :is-loading="isLoading" :is-error="isError"
                no-records-message="No documents found"
              />
            </GridNoRecords>
            <template #path="{ props }">
              <td>
                <KodexaDocumentFamilyCard
                  :key="props.dataItem.changeSequence + selectedDocumentFamilies.includes(props.dataItem)"
                  :document-family="props.dataItem"
                  :is-renaming="isRenaming === props.dataItem.id"
                  :show-avatar="true"
                  :show-labels="true"
                  label-size="small"
                  :selected="selectedDocumentFamilies.some(df => df.id === props.dataItem.id)"
                  size="small"
                  class="mt-1 mb-1"
                  @toggle-selected="toggleSingle(props.dataItem)"
                  @change="refetch"
                  @name-click="openDocumentFamily(props.dataItem)"
                >
                  <div class="flex">
                    <KodexaDocumentFamilyStatistics
                      :document-family="props.dataItem"
                      class="flex-1"
                      size="small"
                    />
                    <div v-if="props.dataItem.guidance" class="mr-1">
                      <span
                        class="rounded bg-orange-100 px-2 py-0.5 text-xs font-medium text-orange-800 inline-flex items-center"
                        @click="toggleGuidance(props.dataItem)"
                      >Guidance <MaterialDesignIcon name="close" size="10" class="ml-1" /></span>
                    </div>
                    <div v-if="isInWorkspace(props.dataItem)">
                      <span
                        class="rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800 inline-flex items-center"
                        @click="removeDocumentFamily(props.dataItem)"
                      >In Data Form <MaterialDesignIcon name="close" size="10" class="ml-1" /></span>
                    </div>
                  </div>
                </KodexaDocumentFamilyCard>
              </td>
            </template>
            <template #activity="{ props }">
              <td>
                <KodexaDocumentRecentActivity :document-family="props.dataItem" @changed="refetch" />
              </td>
            </template>
            <template #extractedData="{ props }">
              <td>
                <KodexaDocumentExtractedData :document-family="props.dataItem" />
              </td>
            </template>
            <template #assignees="{ props }">
              <td>
                <KodexaDocumentAssignees :document-family="props.dataItem" @change="refetch" />
              </td>
            </template>
            <template #actions="{ props }">
              <td>
                <KodexaWorkspaceDocumentMenu
                  :key="props.dataItem.id + isInWorkspace(props.dataItem)"
                  :document-family="props.dataItem" style="float:right; z-index: 1000"
                  :in-workspace="isInWorkspace(props.dataItem)"
                  @delete="openDeleteFamilyConfirm(props.dataItem)"
                  @details="openDetails(props.dataItem)"
                  @download="downloadDocumentFamily(props.dataItem)"
                  @open="openDocumentFamily(props.dataItem)"
                  @rename="isRenaming = props.dataItem.id as string"
                  @add-to-workspace="addDocumentFamily(props.dataItem)"
                  @remove-from-workspace="removeDocumentFamily(props.dataItem)"
                  @toggle-lock="toggleLock(props.dataItem)"
                  @toggle-guidance="toggleGuidance(props.dataItem)"
                />
              </td>
            </template>
            <template #modified="{ props }">
              <td>
                <KodexaDateTimeView :date="props.dataItem.modified" />
              </td>
            </template>
            <template #created="{ props }">
              <td>
                <KodexaDateTimeView :date="props.dataItem.created" />
              </td>
            </template>
            <template #size="{ props }">
              <td>
                <KodexaFileSizeView :size="props.dataItem.size" />
              </td>
            </template>
          </Grid>
        </div>
        <div v-show="!fullView">
          <Combobox>
            <div v-if="rawQuery !== '?'" class="mt-1 w-full">
              <nav aria-label="Pagination" class="flex justify-between">
                <div>
                  <p v-show="data?.content && data?.totalElements > 0" class="text-xs text-gray-700">
                    Showing
                    {{ ' ' }}
                    <span class="font-medium">{{ pageSettings?.skip + 1 }}</span>
                    {{ ' ' }}
                    to
                    {{ ' ' }}
                    <span class="font-medium">{{ pageSettings?.skip + data?.content.length }}</span>
                    {{ ' ' }}
                    of
                    {{ ' ' }}
                    <span class="font-medium">{{ data?.totalElements }}</span>
                    {{ ' ' }}
                    results
                  </p>
                </div>
                <div v-if="!isError" class="-mt-2 flex justify-end">
                  <MaterialDesignIcon
                    :class="data?.first ? 'text-gray-300' : 'text-gray-700'"
                    :disabled="data?.first" aria-hidden="true" name="chevron-left"
                    @click="!data?.first && gridHelper.previousPage()"
                  />
                  <MaterialDesignIcon
                    :class="data?.last ? 'text-gray-300' : 'text-gray-700'"
                    :disabled="data?.last" aria-hidden="true" name="chevron-right"
                    @click="!data?.last && gridHelper.nextPage()"
                  />
                </div>
              </nav>
            </div>
            <KodexaSmallLoader v-if="isLoading || (data?.totalElements === 0 && isRefetching)" class="mt-80" />
            <div v-if="!isLoading && (data && data.content)" class="flex-1 bg-gray-50 dark:bg-gray-900" style="height: calc(100vh - 20rem); overflow: auto">
              <ComboboxOptions
                class="scroll-py-10 scroll-pb-2 space-y-4 p-4 pb-2"
                static
              >
                <li v-if="data.content.length > 0">
                  <ul class="-mx-4 text-sm text-gray-700">
                    <ComboboxOption
                      v-for="documentFamily in data.content" :key="documentFamily.id"
                    >
                      <div>
                        <KodexaWorkspaceDocumentMenu
                          :key="documentFamily.id + isInWorkspace(documentFamily)"
                          :document-family="documentFamily" style="float:right;"
                          :in-workspace="isInWorkspace(documentFamily)"
                          @delete="openDeleteFamilyConfirm(documentFamily)"
                          @details="openDetails(documentFamily)"
                          @download="downloadDocumentFamily(documentFamily)"
                          @open="openDocumentFamily(documentFamily)"
                          @rename="isRenaming = documentFamily.id as string"
                          @add-to-workspace="addDocumentFamily(documentFamily)"
                          @remove-from-workspace="removeDocumentFamily(documentFamily)"
                          @toggle-lock="toggleLock(documentFamily)"
                          @toggle-guidance="toggleGuidance(documentFamily)"
                        />
                      </div>
                      <div class="mx-1 mt-1">
                        <KodexaDocumentFamilyCard
                          :document-family="documentFamily"
                          :is-renaming="isRenaming === documentFamily.id"
                          :show-avatar="true"
                          :show-labels="true"
                          label-size="small"
                          :selected="selectedDocumentFamilies.some(df => df.id === documentFamily.id)"
                          size="small"
                          @toggle-selected="toggleSingle(documentFamily)"
                          @change="refetch"
                          @click="openDocumentFamily(documentFamily)"
                        >
                          <div class="flex">
                            <KodexaDocumentFamilyStatistics
                              :document-family="documentFamily"
                              class="flex-1"
                              size="small"
                            />
                            <div v-if="documentFamily.guidance" class="mr-1">
                              <span
                                class="rounded bg-orange-100 px-2 py-0.5 text-xs font-medium text-orange-800"
                                @click="toggleGuidance(documentFamily)"
                              >Guidance <MaterialDesignIcon name="close" size="10" /></span>
                            </div>
                            <div v-if="isInWorkspace(documentFamily)">
                              <span
                                class="rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800"
                                @click="removeDocumentFamily(documentFamily)"
                              >In Data Form <MaterialDesignIcon name="close" size="10" /></span>
                            </div>
                          </div>
                        </KodexaDocumentFamilyCard>
                      </div>
                      <div>
                        <hr class="my-3 h-px border-0 bg-gray-200 dark:bg-gray-700">
                      </div>
                    </ComboboxOption>
                  </ul>
                </li>
              </ComboboxOptions>
            </div>
          </Combobox>
        </div>
      </div>
      <KodexaDocumentStoreUpload v-model="showUpload" :document-store="documentStore" @change="refetch" />
      <KodexaDocumentFamilyDetailsPopup
        v-if="selectedDocumentFamily"
        :key="selectedDocumentFamily?.id" v-model="showDetails" :document-family="selectedDocumentFamily"
      />
    </div>
  </div>
</template>

<style scoped>

</style>
