/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  DataTag,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreditStatus,
  ExceptionResponse,
  ExecuteOrganizationQuery200,
  GetAvailableAssistantsParams,
  GetAvailableModelsParams,
  GetAvailableTemplatesParams,
  Label,
  ListOrganizationsParams,
  Membership,
  ModelInteraction,
  Organization,
  OrganizationMemory,
  PageAssistantDefinition,
  PageOrganization,
  PageProjectTemplate,
  PageStore,
  ProjectTag,
  QueryRequest,
  ThrowableProblem,
  User
} from '../../model'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get a resource with the provided ID
 */
export const getOrganization = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Organization>(
      {url: `/api/organizations/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationQueryKey = (id: MaybeRef<string>,) => {
    return ['api','organizations',id] as const;
    }

    
export const getGetOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetOrganizationQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganization>>> = ({ signal }) => getOrganization(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganization>>, TError, TData> 
}

export type GetOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganization>>>
export type GetOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetOrganization<TData = Awaited<ReturnType<typeof getOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetOrganizationQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the given instance
 */
export const updateOrganization = (
    id: MaybeRef<string>,
    organization: MaybeRef<Organization>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
organization = unref(organization);
      
      return customAxios<Organization>(
      {url: `/api/organizations/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: organization
    },
      options);
    }
  


export const getUpdateOrganizationMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganization>>, TError,{id: string;data: Organization}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateOrganization>>, TError,{id: string;data: Organization}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOrganization>>, {id: string;data: Organization}> = (props) => {
          const {id,data} = props ?? {};

          return  updateOrganization(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrganization>>>
    export type UpdateOrganizationMutationBody = Organization
    export type UpdateOrganizationMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateOrganization = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganization>>, TError,{id: string;data: Organization}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateOrganization>>,
        TError,
        {id: string;data: Organization},
        TContext
      > => {

      const mutationOptions = getUpdateOrganizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteOrganization = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/organizations/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteOrganizationMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganization>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteOrganization>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteOrganization>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteOrganization(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteOrganization>>>
    
    export type DeleteOrganizationMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteOrganization = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteOrganization>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof deleteOrganization>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getDeleteOrganizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const suspend = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/organizations/${id}/suspend`, method: 'PUT'
    },
      options);
    }
  


export const getSuspendMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof suspend>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof suspend>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof suspend>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  suspend(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type SuspendMutationResult = NonNullable<Awaited<ReturnType<typeof suspend>>>
    
    export type SuspendMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useSuspend = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof suspend>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof suspend>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getSuspendMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the organization memory
 */
export const getOrganizationMemory = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<OrganizationMemory>(
      {url: `/api/organizations/${id}/memory`, method: 'GET', signal
    },
      options);
    }
  

export const getGetOrganizationMemoryQueryKey = (id: MaybeRef<string>,) => {
    return ['api','organizations',id,'memory'] as const;
    }

    
export const getGetOrganizationMemoryQueryOptions = <TData = Awaited<ReturnType<typeof getOrganizationMemory>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMemory>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetOrganizationMemoryQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrganizationMemory>>> = ({ signal }) => getOrganizationMemory(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMemory>>, TError, TData> 
}

export type GetOrganizationMemoryQueryResult = NonNullable<Awaited<ReturnType<typeof getOrganizationMemory>>>
export type GetOrganizationMemoryQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetOrganizationMemory<TData = Awaited<ReturnType<typeof getOrganizationMemory>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getOrganizationMemory>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetOrganizationMemoryQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Update the organization memory
 */
export const updateOrganizationMemory = (
    id: MaybeRef<string>,
    organizationMemory: MaybeRef<OrganizationMemory>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
organizationMemory = unref(organizationMemory);
      
      return customAxios<OrganizationMemory>(
      {url: `/api/organizations/${id}/memory`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: organizationMemory
    },
      options);
    }
  


export const getUpdateOrganizationMemoryMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationMemory>>, TError,{id: string;data: OrganizationMemory}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationMemory>>, TError,{id: string;data: OrganizationMemory}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOrganizationMemory>>, {id: string;data: OrganizationMemory}> = (props) => {
          const {id,data} = props ?? {};

          return  updateOrganizationMemory(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateOrganizationMemoryMutationResult = NonNullable<Awaited<ReturnType<typeof updateOrganizationMemory>>>
    export type UpdateOrganizationMemoryMutationBody = OrganizationMemory
    export type UpdateOrganizationMemoryMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateOrganizationMemory = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOrganizationMemory>>, TError,{id: string;data: OrganizationMemory}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof updateOrganizationMemory>>,
        TError,
        {id: string;data: OrganizationMemory},
        TContext
      > => {

      const mutationOptions = getUpdateOrganizationMemoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listOrganizations = (
    params?: MaybeRef<ListOrganizationsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageOrganization>(
      {url: `/api/organizations`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListOrganizationsQueryKey = (params?: MaybeRef<ListOrganizationsParams>,) => {
    return ['api','organizations', ...(params ? [params]: [])] as const;
    }

    
export const getListOrganizationsQueryOptions = <TData = Awaited<ReturnType<typeof listOrganizations>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListOrganizationsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listOrganizations>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListOrganizationsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listOrganizations>>> = ({ signal }) => listOrganizations(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listOrganizations>>, TError, TData> 
}

export type ListOrganizationsQueryResult = NonNullable<Awaited<ReturnType<typeof listOrganizations>>>
export type ListOrganizationsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useListOrganizations<TData = Awaited<ReturnType<typeof listOrganizations>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListOrganizationsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listOrganizations>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getListOrganizationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createOrganization = (
    organization: MaybeRef<Organization>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organization = unref(organization);
      
      return customAxios<Organization>(
      {url: `/api/organizations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: organization, signal
    },
      options);
    }
  


export const getCreateOrganizationMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganization>>, TError,{data: Organization}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createOrganization>>, TError,{data: Organization}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createOrganization>>, {data: Organization}> = (props) => {
          const {data} = props ?? {};

          return  createOrganization(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateOrganizationMutationResult = NonNullable<Awaited<ReturnType<typeof createOrganization>>>
    export type CreateOrganizationMutationBody = Organization
    export type CreateOrganizationMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateOrganization = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createOrganization>>, TError,{data: Organization}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof createOrganization>>,
        TError,
        {data: Organization},
        TContext
      > => {

      const mutationOptions = getCreateOrganizationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Execute an index query against the organization data
 */
export const executeOrganizationQuery = (
    id: MaybeRef<string>,
    queryRequest: MaybeRef<QueryRequest>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
queryRequest = unref(queryRequest);
      
      return customAxios<ExecuteOrganizationQuery200>(
      {url: `/api/organizations/${id}/executeQuery`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: queryRequest, signal
    },
      options);
    }
  


export const getExecuteOrganizationQueryMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof executeOrganizationQuery>>, TError,{id: string;data: QueryRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof executeOrganizationQuery>>, TError,{id: string;data: QueryRequest}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof executeOrganizationQuery>>, {id: string;data: QueryRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  executeOrganizationQuery(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ExecuteOrganizationQueryMutationResult = NonNullable<Awaited<ReturnType<typeof executeOrganizationQuery>>>
    export type ExecuteOrganizationQueryMutationBody = QueryRequest
    export type ExecuteOrganizationQueryMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useExecuteOrganizationQuery = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof executeOrganizationQuery>>, TError,{id: string;data: QueryRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof executeOrganizationQuery>>,
        TError,
        {id: string;data: QueryRequest},
        TContext
      > => {

      const mutationOptions = getExecuteOrganizationQueryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Checks if the organization has enough credit to run a model
 */
export const checkCredit = (
    id: MaybeRef<string>,
    modelInteraction: MaybeRef<ModelInteraction>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
modelInteraction = unref(modelInteraction);
      
      return customAxios<boolean>(
      {url: `/api/organizations/${id}/creditCheck`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: modelInteraction, signal
    },
      options);
    }
  


export const getCheckCreditMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkCredit>>, TError,{id: string;data: ModelInteraction}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof checkCredit>>, TError,{id: string;data: ModelInteraction}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof checkCredit>>, {id: string;data: ModelInteraction}> = (props) => {
          const {id,data} = props ?? {};

          return  checkCredit(id,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CheckCreditMutationResult = NonNullable<Awaited<ReturnType<typeof checkCredit>>>
    export type CheckCreditMutationBody = ModelInteraction
    export type CheckCreditMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCheckCredit = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof checkCredit>>, TError,{id: string;data: ModelInteraction}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof checkCredit>>,
        TError,
        {id: string;data: ModelInteraction},
        TContext
      > => {

      const mutationOptions = getCheckCreditMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the available credit for the organization
 */
export const getAvailableCredit = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<CreditStatus>(
      {url: `/api/organizations/${id}/availableCredit`, method: 'POST', signal
    },
      options);
    }
  


export const getGetAvailableCreditMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getAvailableCredit>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof getAvailableCredit>>, TError,{id: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getAvailableCredit>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  getAvailableCredit(id,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type GetAvailableCreditMutationResult = NonNullable<Awaited<ReturnType<typeof getAvailableCredit>>>
    
    export type GetAvailableCreditMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useGetAvailableCredit = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getAvailableCredit>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof getAvailableCredit>>,
        TError,
        {id: string},
        TContext
      > => {

      const mutationOptions = getGetAvailableCreditMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForOrganization = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/organizations/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForOrganizationQueryKey = (id: MaybeRef<string>,) => {
    return ['api','organizations',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForOrganizationQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForOrganization>>> = ({ signal }) => getChangeSequenceForOrganization(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForOrganization>>, TError, TData> 
}

export type GetChangeSequenceForOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForOrganization>>>
export type GetChangeSequenceForOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetChangeSequenceForOrganization<TData = Awaited<ReturnType<typeof getChangeSequenceForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetChangeSequenceForOrganizationQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary Get the project tags for the organization
 */
export const getAvailableProjectTags = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProjectTag[]>(
      {url: `/api/organizations/${id}/projectTags`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAvailableProjectTagsQueryKey = (id: MaybeRef<string>,) => {
    return ['api','organizations',id,'projectTags'] as const;
    }

    
export const getGetAvailableProjectTagsQueryOptions = <TData = Awaited<ReturnType<typeof getAvailableProjectTags>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableProjectTags>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAvailableProjectTagsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAvailableProjectTags>>> = ({ signal }) => getAvailableProjectTags(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAvailableProjectTags>>, TError, TData> 
}

export type GetAvailableProjectTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getAvailableProjectTags>>>
export type GetAvailableProjectTagsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>


/**
 * @summary Get the project tags for the organization
 */

export function useGetAvailableProjectTags<TData = Awaited<ReturnType<typeof getAvailableProjectTags>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableProjectTags>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetAvailableProjectTagsQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary Get the memberships for the organization
 */
export const getMemberships = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Membership[]>(
      {url: `/api/organizations/${id}/memberships`, method: 'GET', signal
    },
      options);
    }
  

export const getGetMembershipsQueryKey = (id: MaybeRef<string>,) => {
    return ['api','organizations',id,'memberships'] as const;
    }

    
export const getGetMembershipsQueryOptions = <TData = Awaited<ReturnType<typeof getMemberships>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMemberships>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetMembershipsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMemberships>>> = ({ signal }) => getMemberships(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMemberships>>, TError, TData> 
}

export type GetMembershipsQueryResult = NonNullable<Awaited<ReturnType<typeof getMemberships>>>
export type GetMembershipsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>


/**
 * @summary Get the memberships for the organization
 */

export function useGetMemberships<TData = Awaited<ReturnType<typeof getMemberships>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMemberships>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetMembershipsQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary Get the members for the organization
 */
export const getMembers = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<User[]>(
      {url: `/api/organizations/${id}/members`, method: 'GET', signal
    },
      options);
    }
  

export const getGetMembersQueryKey = (id: MaybeRef<string>,) => {
    return ['api','organizations',id,'members'] as const;
    }

    
export const getGetMembersQueryOptions = <TData = Awaited<ReturnType<typeof getMembers>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMembers>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetMembersQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMembers>>> = ({ signal }) => getMembers(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMembers>>, TError, TData> 
}

export type GetMembersQueryResult = NonNullable<Awaited<ReturnType<typeof getMembers>>>
export type GetMembersQueryError = ErrorType<ExceptionResponse | ThrowableProblem>


/**
 * @summary Get the members for the organization
 */

export function useGetMembers<TData = Awaited<ReturnType<typeof getMembers>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMembers>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetMembersQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * @summary Get the labels for the organization
 */
export const getLabels = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Label[]>(
      {url: `/api/organizations/${id}/labels`, method: 'GET', signal
    },
      options);
    }
  

export const getGetLabelsQueryKey = (id: MaybeRef<string>,) => {
    return ['api','organizations',id,'labels'] as const;
    }

    
export const getGetLabelsQueryOptions = <TData = Awaited<ReturnType<typeof getLabels>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLabels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetLabelsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getLabels>>> = ({ signal }) => getLabels(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getLabels>>, TError, TData> 
}

export type GetLabelsQueryResult = NonNullable<Awaited<ReturnType<typeof getLabels>>>
export type GetLabelsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>


/**
 * @summary Get the labels for the organization
 */

export function useGetLabels<TData = Awaited<ReturnType<typeof getLabels>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getLabels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetLabelsQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Determine if the feature flag is enabled
 */
export const isFeatureFlagEnabled = (
    id: MaybeRef<string>,
    feature: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
feature = unref(feature);
      
      return customAxios<boolean>(
      {url: `/api/organizations/${id}/featureFlags/${feature}`, method: 'GET', signal
    },
      options);
    }
  

export const getIsFeatureFlagEnabledQueryKey = (id: MaybeRef<string>,
    feature: MaybeRef<string>,) => {
    return ['api','organizations',id,'featureFlags',feature] as const;
    }

    
export const getIsFeatureFlagEnabledQueryOptions = <TData = Awaited<ReturnType<typeof isFeatureFlagEnabled>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>,
    feature: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof isFeatureFlagEnabled>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getIsFeatureFlagEnabledQueryKey(id,feature);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof isFeatureFlagEnabled>>> = ({ signal }) => isFeatureFlagEnabled(id,feature, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id) && unref(feature))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof isFeatureFlagEnabled>>, TError, TData> 
}

export type IsFeatureFlagEnabledQueryResult = NonNullable<Awaited<ReturnType<typeof isFeatureFlagEnabled>>>
export type IsFeatureFlagEnabledQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useIsFeatureFlagEnabled<TData = Awaited<ReturnType<typeof isFeatureFlagEnabled>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>,
    feature: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof isFeatureFlagEnabled>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getIsFeatureFlagEnabledQueryOptions(id,feature,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the available templates for the organization
 */
export const getAvailableTemplates = (
    id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableTemplatesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
params = unref(params);
      
      return customAxios<PageProjectTemplate>(
      {url: `/api/organizations/${id}/availableTemplates`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetAvailableTemplatesQueryKey = (id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableTemplatesParams>,) => {
    return ['api','organizations',id,'availableTemplates', ...(params ? [params]: [])] as const;
    }

    
export const getGetAvailableTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof getAvailableTemplates>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableTemplatesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableTemplates>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAvailableTemplatesQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAvailableTemplates>>> = ({ signal }) => getAvailableTemplates(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAvailableTemplates>>, TError, TData> 
}

export type GetAvailableTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getAvailableTemplates>>>
export type GetAvailableTemplatesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetAvailableTemplates<TData = Awaited<ReturnType<typeof getAvailableTemplates>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableTemplatesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableTemplates>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetAvailableTemplatesQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the available model for the organization
 */
export const getAvailableModels = (
    id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableModelsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
params = unref(params);
      
      return customAxios<PageStore>(
      {url: `/api/organizations/${id}/availableModels`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetAvailableModelsQueryKey = (id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableModelsParams>,) => {
    return ['api','organizations',id,'availableModels', ...(params ? [params]: [])] as const;
    }

    
export const getGetAvailableModelsQueryOptions = <TData = Awaited<ReturnType<typeof getAvailableModels>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableModelsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableModels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAvailableModelsQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAvailableModels>>> = ({ signal }) => getAvailableModels(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAvailableModels>>, TError, TData> 
}

export type GetAvailableModelsQueryResult = NonNullable<Awaited<ReturnType<typeof getAvailableModels>>>
export type GetAvailableModelsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetAvailableModels<TData = Awaited<ReturnType<typeof getAvailableModels>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableModelsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableModels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetAvailableModelsQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



/**
 * Get the available model for the organization
 */
export const getAvailableAssistants = (
    id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableAssistantsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
params = unref(params);
      
      return customAxios<PageAssistantDefinition>(
      {url: `/api/organizations/${id}/availableAssistants`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetAvailableAssistantsQueryKey = (id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableAssistantsParams>,) => {
    return ['api','organizations',id,'availableAssistants', ...(params ? [params]: [])] as const;
    }

    
export const getGetAvailableAssistantsQueryOptions = <TData = Awaited<ReturnType<typeof getAvailableAssistants>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableAssistantsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableAssistants>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAvailableAssistantsQueryKey(id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAvailableAssistants>>> = ({ signal }) => getAvailableAssistants(id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAvailableAssistants>>, TError, TData> 
}

export type GetAvailableAssistantsQueryResult = NonNullable<Awaited<ReturnType<typeof getAvailableAssistants>>>
export type GetAvailableAssistantsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>



export function useGetAvailableAssistants<TData = Awaited<ReturnType<typeof getAvailableAssistants>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>,
    params?: MaybeRef<GetAvailableAssistantsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAvailableAssistants>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetAvailableAssistantsQueryOptions(id,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = unref(queryOptions).queryKey as DataTag<QueryKey, TData>;

  return query;
}



